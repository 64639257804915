import React from 'react';

import { buildCssClassStringFromClassMap } from '@abb-emobility/shared/util';

import './TabbarPill.scss';

type TabbarPillProps = {
	value?: string,
	disabled?: boolean,
	currency?: string
};

export const TabbarPill = (props: TabbarPillProps) => {
	const { value, disabled = false, currency } = props;

	const pillClassMap = {
		'tabbar-pill': true,
		'tabbar-pill--disabled': disabled
	};

	return (
		<span className={buildCssClassStringFromClassMap(pillClassMap)}>
			{value} {currency}
		</span>
	);
};
