import React, { useRef, useState } from 'react';

import { InstallationPeriod, InstallerAppointment, InstallerModel } from '@abb-emobility/shared/domain-model';
import { createDateFromTimestamp, createDateRangeFromDates, DateRange, Timestamp } from '@abb-emobility/shared/domain-model-foundation';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	ButtonIcon,
	Icon,
	IconIdentifier,
	InputDate,
	InputSelect,
	InputSelectOption,
	InputTime,
	LabelPosition
} from '@abb-emobility/shared/ui-primitive';
import {
	arrayPad,
	booleanFromString,
	buildCssClassStringFromClassMap,
	getCalendarDay,
	getNextBusinessDay,
	getToday,
	Nullable,
	Optional
} from '@abb-emobility/shared/util';

import './TaskTradeAppointmentCraft.scss';

type TaskTradeAppointmentCraftItemProps = {
	earliestInstallationDate: Timestamp,
	period: Nullable<DateRange>,
	availableInstallers: Array<InstallerModel>,
	installationPeriod: InstallationPeriod,
	onRemove?: () => void,
	onChange: (installerAppointment: Nullable<InstallerAppointment>) => void,
	isFirstRow?: boolean
};

export const TaskTradeAppointmentCraftItem = (props: TaskTradeAppointmentCraftItemProps) => {

	const { onRemove, onChange, isFirstRow, availableInstallers, installationPeriod } = props;

	const initialPeriod: Nullable<DateRange> = props.period;
	const ignoreEarliestInstallationDate = booleanFromString(
		new Optional(process.env['NX_FEATURE_TOGGLE_IGNORE_EARLIEST_INSTALLATION_DATE']).getOrDefault('false')
	);
	const earliestInstallationTimestamp = createDateFromTimestamp(props.earliestInstallationDate).getTime();
	const earliestSelectableDate = ignoreEarliestInstallationDate ? getToday() : getNextBusinessDay(new Date(Math.max(earliestInstallationTimestamp, getCalendarDay(1).getTime())));

	const l10n = useL10n();

	const dayDate = useRef<Nullable<Date>>(null);
	const [timeStart, setTimeStart] = useState<Nullable<string>>(null);
	const [timeEnd, setTimeEnd] = useState<Nullable<string>>(null);

	const selectableInstallers = [...availableInstallers].sort((left, right): number => {
		if (left.name === right.name) {
			return 0;
		}
		return (left.name ?? '') < (right.name ?? '') ? -1 : 1;
	});
	const selectedInstallerId = useRef<string>(selectableInstallers?.[0].id ?? '');

	const period = useRef<Nullable<DateRange>>(initialPeriod);

	const convertedInstallerOptions = (): Array<InputSelectOption> => {
		return selectableInstallers.map((availableInstaller) => {
			return {
				label: availableInstaller.name ?? '—',
				value: availableInstaller.id
			};
		});
	};

	const handleRemove = (): void => {
		if (onRemove !== undefined) {
			onRemove();
		}
	};

	const updatePeriodDate = (date: Nullable<Date>): void => {
		dayDate.current = date;
		handleChange(date, timeStart, timeEnd);
	};

	const updatePeriodStart = (start: Nullable<string>): void => {
		setTimeStart(start);
		handleChange(dayDate.current, start, timeEnd);
	};

	const updatePeriodEnd = (end: Nullable<string>): void => {
		setTimeEnd(end);
		handleChange(dayDate.current, timeStart, end);
	};

	const updateInstaller = (installerId: string): void => {
		selectedInstallerId.current = installerId;
		handleChange(dayDate.current, timeStart, timeEnd);
	};

	const validateSelection = (): boolean => {
		if (dayDate.current === null || timeStart === null || timeEnd === null) {
			return false;
		}

		if (dayDate.current.getTime() < earliestSelectableDate.getTime()) {
			return false;
		}

		const startTime = arrayPad(timeStart.split(':'), 2, '00').map((start) => parseInt(start));
		const endTime = arrayPad(timeEnd.split(':'), 2, '00').map((start) => parseInt(start));
		const startMinutes = startTime[0] * 60 + startTime[1];
		const endMinutes = endTime[0] * 60 + endTime[1];
		return startMinutes < endMinutes;
	};

	const handleChange = (dayDate: Nullable<Date>, timeStart: Nullable<string>, timeEnd: Nullable<string>): void => {
		if (dayDate === null || timeStart === null || timeEnd === null) {
			onChange(null);
			return;
		}

		const startTime = arrayPad(timeStart.split(':'), 2, '00').map((start) => parseInt(start));
		const endTime = arrayPad(timeEnd.split(':'), 2, '00').map((start) => parseInt(start));

		const startMinutes = startTime[0] * 60 + startTime[1];
		const endMinutes = endTime[0] * 60 + endTime[1];
		if (startMinutes >= endMinutes) {
			onChange(null);
			return;
		}

		const periodStart: Date = new Date(dayDate);
		periodStart.setHours(startTime[0], startTime[1]);
		const periodEnd: Date = new Date(dayDate);
		periodEnd.setHours(endTime[0], endTime[1]);
		period.current = createDateRangeFromDates(periodStart, periodEnd);

		onChange({
			installerId: selectedInstallerId.current,
			installationPeriod,
			period: period.current
		});
	};

	const renderRow = (): React.JSX.Element => {
		return (
			<>
				<InputSelect
					label={isFirstRow ? l10n.translate('omsInstallationPartnerOfficeApp.task.component.taskTradeAppointmentCraftItem.input.installer') : undefined}
					labelPosition={LabelPosition.TOP}
					options={convertedInstallerOptions()}
					onSelect={updateInstaller}
				/>
				<InputDate
					type="date"
					label={isFirstRow ? l10n.translate('omsInstallationPartnerOfficeApp.task.component.taskTradeAppointmentCraftItem.input.date') : undefined}
					labelPosition={LabelPosition.TOP}
					icon={IconIdentifier.CALENDAR_BLANK}
					onChange={updatePeriodDate}
					min={earliestSelectableDate}
				/>
				<InputTime
					type="time"
					label={isFirstRow ? l10n.translate('omsInstallationPartnerOfficeApp.task.component.taskTradeAppointmentCraftItem.input.start') : undefined}
					labelPosition={LabelPosition.TOP}
					icon={IconIdentifier.CLOCK}
					onChange={updatePeriodStart}
					max={timeEnd ?? undefined}
				/>
				<InputTime
					type="time"
					label={isFirstRow ? l10n.translate('omsInstallationPartnerOfficeApp.task.component.taskTradeAppointmentCraftItem.input.end') : undefined}
					labelPosition={LabelPosition.TOP}
					icon={IconIdentifier.CLOCK}
					onChange={updatePeriodEnd}
					min={timeStart ?? undefined}
				/>
			</>
		);
	};

	const selectionValid = validateSelection();
	const validationIconClassMap = {
		'task-trade-appointment-craft-item__validation-icon': true,
		'task-trade-appointment-craft-item__validation-icon--valid': selectionValid,
		'task-trade-appointment-craft-item__validation-icon--invalid': !selectionValid
	};
	const validationIconIdentifier = selectionValid ? IconIdentifier.CHECK_CIRCLE : IconIdentifier.WARNING_CIRCLE;

	return (
		<div className="task-trade-appointment-craft-item">
			<div className={buildCssClassStringFromClassMap(validationIconClassMap)}>
				<Icon name={validationIconIdentifier} />
			</div>
			{renderRow()}
			<div className="task-trade-appointment-craft-item__remove-icon">
				<ButtonIcon onClick={handleRemove} disabled={onRemove === undefined}>
					<Icon name={IconIdentifier.MINUS_CIRCLE} />
				</ButtonIcon>
			</div>
		</div>
	);
};


