import { ChangeEvent, InputHTMLAttributes } from 'react';

import { buildCssClassStringFromClassMap, buildCssClassNameFromEnumValue, Nullable } from '@abb-emobility/shared/util';

import { LabelPosition } from '../../types/LabelPosition';
import { Icon } from '../icon/Icon';
import { IconIdentifier } from '../icon/Icon.enums';

import './InputTime.scss';

export type InputTimeProps = {
	label?: string,
	labelPosition?: LabelPosition,
	icon?: IconIdentifier,
	onChange?: (time: Nullable<string>) => void,
	min?: string,
	max?: string
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'min' | 'max' | 'required'>;

export function InputTime(props: InputTimeProps) {
	const {
		label,
		labelPosition = LabelPosition.TOP,
		icon,
		disabled,
		readOnly,
		onChange,
		min,
		max,
		...rest
	} = props;

	const labelClassMap = {
		'input-time__label': true,
		'input-time__label--disabled': disabled,
		'input-time__label--readonly': readOnly
	};

	const inputTextFieldClassMap = {
		'input-time__field__input': true,
		'input-time__field__input--readonly': readOnly
	};

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		if (onChange !== undefined) {
			const time = event.target.value;
			onChange(time.length > 0 ? time : null);
		}
	};

	const formatBoundary = (boundary?: string): string | undefined => {
		if (boundary === undefined || boundary.trim().length === 0) {
			return undefined;
		}
		const boundaryParts = boundary.trim().split(':');
		return boundaryParts[0].padStart(2, '0') + ':' + (boundaryParts?.[1].padStart(2, '0') ?? '00');
	};

	// render functions
	const renderLabel = () => {
		if (label === undefined) {
			return null;
		}
		return (
			<span className={`input-time__label ${buildCssClassStringFromClassMap(labelClassMap)} ${buildCssClassNameFromEnumValue(labelPosition, 'input-time__label')}`}>
				{label}
			</span>
		);
	};

	const renderIcon = () => {
		if (icon === undefined) {
			return;
		}
		return (
			<span className="input-time__field__icon">
				<Icon name={icon} />
			</span>
		);
	};

	return (
		<label className={`input-time ${buildCssClassNameFromEnumValue(labelPosition, 'input-time')}`}>
			{renderLabel()}
			<span className="input-time__field">
				<input
					type="time"
					className={`input-time__field__input ${buildCssClassStringFromClassMap(inputTextFieldClassMap)} ${buildCssClassNameFromEnumValue(labelPosition, 'input-time__field__input')}`}
					disabled={disabled}
					onChange={handleChange}
					min={formatBoundary(min)}
					max={formatBoundary(max)}
					required={true}
					{...rest}
				/>
				{renderIcon()}
			</span>
		</label>
	);
}
