import { ReactNode } from 'react';

import './ActionbarFilterContentItems.scss';

export type FilterLayoutContentItemsProps = {
	children: ReactNode
};

export function ActionbarFilterContentItems(props: FilterLayoutContentItemsProps) {

	const { children } = props;

	return (
		<section className="filter-layout-content-items">
			{children}
		</section>
	);
}
