import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	usertaskDataProvider: {
		error: {
			customerCollectionFetchMessage: 'While loading the customer data an error occurred.',
			customerEntityFetchMessage: 'While loading the customer details an error occurred.',
			taskFetchMessage: 'While loading the task details an error occurred.',
			taskSearchMessage: 'While searching for a task an error occurred.',
			taskCollectionFetchMessage: 'While loading the task data an error occurred.',
			orderFetchMessage: 'While loading the order details an error occurred.',
			orderCollectionFetchMessage: 'While loading the order data an error occurred.',
			orderEventCollectionFetchMessage: 'While loading the order history an error occurred.',
			orderEventEntityFetchMessage: 'While loading the order event an error occurred.',
			orderShipmentCollectionFetchMessage: 'While loading the shipment data an error occurred.',
			orderAccountingCollectionFetchMessage: 'While loading the account data an error occurred.',
			orderFilesCollectionFetchMessage: 'While loading the files an error occurred.',
			orderInstallationCollectionFetchMessage: 'While loading the installation data an error occurred.',
			orderNotesCollectionFetchMessage: 'While loading the notes an error occurred.',
			orderInformationCollectionFetchMessage: 'While loading the order information an error occurred.',
			installerAppointmentCollectionFetchMessage: 'While loading the appoinemnts an error occurred.',
			reportCollectionFetchMessage: 'While loading the reports an error occurred.',
			searchCollectionFetchMessage: 'While searching for data an error occurred.',
			orderPaymentCollectionFetchMessage: 'While loading the payment data an error occurred.',
			orderInformationEntityFetchMessage: 'While loading the order information details an error occurred.'
		}
	}
};

export default literals;
