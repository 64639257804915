import React from 'react';

import { InstallerAppointmentModel } from '@abb-emobility/oms/domain-model';
import { createAccessTokenLoader, useAuth } from '@abb-emobility/shared/auth-provider';
import { FetchError } from '@abb-emobility/shared/business-error';
import {
	CollectionDataProviderProps,
	createCrudCollectionDataProviderValue,
	FetchMode,
	useDataProviderFetchFailedHandler,
	useDataProviderFetchInit,
	useDataProviderFetchSuspense
} from '@abb-emobility/shared/data-provider-foundation';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { useEnv } from '@abb-emobility/shared/environment';
import { AppError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { Optional } from '@abb-emobility/shared/util';

import { orderInstallerAppointmentCollectionData } from './OrderInstallerAppointmentCollectionData';
import { createFilterCriteria, createSortCriteria } from './OrderInstallerAppointmentCollectionDataProvider.util';
import {
	OrderInstallerAppointmentCollectionStore,
	orderInstallerAppointmentCollectionStoreAccessors,
	orderInstallerAppointmentCollectionStoreName
} from './OrderInstallerAppointmentCollectionSlice';

type OrderInstallerAppointmentCollectionDataProviderProps = {
	orderId: ModelPrimaryKey
} & Omit<CollectionDataProviderProps<InstallerAppointmentModel>, 'sortCriteria' | 'filterCriteria'>;

/**
 * Uses defaultSortCriteria if they are not provided.
 */
export function OrderInstallerAppointmentCollectionDataProvider(props: OrderInstallerAppointmentCollectionDataProviderProps) {
	const {
		fetchMode = FetchMode.IMMEDIATE,
		suspense = fetchMode === FetchMode.IMMEDIATE,
		pendingComponent = null,
		forceFetch,
		orderId
	} = props;

	const auth = useAuth();
	const env = useEnv();
	const l10n = useL10n();

	const apiBaseUrl = new Optional(process.env['NX_WALLBOX_INSTALLATION_API_BASE_URL'])
		.getOrThrow(new AppError('API base URL unavailable'));

	const dataProviderValue = createCrudCollectionDataProviderValue<InstallerAppointmentModel, OrderInstallerAppointmentCollectionStore>(
		orderInstallerAppointmentCollectionStoreName,
		orderInstallerAppointmentCollectionStoreAccessors,
		apiBaseUrl,
		createAccessTokenLoader(auth, env)
	);

	useDataProviderFetchFailedHandler(dataProviderValue, new FetchError(l10n.translate('omsDataProvider.error.installerAppointmentCollectionFetchMessage')));

	useDataProviderFetchInit(fetchMode, () => dataProviderValue.fetch(createSortCriteria(), createFilterCriteria(orderId), forceFetch));

	return useDataProviderFetchSuspense(dataProviderValue, suspense, pendingComponent).getOrDefault(
		<orderInstallerAppointmentCollectionData.Provider value={dataProviderValue}>
			{props.children}
		</orderInstallerAppointmentCollectionData.Provider>
	);

}
