import React, { ReactNode } from 'react';

import './CollectionItem.scss';

export type CollectionItemProps = {
	children: ReactNode
};

export function CollectionItem(props: CollectionItemProps) {

	const { children } = props;

	return (
		<article className="collection-item">
			{children}
		</article>
	);
}
