import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import React, { ReactNode, useEffect, useRef, useState } from 'react';

import { deregisterFocusTrap, KeyboardEventValues, registerFocusTrap } from '@abb-emobility/shared/browser';
import { Nullable } from '@abb-emobility/shared/util';

import { modalDialogueContext } from './ModalDialogue.context';
import { ModalDialogueSpec } from './ModalDialogue.types';
import { ButtonIcon } from '../button-icon/ButtonIcon';
import { Icon } from '../icon/Icon';
import { IconIdentifier } from '../icon/Icon.enums';
import { useModalDialogueManager } from '../modal-dialogue-manager/ModalDialogueManager.context';

import './ModalDialogue.scss';

export type ModalDialogueProps = {
	dialogueSpec: ModalDialogueSpec,
	stackPosition: number
};

export function ModalDialogue(props: ModalDialogueProps) {

	const { dialogueSpec, stackPosition } = props;

	const modalDialogueManager = useModalDialogueManager();

	const scrollableElementRef = useRef<HTMLDivElement>(null);
	let scrollableElement: Nullable<HTMLDivElement> = null;

	const focusableElementRef = useRef<HTMLDivElement>(null);

	const [dialogueCaption, setDialogueCaption] = useState<Nullable<string>>(dialogueSpec.caption ?? null);
	const [dialogueHeader, setDialogueHeader] = useState<ReactNode>(dialogueSpec.header ? dialogueSpec.header() : null);
	const [dialogueContent, setDialogueContent] = useState<ReactNode>(dialogueSpec.content());
	const [dialogueFooter, setDialogueFooter] = useState<ReactNode>(dialogueSpec.footer ? dialogueSpec.footer() : null);

	useEffect(() => {
		scrollableElement = scrollableElementRef.current;
		if (scrollableElement !== null) {
			disableBodyScroll(scrollableElement);
		}
		return () => {
			if (scrollableElement !== null) {
				enableBodyScroll(scrollableElement);
			}
		};
	}, [scrollableElementRef]);

	useEffect(() => {
		if (focusableElementRef.current !== null) {
			registerFocusTrap(focusableElementRef.current);
		}
		return deregisterFocusTrap;
	}, [focusableElementRef]);

	useEffect(() => {
		window.addEventListener('keyup', handleKeyPress);
		return () => {
			window.removeEventListener('keyup', handleKeyPress);
		};
	}, []);

	const handleKeyPress = (event: KeyboardEvent) => {
		if (event.key === 'Escape' satisfies KeyboardEventValues) {
			modalDialogueManager.pop();
		}
	};

	const modalDialogueContextValue = {
		setCaption: (caption: string): void => {
			setDialogueCaption(caption);
		},
		unsetCaption: (): void => {
			setDialogueCaption(null);
		},
		setHeader: (header: ReactNode): void => {
			setDialogueHeader(header);
		},
		unsetHeader: (): void => {
			setDialogueHeader(null);
		},
		setContent: (content: ReactNode): void => {
			setDialogueContent(content);
		},
		setFooter: (footer: ReactNode): void => {
			setDialogueFooter(footer);
		},
		unsetFooter: (): void => {
			setDialogueFooter(null);
		}
	};

	const renderDialogueHeader = (): ReactNode => {
		if (dialogueCaption !== null) {
			return (
				<header className="modal-dialogue__content__header">
					<h1 className="modal-dialogue__content__header__caption">
						{dialogueCaption}
						<ButtonIcon onClick={modalDialogueManager.pop}>
							<Icon name={IconIdentifier.X} />
						</ButtonIcon>
					</h1>
				</header>
			);
		}
		if (dialogueHeader !== null) {
			return (
				<header className="modal-dialogue__content__header">
					{dialogueHeader}
				</header>
			);
		}
		return null;
	};

	const renderDialogueFooter = (): ReactNode => {
		if (dialogueFooter === null) {
			return null;
		}
		return (
			<footer className="modal-dialogue__content__footer">
				{dialogueFooter}
			</footer>
		);
	};

	const dialogueStyle: React.CSSProperties = {
		zIndex: stackPosition
	};

	const invertedStackPosition = modalDialogueManager.size() - 1 - stackPosition;

	const scale = 1 - invertedStackPosition * 0.03;
	const dialogueContentStyle: React.CSSProperties = {
		transform: 'translateX(-50%) translateY(' + (invertedStackPosition * -10) + 'px) scale(' + scale + ')',
		filter: 'blur(' + (invertedStackPosition * 0.2) + 'px)'
	};

	return (
		<div className="modal-dialogue" key={stackPosition} style={dialogueStyle}>
			<div className="modal-dialogue__backdrop" onClick={modalDialogueManager.pop} />
			<modalDialogueContext.Provider value={modalDialogueContextValue}>
				<article className="modal-dialogue__content" style={dialogueContentStyle} ref={focusableElementRef}>
					{renderDialogueHeader()}
					<main className="modal-dialogue__content__main">
						<div className="modal-dialogue__content__main__scrollable" ref={scrollableElementRef}>
							{dialogueContent}
						</div>
					</main>
					{renderDialogueFooter()}
				</article>
			</modalDialogueContext.Provider>
		</div>
	);

}
