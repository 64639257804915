import React from 'react';

import { FilterCriteria, SingleValueFilterComparator } from '@abb-emobility/shared/api-integration-foundation';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { UiErrorHandler } from '@abb-emobility/shared/ui-error-handler';
import { CollectionItemContent, SpinnerCircle } from '@abb-emobility/shared/ui-primitive';
import { AnyTaskEntitySearchDataProvider } from '@abb-emobility/usertask/data-provider';
import { AnyTaskModel, TaskType } from '@abb-emobility/usertask/domain-model';

import { TaskSearchModalResolver } from './TaskSearchModalResolver';

type TaskSearchModalAssemblerProps = {
	orderId: ModelPrimaryKey,
	taskType: TaskType
};

export function TaskSearchModalAssembler(props: TaskSearchModalAssemblerProps) {

	const { orderId, taskType } = props;

	const filterCriteria: FilterCriteria<AnyTaskModel> = [
		{
			criteria: [
				{
					comparator: SingleValueFilterComparator.EQUAL,
					property: 'orderId',
					value: orderId
				},
				{
					comparator: SingleValueFilterComparator.EQUAL,
					property: 'type',
					value: taskType
				}
			],
			id: 'task'
		}
	];

	return (
		<CollectionItemContent>
			<UiErrorHandler>
				<AnyTaskEntitySearchDataProvider
					filterCriteria={filterCriteria}
					pendingComponent={SpinnerCircle}
				>
					<TaskSearchModalResolver />
				</AnyTaskEntitySearchDataProvider>
			</UiErrorHandler>
		</CollectionItemContent>
	);
}
