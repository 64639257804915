import { useContext } from 'react';

import { TaskCollectionDataProviderValue } from '@abb-emobility/shared/data-provider-foundation';
import { AnyTaskCollectionItemModel } from '@abb-emobility/usertask/domain-model';

import { taskCollectionData } from './TaskCollectionData';

export const useTaskCollectionData = (): TaskCollectionDataProviderValue<AnyTaskCollectionItemModel> => {
	return useContext(taskCollectionData);
};
