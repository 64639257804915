import React, { useEffect } from 'react';

import { JsonRestRequestFactory, JsonTransportValue } from '@abb-emobility/shared/api-integration-foundation';
import { createAccessTokenLoader, useAuth } from '@abb-emobility/shared/auth-provider';
import { useEnv } from '@abb-emobility/shared/environment';
import { AnyErrorObject, WebSocketError } from '@abb-emobility/shared/error';
import { LogLevel, useLogger } from '@abb-emobility/shared/logger';
import { Nullable, Optional } from '@abb-emobility/shared/util';

import { websocketContext } from './WebsocketContext';
import { RemoveEventListenerCallback, WebsocketMessageEventListener, WebsocketSimpleEventListener } from '../websocket/WebsocketEvents';
import { WebsocketInstrument } from '../websocket/WebsocketInstrument';
import { WebsocketState } from '../websocket/WebsocketState';

export type WebsocketProviderErrorProps = {
	error: Nullable<Error | AnyErrorObject>
};

export type WebsocketProviderProps = {
	socketUrl: string,
	onError: (error: WebSocketError) => void,
	children: React.ReactNode
};

type WebsocketOtpResponse = {
	otp?: string
};

export function WebsocketProvider<Topic extends string, Message extends JsonTransportValue>(props: WebsocketProviderProps) {
	const { socketUrl, onError = null } = props;

	const env = useEnv();
	const auth = useAuth();
	const logger = useLogger();

	const stompEnabled = process.env['NX_STOMP_ENABLED'] === 'true';
	const stompDebug = process.env['NX_STOMP_DEBUG'] === 'true';

	useEffect(() => {
		if (!stompEnabled) {
			return;
		}

		const removeErrorListener = WebsocketInstrument.get().addErrorListener(() => {
			if (onError !== null) {
				onError(new WebSocketError('An error on the webscocket connection occurred'));
			}
		});

		const websocketConnect = async (): Promise<void> => {
			const connectOtpLoader = async (): Promise<Nullable<string>> => {
				try {
					const accessTokenLoader = createAccessTokenLoader(auth, env);
					const apiBaseUrl = new Optional(process.env['NX_FRONTEND_NOTIFICATION_API_BASE_URL'])
						.getOrThrow(new WebSocketError('frontendNotificationApiBaseUrl unavailable'));
					const endpointUrl = apiBaseUrl + 'otp';
					const result = await JsonRestRequestFactory.create(await accessTokenLoader()).post(endpointUrl, {});
					return (result.body as WebsocketOtpResponse)?.otp ?? null;
				} catch (e) {
					logger?.logError(new WebSocketError('Receiving an OTP failed'), LogLevel.WARN);
					return null;
				}
			};

			try {
				WebsocketInstrument.get().connect(socketUrl, connectOtpLoader, stompDebug);
			} catch (e) {
				logger?.logError(new WebSocketError('Opening a websocket connection failed'), LogLevel.WARN);
			}
		};

		void websocketConnect();

		return () => {
			removeErrorListener();
			WebsocketInstrument.get().close();
		};
	}, []);

	const websocketContextValue = {
		provisioned: true,
		getState(): WebsocketState {
			return WebsocketInstrument.get().getState();
		},
		isOpened(): boolean {
			return WebsocketInstrument.get().isOpened();
		},
		subscribe(topic: Topic, listener: WebsocketMessageEventListener<Message>, listenerId?: string): string {
			return WebsocketInstrument.get().subscribe(topic, listener, listenerId);
		},
		unsubscribe(topic: Topic, subscriptionId: string): void {
			WebsocketInstrument.get().unsubscribe(topic, subscriptionId);
		},
		addOpenListener(listener: WebsocketSimpleEventListener): RemoveEventListenerCallback {
			return WebsocketInstrument.get().addOpenListener(listener);
		},
		addCloseListener(listener: WebsocketSimpleEventListener): RemoveEventListenerCallback {
			return WebsocketInstrument.get().addCloseListener(listener);
		}
	};

	return (
		<websocketContext.Provider value={websocketContextValue}>
			{props.children}
		</websocketContext.Provider>
	);
}
