import { ReactNode } from 'react';

import { ProgressState } from '@abb-emobility/shared/domain-model';
import { Icon, IconIdentifier, IconSize } from '@abb-emobility/shared/ui-primitive';
import { buildCssClassNameFromEnumValue } from '@abb-emobility/shared/util';

import './DetailProgressStep.scss';

type ProgressStepProps = {
	status: ProgressState,
	label: string,
	index: number
};

export function ProgressStep(props: ProgressStepProps) {
	const { status = ProgressState.PENDING, label, index } = props;

	const statusClassName = buildCssClassNameFromEnumValue(status, 'detail-progress-step');

	const renderCircleContent = (): ReactNode => {
		switch (status) {
			case ProgressState.DONE:
				return (
					<span className="detail-progress-step__circle__check">
						<Icon name={IconIdentifier.CHECK} size={IconSize.SMALL} />
					</span>
				);
		}
		return (
			<span className="detail-progress-step__circle__digit">
				{String(index + 1)}
			</span>
		);
	};

	return (
		<div className={`detail-progress-step ${statusClassName}`}>
			<span className="detail-progress-step__circle">
				{renderCircleContent()}
			</span>
			<span className="detail-progress-step__label">{label}</span>
		</div>
	);
}
