import { useNavigate } from 'react-router-dom';

import { useL10n } from '@abb-emobility/shared/localization-provider';
import { usePageTitle } from '@abb-emobility/shared/react';
import { useBreadcrumbModifier, ErrorFeedback, ErrorFeedbackAction } from '@abb-emobility/shared/ui-primitive';

import { useBuildPageTitle } from '../../components/page-title/PageTitle.hook';
import { RoutePath, useRouteUrl } from '../../router/Routes';

export function NotFoundView() {

	const l10n = useL10n();
	const navigate = useNavigate();
	usePageTitle(useBuildPageTitle('omsInstallationPartnerOfficeApp.pageTitle.notFound'));
	useBreadcrumbModifier([]);
	const { createRouteUrl } = useRouteUrl();

	const reloadAction: ErrorFeedbackAction = {
		label: l10n.translate('omsInstallationPartnerOfficeApp.error.notFound.resolveAction.label'),
		onInvoke: (): void => {
			navigate(createRouteUrl(RoutePath.ROOT));
		}
	};

	return (
		<ErrorFeedback
			heading={l10n.translate('omsInstallationPartnerOfficeApp.error.notFound.heading')}
			message={l10n.translate('omsInstallationPartnerOfficeApp.error.notFound.message')}
			actions={[reloadAction]}
		/>
	);
}
