import React, { Fragment, useRef } from 'react';

import { useL10n } from '@abb-emobility/shared/localization-provider';
import { useForceRerender } from '@abb-emobility/shared/react';
import { l10nLiteralFromEnumValue, l10nLiteralFromModelMember } from '@abb-emobility/shared/util';

import { FilterOptionGroup } from './CollectionGroupedFilter.types';
import { ActionbarFilter } from '../actionbar/actionbar-filter/ActionbarFilter';
import { ActionbarFilterContent } from '../actionbar/actionbar-filter/actionbar-filter-content/ActionbarFilterContent';
import {
	ActionbarFilterContentFooter
} from '../actionbar/actionbar-filter/actionbar-filter-content/actionbar-filter-content-footer/ActionbarFilterContentFooter';
import {
	ActionbarFilterContentFooterReset
} from '../actionbar/actionbar-filter/actionbar-filter-content/actionbar-filter-content-footer/actionbar-filter-content-footer-reset/ActionbarFilterContentFooterReset';
import {
	ActionbarFilterContentItems
} from '../actionbar/actionbar-filter/actionbar-filter-content/actionbar-filter-content-items/ActionbarFilterContentItems';
import {
	ActionbarFilterContentSeparator
} from '../actionbar/actionbar-filter/actionbar-filter-content/actionbar-filter-content-separator/ActionbarFilterContentSeparator';
import { ActionbarFilterHeader } from '../actionbar/actionbar-filter/actionbar-filter-header/ActionbarFilterHeader';
import { InputCheckbox } from '../input-checkbox/InputCheckbox';
import { Separator } from '../separator/Separator';

type CollectionGroupedFilterProps<FilterOption extends string, Model> = {
	filterOptions: Array<FilterOption>,
	filterOptionGroups: Array<FilterOptionGroup<FilterOption, Model>>,
	onApply: (filterOptions: Array<FilterOption>) => void
};

export function CollectionGroupedFilter<FilterOption extends string, Model>(props: CollectionGroupedFilterProps<FilterOption, Model>) {

	const { filterOptions, filterOptionGroups, onApply } = props;

	const l10n = useL10n();
	const rerender = useForceRerender();

	const selectionChanged = useRef<boolean>(false);
	const selectedFilters = useRef<Array<FilterOption>>(filterOptions);

	React.useEffect(() => {
		selectedFilters.current = filterOptions;
		rerender.invoke();
	}, [filterOptions]);

	const handleOpen = () => {
		selectionChanged.current = false;
	};

	const handleFilter = (checked: boolean, filterOption?: FilterOption) => {
		if (filterOption !== undefined) {
			const filters = [...selectedFilters.current].filter((selectedFilter) => {
				return selectedFilter !== filterOption;
			});
			if (checked) {
				filters.push(filterOption);
			}
			selectionChanged.current = true;
			selectedFilters.current = filters;
		}
	};

	const handleReset = () => {
		selectionChanged.current = true;
		selectedFilters.current = [];
		rerender.invoke();
	};

	const handleApply = () => {
		if (!selectionChanged.current) {
			return;
		}
		onApply(selectedFilters.current);
	};

	const renderFilterOptions = () => {
		return filterOptionGroups.map((availableFilterGroup) => {
			const filterOptions = availableFilterGroup.options.map((filterCriteriaRuleValue) => {
					return (
						<InputCheckbox
							defaultChecked={selectedFilters.current.includes(filterCriteriaRuleValue as FilterOption)}
							label={l10n.translate(l10nLiteralFromEnumValue(filterCriteriaRuleValue as string, 'sharedUiPrimitive.collection.filter.options'))}
							value={filterCriteriaRuleValue as FilterOption}
							onChange={handleFilter}
							key={filterCriteriaRuleValue}
						/>
					);
				}
			);
			return (
				<Fragment key={availableFilterGroup.property as string}>
					<ActionbarFilterContentSeparator
						label={l10n.translate(l10nLiteralFromModelMember(availableFilterGroup.property as string, 'sharedUiPrimitive.collection.filter.group'))}
					/>
					{filterOptions}
				</Fragment>
			);
		});
	};

	let filterCriteriaDisplayValue = l10n.translate('sharedUiPrimitive.collection.filter.none');
	if (filterOptions.length > 0) {
		filterCriteriaDisplayValue = l10n.translate(
			'sharedUiPrimitive.collection.filter.active',
			new Map([['count', l10n.formatNumber(filterOptions.length, 0)]])
		);
	}

	return (
		<ActionbarFilter>

			<ActionbarFilterHeader
				label={l10n.translate('sharedUiPrimitive.collection.filter.filter')}
				value={filterCriteriaDisplayValue}
				onOpen={handleOpen}
			/>

			<ActionbarFilterContent onClose={handleApply} key={String(rerender.key())}>
				<ActionbarFilterContentItems>
					{renderFilterOptions()}
				</ActionbarFilterContentItems>

				<Separator />

				<ActionbarFilterContentFooter>
					<ActionbarFilterContentFooterReset label={l10n.translate('sharedUiPrimitive.collection.filter.resetFilter')} onClick={handleReset} />
				</ActionbarFilterContentFooter>

			</ActionbarFilterContent>
		</ActionbarFilter>
	);
}
