import { useEffect, useRef, useState } from 'react';

import { CommandApiClient, HypermediaLink } from '@abb-emobility/shared/api-integration-foundation';
import { useAuth } from '@abb-emobility/shared/auth-provider';
import { ModelConverter, ModelValue } from '@abb-emobility/shared/domain-model-foundation';
import { AppError, AuthenticationFailedError, AuthenticationRequiredError } from '@abb-emobility/shared/error';
import { combineUrl, Nullable, Optional } from '@abb-emobility/shared/util';

import { CommandStatus } from './CommandData.enums';

type CommandData<FetchModel, InvocationModel> = {
	fetch: () => void,
	query: () => Nullable<FetchModel>,
	queryFetchStatus: () => CommandStatus,
	queryFetchError: () => Nullable<Error>,
	invoke: (payload: InvocationModel) => void
	queryInvocationError: () => Nullable<Error>,
	queryInvocationStatus: () => CommandStatus
};

export function useCommandData<FetchModel extends Record<string, ModelValue>, InvocationModel extends Record<string, ModelValue>>(
	hypermediaLink: HypermediaLink,
	fetchModelConverter: ModelConverter<FetchModel>,
	invocationModelConverter: ModelConverter<InvocationModel>,
	initialFetch = true
): CommandData<FetchModel, InvocationModel> {

	const auth = useAuth();

	const [fetchCommandRequested, setFetchCommandRequested] = useState(initialFetch);
	const [fetchCommandStatus, setFetchCommandStatus] = useState<CommandStatus>(CommandStatus.IDLE);
	const [fetchCommandPayload, setCommandPayload] = useState<Nullable<FetchModel>>(null);
	const [fetchError, setFetchError] = useState<Nullable<Error>>(null);

	const [invokeCommandRequested, setInvokeCommandRequested] = useState(false);
	const [invokeCommandStatus, setInvokeCommandStatus] = useState<CommandStatus>(CommandStatus.IDLE);
	const [invokeCommandError, setInvokeCommandError] = useState<Nullable<Error>>(null);
	const commandInvocationPayload = useRef<Partial<InvocationModel>>({});

	const commandApiBaseUrl = new Optional(process.env['NX_COMMAND_API_BASE_URL'])
		.getOrThrow(new AppError('No command API base URL defined in environment'));

	const commandApiEndpointUrl = combineUrl(commandApiBaseUrl, hypermediaLink.link);

	useEffect(() => {
		if (!fetchCommandRequested) {
			return;
		}
		setFetchCommandRequested(false);
		const fetchCommandPayload = async (): Promise<void> => {
			let errorOccured = false;
			setFetchCommandStatus(CommandStatus.PENDING);
			try {
				const commandApiClient = new CommandApiClient<FetchModel, InvocationModel>(
					commandApiEndpointUrl, fetchModelConverter, invocationModelConverter, auth.getToken().get()
				);
				const payload = await commandApiClient.fetchPayload();
				setCommandPayload(payload);
			} catch (error) {
				errorOccured = true;
				if (error instanceof Error) {
					if (error instanceof AuthenticationFailedError || error instanceof AuthenticationRequiredError) {
						throw error;
					}
					setFetchError(error);
					setFetchCommandStatus(CommandStatus.FAILED);
				}
			} finally {
				if (!errorOccured) {
					setFetchCommandStatus(CommandStatus.SUCCESSFULL);
				}
			}
		};
		void fetchCommandPayload();
	}, [fetchCommandRequested]);

	useEffect(() => {
		if (!invokeCommandRequested) {
			return;
		}
		const invokeCommand = async (): Promise<void> => {
			let errorOccured = false;
			setInvokeCommandStatus(CommandStatus.PENDING);
			try {
				const commandApiClient = new CommandApiClient<FetchModel, InvocationModel>(
					commandApiEndpointUrl, fetchModelConverter, invocationModelConverter, auth.getToken().get()
				);
				await commandApiClient.invoke(commandInvocationPayload.current as InvocationModel);
			} catch (error) {
				errorOccured = true;
				if (error instanceof Error) {
					if (error instanceof AuthenticationFailedError || error instanceof AuthenticationRequiredError) {
						throw error;
					}
					setInvokeCommandError(error);
					setInvokeCommandStatus(CommandStatus.FAILED);
				}
			} finally {
				if (!errorOccured) {
					setInvokeCommandStatus(CommandStatus.SUCCESSFULL);
				}
			}
		};
		void invokeCommand();
	}, [invokeCommandRequested]);

	return {
		fetch: () => {
			setFetchCommandRequested(true);
		},
		query: () => {
			return fetchCommandPayload;
		},
		queryFetchStatus: () => {
			return fetchCommandStatus;
		},
		queryFetchError: (): Nullable<Error> => {
			return fetchError;
		},
		invoke: (payload: InvocationModel) => {
			commandInvocationPayload.current = payload;
			setInvokeCommandRequested(true);
			return;
		},
		queryInvocationError: (): Nullable<Error> => {
			return invokeCommandError;
		},
		queryInvocationStatus: (): CommandStatus => {
			return invokeCommandStatus;
		}
	};
}
