import { HypermediaLink } from '@abb-emobility/shared/api-integration-foundation';

export type HypermediaAction = {
	hypermediaLink: HypermediaLink
};

export const createHypermediaActionFromHypermediaLink = (hypermediaLink: HypermediaLink): HypermediaAction => {
	return {
		hypermediaLink: hypermediaLink
	};
};
