import React from 'react';

import { FilterCriteria, SortCriteria } from '@abb-emobility/shared/api-integration-foundation';
import { useFilterCriteriaStorage, useSortCriteriaStorage } from '@abb-emobility/shared/data-provider-util';
import { Card } from '@abb-emobility/shared/ui-primitive';
import { TaskCollectionDataProviderProvider, taskCollectionStoreName, useTaskCollectionData } from '@abb-emobility/usertask/data-provider';
import { AnyTaskCollectionItemModel } from '@abb-emobility/usertask/domain-model';

import { TaskCollection } from './TaskCollection';

export function TaskCollectionDataAssembler() {

	const taskCollectionDataProvider = useTaskCollectionData();

	const filterCriteriaStorage = useFilterCriteriaStorage<AnyTaskCollectionItemModel>(taskCollectionStoreName);
	const sortCriteriaStorage = useSortCriteriaStorage<AnyTaskCollectionItemModel>(taskCollectionStoreName);

	const handleFilterChange = (filterCriteria: FilterCriteria<AnyTaskCollectionItemModel>): void => {
		filterCriteriaStorage.writeFilterCriteria(filterCriteria);
	};

	const handleSortChange = (sortCriteria: SortCriteria<AnyTaskCollectionItemModel>): void => {
		sortCriteriaStorage.writeSortCriteria(sortCriteria);
	};

	return (
		<Card>
			<TaskCollectionDataProviderProvider dataProvider={taskCollectionDataProvider}>
				<TaskCollection onFilterChange={handleFilterChange} onSortChange={handleSortChange} />
			</TaskCollectionDataProviderProvider>
		</Card>
	);
}
