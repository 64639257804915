import React from 'react';

import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { UiErrorHandler } from '@abb-emobility/shared/ui-error-handler';
import { CollectionItemContent, SpinnerCircle } from '@abb-emobility/shared/ui-primitive';
import { AnyTaskEntityDataProvider } from '@abb-emobility/usertask/data-provider';

import { TaskFactory } from '../task-factory/TaskFactory';

type TaskModalAssemblerProps = {
	taskId: ModelPrimaryKey
};

export function TaskModalAssembler(props: TaskModalAssemblerProps) {

	const { taskId } = props;

	return (
		<CollectionItemContent>
			<UiErrorHandler>
				<AnyTaskEntityDataProvider
					primaryKey={taskId}
					pendingComponent={SpinnerCircle}
				>
					<TaskFactory />
				</AnyTaskEntityDataProvider>
			</UiErrorHandler>
		</CollectionItemContent>
	);
}
