import { BreadcrumbEntry } from './Breadcrumbs.types';

export const buildBreadcrumbEntriesChecksum = (entries: Array<BreadcrumbEntry>): string => {
	return entries
		.map<string>((entry) => {
			return buildBreadcrumbEntryChecksum(entry);
		}, '')
		.join(':');
};

export const buildBreadcrumbEntryChecksum = (entry: BreadcrumbEntry): string => {
	return entry.link + '_' + entry.label;
};
