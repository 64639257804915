import React from 'react';

import { createAccessTokenLoader, useAuth } from '@abb-emobility/shared/auth-provider';
import { FetchError } from '@abb-emobility/shared/business-error';
import {
	CollectionDataProviderProps,
	createTaskCollectionDataProviderValue,
	FetchMode,
	useDataProviderFetchFailedHandler,
	useDataProviderFetchInit,
	useDataProviderFetchSuspense,
	useDataProviderWebsocketHandler
} from '@abb-emobility/shared/data-provider-foundation';
import { useEnv } from '@abb-emobility/shared/environment';
import { AppError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { Optional } from '@abb-emobility/shared/util';
import { AnyTaskCollectionItemModel, TaskStatus } from '@abb-emobility/usertask/domain-model';
import { WebsocketEvent, WebsocketMessage, WebsocketTopic } from '@abb-emobility/usertask/websocket';

import { taskCollectionData } from './TaskCollectionData';
import { TaskCollectionCrudStore, taskCollectionStoreAccessors, taskCollectionStoreName } from './TaskCollectionSlice';

export function TaskCollectionDataProvider(props: CollectionDataProviderProps<AnyTaskCollectionItemModel>) {
	const {
		fetchMode = FetchMode.IMMEDIATE,
		suspense = fetchMode === FetchMode.IMMEDIATE,
		pendingComponent = null,
		forceFetch
	} = props;

	const env = useEnv();
	const auth = useAuth();
	const l10n = useL10n();

	const apiBaseUrl = new Optional(process.env['NX_USER_TASK_MANAGEMENT_API_BASE_URL'])
		.getOrThrow(new AppError('API base URL unavailable'));

	const dataProviderValue = createTaskCollectionDataProviderValue<AnyTaskCollectionItemModel, TaskCollectionCrudStore>(
		taskCollectionStoreName,
		taskCollectionStoreAccessors,
		apiBaseUrl,
		createAccessTokenLoader(auth, env)
	);

	useDataProviderFetchFailedHandler(dataProviderValue, new FetchError(l10n.translate('usertaskDataProvider.error.taskCollectionFetchMessage')));

	const initialSortCriteria = props.sortCriteria;
	const initialFilterCriteria = props.filterCriteria;
	const sortCriteria = dataProviderValue.querySortCriteria();
	const filterCriteria = dataProviderValue.queryFilterCriteria();
	const effectiveSortCriteria = sortCriteria ?? initialSortCriteria;
	const effectiveFilterCriteria = filterCriteria ?? initialFilterCriteria;
	useDataProviderFetchInit(fetchMode, () => dataProviderValue.fetch(effectiveSortCriteria, effectiveFilterCriteria, forceFetch));

	const handleMessage = (message: WebsocketMessage): void => {
		switch (message.event) {
			case WebsocketEvent.TASK_ASSIGNED:
				dataProviderValue.refetchEntity(message.resourceId);
				break;
			case WebsocketEvent.TASK_COMPLETED:
				dataProviderValue.setTaskStatus(message.resourceId, TaskStatus.DONE);
				break;
		}
	};
	useDataProviderWebsocketHandler(taskCollectionStoreName, dataProviderValue, WebsocketTopic.INSTALLATION_PARTNER_TASK, handleMessage);

	return useDataProviderFetchSuspense(dataProviderValue, suspense, pendingComponent).getOrDefault(
		<taskCollectionData.Provider value={dataProviderValue}>
			{props.children}
		</taskCollectionData.Provider>
	);
}
