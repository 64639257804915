import { buildCssClassStringFromClassMap } from '@abb-emobility/shared/util';

import { FirstChildWidth } from '../CollectionHead.enums';

import './CollectionHeadItem.scss';

export type CollectionHeadItemProps = {
	label?: string
	firstChildWidth?: FirstChildWidth
};

export function CollectionHeadItem(props: CollectionHeadItemProps) {
	const { label, firstChildWidth = FirstChildWidth.FIRST_CHILD_WIDTH_4 } = props;

	const classMap = {
		'collection-head-item': true,
		'collection-head-item--first-child-width-2': firstChildWidth === FirstChildWidth.FIRST_CHILD_WIDTH_2,
		'collection-head-item--first-child-width-4': firstChildWidth === FirstChildWidth.FIRST_CHILD_WIDTH_4
	};
	return (
		<div className={buildCssClassStringFromClassMap(classMap)}>
			{label}
		</div>
	);
}
