import React, { CSSProperties } from 'react';

import { InstallationPartnerOrderProgressModel } from '@abb-emobility/oms/domain-model';
import { ProgressState } from '@abb-emobility/shared/domain-model';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { Card, CardHeader, Cardlayout } from '@abb-emobility/shared/ui-primitive';
import { arrayReverse, l10nLiteralFromEnumValue } from '@abb-emobility/shared/util';

import './DetailProgress.scss';

import { ProgressStep } from './ProgressStep';

type ProgressProps = {
	progress: InstallationPartnerOrderProgressModel
};

export function Progress(props: ProgressProps) {
	const { progress } = props;

	const l10n = useL10n();

	if (progress === undefined || progress.steps.length === 0) {
		return null;
	}

	// calculate width of background for progress indicator bar (track)
	const steps = progress.steps.length ?? 0;

	// calculate width of green progress indicator bar (bar)
	let currentIndex = progress.steps.findIndex((value) => {
		return value.status === ProgressState.ONGOING;
	});

	if (currentIndex === -1) {
		const reverseIndex = arrayReverse(progress.steps).findIndex((value) => {
			return value.status === ProgressState.DONE;
		});
		if (reverseIndex !== -1) {
			currentIndex = progress.steps.length - 1 - reverseIndex;
		}
	}

	const itemCircleOuterSize = 16;
	let barStyle: CSSProperties;
	let trackStyle: CSSProperties;
	if (steps > 1) {
		barStyle = {
			width: 'calc( ((( (100% - 8px - ' + itemCircleOuterSize + 'px) / (' + (steps - 1) + ') * ' + currentIndex + ')) + ' + itemCircleOuterSize + 'px + 2px)'
		};
		trackStyle = {};
	} else {
		barStyle = {
			width: 'calc(' + itemCircleOuterSize + 'px + 2px)'
		};
		trackStyle = {
			width: 'calc(' + itemCircleOuterSize + 'px + 8px)'
		};
	}

	const renderProgress = () => {
		const items = progress.steps.map((step, index) => {
			return (
				<ProgressStep
					status={step.status}
					label={l10n.translate(l10nLiteralFromEnumValue(step.taskType, 'omsInstallationPartnerOfficeApp.task.progressStepShortName'))}
					index={index}
					key={index}
				/>
			);
		});
		return items;
	};

	return (
		<Card>
			<Cardlayout>
				<CardHeader heading={l10n.translate('omsInstallationPartnerOfficeApp.orderDetail.progress.heading')} />
				<div className="detail-progress">
					<div className="detail-progress__graphic">
						<div className="detail-progress__graphic__background">
							<span style={trackStyle} className="detail-progress__graphic__background__track" />
							<span style={barStyle} className="detail-progress__graphic__background__bar" />
						</div>
						{renderProgress()}
					</div>
				</div>
			</Cardlayout>
		</Card>
	);
}
