import React from 'react';

import { InstallationPartnerOrderCollectionDataProvider, installationPartnerOrderCollectionStoreName } from '@abb-emobility/oms/data-provider';
import { InstallationPartnerOrderModel } from '@abb-emobility/oms/domain-model';
import { FilterCriteria, SortCriteria, SortDirection } from '@abb-emobility/shared/api-integration-foundation';
import { useFilterCriteriaStorage, useSortCriteriaStorage } from '@abb-emobility/shared/data-provider-util';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { UiErrorHandler } from '@abb-emobility/shared/ui-error-handler';
import { SpinnerCircle } from '@abb-emobility/shared/ui-primitive';

import { OrderCollection } from '../order-collection/OrderCollection';

export type OrderCollectionProps = {
	onNavigate: (id: ModelPrimaryKey) => void
};

export function OrderCollectionAssembler(props: OrderCollectionProps) {

	const { onNavigate } = props;

	const filterCriteriaStorage = useFilterCriteriaStorage<InstallationPartnerOrderModel>(installationPartnerOrderCollectionStoreName);
	const persistedFilterCriteria = filterCriteriaStorage.readFilterCriteria();

	const sortCriteriaStorage = useSortCriteriaStorage<InstallationPartnerOrderModel>(installationPartnerOrderCollectionStoreName);
	const persistedSortCriteria = sortCriteriaStorage.readSortCriteria();
	const defaultSortCriteria: SortCriteria<InstallationPartnerOrderModel> = [
		{ property: 'installationDate', direction: SortDirection.ASCENDING }
	];
	const effectiveSortCriteria = persistedSortCriteria ?? defaultSortCriteria;

	const handleFilterChange = (filterCriteria: FilterCriteria<InstallationPartnerOrderModel>): void => {
		filterCriteriaStorage.writeFilterCriteria(filterCriteria);
	};

	const handleSortChange = (sortCriteria: SortCriteria<InstallationPartnerOrderModel>): void => {
		sortCriteriaStorage.writeSortCriteria(sortCriteria);
	};

	return (
		<UiErrorHandler>
			<InstallationPartnerOrderCollectionDataProvider
				pendingComponent={SpinnerCircle}
				filterCriteria={persistedFilterCriteria}
				sortCriteria={effectiveSortCriteria}
				suspense={false}
			>
				<OrderCollection onNavigate={onNavigate} onSortChange={handleSortChange} onFilterChange={handleFilterChange} />
			</InstallationPartnerOrderCollectionDataProvider>
		</UiErrorHandler>
	);
}
