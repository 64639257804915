import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import React, { ReactNode, useRef } from 'react';

import { useForceRerender } from '@abb-emobility/shared/react';
import { Nullable } from '@abb-emobility/shared/util';

import { modalDialogueManagerContext } from './ModalDialogueManager.context';
import { ModalDialogue } from '../modal-dialogue/ModalDialogue';
import { ModalDialogueSpec } from '../modal-dialogue/ModalDialogue.types';

import './ModalDialogueManager.scss';

export type ModalDialogueManagerProps = {
	children: ReactNode
};

export function ModalDialogueManager(props: ModalDialogueManagerProps) {

	const { children } = props;

	const rerender = useForceRerender();
	const dialogues = useRef<Array<ModalDialogueSpec>>([]);

	const providerValue = {
		push: (dialogue: ModalDialogueSpec): void => {
			dialogues.current = [...dialogues.current, dialogue];
			handleScrollLock();
			rerender.invoke();
		},
		replace: (dialogue: ModalDialogueSpec): Nullable<ModalDialogueSpec> => {
			const modalDialogues = [...dialogues.current];
			const replacedDialogue = modalDialogues.pop();
			dialogues.current = [...modalDialogues, dialogue];
			handleScrollLock();
			rerender.invoke();
			return replacedDialogue ?? null;
		},
		pop: (): Nullable<ModalDialogueSpec> => {
			const modalDialogues = [...dialogues.current];
			const dialogue = modalDialogues.pop();
			dialogues.current = modalDialogues;
			handleScrollLock();
			rerender.invoke();
			return dialogue ?? null;
		},
		size: (): number => {
			return dialogues.current.length;
		},
		clear: (): void => {
			dialogues.current = [];
			handleScrollLock();
			rerender.invoke();
		}
	};

	const handleScrollLock = () => {
		if (dialogues.current.length === 0) {
			clearAllBodyScrollLocks();
		}
	};

	const renderBackgroundCover = (): ReactNode => {
		if (dialogues.current.length === 0) {
			return null;
		}
		return (
			<div className="modal-dialogue-manager__background-cover" />
		);
	};

	const renderDialogues = (): ReactNode => {
		if (dialogues.current.length === 0) {
			return null;
		}
		const dialogueElements = dialogues.current.map((dialogue, index) => {
			return (
				<ModalDialogue dialogueSpec={dialogue} stackPosition={index} key={rerender.key() + '_' + index} />
			);
		});
		return (
			<main className="modal-dialogue-manager__dialogues">
				{dialogueElements}
			</main>
		);
	};

	return (
		<modalDialogueManagerContext.Provider value={providerValue}>
			{children}
			<section className="modal-dialogue-manager">
				{renderBackgroundCover()}
				{renderDialogues()}
			</section>
		</modalDialogueManagerContext.Provider>
	);

}
