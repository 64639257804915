import { ReactNode } from 'react';

import { ExpandableProvider } from '@abb-emobility/shared/interaction';

import { ExpandableSectionHeader } from '../expandable-section-header/ExpandableSectionHeader';
import { SectionItems } from '../section-items/SectionItems';

import './ExpandableSection.scss';

export type SectionProps = {
	heading: string,
	expanded?: boolean,
	children?: ReactNode
};

export function ExpandableSection(props: SectionProps) {
	const { children, heading, expanded } = props;

	return (
		<ExpandableProvider expanded={expanded}>
			<article className="expandable-section">
				<ExpandableSectionHeader heading={heading} />
				<SectionItems>
					{children}
				</SectionItems>
			</article>
		</ExpandableProvider>
	);
}
