import { createContext, useContext } from 'react';

import { BreadcrumbEntry } from './Breadcrumbs.types';

export type BreadcrumbsContextValue = {
	setBreadcrumbEntries: (entries: Array<BreadcrumbEntry>) => void,
	getBreadcrumbEntries: () => Array<BreadcrumbEntry>
};

export const breadcrumbsContext = createContext<BreadcrumbsContextValue>({
	setBreadcrumbEntries: (_entries: Array<BreadcrumbEntry>): void => {
		throw new Error('No breadcrumbs provided');
	},
	getBreadcrumbEntries: (): Array<BreadcrumbEntry> => {
		throw new Error('No breadcrumbs provided');
	}
});

export const useBreadcrumbs = () => {
	return useContext(breadcrumbsContext);
};
