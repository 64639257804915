import { ReactNode } from 'react';

import './CollectionHead.scss';

export type CollectionHeadProps = {
	children: ReactNode
};

export function CollectionHead(props: CollectionHeadProps) {
	const { children } = props;

	return (
		<header className="collection-head">
			{children}
		</header>
	);
}
