export function TermsDe() {

	return (
		<>
			<h1>Erklärung</h1>
			<p>Ich bestätige hiermit, dass ich gemäß den geltenden Gesetzen, Vorschriften, lokalen Gesetzen und Normen für die Durchführung der Elektroinstallations- und -konfigurationsarbeiten vollständig kompetent und qualifiziert bin (einschließlich aller erforderlichen Diplome, Zertifikate, Lizenzen oder Registrierungen) und die Befugnis habe, für und im Namen des von ABB E-mobility B. V. Ich kenne und verstehe die Anforderungen für die sichere Installation und Konfiguration der ABB E-Mobility Wallboxen und werde alle geltenden (lokalen) Gesetze und Vorschriften, den Stand der Technik sowie alle von ABB E-Mobility B.V. erteilten Anweisungen, insbesondere gemäß dem <a href="https://new.abb.com/ev-charging/terra-ac-wallbox/ac-wallbox-manuals" target="_blank" rel="noopener noreferrer">Betriebs- und Installationshandbuch,</a> einhalten.</p>
			<p>Es gelten die <a href="https://abb.com" target="_blank" rel="noopener noreferrer">Nutzungsbedingungen</a> in Bezug auf den Zugang und die Nutzung dieser Anwendung. </p>
		</>
	);
}
