import React, { ReactNode } from 'react';

import { buildCssClassStringFromClassMap } from '@abb-emobility/shared/util';

import { Icon } from '../../icon/Icon';
import { IconIdentifier, IconSize } from '../../icon/Icon.enums';

import './CollectionItemLabel.scss';

export type CollectionItemLabel = {
	children?: ReactNode,
	disabled?: boolean,
	label: string,
	orderId?: string,
	additionalAttribute?: string,
	additionalIcon?: IconIdentifier
};

export function CollectionItemLabel(props: CollectionItemLabel) {
	const { children, disabled, label, orderId, additionalAttribute, additionalIcon } = props;

	const labelClassMap = {
		'collection-item-label__label': true,
		[`collection-item-label__label--disabled`]: disabled
	};

	const renderAttributes = (): ReactNode => {
		if (orderId === undefined && additionalAttribute === undefined) {
			return null;
		}

		let orderItem: ReactNode = null;
		if (orderId !== undefined) {
			orderItem = (
				<span className="collection-item-label__description__value collection-item-label__description__value--numeric">
					<Icon name={IconIdentifier.HASH} size={IconSize.SMALL} />
					{orderId}
				</span>
			);
		}

		let additionalItem: ReactNode = null;
		if (additionalAttribute !== undefined) {
			additionalItem = (
				<span className="collection-item-label__description__value">
					<Icon name={additionalIcon ?? IconIdentifier.PLACEHOLDER} size={IconSize.SMALL} />
					{additionalAttribute}
				</span>
			);
		}

		return (
			<span className="collection-item-label__description">
				{orderItem}
				{additionalItem}
			</span>
		);
	};

	return (
		<div className="collection-item-label">
			<span className={buildCssClassStringFromClassMap(labelClassMap)}>
				{label} {children}
			</span>
			{renderAttributes()}
		</div>
	);

}
