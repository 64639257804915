import { useEffect } from 'react';

import { useBreadcrumbs } from './Breadcrumbs.context';
import { BreadcrumbEntry } from './Breadcrumbs.types';

export const useBreadcrumbModifier = (entries: Array<BreadcrumbEntry>): void => {
	const breadcrumbs = useBreadcrumbs();

	useEffect(() => {
		breadcrumbs.setBreadcrumbEntries(entries);
	}, []);
};
