import React, { useRef } from 'react';

import {
	InstallationPeriod,
	InstallationTradeEffortModel,
	InstallationTradeModel,
	isInstallationTradeEffort
} from '@abb-emobility/shared/domain-model';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	ButtonIcon,
	Icon,
	IconIdentifier, InputCheckbox,
	InputSelect, InputSelectOption,
	InputText
} from '@abb-emobility/shared/ui-primitive';
import { buildCssClassStringFromClassMap, l10nLiteralFromEnumValue } from '@abb-emobility/shared/util';

import './TaskTradeAppointmentEffort.scss';

export type TradeOption = {
	label: string,
	value: string
};

type TaskTradeAppointmentEffortItemProps = {
	tradeEffort: Partial<InstallationTradeEffortModel>,
	tradeOptions: Array<InstallationTradeModel>,
	minEffort?: number,
	maxEffort?: number,
	isFirstRow?: boolean,
	onRemove?: () => void,
	onChange?: (tradeEffort: Partial<InstallationTradeEffortModel>) => void
};

export const TaskTradeAppointmentEffortItem = (props: TaskTradeAppointmentEffortItemProps) => {

	const { tradeOptions, minEffort = 0, maxEffort, isFirstRow, onRemove, onChange } = props;
	const initialTradeEffort = props.tradeEffort;

	const l10n = useL10n();

	const tradeEffort = useRef<Partial<InstallationTradeEffortModel>>(initialTradeEffort);

	const handleRemove = () => {
		if (onRemove !== undefined) {
			onRemove();
		}
	};

	const updateTrade = (tradeId: ModelPrimaryKey) => {
		tradeEffort.current = { ...tradeEffort.current, tradeId };
		handleChange();
	};

	const updatePeriod = (period: InstallationPeriod) => {
		tradeEffort.current = { ...tradeEffort.current, period };
		handleChange();
	};

	const updateEffort = (effortInHours: number) => {
		tradeEffort.current = { ...tradeEffort.current, effortInHours };
		handleChange();
	};

	const updateApprovable = (approvable: boolean) => {
		tradeEffort.current = { ...tradeEffort.current, approvable };
		handleChange();
	};

	const handleChange = () => {
		if (onChange !== undefined && tradeEffort.current !== null) {
			onChange(tradeEffort.current);
		}
	};

	const validateSelection = (): boolean => {
		// noinspection RedundantIfStatementJS
		if (!isInstallationTradeEffort(tradeEffort.current) || isNaN(tradeEffort.current.effortInHours) || tradeEffort.current.effortInHours < 1) {
			return false;
		}
		return true;
	};

	const installationPeriodOptions = Object.values(InstallationPeriod).map((installationPeriod): InputSelectOption<InstallationPeriod> => {
		return {
			label: l10n.translate(l10nLiteralFromEnumValue(installationPeriod, 'omsInstallationPartnerOfficeApp.task.component.taskTradeAppointmentEffortItem.installationPeriod')),
			value: installationPeriod as InstallationPeriod
		};
	});

	const installationTradeOptions = (): Array<InputSelectOption> => {
		return tradeOptions.map((tradeOption) => {
			return {
				label: tradeOption.name,
				value: tradeOption.id
			};
		});
	};

	const renderRow = (): React.JSX.Element => {
		return (
			<>
				<div className="task-trade-appointment-effort-item__text">
					<InputSelect
						label={isFirstRow ? l10n.translate('omsInstallationPartnerOfficeApp.task.component.taskTradeAppointmentEffortItem.input.trade') : undefined}
						options={installationTradeOptions()}
						onSelect={updateTrade}
					/>
					<InputSelect<InstallationPeriod>
						label={isFirstRow ? l10n.translate('omsInstallationPartnerOfficeApp.task.component.taskTradeAppointmentEffortItem.input.period') : undefined}
						options={installationPeriodOptions}
						defaultValue={InstallationPeriod.PERMANENT}
						onSelect={(value) => {
							updatePeriod(value as InstallationPeriod);
						}}
					/>
				</div>
				<div className="task-trade-appointment-effort-item__number">
					<InputText
						type="number"
						label={isFirstRow ? l10n.translate('omsInstallationPartnerOfficeApp.task.component.taskTradeAppointmentEffortItem.input.effort') : undefined}
						min={minEffort}
						max={maxEffort}
						step={1}
						placeholder="8"
						icon={IconIdentifier.CLOCK}
						onChange={(value) => {
							updateEffort(parseInt(value));
						}}
					/>
				</div>
				<div className="task-trade-appointment-effort-item__checkbox">
					<InputCheckbox
						label={l10n.translate('omsInstallationPartnerOfficeApp.task.component.taskTradeAppointmentEffortItem.input.canComplete')}
						onChange={updateApprovable}
						defaultChecked={initialTradeEffort.approvable}
					/>
				</div>
			</>
		);
	};

	const selectionValid = validateSelection();
	const validationIconClassMap = {
		'task-trade-appointment-effort-item__validation-icon': true,
		'task-trade-appointment-effort-item__validation-icon--valid': selectionValid,
		'task-trade-appointment-effort-item__validation-icon--invalid': !selectionValid
	};
	const validationIconIdentifier = selectionValid ? IconIdentifier.CHECK_CIRCLE : IconIdentifier.WARNING_CIRCLE;

	return (
		<div>
			<div className="task-trade-appointment-effort-item">
				<div className={buildCssClassStringFromClassMap(validationIconClassMap)}>
					<Icon name={validationIconIdentifier} />
				</div>
				{renderRow()}
				<div className="task-trade-appointment-effort-item__remove-icon">
					<ButtonIcon onClick={handleRemove} disabled={onRemove === undefined}>
						<Icon name={IconIdentifier.MINUS_CIRCLE} />
					</ButtonIcon>
				</div>
			</div>
		</div>
	);
};


