/* eslint-disable @typescript-eslint/no-explicit-any */
import { Client, StompHeaders } from '@stomp/stompjs';
import { v4 as uuid } from 'uuid';

import { JsonTransportValue } from '@abb-emobility/shared/api-integration-foundation';
import { WebSocketError } from '@abb-emobility/shared/error';
import { L10n } from '@abb-emobility/shared/localization-provider';
import { Nullable } from '@abb-emobility/shared/util';

import {
	RemoveEventListenerCallback,
	WebsocketErrorEventListener,
	WebsocketMessageEventListener,
	WebsocketMessageSubscription,
	WebsocketSimpleEventListener
} from './WebsocketEvents';
import { WebsocketState } from './WebsocketState';

type ListenerId = string;

export class WebsocketConnector {

	private brokerUri: Nullable<string> = null;

	private stompClient: Nullable<Client> = null;

	private webSocketState: WebsocketState = WebsocketState.IDLE;

	private openListeners: Map<ListenerId, WebsocketSimpleEventListener> = new Map();

	private closeListeners: Map<ListenerId, WebsocketSimpleEventListener> = new Map();

	private errorListeners: Map<ListenerId, WebsocketErrorEventListener> = new Map();

	private subscriptions: Map<string, Map<ListenerId, WebsocketMessageSubscription<any>>> = new Map();

	private pendingSubscriptions: Map<string, Map<ListenerId, WebsocketMessageEventListener<any>>> = new Map();

	public connect(brokerUri: string, connectOtp: () => Promise<Nullable<string>>, debugMode = false): void {
		this.brokerUri = brokerUri;
		if (this.stompClient === null) {
			this.stompClient = new Client({
				brokerURL: this.brokerUri,
				connectionTimeout: 500,
				reconnectDelay: 1000,
				heartbeatIncoming: 20000,
				heartbeatOutgoing: 20000,
				onConnect: () => {
					this.webSocketState = WebsocketState.OPENED;
					this.processPendingSubscriptions();
					this.callOpenListeners();
				},
				onDisconnect: () => {
					this.webSocketState = WebsocketState.CLOSED;
					this.callCloseListeners();
				},
				onWebSocketClose: () => {
					this.webSocketState = WebsocketState.CLOSED;
					this.callCloseListeners();
				},
				onStompError: () => {
					this.callErrorListeners();
				},
				onWebSocketError: (event) => {
					this.callErrorListeners(event);
				},
				beforeConnect: async (): Promise<void> => {
					if (this.stompClient !== null) {
						let otp = await connectOtp();
						if (otp === null) {
							this.stompClient.connectionTimeout = 0;
							this.stompClient.reconnectDelay = 0;
							otp = '';
						}
						const connectHeaders: StompHeaders = {
							'Accept-Language': L10n.effectiveLocale() ?? '',
							'X-Local-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
							// eslint-disable-next-line @typescript-eslint/naming-convention
							'Accept': 'application/json',
							'X-Connect-Otp': otp
						};
						this.stompClient.connectHeaders = connectHeaders;
					}
				}
			});

			if (debugMode) {
				this.stompClient.logRawCommunication = true;
				this.stompClient.debug = (message) => {
					console.info(message);
				};
			}
		}

		if (!this.stompClient.active) {
			this.stompClient.activate();
		}
	}

	public subscribe<Topic extends string>(topic: Topic, listener: WebsocketMessageEventListener<any>, listenerId?: ListenerId): ListenerId {

		listenerId = listenerId ?? uuid();

		if (!this.isOpened()) {
			if (!this.pendingSubscriptions.has(topic)) {
				this.pendingSubscriptions.set(topic, new Map());
			}

			const pendingSubscriptionTopic = this.pendingSubscriptions.get(topic) ?? null;
			if (pendingSubscriptionTopic === null) {
				throw new WebSocketError('Pending subscription topic not found');
			}

			if (pendingSubscriptionTopic.has(listenerId)) {
				return listenerId;
			}

			pendingSubscriptionTopic.set(listenerId, listener);
			return listenerId;
		}

		if (this.stompClient === null) {
			throw new WebSocketError('Socket unavailable');
		}

		if (!this.subscriptions.has(topic)) {
			this.subscriptions.set(topic, new Map());
		}

		const subscriptionTopic = this.subscriptions.get(topic) ?? null;
		if (subscriptionTopic === null) {
			throw new WebSocketError('Subscription topic not found');
		}

		if (subscriptionTopic.has(listenerId)) {
			return listenerId;
		}

		try {
			const stompSubscription = this.stompClient.subscribe(topic, (message) => {
					/*
					// SUGGESTION: Enable this security feature if the broker origin is available with the message headers
					const origin = new URL(this.brokerUri ?? '').origin;
					const remoteOrigin = trimFromRight(message.headers['origin'], '/');
					if (origin.toLowerCase() !== remoteOrigin.toLowerCase()) {
						this.close();
						throw new WebSocketError('WebSocket XSS violation. Message from invalid origin ' + message.headers['origin'] + ' detected. Only messages from ' + origin + ' are handled.');
					}
					 */
					try {
						const parsedMessage = JSON.parse(message.body);
						this.callTopicListeners(topic, parsedMessage);
					} catch (e) {
						throw new WebSocketError('Unexpected message format');
					}
				}
			);

			subscriptionTopic.set(listenerId, {
				eventListener: listener,
				stompSubscription
			});

			return listenerId;
		} catch (e) {
			throw new WebSocketError('Subscription failed', undefined, undefined, e as Error);
		}
	}

	private processPendingSubscriptions(): void {
		for (const topic of this.pendingSubscriptions.keys()) {
			const pendingSubscription = this.pendingSubscriptions.get(topic) ?? null;
			if (pendingSubscription === null) {
				continue;
			}
			for (const listenerId of pendingSubscription.keys()) {
				const listener = pendingSubscription.get(listenerId) ?? null;
				if (listener === null) {
					continue;
				}
				pendingSubscription.delete(listenerId);
				this.subscribe(topic, listener, listenerId);
			}
		}
	}

	public unsubscribe<Topic extends string>(topic: Topic, subscriptionId: string): void {
		const subscription = this.subscriptions.get(topic) ?? null;
		if (subscription !== null) {
			subscription.get(subscriptionId)?.stompSubscription.unsubscribe();
			subscription.delete(subscriptionId);
		}
		const pendingSubscription = this.pendingSubscriptions.get(topic) ?? null;
		if (pendingSubscription !== null) {
			pendingSubscription.delete(subscriptionId);
		}
	}

	public unsubscribeAll(): void {
		for (const topic of this.subscriptions.keys()) {
			const subscription = this.subscriptions.get(topic) ?? null;
			if (subscription === null) {
				continue;
			}
			for (const listenerId of subscription.keys()) {
				this.unsubscribe(topic, listenerId);
			}
		}
	}

	public close(): void {
		this.unsubscribeAll();
		void this.stompClient?.deactivate();
		this.webSocketState = WebsocketState.CLOSED;
	}

	public getState(): WebsocketState {
		return this.webSocketState;
	}

	public isOpened(): boolean {
		return this.getState() === WebsocketState.OPENED;
	}

	public addOpenListener(listener: WebsocketSimpleEventListener): RemoveEventListenerCallback {
		const listenerId = uuid();
		this.openListeners.set(listenerId, listener);
		return () => {
			this.removeOpenListener(listenerId);
		};
	}

	public removeOpenListener(listenerId: string): void {
		if (this.openListeners.has(listenerId)) {
			this.openListeners.delete(listenerId);
		}
	}

	public addCloseListener(listener: WebsocketSimpleEventListener): RemoveEventListenerCallback {
		const listenerId = uuid();
		this.closeListeners.set(listenerId, listener);
		return () => {
			this.removeCloseListener(listenerId);
		};
	}

	public removeCloseListener(listenerId: string): void {
		if (this.closeListeners.has(listenerId)) {
			this.closeListeners.delete(listenerId);
		}
	}

	public addErrorListener(listener: WebsocketErrorEventListener): RemoveEventListenerCallback {
		const listenerId = uuid();
		this.errorListeners.set(listenerId, listener);
		return () => {
			this.removeErrorListener(listenerId);
		};
	}

	public removeErrorListener(listenerId: string): void {
		if (this.errorListeners.has(listenerId)) {
			this.errorListeners.delete(listenerId);
		}
	}

	private callOpenListeners(): void {
		for (const listener of this.openListeners.values()) {
			listener();
		}
	}

	private callCloseListeners(): void {
		for (const listener of this.closeListeners.values()) {
			listener();
		}
	}

	private callErrorListeners(error?: Event): void {
		for (const listener of this.errorListeners.values()) {
			listener(error);
		}
	}

	private callTopicListeners<Topic extends string, Message extends JsonTransportValue>(topic: Topic, message: Message): void {
		const subscription = this.subscriptions.get(topic) ?? null;
		if (subscription !== null) {
			for (const listener of subscription.values()) {
				listener.eventListener(message);
			}
		}
	}

}
