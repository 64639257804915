import { Icon } from '../../../../../icon/Icon';
import { IconIdentifier, IconSize } from '../../../../../icon/Icon.enums';

import './ActionbarFilterContentFooterReset.scss';

export type ActionbarFilterContentFooterResetProps = {
	label: string,
	onClick: () => void
};

export function ActionbarFilterContentFooterReset(props: ActionbarFilterContentFooterResetProps) {

	const { label, onClick } = props;

	return (
		<button className="actionbar-filter-content-footer-reset" onClick={onClick}>
			<Icon size={IconSize.SMALL} name={IconIdentifier.ARROW_COUNTER_CLOCKWISE} />
			{label}
		</button>
	);
}
