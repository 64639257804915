import React, { ReactNode, useEffect, useState } from 'react';

import { TaskActionStatus } from '@abb-emobility/shared/data-provider-foundation';
import {
	InstallationDecisionType,
	InstallationProjectReason,
	InstallationTradeEffortModel,
	InstallationUnfeasibleReason
} from '@abb-emobility/shared/domain-model';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { convertApiPersistedAnswersToPersistedAnswers } from '@abb-emobility/shared/questionnaire';
import {
	BasicInformation,
	ButtonGhost,
	ButtonGroup,
	ButtonPrimary,
	CollectionItemContentSection,
	CustomerContactInformation,
	ExpandableSection,
	Hint,
	InputSelect,
	InputSelectOption,
	InputTextArea,
	OrderComment,
	OrderCommentCollection,
	SectionHeader,
	Separator,
	SubmissionStatus,
	useModalDialogue,
	useModalDialogueManager
} from '@abb-emobility/shared/ui-primitive';
import { QuestionnaireAnswers } from '@abb-emobility/shared/ui-questionnaire';
import { l10nLiteralFromEnumValue } from '@abb-emobility/shared/util';
import { useAnyTaskEntityData } from '@abb-emobility/usertask/data-provider';
import {
	AnyPreCheckRemotePayloadModel,
	PreCheckRemotePayloadProjectDecisionModel,
	PreCheckRemotePayloadUnfeasibleDecisionModel,
	PreCheckRemoteTaskModel
} from '@abb-emobility/usertask/domain-model';

import { TaskChoice } from '../components/TaskChoice';
import { TaskTradeAppointmentEffort } from '../components/TaskTradeAppointmentEffort';

export type PrecheckRemoteProps = {
	task: PreCheckRemoteTaskModel
};

export function TaskPrecheckRemote(props: PrecheckRemoteProps) {

	const { task } = props;

	const l10n = useL10n();
	const modalDialogue = useModalDialogue();
	const modalDialogueManager = useModalDialogueManager();
	const anyTaskData = useAnyTaskEntityData();
	const [taskPayload, setTaskPayload] = useState<AnyPreCheckRemotePayloadModel>(task.payload);
	const [taskCompletionButtonState, setTaskCompletionButtonState] = useState<SubmissionStatus>(SubmissionStatus.IDLE);
	const completionStatus = anyTaskData.queryActionStatus();
	const taskCompletionButtonDisabled = completionStatus === TaskActionStatus.COMPLETE_PENDING || completionStatus === TaskActionStatus.COMPLETE_SUCCESS;

	anyTaskData.useActionStatusEffect([TaskActionStatus.COMPLETE_PENDING], () => {
		setTaskCompletionButtonState(SubmissionStatus.PENDING);
	}, false);

	anyTaskData.useActionStatusEffect([TaskActionStatus.COMPLETE_SUCCESS], () => {
		setTaskCompletionButtonState(SubmissionStatus.DONE);
	}, true);

	anyTaskData.useActionStatusEffect([TaskActionStatus.COMPLETE_FAILED], () => {
		setTaskCompletionButtonState(SubmissionStatus.IDLE);
	}, true);

	const unfeasibleReasonSelectOptions = Object.values(InstallationUnfeasibleReason).map((value): InputSelectOption => {
		return {
			label: l10n.translate(l10nLiteralFromEnumValue(value, 'omsInstallationPartnerOfficeApp.task.component.taskChoice.unfeasibleInstallationReasons')),
			value: value
		};
	});

	const b2BReasonSelectOptions = Object.values(InstallationProjectReason).map((value): InputSelectOption => {
		return {
			label: l10n.translate(l10nLiteralFromEnumValue(value, 'omsInstallationPartnerOfficeApp.task.component.taskChoice.projectInstallationReasons')),
			value: value
		};
	});

	const taskPayloadValidate = (): boolean => {
		switch (taskPayload.installationType) {
			case InstallationDecisionType.STANDARD:
			case InstallationDecisionType.DELTA:
				return (taskPayload.installationTradeEfforts ?? []).length > 0;
			case InstallationDecisionType.UNFEASIBLE:
			case InstallationDecisionType.PROJECT:
				return true;
		}
		return false;
	};

	useEffect(() => {
		modalDialogue.setFooter(
			<ButtonGroup>
				<ButtonGhost
					label={l10n.translate('omsInstallationPartnerOfficeApp.task.preCheck.button.close')}
					onClick={handleCancel}
				/>
				<ButtonPrimary
					label={l10n.translate('omsInstallationPartnerOfficeApp.task.preCheck.button.submit')}
					submissionStatus={taskCompletionButtonState}
					onClick={handleTaskCompletion}
					disabled={taskCompletionButtonDisabled || !taskPayloadValidate()}
				/>
			</ButtonGroup>
		);
		return () => {
			modalDialogue.unsetFooter();
		};
	}, [taskCompletionButtonState, taskCompletionButtonDisabled, taskPayloadValidate(), taskPayload]);

	const handleTaskCompletion = () => {
		anyTaskData.complete(task, taskPayload);
	};

	const handleCancel = (): void => {
		modalDialogueManager.pop();
	};

	const handleInstallationTypeSelect = (value: InstallationDecisionType) => {
		const payload = {
			...task.payload,
			installationType: value
		};
		switch (value) {
			case InstallationDecisionType.UNFEASIBLE:
				(payload as PreCheckRemotePayloadUnfeasibleDecisionModel).decisionReason
					= unfeasibleReasonSelectOptions[0].value as InstallationUnfeasibleReason;
				break;
			case InstallationDecisionType.PROJECT:
				(payload as PreCheckRemotePayloadProjectDecisionModel).decisionReason
					= b2BReasonSelectOptions[0].value as InstallationProjectReason;
				break;
		}
		setTaskPayload(payload);
	};

	const handleInstallationTypeDeselect = () => {
		setTaskPayload(task.payload);
	};

	const handleUnfeasibleReason = (value: string) => {
		if (taskPayload.installationType === InstallationDecisionType.UNFEASIBLE) {
			setTaskPayload({
				...taskPayload,
				decisionReason: value as InstallationUnfeasibleReason
			});
		}
	};

	const handleProjectReason = (value: string) => {
		if (taskPayload.installationType === InstallationDecisionType.PROJECT) {
			setTaskPayload({
				...taskPayload,
				decisionReason: value as InstallationProjectReason
			});
		}
	};

	const handleInstallationTradeEfforts = (tradeEfforts: Array<InstallationTradeEffortModel>) => {
		if (
			taskPayload.installationType === InstallationDecisionType.STANDARD
			|| taskPayload.installationType === InstallationDecisionType.DELTA
		) {
			setTaskPayload({
				...taskPayload,
				installationTradeEfforts: tradeEfforts
			});
		}
	};

	const handleComment = (comment: string) => {
		const handledComment = comment.length === 0 ? undefined : comment;
		setTaskPayload({
			...taskPayload,
			comment: handledComment
		});
	};

	const renderBasicAnswers = (): ReactNode => {
		return (
			<CollectionItemContentSection>
				<ExpandableSection heading={l10n.translate('omsInstallationPartnerOfficeApp.task.preCheck.questionnaire.heading')}>
					<QuestionnaireAnswers
						questionnaire={task.payload.questionnaire}
						answers={convertApiPersistedAnswersToPersistedAnswers(task.payload.answers)}
						environment={task.payload.questionnaireEnvironment}
					/>
				</ExpandableSection>
			</CollectionItemContentSection>
		);
	};

	const renderComments = (): ReactNode => {
		if (
			task.payload.comments === undefined
			|| task.payload.comments.length === 0
		) {
			return null;
		}
		const comments = task.payload.comments.map((comment, index) => {
			return (
				<OrderComment comment={comment} key={index} />
			);
		});
		return (
			<CollectionItemContentSection>
				<ExpandableSection heading={l10n.translate('omsInstallationPartnerOfficeApp.task.quotation.comments.heading')}>
					<OrderCommentCollection>
						{comments}
					</OrderCommentCollection>
				</ExpandableSection>
			</CollectionItemContentSection>
		);
	};

	return (
		<>
			<CollectionItemContentSection>
				<SectionHeader heading={l10n.translate('omsInstallationPartnerOfficeApp.task.preCheck.contact.heading')} />
				<CustomerContactInformation
					name={taskPayload.customer.name}
					phoneNumber={taskPayload.customer.phoneNumber}
					label={l10n.translate('omsInstallationPartnerOfficeApp.task.preCheck.contact.description')}
					items={taskPayload.preferredTimesOfDay}
					medium={taskPayload.preferredMedium}
				/>
			</CollectionItemContentSection>

			{renderBasicAnswers()}

			{renderComments()}

			<CollectionItemContentSection>
				<SectionHeader heading={l10n.translate('omsInstallationPartnerOfficeApp.task.preCheck.decision.heading')} />

				<TaskChoice
					isSelected={taskPayload.installationType === InstallationDecisionType.STANDARD}
					label={l10n.translate('omsInstallationPartnerOfficeApp.task.component.taskChoice.decision.standard')}
					onSelect={handleInstallationTypeSelect}
					onDeselect={handleInstallationTypeDeselect}
					value={InstallationDecisionType.STANDARD}>
					<CollectionItemContentSection heading={l10n.translate('omsInstallationPartnerOfficeApp.task.component.taskTradeAppointmentEffort.heading')}>
						<TaskTradeAppointmentEffort
							tradeOptions={task.payload.tradeOptions}
							onChange={handleInstallationTradeEfforts}
						/>
					</CollectionItemContentSection>
				</TaskChoice>

				<Separator />

				<TaskChoice
					isSelected={taskPayload.installationType === InstallationDecisionType.DELTA}
					label={l10n.translate('omsInstallationPartnerOfficeApp.task.component.taskChoice.decision.delta')}
					onSelect={handleInstallationTypeSelect}
					onDeselect={handleInstallationTypeDeselect}
					value={InstallationDecisionType.DELTA}>
					<CollectionItemContentSection heading={l10n.translate('omsInstallationPartnerOfficeApp.task.component.taskTradeAppointmentEffort.heading')}>
						<TaskTradeAppointmentEffort
							tradeOptions={task.payload.tradeOptions}
							onChange={handleInstallationTradeEfforts}
						/>
					</CollectionItemContentSection>
				</TaskChoice>

				<Separator />

				<TaskChoice
					isSelected={taskPayload.installationType === InstallationDecisionType.UNFEASIBLE}
					label={l10n.translate('omsInstallationPartnerOfficeApp.task.component.taskChoice.decision.unfeasible')}
					onSelect={handleInstallationTypeSelect}
					onDeselect={handleInstallationTypeDeselect}
					value={InstallationDecisionType.UNFEASIBLE}>
					<InputSelect
						label={l10n.translate('omsInstallationPartnerOfficeApp.task.component.taskChoice.unfeasibleInstallationReasons.label')}
						options={unfeasibleReasonSelectOptions}
						onSelect={handleUnfeasibleReason}
					/>
				</TaskChoice>

				<Separator />

				<TaskChoice
					isSelected={taskPayload.installationType === InstallationDecisionType.PROJECT}
					label={l10n.translate('omsInstallationPartnerOfficeApp.task.component.taskChoice.decision.project')}
					onSelect={handleInstallationTypeSelect}
					onDeselect={handleInstallationTypeDeselect}
					value={InstallationDecisionType.PROJECT}>
					<Hint heading={l10n.translate('omsInstallationPartnerOfficeApp.task.component.taskChoice.projectInstallationReasons.hint.heading')} />
					<InputSelect
						label={l10n.translate('omsInstallationPartnerOfficeApp.task.component.taskChoice.projectInstallationReasons.label')}
						options={b2BReasonSelectOptions}
						onSelect={handleProjectReason}
					/>
					<BasicInformation
						label={l10n.translate('omsInstallationPartnerOfficeApp.task.component.taskChoice.projectInstallationReasons.information.heading')}
						description={l10n.translate('omsInstallationPartnerOfficeApp.task.component.taskChoice.projectInstallationReasons.information.message')}
					/>
				</TaskChoice>
			</CollectionItemContentSection>

			<CollectionItemContentSection>
				<SectionHeader heading={l10n.translate('omsInstallationPartnerOfficeApp.task.preCheck.notes.heading')} />
				<InputTextArea
					placeholder={l10n.translate('omsInstallationPartnerOfficeApp.task.preCheck.notes.placeholder')}
					rows={4}
					onChange={handleComment}
				/>
			</CollectionItemContentSection>

		</>
	);
}
