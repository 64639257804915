import React, { ReactNode, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { FilterCriteria, SortCriteria } from '@abb-emobility/shared/api-integration-foundation';
import { TaskActionStatus } from '@abb-emobility/shared/data-provider-foundation';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { EffectCallbackReturn } from '@abb-emobility/shared/react';
import {
	Actionbar,
	ActionbarItem,
	ButtonGhost,
	CollectionFooter,
	CollectionHead,
	CollectionHeadItem,
	CollectionItem,
	CollectionGroupedFilter,
	CollectionSort,
	filterCriteriaFromFilterOptions,
	filterOptionsFromFilterCriteria,
	IconIdentifier,
	NotificationBig,
	NotificationBigStatus,
	Separator,
	SpinnerCircle,
	ToastLevel,
	useToastManager, useModalDialogueManager
} from '@abb-emobility/shared/ui-primitive';
import {
	TaskCollectionDataInterceptEmpty,
	TaskCollectionDataSuspense,
	useTaskCollectionDataProviderProvider
} from '@abb-emobility/usertask/data-provider';
import { AnyTaskCollectionItemModel } from '@abb-emobility/usertask/domain-model';


import { taskFilterGroups, TaskFilterOption } from './TaskCollection.types';
import { TaskCollectionItem } from '../task-collection-item/TaskCollectionItem';
import { TaskModalAssembler } from '../task-modal/TaskModalAssembler';


export type TaskCollectionProps = {
	onFilterChange?: (filterCriteria: FilterCriteria<AnyTaskCollectionItemModel>) => void,
	onSortChange?: (sortCriteria: SortCriteria<AnyTaskCollectionItemModel>) => void
};

export function TaskCollection(props: TaskCollectionProps) {

	const { onFilterChange, onSortChange } = props;

	const params = useParams();
	const l10n = useL10n();
	const toast = useToastManager();

	const modalDialogueManager = useModalDialogueManager();
	const taskCollectionData = useTaskCollectionDataProviderProvider().getTaskCollectionDataProvider();
	const effectiveFilterCriteria = taskCollectionData.queryFilterCriteria();
	const effectiveSortCriteria = taskCollectionData.querySortCriteria();
	const isPaginated = taskCollectionData.isPaginated();
	const hasNextPage = taskCollectionData.hasNextPage();
	const fetchPending = taskCollectionData.pending();
	const tasks = taskCollectionData.query();

	// Open task from URL
	const taskId = params?.taskId ?? null;
	useEffect((): EffectCallbackReturn => {
		if (taskId !== null) {
			modalDialogueManager.push({
				content: (): ReactNode => {
					return (
						<TaskModalAssembler taskId={taskId} />
					);
				}
			});
			return modalDialogueManager.pop;
		}
	}, []);

	taskCollectionData.useActionStatusEffect([TaskActionStatus.ASSIGN_FAILED], () => {
		toast.addToastEntry({
			label: l10n.translate('omsInstallationPartnerOfficeApp.taskCollection.assign.errorMessage'),
			level: ToastLevel.WARNING
		});
	}, true);

	taskCollectionData.useActionStatusEffect([TaskActionStatus.ASSIGN_SUCCESS], () => {
		toast.addToastEntry({
			label: l10n.translate('omsInstallationPartnerOfficeApp.taskCollection.assign.successMessage'),
			level: ToastLevel.SUCCESS,
			autoDismiss: true
		});
	}, true);

	const handleFilterApply = (filterOptions: Array<TaskFilterOption>) => {
		const filterCriteria = filterCriteriaFromFilterOptions(taskFilterGroups, filterOptions);
		if (onFilterChange !== undefined) {
			onFilterChange(filterCriteria);
		}
		taskCollectionData.applyFilter(filterCriteria);
	};

	const handleSortApply = (sortCriteria: SortCriteria<AnyTaskCollectionItemModel>) => {
		if (onSortChange !== undefined) {
			onSortChange(sortCriteria);
		}
		taskCollectionData.applySort(sortCriteria);
	};

	const handleRefresh = () => {
		taskCollectionData.refetch();
	};

	const handleShowMore = (): void => {
		taskCollectionData.fetchNext();
	};

	const renderEmptyState = (): ReactNode => {
		return (
			<NotificationBig
				status={NotificationBigStatus.EMPTY2}
				heading={l10n.translate('omsInstallationPartnerOfficeApp.taskCollection.emptyState.heading')}
				message={l10n.translate('omsInstallationPartnerOfficeApp.taskCollection.emptyState.message')}
			/>
		);
	};

	const renderItems = () => {
		return tasks.map((task) => {
			return (
				<CollectionItem key={task.id}>
					<TaskCollectionItem task={task} />
				</CollectionItem>
			);
		});
	};
	const renderShowMore = (): ReactNode => {
		if (!isPaginated) {
			return;
		}
		return (
			<ButtonGhost
				label={l10n.translate('omsInstallationPartnerOfficeApp.taskCollection.action.showMore')}
				onClick={handleShowMore}
				disabled={!hasNextPage || fetchPending}
			/>
		);
	};

	return (
		<>
			<Actionbar label={l10n.translate('omsInstallationPartnerOfficeApp.taskCollection.heading')}>
				<ActionbarItem
					label={l10n.translate('omsInstallationPartnerOfficeApp.taskCollection.action.refresh')}
					icon={IconIdentifier.ARROW_CLOCKWISE}
					onTrigger={handleRefresh}
				/>
				<CollectionGroupedFilter
					filterOptions={filterOptionsFromFilterCriteria<TaskFilterOption, AnyTaskCollectionItemModel>(taskFilterGroups, effectiveFilterCriteria ?? [])}
					filterOptionGroups={taskFilterGroups}
					onApply={handleFilterApply}
				/>
				<CollectionSort
					sortOptions={['priority', 'dueDate', 'status']}
					sortCriteria={effectiveSortCriteria ?? undefined}
					implicitSortOption={'dueDate'}
					onApply={handleSortApply}
				/>
			</Actionbar>

			<Separator />

			<TaskCollectionDataSuspense pendingComponent={SpinnerCircle}>
				<TaskCollectionDataInterceptEmpty emptyStateComponent={renderEmptyState}>

					<CollectionHead>
						<CollectionHeadItem label={l10n.translate('omsInstallationPartnerOfficeApp.taskCollection.tableHead.order')} />
						<CollectionHeadItem label={l10n.translate('omsInstallationPartnerOfficeApp.taskCollection.tableHead.status')} />
						<CollectionHeadItem label={l10n.translate('omsInstallationPartnerOfficeApp.taskCollection.tableHead.due')} />
						<CollectionHeadItem label={l10n.translate('omsInstallationPartnerOfficeApp.taskCollection.tableHead.priority')} />
						<CollectionHeadItem label={l10n.translate('omsInstallationPartnerOfficeApp.taskCollection.tableHead.responsible')} />
						{/* last item is used as an alignment util for the list item action */}
						<CollectionHeadItem />
					</CollectionHead>

					<Separator />

					{renderItems()}

					<CollectionFooter>
						{renderShowMore()}
					</CollectionFooter>

				</TaskCollectionDataInterceptEmpty>
			</TaskCollectionDataSuspense>
		</>
	);

}
