import React, { ReactNode } from 'react';

import {
	InstallationPartnerOrderCollectionDataInterceptEmpty,
	InstallationPartnerOrderCollectionDataSuspense,
	useInstallationPartnerOderCollectionData
} from '@abb-emobility/oms/data-provider';
import { InstallationPartnerOrderModel } from '@abb-emobility/oms/domain-model';
import { FilterCriteria, SortCriteria } from '@abb-emobility/shared/api-integration-foundation';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	Actionbar,
	ActionbarItem,
	ButtonGhost,
	Card,
	CollectionFooter,
	CollectionHead,
	CollectionHeadItem,
	CollectionGroupedFilter,
	CollectionSort,
	filterCriteriaFromFilterOptions,
	FilterOptionGroup,
	filterOptionsFromFilterCriteria,
	IconIdentifier,
	NotificationBig,
	NotificationBigStatus,
	Separator,
	SpinnerCircle
} from '@abb-emobility/shared/ui-primitive';

import { OrderFilterOption, orderFilterOptionGroups } from './OrderCollection.types';
import { OrderCollectionItem } from './OrderCollectionItem';

import './OrderCollectionItem.scss';

export type OrderCollectionProps = {
	onNavigate: (id: ModelPrimaryKey) => void,
	onFilterChange: (filterCriteria: FilterCriteria<InstallationPartnerOrderModel>) => void,
	onSortChange: (sortCriteria: SortCriteria<InstallationPartnerOrderModel>) => void
};

export function OrderCollection(props: OrderCollectionProps) {

	const l10n = useL10n();

	const { onNavigate, onFilterChange, onSortChange } = props;

	const orderCollectionData = useInstallationPartnerOderCollectionData();
	const effectiveFilterCriteria = orderCollectionData.queryFilterCriteria();
	const effectiveSortCriteria = orderCollectionData.querySortCriteria();
	const isPaginated = orderCollectionData.isPaginated();
	const hasNextPage = orderCollectionData.hasNextPage();
	const fetchPending = orderCollectionData.pending();
	const orders = orderCollectionData.query();

	const handleFilterApply = (filterOptions: Array<OrderFilterOption>) => {
		const filterCriteria = filterCriteriaFromFilterOptions(orderFilterOptionGroups, filterOptions);
		if (onFilterChange !== undefined) {
			onFilterChange(filterCriteria);
		}
		orderCollectionData.applyFilter(filterCriteria);
	};

	const handleSortApply = (sortCriteria: SortCriteria<InstallationPartnerOrderModel>) => {
		if (onSortChange !== undefined) {
			onSortChange(sortCriteria);
		}
		orderCollectionData.applySort(sortCriteria);
	};

	const handleRefresh = () => {
		orderCollectionData.refetch();
	};

	const handleShowMore = () => {
		orderCollectionData.fetchNext();
	};

	const renderEmptyState = (): ReactNode => {
		return (
			<NotificationBig
				status={NotificationBigStatus.EMPTY2}
				heading={l10n.translate('omsInstallationPartnerOfficeApp.orderCollection.emptyState.heading')}
				message={l10n.translate('omsInstallationPartnerOfficeApp.orderCollection.emptyState.message')}
			/>
		);
	};

	const renderShowMore = (): ReactNode => {
		if (!isPaginated) {
			return;
		}
		return (
			<ButtonGhost
				label={l10n.translate('omsInstallationPartnerOfficeApp.orderCollection.action.showMore')}
				onClick={handleShowMore}
				disabled={!hasNextPage || fetchPending}
			/>
		);
	};

	const renderOrders = () => {
		return orders.map((order): React.JSX.Element => {
			return (
				<OrderCollectionItem
					order={order}
					key={order.id}
					onNavigate={onNavigate}
				/>
			);
		});
	};

	return (
		<Card>

			<Actionbar label={l10n.translate('omsInstallationPartnerOfficeApp.orderCollection.heading')}>
				<ActionbarItem
					label={l10n.translate('omsInstallationPartnerOfficeApp.orderCollection.action.refresh')}
					icon={IconIdentifier.ARROW_CLOCKWISE}
					onTrigger={handleRefresh}
				/>
				<CollectionGroupedFilter
					filterOptions={filterOptionsFromFilterCriteria(
						orderFilterOptionGroups as Array<FilterOptionGroup<OrderFilterOption, InstallationPartnerOrderModel>>,
						effectiveFilterCriteria ?? []
					)}
					filterOptionGroups={orderFilterOptionGroups}
					onApply={handleFilterApply}
				/>
				<CollectionSort
					sortOptions={['installationDate', 'orderDate', 'estimatedCompletionDate']}
					sortCriteria={effectiveSortCriteria ?? undefined}
					implicitSortOption={'estimatedCompletionDate'}
					onApply={handleSortApply}
				/>
			</Actionbar>

			<Separator />

			<InstallationPartnerOrderCollectionDataSuspense pendingComponent={SpinnerCircle}>
				<InstallationPartnerOrderCollectionDataInterceptEmpty emptyStateComponent={renderEmptyState}>

					<CollectionHead>
						<CollectionHeadItem label={l10n.translate('omsInstallationPartnerOfficeApp.orderCollection.tableHead.order')} />
						<CollectionHeadItem label={l10n.translate('omsInstallationPartnerOfficeApp.orderCollection.tableHead.status')} />
						<CollectionHeadItem label={l10n.translate('omsInstallationPartnerOfficeApp.orderCollection.tableHead.estimated')} />
						<CollectionHeadItem label={l10n.translate('omsInstallationPartnerOfficeApp.orderCollection.tableHead.progress')} />
						{/* last item is used as an alignment util for the list item action */}
						<CollectionHeadItem />
					</CollectionHead>

					<Separator />

					{renderOrders()}

					<CollectionFooter>
						{renderShowMore()}
					</CollectionFooter>

				</InstallationPartnerOrderCollectionDataInterceptEmpty>
			</InstallationPartnerOrderCollectionDataSuspense>

		</Card>
	);
}
