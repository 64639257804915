import React, { useEffect, useRef, useState } from 'react';

import { TaskActionStatus } from '@abb-emobility/shared/data-provider-foundation';
import { createUploadFileFromFile } from '@abb-emobility/shared/domain-model-foundation';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	ButtonGhost,
	ButtonGroup,
	ButtonPrimary,
	CollectionItemContentSection,
	GridOperatorInformation,
	Hint,
	HintLevel,
	IconIdentifier,
	InputTextArea,
	SectionHeader,
	Separator,
	SubmissionStatus,
	Upload,
	useModalDialogue,
	useModalDialogueManager
} from '@abb-emobility/shared/ui-primitive';
import { Nullable, numberFromString, Optional } from '@abb-emobility/shared/util';
import { useAnyTaskEntityData } from '@abb-emobility/usertask/data-provider';
import { PreCheckRegistrationGridOperatorTaskModel } from '@abb-emobility/usertask/domain-model';

import { TaskChoice } from '../components/TaskChoice';

export type TaskPrecheckRejectedProps = {
	task: PreCheckRegistrationGridOperatorTaskModel
};

export function TaskRegistrationGridOperator(props: TaskPrecheckRejectedProps) {

	const { task } = props;

	const l10n = useL10n();
	const modalDialogue = useModalDialogue();
	const modalDialogueManager = useModalDialogueManager();
	const anyTaskData = useAnyTaskEntityData();

	const documentSelection = useRef<Array<File>>([]);

	const [registrationUnnecessary, setRegistrationUnnecessary] = useState<Nullable<boolean>>(null);
	const [completionRequested, setCompletionRequested] = useState<boolean>(false);
	const [comment, setComment] = useState<Nullable<string>>(null);
	const [taskCompletionButtonState, setTaskCompletionButtonState] = useState<SubmissionStatus>(SubmissionStatus.IDLE);
	const completionActionStatus = anyTaskData.queryActionStatus();

	useEffect((): void => {
		if (!completionRequested) {
			return;
		}
		const completeTask = async (): Promise<void> => {
			const payload = { ...task.payload };
			const uploadFilePromises = documentSelection.current.map(async (file) => {
				return createUploadFileFromFile(file);
			});
			payload.documents = await Promise.all(uploadFilePromises);
			payload.comment = comment ?? undefined;
			payload.registrationUnnecessary = registrationUnnecessary ?? false;
			anyTaskData.complete(task, payload);
		};
		void completeTask();
	}, [completionRequested]);

	anyTaskData.useActionStatusEffect([TaskActionStatus.COMPLETE_PENDING], () => {
		setTaskCompletionButtonState(SubmissionStatus.PENDING);
	}, false);

	anyTaskData.useActionStatusEffect([TaskActionStatus.COMPLETE_SUCCESS], () => {
		setTaskCompletionButtonState(SubmissionStatus.DONE);
	}, true);

	anyTaskData.useActionStatusEffect([TaskActionStatus.COMPLETE_FAILED], () => {
		setTaskCompletionButtonState(SubmissionStatus.IDLE);
	}, true);

	useEffect(() => {
		modalDialogue.setFooter(
			<ButtonGroup>
				<ButtonGhost
					label={l10n.translate('omsInstallationPartnerOfficeApp.task.gridOperatorRegistration.button.close')}
					onClick={handleCancel}
				/>
				<ButtonPrimary
					label={l10n.translate('omsInstallationPartnerOfficeApp.task.gridOperatorRegistration.button.submit')}
					submissionStatus={taskCompletionButtonState}
					onClick={handleTaskCompletion}
					disabled={registrationUnnecessary === null || completionActionStatus !== TaskActionStatus.IDLE}
				/>
			</ButtonGroup>
		);
		return () => {
			modalDialogue.unsetFooter();
		};
	}, [taskCompletionButtonState, registrationUnnecessary, completionActionStatus]);

	const handleCancel = (): void => {
		modalDialogueManager.pop();
	};

	const handleDocumentChange = (files: ReadonlyArray<File>): void => {
		documentSelection.current = [...files];
	};

	const handleRegistrationUnnecessarySelect = (value: boolean) => {
		setRegistrationUnnecessary(value);
	};

	const handleRegistrationUnnecessaryDeselect = () => {
		setRegistrationUnnecessary(null);
	};

	const handleComment = (comment: string) => {
		const handledComment = comment.length === 0 ? null : comment;
		setComment(handledComment);
	};

	const handleTaskCompletion = () => {
		setCompletionRequested(true);
	};

	const getMaxFileSize = (): number => {
		return numberFromString(new Optional(process.env['NX_MAX_UPLOAD_FILE_SIZE']).get(), Number.MAX_SAFE_INTEGER);
	};

	return (
		<>
			<CollectionItemContentSection>
				<SectionHeader heading={l10n.translate('omsInstallationPartnerOfficeApp.task.gridOperatorRegistration.gridOperator.heading')} />
				<GridOperatorInformation gridOperator={task.payload.gridOperator} />
			</CollectionItemContentSection>

			<CollectionItemContentSection>
				<SectionHeader heading={l10n.translate('omsInstallationPartnerOfficeApp.task.gridOperatorRegistration.wallboxPower.heading')} />
				<Hint
					heading={l10n.translate(
						'omsInstallationPartnerOfficeApp.task.gridOperatorRegistration.wallboxPower.hint',
						new Map([['wallboxPower', l10n.formatNumber(task.payload.wallboxPower, 0)]])
					)}
					level={HintLevel.INFO}
					icon={IconIdentifier.BATTERY_CHARGING_VERTICAL}
				/>
			</CollectionItemContentSection>

			<CollectionItemContentSection>
				<SectionHeader heading={l10n.translate('omsInstallationPartnerOfficeApp.task.gridOperatorRegistration.documents.heading')} />
				<Upload
					maxFileSize={getMaxFileSize()}
					files={documentSelection.current}
					onChange={handleDocumentChange}
				/>
			</CollectionItemContentSection>

			<CollectionItemContentSection>
				<SectionHeader heading={l10n.translate('omsInstallationPartnerOfficeApp.task.gridOperatorRegistration.registrationRequired.heading')} />

				<TaskChoice<boolean>
					isSelected={registrationUnnecessary === false}
					label={l10n.translate('omsInstallationPartnerOfficeApp.task.gridOperatorRegistration.registrationRequired.required')}
					onSelect={handleRegistrationUnnecessarySelect}
					onDeselect={handleRegistrationUnnecessaryDeselect}
					value={false}>
				</TaskChoice>

				<Separator />

				<TaskChoice<boolean>
					isSelected={registrationUnnecessary === true}
					label={l10n.translate('omsInstallationPartnerOfficeApp.task.gridOperatorRegistration.registrationRequired.unnecessary')}
					onSelect={handleRegistrationUnnecessarySelect}
					onDeselect={handleRegistrationUnnecessaryDeselect}
					value={true}>
				</TaskChoice>

				<Separator />
			</CollectionItemContentSection>

			<CollectionItemContentSection>
				<SectionHeader heading={l10n.translate('omsInstallationPartnerOfficeApp.task.gridOperatorRegistration.notes.heading')} />
				<InputTextArea
					placeholder={l10n.translate('omsInstallationPartnerOfficeApp.task.gridOperatorRegistration.notes.placeholder')}
					rows={4}
					onChange={handleComment}
				/>
			</CollectionItemContentSection>

		</>
	);
}
