import { Navigate, Route } from 'react-router-dom';

import { useSentryRoutes } from '@abb-emobility/shared/sentry-integration';

import { RoutePath, useRouteUrl } from './Routes';
import { DashboardView } from '../views/dashboard-view/DashboardView';
import { NotFoundView } from '../views/not-found-view/NotFoundView';
import { OrderView } from '../views/order-view/OrderView';
import { OrdersView } from '../views/orders-view/OrdersView';

export function Router() {

	const { createRouteUrl } = useRouteUrl();

	// eslint-disable-next-line @typescript-eslint/naming-convention
	const Routes = useSentryRoutes();

	return (
		<Routes>
			<Route path={createRouteUrl(RoutePath.ROOT)} element={<Navigate to={createRouteUrl(RoutePath.TASKS)} replace />} />
			<Route path={createRouteUrl(RoutePath.TASKS)} element={<DashboardView />} />
			<Route path={createRouteUrl(RoutePath.TASK)} element={<DashboardView />} />
			<Route path={createRouteUrl(RoutePath.ORDERS)} element={<OrdersView />} />
			<Route path={createRouteUrl(RoutePath.ORDER)} element={<OrderView />} />
			<Route path={createRouteUrl(RoutePath.ORDER_TASK)} element={<OrderView />} />
			<Route path="*" element={<NotFoundView />} />
		</Routes>
	);

}
