import { ReactNode, useState } from 'react';

import { breadcrumbsContext } from './Breadcrumbs.context';
import { BreadcrumbEntry } from './Breadcrumbs.types';
import { buildBreadcrumbEntriesChecksum } from './Breadcrumbs.util';

export type BreadcrumbsProps = {
	children: ReactNode
};

export function Breadcrumbs(props: BreadcrumbsProps) {

	const { children } = props;

	const [breadcrumbs, setBreadcrumbs] = useState<Array<BreadcrumbEntry>>([]);

	const providerValue = {
		setBreadcrumbEntries: (entries: Array<BreadcrumbEntry>): void => {
			if (buildBreadcrumbEntriesChecksum(breadcrumbs) === buildBreadcrumbEntriesChecksum(entries)) {
				return;
			}
			setBreadcrumbs(entries);
		},
		getBreadcrumbEntries: (): Array<BreadcrumbEntry> => {
			return breadcrumbs;
		}
	};

	return (
		<breadcrumbsContext.Provider value={providerValue}>
			{children}
		</breadcrumbsContext.Provider>
	);

}
