import { InstallerAppointmentModel } from '@abb-emobility/oms/domain-model';

export type GroupedTradeAppointment = {
	tradeName: string,
	installerAppointments: Array<InstallerAppointmentModel>
};

export const groupInstallerAppointmentsByTrades = (installerAppointments: ReadonlyArray<InstallerAppointmentModel>): ReadonlyArray<GroupedTradeAppointment> => {
	const result: Array<GroupedTradeAppointment> = [];

	installerAppointments.forEach((installerAppointment) => {
		const availableResult = result.find(item => item.tradeName === installerAppointment.trade.name);

		if (availableResult) {
			availableResult.installerAppointments.push(installerAppointment);
			return;
		} else {
			result.push({
				tradeName: installerAppointment.trade.name,
				installerAppointments: [installerAppointment]
			});
		}
	});

	return result;
};
