import { withProfiler } from '@sentry/react';
import React, { ReactElement, ReactNode } from 'react';
import { useDispatch } from 'react-redux';

import { AuthProvider, AuthProviderInterceptingProps } from '@abb-emobility/shared/auth-provider';
import { CandidateGroup, useCandidateGroups } from '@abb-emobility/shared/candidate-groups-provider';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { useEnv } from '@abb-emobility/shared/environment';
import { AppError, ErrorHandler, SilentErrorHandler, WebSocketError } from '@abb-emobility/shared/error';
import { useIdentity } from '@abb-emobility/shared/identity-provider';
import { KeycloakApiClientFactory, TokenResponseModel } from '@abb-emobility/shared/keycloak-integration';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { EventType, LogLevel, useLogger } from '@abb-emobility/shared/logger';
import { useNetworkAvailable } from '@abb-emobility/shared/network';
import { OauthEmitterFactory } from '@abb-emobility/shared/ui-auth-emitter';
import { AcceptanceInterceptor, ErrorFeedback, ModalDialogueManager, ScrollToTop, ToastManager } from '@abb-emobility/shared/ui-primitive';
import { Optional } from '@abb-emobility/shared/util';
import { WebsocketProvider } from '@abb-emobility/shared/websocket';

import { AppContainer } from './components/app-container/AppContainer';
import { DefaultErrorView } from './views/default-error-view/DefaultErrorView';
import { TermsDe } from '../l10n/terms/TermsDe';
import { TermsEn } from '../l10n/terms/TermsEn';
import { TermsEs } from '../l10n/terms/TermsEs';
import { TermsFr } from '../l10n/terms/TermsFr';
import { TermsIt } from '../l10n/terms/TermsIt';
import { TermsNl } from '../l10n/terms/TermsNl';
import { TermsPt } from '../l10n/terms/TermsPt';
import { TermsSv } from '../l10n/terms/TermsSv';
import { UNAUTHENTICATE_SIGNAL } from '../store/store';

type AdditionalTokenResponse = {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	user_id?: ModelPrimaryKey,
	// eslint-disable-next-line @typescript-eslint/naming-convention
	user_fullname?: string,
	// eslint-disable-next-line @typescript-eslint/naming-convention
	user_mail_address?: string,
	// eslint-disable-next-line @typescript-eslint/naming-convention
	user_candidate_groups?: ReadonlyArray<CandidateGroup>,
	// eslint-disable-next-line @typescript-eslint/naming-convention
	available_candidate_groups?: ReadonlyArray<CandidateGroup>
};

export function App() {

	const env = useEnv();
	const logger = useLogger();
	const l10n = useL10n();
	const dispatch = useDispatch();
	const identity = useIdentity();
	const candidateGroups = useCandidateGroups();
	const networkStatus = useNetworkAvailable();

	if (!networkStatus) {
		logger?.logEvent('Lost network connection', { type: EventType.INFO }, 'network', LogLevel.INFO);
		return (
			<ErrorFeedback
				heading={l10n.translate('omsInstallationPartnerOfficeApp.error.offline.heading')}
				message={l10n.translate('omsInstallationPartnerOfficeApp.error.offline.message')}
			/>
		);
	}

	const oauthRealm = env.get<string>('oauthRealm').getOrThrow(new AppError('Oauth realm unavailable'));
	const stompSocketUrl = new Optional(process.env['NX_FRONTEND_NOTIFICATION_STOMP_SOCKET_URL'])
		.getOrThrow(new AppError('STOMP socket URL unavailable'));

	const handleReauthenticate = () => {
		logger?.logEvent('Reauthenticate', { type: EventType.USER }, 'oauth', LogLevel.INFO);
		identity.unsetIdentity();
		candidateGroups.unsetAvailableCandidateGroups();
		dispatch({ type: UNAUTHENTICATE_SIGNAL });
	};

	const handleUnauthenticate = () => {
		logger?.logEvent('Unauthenticate', { type: EventType.USER }, 'oauth', LogLevel.INFO);
		identity.unsetIdentity();
		candidateGroups.unsetAvailableCandidateGroups();
		dispatch({ type: UNAUTHENTICATE_SIGNAL });
		try {
			const oauthBaseUrl = new Optional(process.env['NX_KEYCLOAK_API_BASE_URL'])
				.getOrThrow(new AppError('Oauth base URL unavailable'));
			KeycloakApiClientFactory.create().logout(oauthBaseUrl, oauthRealm);
		} catch (e) {
			console.warn(e);
		}
	};

	const handleTokenReceived = (oauthTokenResponse: TokenResponseModel & AdditionalTokenResponse) => {
		logger?.logEvent('Authenticate', { type: EventType.USER }, 'oauth', LogLevel.INFO);
		identity.setIdentity({
			userId: oauthTokenResponse.user_id,
			fullname: oauthTokenResponse.user_fullname,
			mailAddress: oauthTokenResponse.user_mail_address,
			candidateGroups: oauthTokenResponse.user_candidate_groups ? [...oauthTokenResponse.user_candidate_groups] : []
		});
		candidateGroups.setAvailableCandidateGroups(oauthTokenResponse.available_candidate_groups ? [...oauthTokenResponse.available_candidate_groups] : []);
	};

	const renderAuthEmitter = (): ReactElement<AuthProviderInterceptingProps> => {
		return (<OauthEmitterFactory<AdditionalTokenResponse> onTokenReceived={handleTokenReceived} />);
	};

	const handleWebsocketError = (error: Error): boolean => {
		if (error instanceof WebSocketError) {
			logger?.logError(error, LogLevel.INFO);
			return true;
		}
		return false;
	};

	const renderTerms = (): ReactNode => {
		switch (l10n.language) {
			case 'de':
				return (<TermsDe />);
			case 'es':
				return (<TermsEs />);
			case 'fr':
				return (<TermsFr />);
			case 'it':
				return (<TermsIt />);
			case 'nl':
				return (<TermsNl />);
			case 'pt':
				return (<TermsPt />);
			case 'sv':
				return (<TermsSv />);
			default:
				return (<TermsEn />);
		}
	};

	return (
		<ErrorHandler errorComponent={DefaultErrorView}>
			<AuthProvider
				interceptingComponent={renderAuthEmitter}
				onReauthenticate={handleReauthenticate}
				onUnauthenticate={handleUnauthenticate}
				authScope={oauthRealm}
			>
				<SilentErrorHandler handleError={handleWebsocketError} transparent={true}>
					<WebsocketProvider socketUrl={stompSocketUrl} onError={handleWebsocketError}>
						<ToastManager>
							<ModalDialogueManager>
								<AcceptanceInterceptor localStorageKey="installer-terms-v1" renderTerms={renderTerms}>
									<AppContainer />
								</AcceptanceInterceptor>
							</ModalDialogueManager>
						</ToastManager>
					</WebsocketProvider>
				</SilentErrorHandler>
			</AuthProvider>
			<ScrollToTop />
		</ErrorHandler>
	);
}

export const AppWithSentryProfiler = withProfiler(App);
