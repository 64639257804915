import React, { useEffect, useState } from 'react';

import { TaskActionStatus } from '@abb-emobility/shared/data-provider-foundation';
import { sortableValueFromInstallationPeriod, TradeAppointment } from '@abb-emobility/shared/domain-model';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	ButtonGhost,
	ButtonGroup,
	ButtonPrimary,
	CollectionItemContentSection,
	Hint,
	HintLevel,
	Icon,
	IconIdentifier,
	InputTextArea,
	SectionHeader,
	SubmissionStatus,
	useModalDialogue,
	useModalDialogueManager
} from '@abb-emobility/shared/ui-primitive';
import { Nullable } from '@abb-emobility/shared/util';
import { useAnyTaskEntityData } from '@abb-emobility/usertask/data-provider';
import {
	ArrangeAppointmentAppointmentType,
	ArrangeAppointmentInstallationType,
	ArrangeAppointmentTaskModel
} from '@abb-emobility/usertask/domain-model';

import { TaskTradeAppointmentCraft } from '../components/TaskTradeAppointmentCraft';

import './TaskArrangeInstallationAppointment.scss';

export type ArrangeInstallationAppointmentProps = {
	task: ArrangeAppointmentTaskModel
};

export function TaskArrangeInstallationAppointment(props: ArrangeInstallationAppointmentProps) {

	const { task } = props;

	const l10n = useL10n();
	const modalDialogue = useModalDialogue();
	const modalDialogueManager = useModalDialogueManager();

	const [tradeAppointments, setTradeAppointments] = useState<Map<string, TradeAppointment>>(new Map());
	const [comment, setComment] = useState<Nullable<string>>(null);
	const [taskCompletionButtonState, setTaskCompletionButtonState] = useState<SubmissionStatus>(SubmissionStatus.IDLE);

	const anyTaskData = useAnyTaskEntityData();
	const completionState = anyTaskData.queryActionStatus();

	const rescheduleInfoText = l10n.translate('omsInstallationPartnerOfficeApp.task.arrangeInstallationAppointment.rescheduleInfo');
	const replacementInfoText = l10n.translate('omsInstallationPartnerOfficeApp.task.arrangeInstallationAppointment.replacementInfo');

	anyTaskData.useActionStatusEffect([TaskActionStatus.COMPLETE_PENDING], () => {
		setTaskCompletionButtonState(SubmissionStatus.PENDING);
	}, false);

	anyTaskData.useActionStatusEffect([TaskActionStatus.COMPLETE_SUCCESS], () => {
		setTaskCompletionButtonState(SubmissionStatus.DONE);
	}, true);

	anyTaskData.useActionStatusEffect([TaskActionStatus.COMPLETE_FAILED], () => {
		setTaskCompletionButtonState(SubmissionStatus.IDLE);
	}, true);

	const hasValidAppointmentTrades = (): boolean => {
		const tradeAppointmentList = Array.from(tradeAppointments.values());
		if (tradeAppointmentList.length === 0) {
			return false;
		}
		for (const tradeAppointment of tradeAppointmentList) {
			if (tradeAppointment.installerAppointments.length === 0) {
				return false;
			}
		}
		return true;
	};

	const buildTradeAppointmentsPayload = (): Array<TradeAppointment> => {
		const result: Array<TradeAppointment> = [];

		Array.from(tradeAppointments.values()).forEach((tradeAppointment) => {
			const existingItem = result.find(item => item.tradeId === tradeAppointment.tradeId);

			if (existingItem) {
				existingItem.installerAppointments.push(...tradeAppointment.installerAppointments);
			} else {
				result.push({ tradeId: tradeAppointment.tradeId, installerAppointments: [...tradeAppointment.installerAppointments] });
			}
		});
		return result;
	};

	useEffect(() => {
		modalDialogue.setFooter(
			<ButtonGroup>
				<ButtonGhost
					label={l10n.translate('omsInstallationPartnerOfficeApp.task.arrangeInstallationAppointment.button.close')}
					onClick={handleCancel}
				/>
				<ButtonPrimary
					label={l10n.translate('omsInstallationPartnerOfficeApp.task.arrangeInstallationAppointment.button.submit')}
					submissionStatus={taskCompletionButtonState}
					onClick={handleTaskCompletion}
					disabled={completionState !== TaskActionStatus.IDLE || !hasValidAppointmentTrades()}
				/>
			</ButtonGroup>
		);
		return () => {
			modalDialogue.unsetFooter();
		};
	}, [taskCompletionButtonState, completionState, hasValidAppointmentTrades(), comment, tradeAppointments]);

	const handleTaskCompletion = (() => {
		const payload = {
			...task.payload,
			tradeAppointments: buildTradeAppointmentsPayload(),
			comment: comment ?? undefined
		};
		anyTaskData.complete(task, payload);
	});

	const handleCancel = (): void => {
		modalDialogueManager.pop();
	};

	const handleComment = (comment: string) => {
		const handledComment = comment.length === 0 ? null : comment;
		setComment(handledComment);
	};

	const renderTrades = () => {
		return [...task.payload.requestedTrades]
			.sort((left, right): number => {
				const leftInstallationPeriod = sortableValueFromInstallationPeriod(left.installationPeriod);
				const rightInstallationPeriod = sortableValueFromInstallationPeriod(right.installationPeriod);
				if (leftInstallationPeriod === rightInstallationPeriod) {
					const leftName = left.trade.name;
					const rightName = right.trade.name;
					if (leftName === rightName) {
						return 0;
					}
					return leftName < rightName ? -1 : 1;
				}
				return leftInstallationPeriod < rightInstallationPeriod ? -1 : 1;
			})
			.map((availableTrade) => {
				const handleTradeChange = (trade: TradeAppointment) => {
					setTradeAppointments((new Map(tradeAppointments)).set(trade.tradeId + availableTrade.installationPeriod, trade));
				};

				return (
					<TaskTradeAppointmentCraft
						key={availableTrade.trade.id + availableTrade.installationPeriod}
						earliestInstallationDate={task.payload.earliestInstallationDate}
						availableTrade={availableTrade}
						onChange={handleTradeChange}
					/>
				);
			});
	};

	const renderAppointmentTypeNotification = () => {
		if (task.payload.appointmentType !== ArrangeAppointmentAppointmentType.RESCHEDULED) {
			return null;
		}
		return (
			<Hint
				heading={rescheduleInfoText}
				level={HintLevel.INFO}
				icon={IconIdentifier.INFO}
				gray={true}
			/>
		);
	};

	const renderInstallationTypeNotification = () => {
		if (task.payload.installationType !== ArrangeAppointmentInstallationType.REPLACEMENT) {
			return null;
		}
		return (
			<Hint
				heading={replacementInfoText}
				level={HintLevel.INFO}
				icon={IconIdentifier.INFO}
				gray={true}
			/>
		);
	};

	return (
		<>
			<CollectionItemContentSection>
				{renderAppointmentTypeNotification()}
				{renderInstallationTypeNotification()}
				<SectionHeader heading={l10n.translate('omsInstallationPartnerOfficeApp.task.arrangeInstallationAppointment.contact.heading')} />
				<article className="task-arrange-installation-appointment__contact">
					<section className="task-arrange-installation-appointment__contact__sections">
						<section className="task-arrange-installation-appointment__contact__sections__section">
							<span className="task-arrange-installation-appointment__contact__sections__section__icon">
								<Icon name={IconIdentifier.USER_CIRCLE} />
							</span>
							{task.payload.customer.name}
						</section>
						<section className="task-arrange-installation-appointment__contact__sections__section">
							<span className="task-arrange-installation-appointment__contact__sections__section__icon">
								<Icon name={IconIdentifier.PHONE} />
							</span>
							<a href={`tel:${task.payload.customer.phoneNumber}`}>{task.payload.customer.phoneNumber}</a>
						</section>
					</section>
				</article>
			</CollectionItemContentSection>

			<SectionHeader heading={l10n.translate('omsInstallationPartnerOfficeApp.task.arrangeInstallationAppointment.heading')} />

			<section className="task-arrange-installation-appointment__trade-appointments">
				{renderTrades()}
			</section>

			<CollectionItemContentSection>
				<SectionHeader heading={l10n.translate('omsInstallationPartnerOfficeApp.task.arrangeInstallationAppointment.notes.heading')} />
				<InputTextArea
					placeholder={l10n.translate('omsInstallationPartnerOfficeApp.task.arrangeInstallationAppointment.notes.placeholder')}
					rows={4}
					onChange={handleComment}
				/>
			</CollectionItemContentSection>

		</>
	);
}
