import React, { ReactNode, useEffect, useRef, useState } from 'react';

import { OrderInstallerAppointmentCollectionDataProvider } from '@abb-emobility/oms/data-provider';
import { TaskActionStatus } from '@abb-emobility/shared/data-provider-foundation';
import { createUploadFileFromFile } from '@abb-emobility/shared/domain-model-foundation';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { UiErrorHandler } from '@abb-emobility/shared/ui-error-handler';
import {
	ButtonGhost,
	ButtonGroup,
	ButtonPrimary,
	CollectionItemContentSection,
	CustomerContactInformation,
	InputTextArea,
	SectionHeader,
	Separator,
	SpinnerCircle,
	SubmissionStatus,
	Upload,
	useModalDialogue,
	useModalDialogueManager
} from '@abb-emobility/shared/ui-primitive';
import { Nullable, numberFromString, Optional } from '@abb-emobility/shared/util';
import { useAnyTaskEntityData } from '@abb-emobility/usertask/data-provider';
import { InstallationCompletionPayloadModel, InstallationCompletionTaskModel } from '@abb-emobility/usertask/domain-model';

import { InstallerAppointmentFlatCollection } from '../../installer-appointment-collection/InstallerAppointmentFlatCollection';
import { TaskChoice } from '../components/TaskChoice';

import './TaskInstallationComplete.scss';

export type TaskInstallationCompleteProps = {
	task: InstallationCompletionTaskModel
};

export function TaskInstallationComplete(props: TaskInstallationCompleteProps) {

	const { task } = props;

	const documentSelection = useRef<Array<File>>([]);

	const l10n = useL10n();
	const modalDialogue = useModalDialogue();
	const modalDialogueManager = useModalDialogueManager();
	const anyTaskData = useAnyTaskEntityData();

	const [completionRequested, setCompletionRequested] = useState<boolean>(false);
	const [customerOnSite, setCustomerOnSite] = useState<Nullable<boolean>>(task.payload.customerIsPresent ?? null);
	const [comment, setComment] = useState<Nullable<string>>(task.payload.comment ?? null);
	const [taskCompletionButtonState, setTaskCompletionButtonState] = useState<SubmissionStatus>(SubmissionStatus.IDLE);
	const completionActionStatus = anyTaskData.queryActionStatus();

	useEffect((): void => {
		if (!completionRequested) {
			return;
		}
		const completeTask = async (): Promise<void> => {
			const payload: InstallationCompletionPayloadModel = { ...task.payload };
			const uploadFilePromises = documentSelection.current.map(async (file) => {
				return createUploadFileFromFile(file);
			});
			payload.documents = await Promise.all(uploadFilePromises);
			payload.customerIsPresent = customerOnSite ?? false;
			payload.comment = comment ?? undefined;
			anyTaskData.complete(task, payload);
		};
		void completeTask();
	}, [completionRequested]);

	anyTaskData.useActionStatusEffect([TaskActionStatus.COMPLETE_PENDING], () => {
		setTaskCompletionButtonState(SubmissionStatus.PENDING);
	}, false);

	anyTaskData.useActionStatusEffect([TaskActionStatus.COMPLETE_SUCCESS], () => {
		setTaskCompletionButtonState(SubmissionStatus.DONE);
	}, true);

	anyTaskData.useActionStatusEffect([TaskActionStatus.COMPLETE_FAILED], () => {
		setTaskCompletionButtonState(SubmissionStatus.IDLE);
	}, true);

	useEffect(() => {
		modalDialogue.setFooter(
			<ButtonGroup>
				<ButtonGhost
					label={l10n.translate('omsInstallationPartnerOfficeApp.task.installationComplete.button.close')}
					onClick={handleCancel}
				/>
				<ButtonPrimary
					label={l10n.translate('omsInstallationPartnerOfficeApp.task.installationComplete.button.submit')}
					submissionStatus={taskCompletionButtonState}
					onClick={handleTaskCompletion}
					disabled={completionActionStatus !== TaskActionStatus.IDLE || customerOnSite === null}
				/>
			</ButtonGroup>
		);
		return () => {
			modalDialogue.unsetFooter();
		};
	}, [taskCompletionButtonState, customerOnSite]);

	const handleCancel = (): void => {
		modalDialogueManager.pop();
	};

	const handleDocumentChange = (files: ReadonlyArray<File>): void => {
		documentSelection.current = [...files];
	};

	const handleCustomerAcceptanceOnSiteSelection = (onSite: boolean): void => {
		setCustomerOnSite(onSite);
	};

	const handleCustomerAcceptanceOnSiteDeselection = (): void => {
		setCustomerOnSite(null);
	};

	const handleComment = (comment: string) => {
		const handledComment = comment.length === 0 ? null : comment;
		setComment(handledComment);
	};

	const handleTaskCompletion = () => {
		setCompletionRequested(true);
	};

	const renderThrottleNote = (): ReactNode => {
		if (!task.payload.throttleWallboxPowerConsumption) {
			return null;
		}
		return (
			<div className="installation-complete__wallbox">
				<span className="installation-complete__wallbox__description">{l10n.translate('omsInstallationPartnerOfficeApp.task.installationComplete.wallbox.throttle')}</span>
			</div>
		);
	};

	const getMaxFileSize = (): number => {
		return numberFromString(new Optional(process.env['NX_MAX_UPLOAD_FILE_SIZE']).get(), Number.MAX_SAFE_INTEGER);
	};

	return (
		<>
			{/*Contact*/}
			<CollectionItemContentSection>
				<SectionHeader heading={l10n.translate('omsInstallationPartnerOfficeApp.task.installationComplete.contact.heading')} />
				<CustomerContactInformation
					name={task.payload.customer.name}
					phoneNumber={task.payload.customer.phoneNumber}
				/>
			</CollectionItemContentSection>

			{/*Installation*/}
			<CollectionItemContentSection>
				<SectionHeader heading={l10n.translate('omsInstallationPartnerOfficeApp.task.installationComplete.trades.heading')} />
				<UiErrorHandler>
					<OrderInstallerAppointmentCollectionDataProvider
						orderId={task.orderId}
						pendingComponent={SpinnerCircle}
					>
						<InstallerAppointmentFlatCollection />
					</OrderInstallerAppointmentCollectionDataProvider>
				</UiErrorHandler>
			</CollectionItemContentSection>

			{/*Upload*/}
			<CollectionItemContentSection>
				<SectionHeader heading={l10n.translate('omsInstallationPartnerOfficeApp.task.installationComplete.documents.heading')} />
				<Upload
					maxFileSize={getMaxFileSize()}
					files={documentSelection.current}
					onChange={handleDocumentChange}
				/>
			</CollectionItemContentSection>

			{/*Customer decision*/}
			<CollectionItemContentSection>
				<SectionHeader heading={l10n.translate('omsInstallationPartnerOfficeApp.task.installationComplete.customerAcceptance.heading')} />

				<TaskChoice<boolean>
					isSelected={customerOnSite === true}
					label={l10n.translate('omsInstallationPartnerOfficeApp.task.installationComplete.customerAcceptance.onSite')}
					onSelect={handleCustomerAcceptanceOnSiteSelection}
					onDeselect={handleCustomerAcceptanceOnSiteDeselection}
					value={true}>
				</TaskChoice>

				<Separator />

				<TaskChoice<boolean>
					isSelected={customerOnSite === false}
					label={l10n.translate('omsInstallationPartnerOfficeApp.task.installationComplete.customerAcceptance.remote')}
					onSelect={handleCustomerAcceptanceOnSiteSelection}
					onDeselect={handleCustomerAcceptanceOnSiteDeselection}
					value={false}>
				</TaskChoice>

				<Separator />
			</CollectionItemContentSection>

			{/*Text0*/}
			<CollectionItemContentSection>
				<SectionHeader heading={l10n.translate('omsInstallationPartnerOfficeApp.task.installationComplete.wallbox.heading')} />
				<div className="installation-complete">
					<div className="installation-complete__wallbox">
						<span className="installation-complete__wallbox__description">
							{l10n.translate('omsInstallationPartnerOfficeApp.task.installationComplete.wallbox.serial', new Map([['serialNumber', task.payload.wallboxSerialNumber]]))}
						</span>
					</div>
					{renderThrottleNote()}
				</div>
			</CollectionItemContentSection>

			{/*Notes*/}
			<CollectionItemContentSection>
				<SectionHeader heading={l10n.translate('omsInstallationPartnerOfficeApp.task.installationComplete.notes.heading')} />
				<InputTextArea
					placeholder={l10n.translate('omsInstallationPartnerOfficeApp.task.installationComplete.notes.placeholder')}
					rows={4}
					onChange={handleComment}
				/>
			</CollectionItemContentSection>

		</>
	);
}
