import React, { ReactNode } from 'react';

import { InstallerModel } from '@abb-emobility/shared/domain-model';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { DaynameFormat, useL10n } from '@abb-emobility/shared/localization-provider';
import { Gravatar, GravatarSize, Icon, IconIdentifier } from '@abb-emobility/shared/ui-primitive';
import { Nullable } from '@abb-emobility/shared/util';

import { GroupedTradeAppointment } from './InstallerAppointmentCollection.util';

import './InstallerAppointmentCollectionItem.scss';

type Props = {
	tradeAppointment: GroupedTradeAppointment
};

export function InstallerAppointmentCollectionItem(props: Props) {
	const { tradeAppointment } = props;

	const l10n = useL10n();

	const renderInstaller = (installer: InstallerModel): ReactNode => {
		return (
			<>
				<Gravatar name={installer.name} mailAddress={installer.mailAddress} size={GravatarSize.EXTRA_LARGE} />
				<section className="installer-appointment-collection-item__appointments__row__contact">
					<div className="installer-appointment-collection-item__appointments__row__contact__name"> {installer.name ?? '—'} </div>
					<div className="installer-appointment-collection-item__appointments__row__contact__link">
						<span>
							<a href={`tel:phoneNumber`}>{installer.phoneNumber}</a>
						</span>
						<span>
							<a href={`mailto:${installer.mailAddress}`}>{installer.mailAddress}</a>
						</span>
					</div>
				</section>
			</>
		);
	};

	const renderTradeAppointment = () => {
		let lastInstallerId: Nullable<ModelPrimaryKey> = null;
		return tradeAppointment.installerAppointments.map((installerAppointment) => {
			const dayName = l10n.formatTimestampDayname(installerAppointment.period.start, 'Day', DaynameFormat.SHORT);
			const date = l10n.formatTimestampDate(installerAppointment.period.start);
			const time = l10n.formatTimestampTime(installerAppointment.period.start) + ' - ' + l10n.formatTimestampTime(installerAppointment.period.end);

			const installer = installerAppointment.installer.id !== lastInstallerId ? renderInstaller(installerAppointment.installer) : null;
			lastInstallerId = installerAppointment.installer.id;

			return (
				<div key={installerAppointment.id} className="installer-appointment-collection-item__appointments__row">
					{installer}
					<section className="installer-appointment-collection-item__appointments__row__items">
						<div className="installer-appointment-collection-item__appointments__row__items__item">
							<span className="installer-appointment-collection-item__appointments__row__items__item__block">
								<Icon name={IconIdentifier.CALENDAR_BLANK} />
								<span className="installer-appointment-collection-item__appointments__row__items__item__block__date">
									<span className="installer-appointment-collection-item__appointments__row__items__item__block__date__day">{dayName}</span>
									<span> {date}</span>
								</span>
							</span>
							<span className="installer-appointment-collection-item__appointments__row__items__item__block">
								<Icon name={IconIdentifier.CLOCK} />
								<span className="installer-appointment-collection-item__appointments__row__items__item__block__time">{time}</span>
							</span>
						</div>
					</section>
				</div>
			);
		});
	};

	return (
		<section className="installer-appointment-collection-item">
			<h1 className="installer-appointment-collection-item__heading">
				{tradeAppointment.tradeName}
			</h1>
			<div className="installer-appointment-collection-item__appointments">
				{renderTradeAppointment()}
			</div>
		</section>
	);
}
