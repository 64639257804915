import { InstallationTradeEffortModel } from './InstallationTradeEffortModel';

export const isInstallationTradeEffort = (tradeEffort: unknown): tradeEffort is InstallationTradeEffortModel => {
	return (tradeEffort as InstallationTradeEffortModel).tradeId !== undefined
		&& (tradeEffort as InstallationTradeEffortModel).period !== undefined
		&& (tradeEffort as InstallationTradeEffortModel).effortInHours !== undefined;
};

export const hasApprovableInstallationTradeEffort = (tradeEfforts: Array<InstallationTradeEffortModel>): boolean => {
	return tradeEfforts.reduce<boolean>((previousValue, currentValue) => {
		return currentValue.approvable || previousValue;
	}, false);
};
