import { PreCheckMediumModel, PreCheckMediumType } from '@abb-emobility/shared/domain-model';
import { TimeOfDay } from '@abb-emobility/shared/domain-model-foundation';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { l10nLiteralFromEnumValue } from '@abb-emobility/shared/util';

import { Icon } from '../icon/Icon';
import { IconIdentifier } from '../icon/Icon.enums';
import { Tag } from '../tag/Tag';

import './CustomerContactInformation.scss';

type TaskContactProps = {
	name: string,
	phoneNumber: string,
	label?: string,
	items?: Array<TimeOfDay>,
	medium?: PreCheckMediumModel
};

export function CustomerContactInformation(props: TaskContactProps) {
	const { name, phoneNumber, label, items, medium } = props;

	const l10n = useL10n();

	const renderItems = () => {
		if (items) {
			return (
				<section className="task-contact__items">
					{
						items.map((item, index) => {
							return <Tag label={l10n.translate(l10nLiteralFromEnumValue(item, 'sharedUiPrimitive.customerContactInformation.timeOfDay'))} key={index} />;
						})
					}
				</section>
			);
		}
		return;
	};

	const renderContactIcon = () => {
		if (medium) {
			switch (medium.type) {
				case PreCheckMediumType.VIDEO_CALL:
					return (<Icon name={IconIdentifier.PHONE} />);
				default:
					return (<Icon name={IconIdentifier.CAMERA} />);
			}
		}

		return <Icon name={IconIdentifier.PHONE} />;
	};

	const renderContactLink = () => {
		if (medium) {
			return (
				<>
					<span>{medium.name}</span>
					<a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
				</>
			);
		} else if (!medium && phoneNumber) {
			return (
				<a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
			);
		}

		return;
	};

	const renderLabel = () => {
		if (label) {
			return (
				<section className="task-contact__sections__section">
					<span className="task-contact__sections__section__icon">
						<Icon name={IconIdentifier.CLOCK} />
					</span>
					{label}
				</section>
			);
		}

		return;
	};

	return (
		<article className="task-contact">
			<section className="task-contact__sections">
				<section className="task-contact__sections__section">
					<span className="task-contact__sections__section__icon">
						<Icon name={IconIdentifier.USER_CIRCLE} />
					</span>
					{name}
				</section>
				<section className="task-contact__sections__section">
					<span className="task-contact__sections__section__icon">
						{renderContactIcon()}
					</span>
					{renderContactLink()}
				</section>
				{renderLabel()}
			</section>

			{renderItems()}
		</article>
	);
}
