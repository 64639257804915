import { ReactNode } from 'react';

import './Actionbar.scss';

export type ActionbarProps = {
	label: string,
	children?: ReactNode
};

export function Actionbar(props: ActionbarProps) {

	const { label, children } = props;

	const renderChildren = () => {
		if (children) {
			return (
				<div className="actionbar__items">
					{children}
				</div>
			);
		}

		return;
	};

	return (
		<section className="actionbar">
			<h1 className="actionbar__label">
				{label}
			</h1>
			{renderChildren()}
		</section>
	);
}
