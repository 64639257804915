import React, { useEffect, useRef, useState } from 'react';

import { scrollElementIntoView } from '@abb-emobility/shared/browser';
import { calculateMajorUnitFromMinorUnit, Currency, getCurrencyByCode, getCurrencySymbolByCurrency } from '@abb-emobility/shared/currency';
import { InstallationPartnerDeltaInstallationPackageModel } from '@abb-emobility/shared/domain-model';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { AppError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { Tabbar, TabbarItem } from '@abb-emobility/shared/ui-primitive';
import { Nullable } from '@abb-emobility/shared/util';

import { TaskOfferCollectionItem } from './TaskOfferCollectionItem';

import './TaskOfferCollection.scss';

type TaskOfferCollectionProps = {
	deltaInstallationPackages: Array<InstallationPartnerDeltaInstallationPackageModel>,
	onUpdate: (selectedPackageAmount: Map<ModelPrimaryKey, number>) => void
};

type Category = {
	name: string,
	fullPrice: number,
	currency: Nullable<Currency>
};

export function TaskOfferCollection(props: TaskOfferCollectionProps) {

	const { deltaInstallationPackages, onUpdate } = props;

	const l10n = useL10n();

	const offerCollectionRef = useRef<HTMLElement>(null);
	const [selectedPackageAmount, setSelectedPackageAmount] = useState<Map<ModelPrimaryKey, number>>(new Map());
	const [categoryTabs, setCategoryTabs] = useState<Array<Category>>([]);
	const [selectedCategory, setSelectedCategory] = useState<string>(deltaInstallationPackages[0].category);

	const currency = getCurrencyByCode(deltaInstallationPackages[0].currencyCode);
	if (currency === null) {
		throw new AppError('Currency with code ' + deltaInstallationPackages[0].currencyCode + ' not found');
	}

	useEffect(() => {
		createCategoryTabs();
	}, []);

	// STATE ACTIONS
	const createCategoryTabs = (): void => {
		const categories: Array<Category> = [];
		deltaInstallationPackages.forEach((deltaInstallationPackage) => {
			const currency = getCurrencyByCode(deltaInstallationPackage.currencyCode);
			if (categories.some(category => category.name === deltaInstallationPackage.category)) {
				return;
			}
			const amount = selectedPackageAmount.get(deltaInstallationPackage.id) ?? 0;
			categories.push({
				name: deltaInstallationPackage.category,
				fullPrice: deltaInstallationPackage.purchaseNetPrice * amount,
				currency: currency
			});
		});

		setCategoryTabs(categories);
	};

	const updateCategoryTabs = (packageAmount: Map<ModelPrimaryKey, number>): void => {
		const updatedCategoryTabs = categoryTabs.map((categoryTab) => {
			let categoryTabFullPrice = 0;
			deltaInstallationPackages.forEach((deltaInstallationPackage) => {
				const amount = packageAmount.get(deltaInstallationPackage.id) ?? 0;
				if (amount > 0 && (deltaInstallationPackage.category === categoryTab.name)) {
					categoryTabFullPrice += (deltaInstallationPackage.purchaseNetPrice * amount);
				}
			});
			return {
				...categoryTab,
				fullPrice: categoryTabFullPrice
			};
		});
		setCategoryTabs(updatedCategoryTabs);
	};

	// CALCULATION
	const calculateTotalPrice = (): string => {
		let totalPrice = 0;
		deltaInstallationPackages.forEach((deltaInstallationPackage) => {
			const amount = selectedPackageAmount.get(deltaInstallationPackage.id) ?? 0;
			totalPrice += amount * deltaInstallationPackage.purchaseNetPrice;
		});

		return l10n.formatNumber(calculateMajorUnitFromMinorUnit(currency.code, totalPrice), currency.fraction);
	};

	// EVENT HANDLING
	const handleOfferUpdate = (deltaInstallationPackageId: ModelPrimaryKey, amount: number) => {
		const packageAmount = new Map(selectedPackageAmount);
		packageAmount.set(deltaInstallationPackageId, amount);
		setSelectedPackageAmount(packageAmount);
		updateCategoryTabs(packageAmount);
		onUpdate(packageAmount);
	};

	const handleCategorySelection = (categoryName: string) => {
		setSelectedCategory(categoryName);
		if (offerCollectionRef.current) {
			scrollElementIntoView(offerCollectionRef.current);
		}
	};

	// RENDER
	const renderTabBar = () => {
		const tabItems = categoryTabs.map((category) => {
			const currencySymbol = getCurrencySymbolByCurrency(category.currency);
			const fullPrice = l10n.formatNumber(
				calculateMajorUnitFromMinorUnit(currency.code, category.fullPrice), category.currency?.fraction ?? 0
			);

			return (
				<TabbarItem
					key={category.name}
					tabId={category.name}
					onClick={() => handleCategorySelection(category.name)}
					label={category.name}
					pill={category.fullPrice > 0 ? fullPrice : undefined}
					currency={currencySymbol}
					active={category.name === selectedCategory}
				/>
			);
		});

		return (
			<Tabbar>
				{tabItems}
			</Tabbar>
		);
	};

	const renderOffers = () => {
		return deltaInstallationPackages
			.filter((deltaInstallationPackage) => {
				return deltaInstallationPackage.category === selectedCategory;
			})
			.map((deltaInstallationPackage) => {
				return (
					<TaskOfferCollectionItem
						key={deltaInstallationPackage.id}
						onChange={handleOfferUpdate}
						amount={selectedPackageAmount.get(deltaInstallationPackage.id)}
						deltaPackage={deltaInstallationPackage}
					/>
				);
			});
	};

	return (
		<section className="task-offer-collection" ref={offerCollectionRef}>
			<header className="task-offer-collection__header">
				{renderTabBar()}
			</header>

			<main className="task-offer-collection__main">
				{renderOffers()}
			</main>

			<footer className="task-offer-collection__footer">
				<section className="task-offer-collection__footer__wrapper">
					<div className="task-offer-collection__footer__wrapper__price">
						<span className="task-offer-collection__footer__wrapper__price__key">{l10n.translate('omsInstallationPartnerOfficeApp.task.component.taskOfferCollection.fullPrice')}</span>
						<span className="task-offer-collection__footer__wrapper__price__value">{calculateTotalPrice()} {getCurrencySymbolByCurrency(currency)}</span>
					</div>
				</section>
			</footer>
		</section>
	);
}
