import { createContext } from 'react';

import { AppError } from '@abb-emobility/shared/error';

import { CandidateGroup } from '../foundation/CandidateGroup';

export type CandidateGroupContextValue = {
	isPresent(): boolean,
	setAvailableCandidateGroups(candidateGroups: Array<CandidateGroup>): void,
	unsetAvailableCandidateGroups(): void,
	getAvailableCandidateGroups(): Array<CandidateGroup>
};

export const candidateGroupContext = createContext<CandidateGroupContextValue>({
	isPresent(): boolean {
		return false;
	},
	setAvailableCandidateGroups: (_candidateGroups: Array<CandidateGroup>): void => {
		throw new AppError('No identity context provided');
	},
	unsetAvailableCandidateGroups: (): void => {
		throw new AppError('No identity context provided');
	},
	getAvailableCandidateGroups: (): Array<CandidateGroup> => {
		throw new AppError('No identity context provided');
	}
});
