import { FilterCriteria } from '@abb-emobility/shared/api-integration-foundation';
import { useIdentity } from '@abb-emobility/shared/identity-provider';
import { readLocalStorage, writeLocalStorage } from '@abb-emobility/shared/local-storage';
import { Maybe } from '@abb-emobility/shared/util';

export const useFilterCriteriaStorage = <T>(storageKey: string) => {

	const identity = useIdentity();

	const readFilterCriteria = (): Maybe<FilterCriteria<T>> => {
		return readLocalStorage<FilterCriteria<T>>(
			'filter-criteria_' + storageKey,
			{ namespace: identity.getIdentity().get()?.userId ?? undefined }
		).getOrUndefined();
	};

	const writeFilterCriteria = (filterCriteria: FilterCriteria<T>): void => {
		return writeLocalStorage<FilterCriteria<T>>(
			'filter-criteria_' + storageKey,
			filterCriteria,
			{ namespace: identity.getIdentity().get()?.userId ?? undefined }
		);
	};

	return { readFilterCriteria, writeFilterCriteria };
};
