import React, { ForwardedRef, ReactNode } from 'react';

import { buildCssClassStringFromClassMap } from '@abb-emobility/shared/util';

import { FirstChildWidth } from '../../../collection-head/CollectionHead.enums';

import './CollectionItemHeaderItem.scss';

export type CollectionItemHeaderItemProps = {
	allowOverflow?: boolean,
	firstChildWidth?: FirstChildWidth,
	children: ReactNode
};

export const CollectionItemHeaderItem = React.forwardRef((props: CollectionItemHeaderItemProps, ref: ForwardedRef<HTMLDivElement>) => {
	const { allowOverflow = false, firstChildWidth = FirstChildWidth.FIRST_CHILD_WIDTH_4, children } = props;

	const cssClassMap = {
		'collection-item-header-item': true,
		'collection-item-header-item--overflow': allowOverflow,
		'collection-item-header-item--first-child-width-2': firstChildWidth === FirstChildWidth.FIRST_CHILD_WIDTH_2,
		'collection-item-header-item--first-child-width-4': firstChildWidth === FirstChildWidth.FIRST_CHILD_WIDTH_4
	};

	return (
		<div className={buildCssClassStringFromClassMap(cssClassMap)} ref={ref}>
			{children}
		</div>
	);
});
