import { useNavigate } from 'react-router-dom';

import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { usePageTitle } from '@abb-emobility/shared/react';
import { useBreadcrumbModifier, ViewHeader } from '@abb-emobility/shared/ui-primitive';

import { OrderCollectionAssembler } from '../../components/order-collection-assembler/OrderCollectionAssembler';
import { useBuildPageTitle } from '../../components/page-title/PageTitle.hook';
import { RoutePath, useRouteUrl } from '../../router/Routes';

export function OrdersView() {

	const l10n = useL10n();
	const navigate = useNavigate();
	const { createRouteUrl } = useRouteUrl();
	usePageTitle(useBuildPageTitle('omsInstallationPartnerOfficeApp.pageTitle.orders'));

	useBreadcrumbModifier([
		{
			label: l10n.translate('omsInstallationPartnerOfficeApp.breadcrumbs.orders'),
			link: createRouteUrl(RoutePath.ORDERS)
		}
	]);

	const handleNavigate = (orderId: ModelPrimaryKey): void => {
		navigate(createRouteUrl(RoutePath.ORDER, ['orderId', orderId]));
	};

	return (
		<>
			<ViewHeader heading={l10n.translate('omsInstallationPartnerOfficeApp.viewHeader.orders')} />
			<OrderCollectionAssembler onNavigate={handleNavigate} />
		</>
	);
}
