import React, { ReactNode, useEffect, useState } from 'react';

import { TaskActionStatus } from '@abb-emobility/shared/data-provider-foundation';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { convertApiPersistedAnswersToPersistedAnswers } from '@abb-emobility/shared/questionnaire';
import {
	ButtonGhost,
	ButtonGroup,
	ButtonPrimary,
	CollectionItemContentSection,
	ExpandableSection,
	Icon,
	IconIdentifier,
	InputTextArea,
	OrderComment,
	OrderCommentCollection,
	SectionHeader,
	Separator,
	SubmissionStatus,
	useModalDialogue,
	useModalDialogueManager
} from '@abb-emobility/shared/ui-primitive';
import { QuestionnaireAnswers } from '@abb-emobility/shared/ui-questionnaire';
import { Nullable } from '@abb-emobility/shared/util';
import { useAnyTaskEntityData } from '@abb-emobility/usertask/data-provider';
import { QuotationPayloadModel, QuotationTaskModel, SelectedDeltaInstallationModel } from '@abb-emobility/usertask/domain-model';

import { TaskOfferCollection } from '../components/TaskOfferCollection';

import './TaskQuotation.scss';

export type QuotationProps = {
	task: QuotationTaskModel
};

export function TaskQuotation(props: QuotationProps) {

	const { task } = props;

	const l10n = useL10n();
	const modalDialogue = useModalDialogue();
	const modalDialogueManager = useModalDialogueManager();
	const anyTaskData = useAnyTaskEntityData();

	const [selectedDeltaPackages, setSelectedDeltaPackages] = useState<Array<SelectedDeltaInstallationModel>>(task.payload.selectedDeltaInstallationPackages ?? []);
	const [conditions, setConditions] = useState<Nullable<string>>(null);
	const [comment, setComment] = useState<Nullable<string>>(null);
	const [taskCompletionButtonState, setTaskCompletionButtonState] = useState<SubmissionStatus>(SubmissionStatus.IDLE);

	anyTaskData.useActionStatusEffect([TaskActionStatus.COMPLETE_PENDING], () => {
		setTaskCompletionButtonState(SubmissionStatus.PENDING);
	}, false);

	anyTaskData.useActionStatusEffect([TaskActionStatus.COMPLETE_SUCCESS], () => {
		setTaskCompletionButtonState(SubmissionStatus.DONE);
	}, true);

	anyTaskData.useActionStatusEffect([TaskActionStatus.COMPLETE_FAILED], () => {
		setTaskCompletionButtonState(SubmissionStatus.IDLE);
	}, true);

	const deltaInstallationPackages = task.payload.deltaInstallationPackages;

	useEffect(() => {
		modalDialogue.setFooter(
			<ButtonGroup>
				<ButtonGhost
					label={l10n.translate('omsInstallationPartnerOfficeApp.task.quotation.button.close')}
					onClick={handleCancel}
				/>
				<ButtonPrimary
					label={l10n.translate('omsInstallationPartnerOfficeApp.task.quotation.button.submit')}
					submissionStatus={taskCompletionButtonState}
					onClick={handleTaskCompletion}
					disabled={taskCompletionButtonState !== SubmissionStatus.IDLE || selectedDeltaPackages.length === 0}
				/>
			</ButtonGroup>
		);
		return () => {
			modalDialogue.unsetFooter();
		};
	}, [taskCompletionButtonState, selectedDeltaPackages, conditions, comment]);

	const handleTaskCompletion = () => {
		const payload: QuotationPayloadModel = {
			...task.payload,
			selectedDeltaInstallationPackages: selectedDeltaPackages,
			quotationConditions: conditions ?? undefined,
			comment: comment ?? undefined
		};
		anyTaskData.complete(task, payload);
	};

	const handleCancel = (): void => {
		modalDialogueManager.pop();
	};

	const handleDeltaPackageUpdate = (selectedPackageAmount: Map<ModelPrimaryKey, number>) => {
		const selectedDeltaInstallationPackages = deltaInstallationPackages
			.map((deltaInstallationPackage) => {
				return {
					deltaInstallationPackageId: deltaInstallationPackage.id,
					amount: selectedPackageAmount.get(deltaInstallationPackage.id) ?? 0
				};
			})
			.filter((selectedDeltaInstallationPackages) => {
				return selectedDeltaInstallationPackages.amount > 0;
			});
		setSelectedDeltaPackages(selectedDeltaInstallationPackages);
	};

	const handleComment = (comment: string) => {
		const handledComment = comment.length === 0 ? null : comment;
		setComment(handledComment);
	};

	const handleConditions = (conditions: string) => {
		const handledConditions = conditions.length === 0 ? null : conditions;
		setConditions(handledConditions);
	};

	const renderCustomerInformation = (): ReactNode => {
		return (
			<CollectionItemContentSection>
				<SectionHeader heading={l10n.translate('omsInstallationPartnerOfficeApp.task.quotation.customer.heading')} />
				<article className="task-quotation__contact">
					<section className="task-quotation__contact__sections">
						<section className="task-quotation__contact__sections__section">
							<span className="task-quotation__contact__sections__section__icon">
								<Icon name={IconIdentifier.USER_CIRCLE} />
							</span>
							{task.payload.customer.name}
						</section>
						<section className="task-quotation__contact__sections__section">
							<span className="task-quotation__contact__sections__section__icon">
								<Icon name={IconIdentifier.MAP_PIN} />
							</span>
							{task.payload.customer.address}
						</section>
					</section>
				</article>
			</CollectionItemContentSection>
		);
	};

	const renderEffort = () => {
		const hoursDisplayUnit = l10n.translate('omsInstallationPartnerOfficeApp.task.component.taskTradeAppointmentCraft.unit.hours');
		return task.payload.requestedTradeEfforts.map((tradeEffort, index) => {
			return (
				<div className="task-quotation__effort__item" key={index}>
					<span className="task-quotation__effort__item--grey">{tradeEffort.trade}</span>
					<span>{tradeEffort.effort} {hoursDisplayUnit}</span>
				</div>
			);
		});
	};

	const renderBasicAnswers = (): ReactNode => {
		if (
			task.payload.questionnaire === undefined
			|| task.payload.answers === undefined
			|| task.payload.questionnaireEnvironment === undefined
		) {
			return null;
		}
		return (
			<CollectionItemContentSection>
				<ExpandableSection heading={l10n.translate('omsInstallationPartnerOfficeApp.task.quotation.questionnaire.heading')}>
					<QuestionnaireAnswers
						questionnaire={task.payload.questionnaire}
						answers={convertApiPersistedAnswersToPersistedAnswers(task.payload.answers)}
						environment={task.payload.questionnaireEnvironment}
					/>
				</ExpandableSection>
			</CollectionItemContentSection>
		);
	};

	const renderComments = (): ReactNode => {
		if (
			task.payload.comments === undefined
			|| task.payload.comments.length === 0
		) {
			return null;
		}
		const comments = task.payload.comments.map((comment) => {
			return (
				<OrderComment comment={comment} key={comment.id} />
			);
		});
		return (
			<CollectionItemContentSection>
				<ExpandableSection heading={l10n.translate('omsInstallationPartnerOfficeApp.task.quotation.comments.heading')}>
					<OrderCommentCollection>
						{comments}
					</OrderCommentCollection>
				</ExpandableSection>
			</CollectionItemContentSection>
		);
	};

	return (
		<>

			{renderCustomerInformation()}

			{renderBasicAnswers()}

			{renderComments()}

			<CollectionItemContentSection>
				<SectionHeader heading={l10n.translate('omsInstallationPartnerOfficeApp.task.quotation.preCheck.heading')} />
				<div className="task-quotation__effort">
					{renderEffort()}
				</div>
			</CollectionItemContentSection>

			<CollectionItemContentSection>
				<SectionHeader heading={l10n.translate('omsInstallationPartnerOfficeApp.task.quotation.additionalEffort.heading')} />
				<div className="task-quotation__effort__text">{l10n.translate('omsInstallationPartnerOfficeApp.task.quotation.additionalEffort.text')}</div>
			</CollectionItemContentSection>

			<Separator />

			<CollectionItemContentSection>
				<TaskOfferCollection deltaInstallationPackages={deltaInstallationPackages} onUpdate={handleDeltaPackageUpdate} />
			</CollectionItemContentSection>

			<CollectionItemContentSection>
				<SectionHeader heading={l10n.translate('omsInstallationPartnerOfficeApp.task.quotation.conditions.heading')} />
				<InputTextArea
					placeholder={l10n.translate('omsInstallationPartnerOfficeApp.task.quotation.conditions.placeholder')}
					rows={6}
					onChange={handleConditions}
				/>
			</CollectionItemContentSection>

			<CollectionItemContentSection>
				<SectionHeader heading={l10n.translate('omsInstallationPartnerOfficeApp.task.quotation.notes.heading')} />
				<InputTextArea
					placeholder={l10n.translate('omsInstallationPartnerOfficeApp.task.quotation.notes.placeholder')}
					rows={4}
					onChange={handleComment}
				/>
			</CollectionItemContentSection>

		</>
	);
}
