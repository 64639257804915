import React, { useState } from 'react';

import { calculateMajorUnitFromMinorUnit, getCurrencyByCode, getCurrencySymbolByCurrency } from '@abb-emobility/shared/currency';
import { InstallationPartnerDeltaInstallationPackageModel } from '@abb-emobility/shared/domain-model';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { NumericStepper, Separator, Specification, SpecificationItem } from '@abb-emobility/shared/ui-primitive';

import './TaskOfferCollection.scss';

export type TaskOfferCollectionItemProps = {
	deltaPackage: InstallationPartnerDeltaInstallationPackageModel,
	amount?: number,
	onChange?: (deltaInstallationPackageId: ModelPrimaryKey, amount: number) => void
};

export function TaskOfferCollectionItem(props: TaskOfferCollectionItemProps) {
	const { deltaPackage, amount = 0, onChange } = props;

	const l10n = useL10n();
	const currency = getCurrencyByCode(deltaPackage.currencyCode);

	const [fullPrice, setFullPrice] = useState(deltaPackage.purchaseNetPrice * amount);

	const stepperConfig = {
		stepperVal: amount,
		stepperMin: 0,
		stepperMax: 100
	};

	const handleChangeAmount = (value: number) => {
		setFullPrice(deltaPackage.purchaseNetPrice * (value ?? 0));
		if (onChange) {
			onChange(deltaPackage.id, value);
		}
	};

	const renderAttributes = () => {
		return deltaPackage.includedGoodsInternal?.map((good, index) => {
			return <SpecificationItem key={index} attribute={good} />;
		});
	};

	return (
		<article className="task-offer-collection-item">
			<header className="task-offer-collection-item__header">
				<div className="task-offer-collection-item__header__item">
					<h1 className="task-offer-collection-item__header__item__heading">{deltaPackage.name}</h1>
					<p className="task-offer-collection-item__header__item__description">
						{deltaPackage.description}
					</p>
				</div>
				<div className="task-offer-collection-item__header__item">
					<span className="task-offer-collection-item__header__item__price">
						<span className="task-offer-collection-item__header__item__price--default">
							{getCurrencySymbolByCurrency(currency)}
							&nbsp;
							{l10n.formatNumber(calculateMajorUnitFromMinorUnit(deltaPackage.currencyCode, deltaPackage.purchaseNetPrice), currency?.fraction ?? 0)}
						</span>
						/
						<span className="task-offer-collection-item__header__item__price--bold">
							{getCurrencySymbolByCurrency(currency)}
							&nbsp;
							{l10n.formatNumber(calculateMajorUnitFromMinorUnit(deltaPackage.currencyCode, fullPrice), currency?.fraction ?? 0)}
						</span>
					</span>
					<NumericStepper
						onChange={handleChangeAmount}
						value={stepperConfig.stepperVal}
						min={stepperConfig.stepperMin}
						max={stepperConfig.stepperMax}
					/>
				</div>
			</header>

			<Separator />

			<main className="task-offer-collection-item__main">
				<Specification>
					{renderAttributes()}
				</Specification>
			</main>
		</article>
	);
}
