import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	omsInstallationPartnerOfficeApp: {
		action: {
			cancelOrderAppointments: 'Annulla appuntamenti'
		},
		actionItemFactory: {
			cancelOrderAppointmentsLabel: 'Annulla appuntamenti'
		},
		actionModalFactory: {
			cancelOrderAppointmentsHeading: 'Annulla gli appuntamenti per l\'ordine'
		},
		actionModal: {
			cancelOrderAppointments: {
				content: {
					notificationHeading: 'Annulla gli appuntamenti per l\'installazione.',
					notificationMessage: 'Tutti gli appuntamenti per l\'installazione saranno annullati.',
					successHeading: 'Appuntamenti annullati',
					successCloseButtonLabel: 'Chiudi',
					reason: 'Motivo'
				},
				cancelReasonValue: {
					deliveryDelay: 'Ritardo nella consegna',
					installationPartnerConflict: 'Conflitto con il partner di installazione',
					customerConflict: 'Conflitto con il cliente',
					replacement: 'Sostituzione'
				},
				footer: {
					confirm: 'Conferma annullamento',
					cancel: 'Annulla'
				},
				error: {
					heading: 'Non è stato possibile annullare gli appuntamenti.',
					message: 'Si è verificato un errore durante l\'eliminazione degli appuntamenti per l\'ordine.'
				}
			}
		},
		appName: 'Ufficio installazione centralina a parete ABB',
		pageTitle: {
			dashboard: 'Dashboard',
			error: 'Errore',
			notFound: 'Non trovato',
			orders: 'Ordini',
			order: 'Ordina per {{displayName}}'
		},
		navigation: {
			dashboard: 'Dashboard',
			orders: 'Ordini',
			logout: 'Esci'
		},
		breadcrumbs: {
			tasks: 'Panoramica attività',
			orders: 'Panoramica ordine'
		},
		viewHeader: {
			tasks: 'Panoramica attività',
			orders: 'Panoramica ordini'
		},
		installerAppointmentCollection: {
			heading: 'Installazioni programmate',
			emptyState: {
				heading: 'Nessuna installazione programmata',
				message: 'L\'ordine non prevede installazioni.'
			}
		},
		orderCollection: {
			heading: 'Ordini',
			tableHead: {
				order: 'Ordine',
				status: 'Stato',
				estimated: 'Data di installazione prevista',
				progress: 'Progresso'
			},
			emptyState: {
				heading: 'Nessun ordine trovato',
				message: 'Regola le impostazioni dei filtri per trovare ordini.'
			},
			action: {
				refresh: 'Aggiorna',
				showMore: 'Mostra altro'
			},
			filter: {
				filter: 'Filtro',
				none: 'nessuno',
				active: '{{count}} attivo',
				resetFilter: 'Reimposta filtro',
				options: {
					dueDateOverdue: 'Rimandato',
					dueDateToday: 'In scadenza oggi',
					priorityHigh: 'Priorità alta',
					priorityMedium: 'Priorità media',
					priorityLow: 'Priorità bassa',
					statusDone: 'Fatto',
					statusOpen: 'Apri',
					installationTypeStandard: 'Installazione standard',
					installationTypeDelta: 'Installazione Delta',
					installationTypeUndetermined: 'Indeterminato'
				}
			}
		},
		orderDetail: {
			progress: {
				heading: 'Progresso'
			},
			customerInformation: {
				heading: 'Informazioni del cliente',
				address: 'Indirizzo',
				phone: 'Telefono',
				mail: 'E-mail'
			},
			installationAddress: {
				heading: 'Indirizzo di installazione',
				address: 'Indirizzo'
			},
			status: {
				onSchedule: 'In programma',
				delayed: 'Rimandato',
				cancelPending: 'Annulla in sospeso',
				cancelled: 'Annullato',
				done: 'Fatto'
			},
			installationType: {
				none: 'Indeterminato',
				undetermined: 'Indeterminato',
				cancelled: 'Annullato',
				standard: 'Standard',
				delta: 'Delta'
			}
		},
		task: {
			progressStepShortName: {
				installationPartnerEvaluateBasicAnswers: 'Controllo di base',
				installationPartnerArrangeAppointment: 'Appuntamento',
				installationPartnerExecuteRemotePreCheck: 'Verifica finale',
				installationPartnerRegisterAtGridOperator: 'Registrazione gestore di rete',
				installationPartnerProvideGridOperatorDecision: 'Decisione gestore di rete',
				installationPartnerRevokeGridOperatorRegistration: 'Revoca gestore di rete',
				installationPartnerProvideQuotation: 'Offerta',
				installationPartnerCompleteInstallation: 'Installazione'
			},
			displayName: {
				installationPartnerEvaluateBasicAnswers: 'Informazioni di base',
				installationPartnerArrangeAppointment: 'Fissa appuntamento',
				installationPartnerExecuteRemotePreCheck: 'Controllo preliminare',
				installationPartnerRegisterAtGridOperator: 'Registrazione gestore di rete',
				installationPartnerProvideGridOperatorDecision: 'Decisione gestore di rete',
				installationPartnerRevokeGridOperatorRegistration: 'Revoca gestore di rete',
				installationPartnerProvideQuotation: 'Offerta',
				installationPartnerCompleteInstallation: 'Installazione completata'
			},
			status: {
				started: 'Prossimamente',
				dueToday: 'In scadenza oggi',
				overdue: 'Scaduto',
				escalated: 'Inoltrato',
				done: 'Fatto',
				doneOverdue: 'Fatto',
				doneEscalated: 'Fatto'
			},
			priority: {
				low: 'Bassa',
				medium: 'Media',
				high: 'Alta'
			},
			assign: {
				successMessage: 'L\'attività è stato assegnata con successo'
			},
			completion: {
				successHeading: 'Attività completata',
				closeButtonLabel: 'Chiudi'
			},
			component: {
				taskTradeAppointmentCraft: {
					hint: {
						invalid: 'Una o più impostazioni dell\'attività e dello sforzo sono incomplete o non valide e non saranno inviate.'
					},
					estimatedEffort: 'Sforzo stimato',
					selectedEffort: 'Selezionato',
					installationPeriod: {
						preparation: 'come preparazione all\'installazione',
						beginning: 'all\'inizio dell\'installazione',
						permanent: 'durante l\'installazione',
						end: 'al termine dell\'installazione',
						followUp: 'dopo l\'installazione'
					},
					unit: {
						hours: 'ore'
					},
					button: {
						add: 'Aggiungi altri giorni'
					}
				},
				taskTradeAppointmentCraftItem: {
					input: {
						installer: 'Installatore',
						date: 'Data',
						start: 'Inizio',
						end: 'Fine'
					}
				},
				taskTradeAppointmentEffort: {
					heading: 'Attività e sforzi di installazione previsti',
					hint: {
						invalid: 'Una o più impostazioni dell\'attività e dello sforzo sono incomplete o non valide e non saranno inviate.',
						notApprovable: 'Non è stata determinata alcuna attività accettabile. Per completare l\'installazione, almeno un\'attività deve essere pronta per l\'accettazione.'
					},
					button: {
						add: 'Aggiungi attività'
					}
				},
				taskTradeAppointmentEffortItem: {
					input: {
						trade: 'Attività',
						period: 'Periodo',
						effort: 'Sforzo',
						canComplete: 'può completare'
					},
					installationPeriod: {
						preparation: 'Preparazione all\'installazione',
						beginning: 'Inizio dell\'installazione',
						permanent: 'Permanente',
						end: 'Fine dell\'installazione',
						followUp: 'Follow-up dell\'installazione'
					}
				},
				taskChoice: {
					decision: {
						standard: 'Informazioni sufficienti - installazione standard',
						delta: 'Informazioni sufficienti - installazione delta',
						preCheckRequired: 'Sono necessarie ulteriori informazioni',
						unfeasible: 'Installazione non possibile',
						project: 'Possibilità di installazione come progetto separato'
					},
					unfeasibleInstallationReasons: {
						label: 'Selezionare un motivo',
						noPotentialRailAvailable: 'Nessuna guida potenziale disponibile',
						overvoltageProtectionUnfeasible: 'La protezione da sovratensione non può essere installata',
						spaceForCableOutletMissing: 'Mancanza di spazio sufficiente per un\'uscita supplementare per i cavi',
						houseConnectionUndersized: 'Allacciamento alla casa sottodimensionato',
						noThreePhaseAvailable: 'Nessuna corrente trifase in casa (1 fase)',
						outdatedMeterCabinet: 'Armadio del contatore obsoleto',
						notTabCompliant: 'Non conforme alla TAB',
						other: 'Altro motivo'
					},
					projectInstallationReasons: {
						label: 'Selezionare un motivo',
						condominiumOwnersAssociation: 'Condominio (è necessaria l\'autorizzazione)',
						highPowerEvu: 'Richiesta 22 KW, sfide EVU',
						undergroundGarageInstallation: 'Installazione nel garage sotterraneo',
						fireProtectionIssues: 'Sfide della protezione antincendio',
						multipleWallboxManagement: 'È necessaria una gestione del carico per la presenza di più centraline a parete',
						other: 'Altro motivo',
						hint: {
							heading: 'Se l\'installazione è possibile in generale, ma non in un rapporto diretto con il cliente, si può scegliere di creare un progetto B2B dall\'ordine che deve essere gestito separatamente.'
						},
						information: {
							heading: 'Proponi un progetto di installazione',
							message: 'Il cliente e ABB saranno informati della decisione. Tutte le informazioni rilevanti, comprese quelle del cliente, ti vengono inviate per mail. A questo punto puoi metterti in contatto con il cliente per organizzare un progetto di installazione separato.'
						}
					}
				},
				taskOfferCollection: {
					fullPrice: 'Prezzo totale (netto)'
				}
			},
			arrangeInstallationAppointment: {
				heading: 'Appuntamento',
				replacementInfo: 'L\'installazione da programmare è un\'installazione sostitutiva.',
				rescheduleInfo: 'L\'appuntamento da fissare è una riprogrammazione.',
				contact: {
					heading: 'Contatto del cliente'
				},
				notes: {
					heading: 'Note',
					placeholder: 'Aggiungi note'
				},
				button: {
					close: 'Chiudi',
					submit: 'Fatto'
				}
			},
			basicAnswers: {
				customer: {
					heading: 'Indirizzo di installazione'
				},
				questionnaire: {
					heading: 'Risposte dei clienti'
				},
				decision: {
					heading: 'Decisione'
				},
				notes: {
					heading: 'Note',
					placeholder: 'Aggiungi note'
				},
				button: {
					close: 'Chiudi',
					submit: 'Fatto'
				},
				continueToQuotation: {
					buttonLabel: 'Crea un\'offerta'
				}
			},
			gridOperatorDecision: {
				gridOperator: {
					heading: 'Gestore di rete'
				},
				wallboxPower: {
					heading: 'Informazioni sulla centralina a parete',
					hint: 'Il cliente ha ordinato una centralina a parete con consumo energetico pari a {{wallboxPower}} kW.'
				},
				documents: {
					heading: 'Documenti'
				},
				decision: {
					heading: 'Decisione',
					accepted: 'Il gestore di rete ha acconsentito all\'installazione',
					declined: 'Il gestore di rete ha rifiutato l\'installazione'
				},
				notes: {
					heading: 'Note',
					placeholder: 'Aggiungi note'
				},
				button: {
					close: 'Chiudi',
					submit: 'Invia'
				}
			},
			gridOperatorRegistration: {
				gridOperator: {
					heading: 'Gestore di rete'
				},
				wallboxPower: {
					heading: 'Informazioni sulla centralina a parete',
					hint: 'Il cliente ha ordinato una centralina a parete con consumo energetico pari a {{wallboxPower}} kW.'
				},
				documents: {
					heading: 'Documenti'
				},
				registrationRequired: {
					heading: 'Registrazione obbligatoria',
					required: 'Sì, è necessaria la registrazione',
					unnecessary: 'No, la registrazione non è necessaria'
				},
				notes: {
					heading: 'Note',
					placeholder: 'Aggiungi note'
				},
				button: {
					close: 'Chiudi',
					submit: 'Invia'
				},
				continueToFeedback: {
					buttonLabel: 'Inserire il feedback del gestore di rete'
				}
			},
			gridOperatorRevokeRegistration: {
				gridOperator: {
					heading: 'Gestore di rete'
				},
				notes: {
					heading: 'Note',
					placeholder: 'Aggiungi note'
				},
				button: {
					close: 'Chiudi',
					submit: 'Invia'
				}
			},
			preCheck: {
				contact: {
					heading: 'Contatto del cliente',
					description: 'A che ora del giorno il cliente si trova in casa?'
				},
				questionnaire: {
					heading: 'Risposte del cliente al questionario di base'
				},
				decision: {
					heading: 'Decisione'
				},
				notes: {
					heading: 'Note',
					placeholder: 'Aggiungi note'
				},
				button: {
					close: 'Chiudi',
					submit: 'Fatto'
				}
			},
			quotation: {
				customer: {
					heading: 'Indirizzo di installazione'
				},
				questionnaire: {
					heading: 'Risposte del cliente al questionario di base'
				},
				preCheck: {
					heading: 'Risultati del controllo preliminare: Appuntamenti e sforzi per l\'installazione'
				},
				comments: {
					heading: 'Commenti aggiuntivi'
				},
				additionalEffort: {
					heading: 'Sforzi aggiuntivi',
					text: 'Per realizzare l\'installazione è necessario eseguire lavorazioni aggiuntive rispetto all\'installazione standard. Verrà creata un\'offerta corrispondente in base ai seguenti pacchetti di installazione Delta.'
				},
				conditions: {
					heading: 'Condizioni di offerta per il cliente',
					placeholder: 'A quali condizioni è valida l\'offerta?'
				},
				notes: {
					heading: 'Note',
					placeholder: 'Aggiungi note'
				},
				button: {
					close: 'Chiudi',
					submit: 'Invia'
				},
				deltaPackages: {
					fullPrice: 'Prezzo totale (netto)'
				}
			},
			installationComplete: {
				contact: {
					heading: 'Cliente'
				},
				documents: {
					heading: 'Documenti'
				},
				trades: {
					heading: 'Attività e appuntamenti'
				},
				customerAcceptance: {
					heading: 'Accettazione del cliente',
					onSite: 'Il cliente ha autorizzato l\'installazione in loco',
					remote: 'Il cliente non era disponibile'
				},
				wallbox: {
					heading: 'Centralina a parete ordinata',
					serial: 'Con la presente confermo la corretta e professionale installazione e messa in funzione della centralina a parete consegnata con il seguente numero di serie: {{serialNumber}}.',
					throttle: 'Il consumo energetico della centralina a parete è stato ridotto in base ai requisiti dei gestori di rete.'
				},
				notes: {
					heading: 'Note',
					placeholder: 'Aggiungi note'
				},
				button: {
					close: 'Chiudi',
					submit: 'Invia'
				}
			}
		},
		taskCollection: {
			heading: 'Attività',
			tableHead: {
				order: 'Attività',
				status: 'Stato',
				due: 'Scadenza',
				priority: 'Priorità',
				responsible: 'Responsabile'
			},
			action: {
				refresh: 'Aggiorna',
				showMore: 'Mostra altro'
			},
			assign: {
				successMessage: 'L\'attività è stato assegnata con successo',
				errorMessage: 'Si è verificato un errore durante l\'assegnazione dell\'attività.'
			},
			emptyState: {
				heading: 'Nessuna attività trovata',
				message: 'Regola le impostazioni dei filtri per trovare attività.'
			},
			orderEmptyState: {
				heading: 'Nessuna attività trovata',
				message: 'Per questo ordine non è necessaria alcuna azione.'
			}
		},
		error: {
			generic: {
				heading: 'Si è verificato un errore',
				message: 'Si è verificato un errore imprevisto che non è stato possibile risolvere',
				resolveAction: {
					label: 'Ricarica'
				}
			},
			offline: {
				heading: 'Connessione di rete non disponibile',
				message: 'Per utilizzare l\'applicazione, collegarsi a una rete.'
			},
			notFound: {
				heading: 'Non trovato',
				message: 'La risorsa richiesta non è stata trovata',
				resolveAction: {
					label: 'Dashboard'
				}
			},
			orderNotFound: 'Ordine non trovato.',
			taskNotFound: 'Attività non trovata.',
			taskTypeNotFound: 'Tipo di attività non trovato: {{type}}',
			taskCompleteMessage: 'Si è verificato un errore durante il completamento dell\'attività.',
			taskAssignMessage: 'Si è verificato un errore durante l\'assegnazione dell\'attività.'
		}
	}
};

export default literals;
