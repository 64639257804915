import React from 'react';

import { useOrderInstallerAppointmentCollectionData } from '@abb-emobility/oms/data-provider';

import { groupInstallerAppointmentsByTrades } from './InstallerAppointmentCollection.util';
import { InstallerAppointmentCollectionItem } from './InstallerAppointmentCollectionItem';

export function InstallerAppointmentFlatCollection() {

	const orderInstallerAppointmentCollectionData = useOrderInstallerAppointmentCollectionData();
	const installerAppointments = orderInstallerAppointmentCollectionData.query();

	const renderAppointments = () => {
		return groupInstallerAppointmentsByTrades(installerAppointments).map((tradeAppointment, index) => {
			return (
				<InstallerAppointmentCollectionItem key={index} tradeAppointment={tradeAppointment} />
			);
		});
	};

	return (
		<>
			{renderAppointments()}
		</>
	);
}
