import React, { ReactNode } from 'react';

import { Nullable } from '@abb-emobility/shared/util';

import './CollectionItemContent.scss';

export type CollectionItemContentProps = {
	children: ReactNode
};

export function CollectionItemContent(props: CollectionItemContentProps): Nullable<React.JSX.Element> {
	const { children } = props;

	return (
		<main className="collection-item-content">
			{children}
		</main>
	);
}
