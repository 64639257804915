import React, { ReactNode } from 'react';

import { Separator } from '../separator/Separator';

import './Tabbar.scss';

export type TabbarProps = {
	children: ReactNode
};

export function Tabbar(props: TabbarProps) {
	const { children } = props;

	return (
		<section className="tabbar">
			<div className="tabbar__tabbar-item-container">
				{children}
			</div>
			<div className="tabbar__separator">
				<Separator />
			</div>
		</section>
	);
}
