import { ReactNode } from 'react';

import { buildCssClassStringFromClassMap } from '@abb-emobility/shared/util';

import './SegmentHeader.scss';

export type SegmentHeaderProps = {
	caption: string,
	children?: ReactNode
	hideOverflow?: boolean
};

export function SegmentHeader(props: SegmentHeaderProps) {
	const { caption, children, hideOverflow = false } = props;

	const actioClassMap = {
		'segment-header__actions': true,
		'segment-header__actions--overflow-hidden': hideOverflow
	};

	const renderActions = (): ReactNode => {
		if (children) {
			return (
				<section className={buildCssClassStringFromClassMap(actioClassMap)}>{children}</section>
			);
		}
		return;
	};

	return (
		<header className="segment-header">
			<h1 className="segment-header__caption">{caption}</h1>
			{renderActions()}
		</header>
	);
}
