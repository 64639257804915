import { ReactNode } from 'react';

import { SuccessImageIdentifier } from './Success.enums';

import './SuccessFeedback.scss';

export type SuccessProps = {
	image?: SuccessImageIdentifier,
	heading: string,
	message?: string,
	children?: ReactNode
};

export function SuccessFeeback(props: SuccessProps) {
	const { image = SuccessImageIdentifier.IMAGE1, heading, message, children = null } = props;

	const renderFooter = () => {
		if (children === null) {
			return;
		}

		return (
			<footer className="success__footer">
				{children}
			</footer>
		);
	};

	const renderMessage = () => {
		if (message === undefined) {
			return;
		}
		return (
			<p className="success__main__description">
				{message}
			</p>
		);
	};

	return (
		<article className="success">
			<header className="success__header">
				<img src={image} alt="success" />
			</header>
			<main className="success__main">
				<h1 className="success__main__heading">
					{heading}
				</h1>
				{renderMessage()}
			</main>
			{renderFooter()}
		</article>
	);
}
