import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	omsInstallationPartnerOfficeApp: {
		action: {
			cancelOrderAppointments: 'Termine absagen'
		},
		actionItemFactory: {
			cancelOrderAppointmentsLabel: 'Termine absagen'
		},
		actionModalFactory: {
			cancelOrderAppointmentsHeading: 'Installationstermine absagen'
		},
		actionModal: {
			cancelOrderAppointments: {
				content: {
					notificationHeading: 'Installationstermine absagen',
					notificationMessage: 'Alle Installationstermine werden abgesagt.',
					successHeading: 'Installationstermine wurden abgesagt',
					successCloseButtonLabel: 'Schliessen',
					reason: 'Grund'
				},
				cancelReasonValue: {
					deliveryDelay: 'Verspätete Lieferung',
					installationPartnerConflict: 'Installationspartnerkonflikt',
					customerConflict: 'Kundenkonflikt',
					replacement: 'Ersatzlieferung'
				},
				footer: {
					confirm: 'Terminabsage bestätigen',
					cancel: 'Abbrechen'
				},
				error: {
					heading: 'Installationstermine konnten nicht abgesagt werden.',
					message: 'Bei dem Versuch die Installationstermine abzusagen ist ein Fehler aufgetreten.'
				}
			}
		},
		appName: 'ABB Wallbox Installation Office',
		pageTitle: {
			dashboard: 'Dashboard',
			error: 'Fehler',
			notFound: 'Not found',
			orders: 'Bestellungen',
			order: 'Bestellung von {{displayName}}'
		},
		navigation: {
			dashboard: 'Dashboard',
			orders: 'Bestellungen',
			logout: 'Abmelden'
		},
		breadcrumbs: {
			tasks: 'Aufgabenübersicht',
			orders: 'Bestellübersicht'
		},
		viewHeader: {
			tasks: 'Aufgabenübersicht',
			orders: 'Bestellübersicht'
		},
		installerAppointmentCollection: {
			heading: 'Geplante Installation',
			emptyState: {
				heading: 'Keine geplanten Installationen gefunden',
				message: 'Für die Bestellung sind keine Installationen geplant.'
			}
		},
		orderCollection: {
			heading: 'Bestellungen',
			tableHead: {
				order: 'Bestellung',
				status: 'Status',
				estimated: 'Erwartetes Installationsdatum',
				progress: 'Fortschritt'
			},
			emptyState: {
				heading: 'Keine Bestellungen gefunden',
				message: 'Verändern Sie Ihre Filter-Einstellungen, um weitere Bestellungen zu finden.'
			},
			action: {
				refresh: 'Aktualisieren',
				showMore: 'Mehr laden'
			},
			filter: {
				filter: 'Filter',
				none: 'keiner',
				active: '{{count}} aktiv',
				resetFilter: 'Zurücksetzen',
				options: {
					dueDateOverdue: 'Überfällig',
					dueDateToday: 'Heute fällig',
					priorityHigh: 'Hohe Priorität',
					priorityMedium: 'Mittlere Priorität',
					priorityLow: 'Geringe Priorität',
					statusDone: 'Erledigt',
					statusOpen: 'Offen',
					installationTypeStandard: 'Standard Installation',
					installationTypeDelta: 'Delta Installation',
					installationTypeUndetermined: 'Noch nicht ermittelt'
				}
			}
		},
		orderDetail: {
			progress: {
				heading: 'Fortschritt'
			},
			customerInformation: {
				heading: 'Kundeninformation',
				address: 'Adresse',
				phone: 'Telefon',
				mail: 'E-Mail'
			},
			installationAddress: {
				heading: 'Installationsadresse',
				address: 'Adresse'
			},
			status: {
				onSchedule: 'Im Plan',
				delayed: 'Verspätet',
				cancelPending: 'Wird storniert',
				cancelled: 'Storniert',
				done: 'Abgeschlossen'
			},
			installationType: {
				none: 'Noch nicht ermittelt',
				undetermined: 'Noch nicht ermittelt',
				cancelled: 'Storniert',
				standard: 'Standard',
				delta: 'Delta'
			}
		},
		task: {
			progressStepShortName: {
				installationPartnerEvaluateBasicAnswers: 'Check',
				installationPartnerArrangeAppointment: 'Termin',
				installationPartnerExecuteRemotePreCheck: 'Finaler Check',
				installationPartnerRegisterAtGridOperator: 'Registrierung Netzbetreiber',
				installationPartnerProvideGridOperatorDecision: 'Rückmeldung Netzbetreiber',
				installationPartnerRevokeGridOperatorRegistration: 'Stornierung Netzbetreiber',
				installationPartnerProvideQuotation: 'Angebot',
				installationPartnerCompleteInstallation: 'Installation'
			},
			displayName: {
				installationPartnerEvaluateBasicAnswers: 'Installations-Check durchführen',
				installationPartnerArrangeAppointment: 'Installationstermin vereinbaren',
				installationPartnerExecuteRemotePreCheck: 'Finalen Check durchführen',
				installationPartnerRegisterAtGridOperator: 'Registrierung Netzbetreiber',
				installationPartnerProvideGridOperatorDecision: 'Rückmeldung Netzbetreiber',
				installationPartnerRevokeGridOperatorRegistration: 'Stornierung Netzbetreiber-Registrierung',
				installationPartnerProvideQuotation: 'Angebot erstellen',
				installationPartnerCompleteInstallation: 'Installation abschließen'
			},
			status: {
				started: 'Bevorstehend',
				dueToday: 'Heute fällig',
				overdue: 'Überfällig',
				escalated: 'Eskaliert',
				done: 'Erledigt',
				doneOverdue: 'Erledigt',
				doneEscalated: 'Erledigt'
			},
			priority: {
				low: 'Gering',
				medium: 'Mittel',
				high: 'Hoch'
			},
			assign: {
				successMessage: 'Die Aufgabe wurde erfolgreich zugewiesen'
			},
			completion: {
				successHeading: 'Aufgabe erledigt',
				closeButtonLabel: 'Schließen'
			},
			component: {
				taskTradeAppointmentCraft: {
					hint: {
						invalid: 'Eine oder mehrere Einstellungen sind nicht komplett oder ungültig und werden nicht übermittelt werden.'
					},
					estimatedEffort: 'Erwarteter Aufwand',
					selectedEffort: 'Gewählt',
					installationPeriod: {
						preparation: 'in der Vorbereitung',
						beginning: 'zu Beginn der Installation',
						permanent: 'während der Installation',
						end: 'am Ende der Installation',
						followUp: 'nach der Installation'
					},
					unit: {
						hours: 'Stunden'
					},
					button: {
						add: 'Weiteren Tag hinzufügen'
					}
				},
				taskTradeAppointmentCraftItem: {
					input: {
						installer: 'Installateur',
						date: 'Datum',
						start: 'Beginn',
						end: 'Ende'
					}
				},
				taskTradeAppointmentEffort: {
					heading: 'Erwartete Gewerke und Aufwände',
					hint: {
						invalid: 'Eine oder mehrere Einstellungen sind nicht komplett oder ungültig und werden nicht übermittelt werden.',
						notApprovable: 'Es wurde kein abnahmefähiges Gewerk bestimmt. Mindestens ein Gewerk muss abnahmefähig sein, um die Installation abzuschließen.'
					},
					button: {
						add: 'Gewerk hinzufügen'
					}
				},
				taskTradeAppointmentEffortItem: {
					input: {
						trade: 'Gewerk',
						period: 'Zeitraum',
						effort: 'Aufwand',
						canComplete: 'Abnahmefähig'
					},
					installationPeriod: {
						preparation: 'Installationsvorbereitung',
						beginning: 'Installationsbeginn',
						permanent: 'Permanent',
						end: 'Installationsabschluss',
						followUp: 'Installationsnachbereitung'
					}
				},
				taskChoice: {
					decision: {
						standard: 'Informationen ausreichend - Standard Installation',
						delta: 'Informationen ausreichend - Delta Installation',
						preCheckRequired: 'Weitere Informationen benötigt',
						unfeasible: 'Installation technisch nicht möglich',
						project: 'Installation als eigenes Projekt möglich'
					},
					unfeasibleInstallationReasons: {
						label: 'Bitte wählen Sie einen Grund',
						noPotentialRailAvailable: 'Keine Potenzialschiene vorhanden',
						overvoltageProtectionUnfeasible: 'Überspannungsschutz kann nicht installiert werden',
						spaceForCableOutletMissing: 'Kein Platz für zusätzlichen Leitungsabgang',
						houseConnectionUndersized: 'Hausanschluss unterdimensioniert',
						noThreePhaseAvailable: 'Kein Drehstrom vorhanden im Haus (1 Phasig)',
						outdatedMeterCabinet: 'Veralteter Zählerschrank',
						notTabCompliant: 'Erfüllt nicht die TAB',
						other: 'Anderer Grund'
					},
					projectInstallationReasons: {
						label: 'Bitte wählen Sie einen Grund',
						condominiumOwnersAssociation: 'WEG (Genehmigung wird benötigt)',
						highPowerEvu: 'Anfrage 22 KW, EVU-Herausforderungen',
						undergroundGarageInstallation: 'Installation in Tiefgarage',
						fireProtectionIssues: 'Brandschutz-Herausforderungen',
						multipleWallboxManagement: 'Lastenmanagement wegen mehrerer Wallboxen erforderlich',
						other: 'Anderer Grund',
						hint: {
							heading: 'Wenn einer Installation generell nichts im Wege steht, aber nicht direkt mit dem Kunden durchfühbar ist, können Sie die Kunden- und Bestellinformationen in ein eigenständiges Projekt überführen.'
						},
						information: {
							heading: 'Eigenständiges Projekt vorschlagen',
							message: 'Der Kunde und ABB werden über Ihre Entscheidung informiert. Alle relevanten Informationen zu Kunde und Bestellung werden Ihnen per E-Mail zur Verfügung gestellt, so dass Sie mit dem Kunden in Kontakt treten können, um ein eigenständiges Installationsprojekt zu initiieren.'
						}
					}
				},
				taskOfferCollection: {
					fullPrice: 'Gesamtkosten (netto)'
				}
			},
			arrangeInstallationAppointment: {
				heading: 'Termin',
				replacementInfo: 'Gegenstand dieser Terminvereinbarung ist die Installation einer Ersatzlieferung.',
				rescheduleInfo: 'Es handelt sich um die Erstellung eines Ersatztermins.',
				contact: {
					heading: 'Kundenkontakt'
				},
				notes: {
					heading: 'Anmerkungen',
					placeholder: 'Anmerkungen sind nur für Sie und Ihre Kollegen sichtbar.'
				},
				button: {
					close: 'Schließen',
					submit: 'Erledigen'
				}
			},
			basicAnswers: {
				customer: {
					heading: 'Installationsadresse'
				},
				questionnaire: {
					heading: 'Antowrten des Kunden'
				},
				decision: {
					heading: 'Entscheidung'
				},
				notes: {
					heading: 'Anmerkungen',
					placeholder: 'Anmerkungen sind nur für Sie und Ihre Kollegen sichtbar.'
				},
				button: {
					close: 'Schließen',
					submit: 'Erledigen'
				},
				continueToQuotation: {
					buttonLabel: 'Direkt zur Angebotserstellung'
				}
			},
			gridOperatorDecision: {
				gridOperator: {
					heading: 'Netzbetreiberinformation'
				},
				wallboxPower: {
					heading: 'Wallbox-Information',
					hint: 'Der Kunde hat eine Wallbox mit einer Lastaufnahme von {{wallboxPower}} kW bestellt.'
				},
				documents: {
					heading: 'Zusätzliche Dokumente'
				},
				decision: {
					heading: 'Entscheidung',
					accepted: 'Der Netzbetreiber hat der Installation zugestimmt',
					declined: 'Der Netzbetreiber hat der Installation nicht zugestimmt'
				},
				notes: {
					heading: 'Anmerkungen',
					placeholder: 'Anmerkungen sind nur für Sie und Ihre Kollegen sichtbar.'
				},
				button: {
					close: 'Schließen',
					submit: 'Erledigen'
				}
			},
			gridOperatorRegistration: {
				gridOperator: {
					heading: 'Netzbetreiberinformation'
				},
				wallboxPower: {
					heading: 'Wallbox-Information',
					hint: 'Der Kunde hat eine Wallbox mit einer Lastaufnahme von {{wallboxPower}} kW bestellt.'
				},
				documents: {
					heading: 'Zusätzliche Dokumente'
				},
				registrationRequired: {
					heading: 'Registrierung erforderlich',
					required: 'Ja, Registrierung ist erforderlich',
					unnecessary: 'Nein, eine Registrierung ist nicht erforderlich'
				},
				notes: {
					heading: 'Anmerkungen',
					placeholder: 'Anmerkungen sind nur für Sie und Ihre Kollegen sichtbar.'
				},
				button: {
					close: 'Schließen',
					submit: 'Erledigen'
				},
				continueToFeedback: {
					buttonLabel: 'Direkt zur Netzbetreiber-Rückmeldung'
				}
			},
			gridOperatorRevokeRegistration: {
				gridOperator: {
					heading: 'Netzbetreiberinformation'
				},
				notes: {
					heading: 'Anmerkungen',
					placeholder: 'Anmerkungen sind nur für Sie und Ihre Kollegen sichtbar.'
				},
				button: {
					close: 'Schließen',
					submit: 'Erledigen'
				}
			},
			preCheck: {
				contact: {
					heading: 'Kundenkontakt',
					description: 'Wann ist der Kunde erreichbar?'
				},
				questionnaire: {
					heading: 'Antworten des Kunden'
				},
				decision: {
					heading: 'Entscheidung'
				},
				notes: {
					heading: 'Anmerkungen',
					placeholder: 'Anmerkungen sind nur für Sie und Ihre Kollegen sichtbar.'
				},
				button: {
					close: 'Schließen',
					submit: 'Erledigen'
				}
			},
			quotation: {
				customer: {
					heading: 'Installationsadresse'
				},
				questionnaire: {
					heading: 'Antworten des Kunden'
				},
				preCheck: {
					heading: 'Ergebnis Installations-Check: Gewerke und Aufwände'
				},
				comments: {
					heading: 'Bisherige Kommentare'
				},
				additionalEffort: {
					heading: 'Mehraufwand anbieten',
					text: 'Um die Installation durchzuführen müssen Mehraufwände zusätzlich zur Standardinstallation erbracht werden. Ein entsprechendes Angebot wird auf Basis der folgenden Delta-Installationspakete erstellt.'
				},
				conditions: {
					heading: 'Angebotsbedingungen für den Kunden',
					placeholder: 'Unter welchen Bedingungen ist das Angebot gültig?'
				},
				notes: {
					heading: 'Anmerkungen',
					placeholder: 'Anmerkungen sind nur für Sie und Ihre Kollegen sichtbar.'
				},
				button: {
					close: 'Schließen',
					submit: 'Erledigen'
				},
				deltaPackages: {
					fullPrice: 'Gesamtkosten (netto)'
				}
			},
			installationComplete: {
				contact: {
					heading: 'Kundenkontakt'
				},
				documents: {
					heading: 'Dokumente'
				},
				trades: {
					heading: 'Gewerke und Termine'
				},
				customerAcceptance: {
					heading: 'Kundenabnahme',
					onSite: 'Der Kunde hat die Installation vor Ort abgenommen',
					remote: 'Der Kunde war nicht vor Ort'
				},
				wallbox: {
					heading: 'Bestellte Wallbox',
					serial: 'Hiermit bestätige ich die ordnungsgemäße und fachgerechte Installation und Inbetriebnahme der ausgelieferten Wallbox mit der folgenden Seriennummer: {{serialNumber}}.',
					throttle: 'Entsprechend der Anforderungen des Netzbetreibers wurde die Lastaufnahme der Wallbox reduziert.'
				},
				notes: {
					heading: 'Anmerkungen',
					placeholder: 'Anmerkungen sind nur für Sie und Ihre Kollegen sichtbar.'
				},
				button: {
					close: 'Schließen',
					submit: 'Erledigen'
				}
			}
		},
		taskCollection: {
			heading: 'Aufgaben',
			tableHead: {
				order: 'Aufgabe',
				status: 'Status',
				due: 'Fälligkeit',
				priority: 'Priorität',
				responsible: 'Zugewiesen'
			},
			action: {
				refresh: 'Aktualisieren',
				showMore: 'Mehr laden'
			},
			assign: {
				successMessage: 'Die Aufgabe wurde erfolgreich zugewiesen',
				errorMessage: 'Während der Zuweisung der Aufgabe ist ein Fehler aufgetreten.'
			},
			emptyState: {
				heading: 'Keine Aufgaben gefunden',
				message: 'Verändern Sie Ihre Filter-Einstellungen, um weitere Aufgaben zu finden.'
			},
			orderEmptyState: {
				heading: 'Keine Aufgaben gefunden',
				message: 'Für die Bestellung sind keine Aufgaben offen.'
			}
		},
		error: {
			generic: {
				heading: 'Etwas ist schiefgegangen',
				message: 'Ein unvohergesehener Fehler ist aufgetreten.',
				resolveAction: {
					label: 'Neu laden'
				}
			},
			offline: {
				heading: 'Netzwerkverbindung nicht verfügbar',
				message: 'Bitte verbinden Sie sich mit einem Netzwerk, um die Anwendung nutzen zu können.'
			},
			notFound: {
				heading: 'Nicht gefunden',
				message: 'Die angefragte Ressource konnte nicht gefunden werden.',
				resolveAction: {
					label: 'Dashboard'
				}
			},
			orderNotFound: 'Die Bestellung konnte nicht gefunden werden.',
			taskNotFound: 'Die Aufgabe konnte nicht gefunden werden.',
			taskTypeNotFound: 'Der Aufgabentyp {{type}} ist unbekannt.',
			taskCompleteMessage: 'Während der Erledigung der Aufgabe ist ein Fehler aufgetreten.',
			taskAssignMessage: 'Während der Zuweisung der Aufgabe ist ein Fehler aufgetreten.'
		}
	}
};

export default literals;
