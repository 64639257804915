import { ReactNode } from 'react';

import './CollectionFooter.scss';

export type CollectionFooterProps = {
	children: ReactNode
};

export function CollectionFooter(props: CollectionFooterProps) {
	const { children } = props;

	return (
		<footer className="collection-footer">
			{children}
		</footer>
	);
}
