import React, { ReactNode } from 'react';

import { InstallationPartnerActionType } from '@abb-emobility/oms/domain-model';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	ActionModalContent,
	ButtonIcon,
	Icon,
	IconIdentifier,
	SegmentHeader,
	useModalDialogueManager
} from '@abb-emobility/shared/ui-primitive';

import { HypermediaAction } from './ActionModalFactory.types';
import { CancelOrderAppointmentsActionModal } from './cancel-order-appointments-action-modal/CancelOrderAppointmentsActionModal';

type ActionModalFactoryReturnType = {
	renderModal: (action: HypermediaAction) => void;
};

export function useActionModalFactory(): ActionModalFactoryReturnType {

	const modalDialogueManager = useModalDialogueManager();
	const l10n = useL10n();

	const renderActionModal = (action: HypermediaAction): void => {

		switch (action.hypermediaLink.rel) {
			case InstallationPartnerActionType.CANCEL_ORDER_APPOINTMENTS:
				modalDialogueManager.push({
						header: () => {
							return (
								<SegmentHeader caption={l10n.translate('omsInstallationPartnerOfficeApp.actionModalFactory.cancelOrderAppointmentsHeading')}>
									<ButtonIcon onClick={modalDialogueManager.pop}>
										<Icon name={IconIdentifier.X} />
									</ButtonIcon>
								</SegmentHeader>
							);
						},
						content: (): ReactNode => {
							return (
								<ActionModalContent>
									<CancelOrderAppointmentsActionModal action={action} />
								</ActionModalContent>
							);
						}
					}
				);
				break;
			default:
				console.warn('HypermediaActionModalFactory: case not defined: ' + action.hypermediaLink.rel);
		}
	};

	return {
		renderModal: renderActionModal
	};
}
