import React, { ReactNode, useEffect } from 'react';

import { useAuth } from '@abb-emobility/shared/auth-provider';
import { TaskAssignError, TaskCompleteError } from '@abb-emobility/shared/business-error';
import { TaskActionStatus } from '@abb-emobility/shared/data-provider-foundation';
import { InstallationDecisionType } from '@abb-emobility/shared/domain-model';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { AuthenticationFailedError, AuthenticationRequiredError, NotFoundError, restoreError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	ButtonGhost,
	ButtonSecondary,
	IconIdentifier,
	SuccessFeeback,
	SuccessImageIdentifier,
	ToastLevel,
	useModalDialogue,
	useModalDialogueManager,
	useToastManager
} from '@abb-emobility/shared/ui-primitive';
import { booleanFromString, Optional } from '@abb-emobility/shared/util';
import { useAnyTaskEntityData } from '@abb-emobility/usertask/data-provider';
import { taskIsCompleted, TaskType } from '@abb-emobility/usertask/domain-model';

import { TaskArrangeInstallationAppointment } from './payload/TaskArrangeInstallationAppointment';
import { TaskBasicAnswers } from './payload/TaskBasicAnswers';
import { TaskDecisionGridOperator } from './payload/TaskDecisionGridOperator';
import { TaskInstallationComplete } from './payload/TaskInstallationComplete';
import { TaskPrecheckRemote } from './payload/TaskPrecheckRemote';
import { TaskQuotation } from './payload/TaskQuotation';
import { TaskRegistrationGridOperator } from './payload/TaskRegistrationGridOperator';
import { TaskRevcationGridOperatorRegistration } from './payload/TaskRevcationGridOperatorRegistration';
import { TaskCollectionItemHeader } from '../task-collection-item/TaskCollectionItemHeader';
import { TaskSearchModalAssembler } from '../task-modal/TaskSearchModalAssembler';

export function TaskFactory() {

	const l10n = useL10n();
	const auth = useAuth();
	const toast = useToastManager();
	const modalDialogue = useModalDialogue();
	const modalDialogueManager = useModalDialogueManager();

	const anyTaskData = useAnyTaskEntityData();
	const task = anyTaskData.query().getOrThrow(new NotFoundError(l10n.translate('omsInstallationPartnerOfficeApp.error.taskNotFound')));
	const actionErrorObject = anyTaskData.queryActionError();

	anyTaskData.useActionStatusEffect([TaskActionStatus.ASSIGN_SUCCESS], () => {
		toast.addToastEntry({
			label: l10n.translate('omsInstallationPartnerOfficeApp.task.assign.successMessage'),
			level: ToastLevel.SUCCESS,
			autoDismiss: true
		});
	}, true);

	anyTaskData.useActionStatusEffect([TaskActionStatus.COMPLETE_FAILED], () => {
		if (actionErrorObject === null) {
			return;
		}
		const error = restoreError(actionErrorObject);
		if (error instanceof AuthenticationFailedError || error instanceof AuthenticationRequiredError) {
			throw error;
		}
		throw new TaskCompleteError(l10n.translate('omsInstallationPartnerOfficeApp.error.taskCompleteMessage'), undefined, undefined, error);
	}, true);

	anyTaskData.useActionStatusEffect([TaskActionStatus.ASSIGN_FAILED], () => {
		if (actionErrorObject === null) {
			return;
		}
		const error = restoreError(actionErrorObject);
		if (error instanceof AuthenticationFailedError || error instanceof AuthenticationRequiredError) {
			throw error;
		}
		throw new TaskAssignError(l10n.translate('omsInstallationPartnerOfficeApp.error.taskAssignMessage'), undefined, undefined, error);
	}, true);

	const handleCandidateGroupsSelect = (candidateGroupIds: Array<ModelPrimaryKey>): void => {
		if (auth.hasRole('installation-partner')) {
			anyTaskData.assign(task, candidateGroupIds);
		}
	};

	const handleDismissModal = () => {
		modalDialogueManager.pop();
	};

	useEffect(() => {
		modalDialogue.setHeader(
			<TaskCollectionItemHeader
				task={task}
				icon={IconIdentifier.X}
				onAssign={handleCandidateGroupsSelect}
				onClick={handleDismissModal}
				disabled={taskIsCompleted(task.status)}
			/>
		);
		return () => {
			modalDialogue.unsetHeader();
		};
	}, [task]);

	const renderTaskCompletionFeedback = (): ReactNode => {
		if (!taskIsCompleted(task.status)) {
			return null;
		}

		let continueButton: ReactNode = null;
		switch (task.type) {
			case TaskType.INSTALLATION_PARTNER_REGISTER_AT_GRID_OPERATOR: {
				const featureToggleSearchForGridOperatorTask = booleanFromString(
					new Optional(process.env['NX_FEATURE_TOGGLE_SEARCH_FOR_GRID_OPERATOR_TASK']).getOrDefault('false')
				);
				if (featureToggleSearchForGridOperatorTask && !task.payload.registrationUnnecessary) {
					const continueToGridOperatorFeedback = (): void => {
						modalDialogueManager.replace({
							content: (): ReactNode => {
								return (
									<TaskSearchModalAssembler
										orderId={task.orderId}
										taskType={TaskType.INSTALLATION_PARTNER_PROVIDE_GRID_OPERATOR_DECISION}
									/>
								);
							}
						});
					};
					continueButton = (
						<ButtonSecondary
							label={l10n.translate('omsInstallationPartnerOfficeApp.task.gridOperatorRegistration.continueToFeedback.buttonLabel')}
							onClick={continueToGridOperatorFeedback}
						/>
					);
				}
				break;
			}
			case TaskType.INSTALLATION_PARTNER_EVALUATE_BASIC_ANSWERS:
			case TaskType.INSTALLATION_PARTNER_EXECUTE_REMOTE_PRE_CHECK:
				if (task.payload.installationType === InstallationDecisionType.DELTA) {
					const continueToQuotation = (): void => {
						modalDialogueManager.replace({
							content: (): ReactNode => {
								return (
									<TaskSearchModalAssembler
										orderId={task.orderId}
										taskType={TaskType.INSTALLATION_PARTNER_PROVIDE_QUOTATION}
									/>
								);
							}
						});
					};
					continueButton = (
						<ButtonSecondary
							label={l10n.translate('omsInstallationPartnerOfficeApp.task.basicAnswers.continueToQuotation.buttonLabel')}
							onClick={continueToQuotation}
						/>
					);
				}
				break;
		}

		return (
			<SuccessFeeback
				heading={l10n.translate('omsInstallationPartnerOfficeApp.task.completion.successHeading')}
				image={SuccessImageIdentifier.IMAGE4}
			>
				{continueButton}
				<ButtonGhost label={l10n.translate('omsInstallationPartnerOfficeApp.task.completion.closeButtonLabel')} onClick={handleDismissModal} />
			</SuccessFeeback>
		);
	};

	const renderTask = () => {
		if (taskIsCompleted(task.status)) {
			return;
		}

		switch (task.type) {
			case TaskType.INSTALLATION_PARTNER_EVALUATE_BASIC_ANSWERS:
				return (<TaskBasicAnswers task={task} />);
			case TaskType.INSTALLATION_PARTNER_EXECUTE_REMOTE_PRE_CHECK:
				return (<TaskPrecheckRemote task={task} />);
			case TaskType.INSTALLATION_PARTNER_REGISTER_AT_GRID_OPERATOR:
				return (<TaskRegistrationGridOperator task={task} />);
			case TaskType.INSTALLATION_PARTNER_PROVIDE_GRID_OPERATOR_DECISION:
				return (<TaskDecisionGridOperator task={task} />);
			case TaskType.INSTALLATION_PARTNER_REVOKE_GRID_OPERATOR_REGISTRATION:
				return (<TaskRevcationGridOperatorRegistration task={task} />);
			case TaskType.INSTALLATION_PARTNER_PROVIDE_QUOTATION:
				return (<TaskQuotation task={task} />);
			case TaskType.INSTALLATION_PARTNER_ARRANGE_APPOINTMENT:
				return (<TaskArrangeInstallationAppointment task={task} />);
			case TaskType.INSTALLATION_PARTNER_COMPLETE_INSTALLATION:
				return <TaskInstallationComplete task={task} />;
			default:
				throw new NotFoundError(l10n.translate('omsInstallationPartnerOfficeApp.error.taskTypeNotFound', new Map([['type', task.type]])));
		}
	};

	return (
		<>
			{renderTaskCompletionFeedback()}
			{renderTask()}
		</>
	);

}
