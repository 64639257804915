import './CollectionItemDate.scss';

export type CollectionItemDateProps = {
	date: string
};

export function CollectionItemDate(props: CollectionItemDateProps) {
	const { date } = props;

	return (
		<div className="collection-item-date">
			{date}
		</div>
	);
}
