import { createContext, ReactNode, useContext } from 'react';

export type ModalDialogueContextValue = {
	setCaption: (caption: string) => void,
	unsetCaption: () => void,
	setHeader: (header: ReactNode) => void,
	unsetHeader: () => void,
	setContent: (content: ReactNode) => void,
	setFooter: (footer: ReactNode) => void,
	unsetFooter: () => void
};

export const modalDialogueContext = createContext<ModalDialogueContextValue>({
	setCaption: (_caption: string): void => {
		throw new Error('No dialogue provided');
	},
	unsetCaption: (): void => {
		throw new Error('No dialogue provided');
	},
	setHeader: (_header: ReactNode): void => {
		throw new Error('No dialogue provided');
	},
	unsetHeader: (): void => {
		throw new Error('No dialogue provided');
	},
	setContent: (_content: ReactNode): void => {
		throw new Error('No dialogue provided');
	},
	setFooter: (_footer: ReactNode): void => {
		throw new Error('No dialogue provided');
	},
	unsetFooter: (): void => {
		throw new Error('No dialogue provided');
	}
});

export const useModalDialogue = () => {
	return useContext(modalDialogueContext);
};
