import { TaskStatus } from './TaskStatus';

export const taskIsCompleted = (taskStatus: TaskStatus): boolean => {
	switch (taskStatus) {
		case TaskStatus.DONE:
		case TaskStatus.DONE_ESCALATED:
		case TaskStatus.DONE_OVERDUE:
			return true;
		default:
			return false;
	}
};
