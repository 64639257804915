import { MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import './TopbarBreadcrumbItem.scss';

export type TopbarBreadcrumbItemProps = {
	label: string,
	link: string
};

export function TopbarBreadcrumbItem(props: TopbarBreadcrumbItemProps) {

	const { link, label } = props;

	const navigate = useNavigate();

	const handleClick = (event: MouseEvent<HTMLAnchorElement>): void => {
		event.preventDefault();
		navigate(link);
	};

	return (
		<a href={link} onClick={handleClick} className="topbar-breadcrumb-item">
			{label}
		</a>
	);
}
