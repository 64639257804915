import { ReactNode } from 'react';

import './ActionModalFooter.scss';

export type ActionModalFooterProps = {
	children?: ReactNode
};

export function ActionModalFooter(props: ActionModalFooterProps) {

	const { children } = props;

	return (
		<footer className="action-modal-footer">
			{children}
		</footer>
	);
}
