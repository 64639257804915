import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	omsInstallationPartnerOfficeApp: {
		action: {
			cancelOrderAppointments: 'Annuler des rendez-vous'
		},
		actionItemFactory: {
			cancelOrderAppointmentsLabel: 'Annuler des rendez-vous'
		},
		actionModalFactory: {
			cancelOrderAppointmentsHeading: 'Annuler les rendez-vous de commande'
		},
		actionModal: {
			cancelOrderAppointments: {
				content: {
					notificationHeading: 'Annuler les rendez-vous d’installation.',
					notificationMessage: 'Tous les rendez-vous d’installation seront annulés.',
					successHeading: 'Rendez-vous annulés',
					successCloseButtonLabel: 'Fermer',
					reason: 'Motif'
				},
				cancelReasonValue: {
					deliveryDelay: 'Retard de livraison',
					installationPartnerConflict: 'Conflit de partenaire d’installation',
					customerConflict: 'Conflit de clientèle',
					replacement: 'Remplacements'
				},
				footer: {
					confirm: 'Confirmer l’annulation',
					cancel: 'Annuler'
				},
				error: {
					heading: 'Impossibilité d’annuler des rendez-vous.',
					message: 'Une erreur s’est produite lors de la suppression des rendez-vous de commande.'
				}
			}
		},
		appName: 'Bureau d’installation de Wallbox ABB',
		pageTitle: {
			dashboard: 'Tableau de bord',
			error: 'Erreur',
			notFound: 'Introuvable',
			orders: 'Commandes',
			order: 'Commande par {{displayName}}'
		},
		navigation: {
			dashboard: 'Tableau de bord',
			orders: 'Commandes',
			logout: 'Déconnexion'
		},
		breadcrumbs: {
			tasks: 'Aperçu des tâches',
			orders: 'Aperçu des commandes'
		},
		viewHeader: {
			tasks: 'Aperçu des tâches',
			orders: 'Aperçu des commandes'
		},
		installerAppointmentCollection: {
			heading: 'Installations prévues',
			emptyState: {
				heading: 'Aucune installation prévue',
				message: 'La commande n’a aucune installation prévue.'
			}
		},
		orderCollection: {
			heading: 'Commandes',
			tableHead: {
				order: 'Commande',
				status: 'État',
				estimated: 'Date d’installation estimée',
				progress: 'Progression'
			},
			emptyState: {
				heading: 'Aucune commande trouvée',
				message: 'Ajustez vos paramètres de filtrage pour trouver des commandes.'
			},
			action: {
				refresh: 'Actualiser',
				showMore: 'Afficher plus'
			},
			filter: {
				filter: 'Filtrer',
				none: 'aucun(e)',
				active: '{{count}} actif',
				resetFilter: 'Réinitialiser le filtre',
				options: {
					dueDateOverdue: 'Retardé',
					dueDateToday: 'À remettre aujourd’hui',
					priorityHigh: 'Priorité élevée',
					priorityMedium: 'Priorité moyenne',
					priorityLow: 'Priorité faible',
					statusDone: 'Terminé',
					statusOpen: 'Ouvert',
					installationTypeStandard: 'Installation standard',
					installationTypeDelta: 'Installation Delta',
					installationTypeUndetermined: 'Indéterminé'
				}
			}
		},
		orderDetail: {
			progress: {
				heading: 'Progression'
			},
			customerInformation: {
				heading: 'Informations sur les clients',
				address: 'Adresse',
				phone: 'Téléphone',
				mail: 'Courrier'
			},
			installationAddress: {
				heading: 'Adresse d’installation',
				address: 'Adresse'
			},
			status: {
				onSchedule: 'Dans les délais',
				delayed: 'Retardé',
				cancelPending: 'Annuler en attente',
				cancelled: 'Annulé',
				done: 'Terminé'
			},
			installationType: {
				none: 'Indéterminé',
				undetermined: 'Indéterminé',
				cancelled: 'Annulé',
				standard: 'Standard',
				delta: 'Delta'
			}
		},
		task: {
			progressStepShortName: {
				installationPartnerEvaluateBasicAnswers: 'Contrôle de base',
				installationPartnerArrangeAppointment: 'Rendez-vous',
				installationPartnerExecuteRemotePreCheck: 'Contrôle final',
				installationPartnerRegisterAtGridOperator: 'Enregistrement de l’opérateur réseau',
				installationPartnerProvideGridOperatorDecision: 'Décision de l’opérateur réseau',
				installationPartnerRevokeGridOperatorRegistration: 'Révocation de l’opérateur réseau',
				installationPartnerProvideQuotation: 'Offre',
				installationPartnerCompleteInstallation: 'Installation'
			},
			displayName: {
				installationPartnerEvaluateBasicAnswers: 'Informations de base',
				installationPartnerArrangeAppointment: 'Fixer un rendez-vous',
				installationPartnerExecuteRemotePreCheck: 'Pré-contrôle',
				installationPartnerRegisterAtGridOperator: 'Enregistrement de l’opérateur réseau',
				installationPartnerProvideGridOperatorDecision: 'Décision de l’opérateur réseau',
				installationPartnerRevokeGridOperatorRegistration: 'Révocation de l’opérateur réseau',
				installationPartnerProvideQuotation: 'Offre',
				installationPartnerCompleteInstallation: 'Achèvement de l’installation'
			},
			status: {
				started: 'À venir',
				dueToday: 'À remettre aujourd’hui',
				overdue: 'En retard',
				escalated: 'Remonté',
				done: 'Terminé',
				doneOverdue: 'Terminé',
				doneEscalated: 'Terminé'
			},
			priority: {
				low: 'Faible',
				medium: 'Moyen',
				high: 'Élevé'
			},
			assign: {
				successMessage: 'La tâche a été assignée avec succès'
			},
			completion: {
				successHeading: 'Tâche terminée',
				closeButtonLabel: 'Fermer'
			},
			component: {
				taskTradeAppointmentCraft: {
					hint: {
						invalid: 'Un ou plusieurs paramètres relatifs aux métiers et aux efforts sont incomplets ou invalides et ne seront pas soumis.'
					},
					estimatedEffort: 'Effort estimé',
					selectedEffort: 'Sélectionné',
					installationPeriod: {
						preparation: 'comme préparation à l’installation',
						beginning: 'au début de l’installation',
						permanent: 'pendant l’installation',
						end: 'à la fin de l’installation',
						followUp: 'après l’installation'
					},
					unit: {
						hours: 'heures'
					},
					button: {
						add: 'Ajouter plus de jours'
					}
				},
				taskTradeAppointmentCraftItem: {
					input: {
						installer: 'Installateur',
						date: 'Date',
						start: 'Début',
						end: 'Fin'
					}
				},
				taskTradeAppointmentEffort: {
					heading: 'Métiers et efforts d’installation prévus',
					hint: {
						invalid: 'Un ou plusieurs paramètres relatifs aux métiers et aux efforts sont incomplets ou invalides et ne seront pas soumis.',
						notApprovable: 'Aucun métier susceptible d’être accepté n’a été déterminé. Au moins un métier doit être prêt à être accepté pour que l’installation soit terminée.'
					},
					button: {
						add: 'Ajouter un métier'
					}
				},
				taskTradeAppointmentEffortItem: {
					input: {
						trade: 'Métier',
						period: 'Période',
						effort: 'Effort',
						canComplete: 'peut compléter'
					},
					installationPeriod: {
						preparation: 'Préparation de l’installation',
						beginning: 'Début de l’installation',
						permanent: 'Permanent',
						end: 'Fin de l’installation',
						followUp: 'Suivi de l’installation'
					}
				},
				taskChoice: {
					decision: {
						standard: 'Informations suffisantes - installation standard',
						delta: 'Informations suffisantes - installation delta',
						preCheckRequired: 'Plus d’informations nécessaires',
						unfeasible: 'Installation impossible',
						project: 'Possibilité d’installation en tant que projet séparé'
					},
					unfeasibleInstallationReasons: {
						label: 'Sélectionner un motif',
						noPotentialRailAvailable: 'Pas de rail potentiel disponible',
						overvoltageProtectionUnfeasible: 'La protection contre les surtensions ne peut pas être installée',
						spaceForCableOutletMissing: 'Pas d’espace pour une sortie de câble supplémentaire',
						houseConnectionUndersized: 'Raccordement à la maison sous-dimensionné',
						noThreePhaseAvailable: 'Pas de courant triphasé dans la maison (monophasé)',
						outdatedMeterCabinet: 'Armoire à compteurs obsolète',
						notTabCompliant: 'N’est pas conforme à l’ONGLET',
						other: 'Autre motif'
					},
					projectInstallationReasons: {
						label: 'Sélectionner un motif',
						condominiumOwnersAssociation: 'Copropriété (autorisation requise)',
						highPowerEvu: 'Demande 22 KW, défis EVU',
						undergroundGarageInstallation: 'Installation dans un garage souterrain',
						fireProtectionIssues: 'Défis en matière de protection contre l’incendie',
						multipleWallboxManagement: 'Gestion de la charge nécessaire en raison de la présence de plusieurs Wallbox',
						other: 'Autre motif',
						hint: {
							heading: 'Si l’installation est possible en général, mais pas dans le cadre d’une relation directe avec le client, vous pouvez choisir de créer un projet B2B à partir de la commande, qui doit être traité séparément.'
						},
						information: {
							heading: 'Proposer un projet d’installation',
							message: 'Le client et ABB seront informés de votre décision. Toutes les informations pertinentes, y compris les informations supplémentaires fournies par le client, vous sont transmises par courrier. Vous pouvez ensuite prendre contact avec le client pour mettre en place un projet d’installation séparé.'
						}
					}
				},
				taskOfferCollection: {
					fullPrice: 'Prix total (net)'
				}
			},
			arrangeInstallationAppointment: {
				heading: 'Rendez-vous',
				replacementInfo: 'L\'installation à programmer est une installation de remplacement.',
				rescheduleInfo: 'Le rendez-vous à fixer est un report.',
				contact: {
					heading: 'Contact client'
				},
				notes: {
					heading: 'Notes',
					placeholder: 'Ajouter des notes'
				},
				button: {
					close: 'Fermer',
					submit: 'Terminé'
				}
			},
			basicAnswers: {
				customer: {
					heading: 'Adresse d’installation'
				},
				questionnaire: {
					heading: 'Réponses des clients'
				},
				decision: {
					heading: 'Décision'
				},
				notes: {
					heading: 'Notes',
					placeholder: 'Ajouter des notes'
				},
				button: {
					close: 'Fermer',
					submit: 'Terminé'
				},
				continueToQuotation: {
					buttonLabel: 'Créer une offre'
				}
			},
			gridOperatorDecision: {
				gridOperator: {
					heading: 'Opérateur réseau'
				},
				wallboxPower: {
					heading: 'Informations sur la Wallbox',
					hint: 'Le client a commandé une Wallbox avec une consommation électrique de {{wallboxPower}} kW.'
				},
				documents: {
					heading: 'Documents'
				},
				decision: {
					heading: 'Décision',
					accepted: 'L’opérateur réseau a accepté l’installation',
					declined: 'L’opérateur réseau a refusé l’installation'
				},
				notes: {
					heading: 'Notes',
					placeholder: 'Ajouter des notes'
				},
				button: {
					close: 'Fermer',
					submit: 'Soumettre'
				}
			},
			gridOperatorRegistration: {
				gridOperator: {
					heading: 'Opérateur réseau'
				},
				wallboxPower: {
					heading: 'Informations sur la Wallbox',
					hint: 'Le client a commandé une Wallbox avec une consommation électrique de {{wallboxPower}} kW.'
				},
				documents: {
					heading: 'Documents'
				},
				registrationRequired: {
					heading: 'Inscription requise',
					required: 'Oui, une inscription est requise',
					unnecessary: 'Non, une inscription n’est pas requise'
				},
				notes: {
					heading: 'Notes',
					placeholder: 'Ajouter des notes'
				},
				button: {
					close: 'Fermer',
					submit: 'Soumettre'
				},
				continueToFeedback: {
					buttonLabel: 'Saisir le feedback de l’opérateur réseau'
				}
			},
			gridOperatorRevokeRegistration: {
				gridOperator: {
					heading: 'Opérateur réseau'
				},
				notes: {
					heading: 'Notes',
					placeholder: 'Ajouter des notes'
				},
				button: {
					close: 'Fermer',
					submit: 'Soumettre'
				}
			},
			preCheck: {
				contact: {
					heading: 'Contact client',
					description: 'À quelle heure de la journée le client est-il chez lui ?'
				},
				questionnaire: {
					heading: 'Réponses des clients du questionnaire de base'
				},
				decision: {
					heading: 'Décision'
				},
				notes: {
					heading: 'Notes',
					placeholder: 'Ajouter des notes'
				},
				button: {
					close: 'Fermer',
					submit: 'Terminé'
				}
			},
			quotation: {
				customer: {
					heading: 'Adresse d’installation'
				},
				questionnaire: {
					heading: 'Réponses des clients du questionnaire de base'
				},
				preCheck: {
					heading: 'Pré-contrôlez les résultats : Rendez-vous et efforts d’installation'
				},
				comments: {
					heading: 'Commentaires supplémentaires'
				},
				additionalEffort: {
					heading: 'Effort supplémentaire',
					text: 'Pour réaliser l’installation, des travaux supplémentaires doivent être effectués en plus de l’installation standard. Une offre correspondante sera créée sur la base des packs d’installation Delta suivants.'
				},
				conditions: {
					heading: 'Conditions de l’offre pour le client',
					placeholder: 'Sous quelles conditions l’offre est-elle valable ?'
				},
				notes: {
					heading: 'Notes',
					placeholder: 'Ajouter des notes'
				},
				button: {
					close: 'Fermer',
					submit: 'Soumettre'
				},
				deltaPackages: {
					fullPrice: 'Prix total (net)'
				}
			},
			installationComplete: {
				contact: {
					heading: 'Client'
				},
				documents: {
					heading: 'Documents'
				},
				trades: {
					heading: 'Métiers et nominations'
				},
				customerAcceptance: {
					heading: 'Acceptation du client',
					onSite: 'Le client a approuvé l’installation sur site',
					remote: 'Le client n’était pas disponible'
				},
				wallbox: {
					heading: 'Wallbox commandée',
					serial: 'Je confirme par la présente l’installation et la mise en service correctes et professionnelles de la Wallbox livrée comportant le numéro de série suivant : {{serialNumber}}.',
					throttle: 'La consommation électrique de la Wallbox a été réduite en fonction des exigences des opérateurs réseau.'
				},
				notes: {
					heading: 'Notes',
					placeholder: 'Ajouter des notes'
				},
				button: {
					close: 'Fermer',
					submit: 'Soumettre'
				}
			}
		},
		taskCollection: {
			heading: 'Tâches',
			tableHead: {
				order: 'Tâche',
				status: 'État',
				due: 'Date d’échéance',
				priority: 'Priorité',
				responsible: 'Responsable'
			},
			action: {
				refresh: 'Actualiser',
				showMore: 'Afficher plus'
			},
			assign: {
				successMessage: 'La tâche a été assignée avec succès',
				errorMessage: 'Une erreur s’est produite lors de l’attribution de la tâche.'
			},
			emptyState: {
				heading: 'Aucune tâche trouvée',
				message: 'Ajustez vos paramètres de filtrage pour trouver certaines tâches.'
			},
			orderEmptyState: {
				heading: 'Aucune tâche trouvée',
				message: 'Il n’y a rien à faire pour cette commande.'
			}
		},
		error: {
			generic: {
				heading: 'Un problème est survenu',
				message: 'Une erreur imprévue s’est produite et n’a pas pu être résolue',
				resolveAction: {
					label: 'Recharger'
				}
			},
			offline: {
				heading: 'Connexion réseau indisponible',
				message: 'Veuillez vous connecter à un réseau pour utiliser l’application.'
			},
			notFound: {
				heading: 'Introuvable',
				message: 'Ressource demandée introuvable',
				resolveAction: {
					label: 'Tableau de bord'
				}
			},
			orderNotFound: 'Commande introuvable.',
			taskNotFound: 'Tâche introuvable.',
			taskTypeNotFound: 'Type de tâche introuvable : {{type}}',
			taskCompleteMessage: 'Une erreur s’est produite lors de l’exécution de la tâche.',
			taskAssignMessage: 'Une erreur s’est produite lors de l’attribution de la tâche.'
		}
	}
};

export default literals;
