import { ChangeEvent, InputHTMLAttributes } from 'react';

import { buildCssClassStringFromClassMap, buildCssClassNameFromEnumValue, Nullable } from '@abb-emobility/shared/util';

import { LabelPosition } from '../../types/LabelPosition';
import { Icon } from '../icon/Icon';
import { IconIdentifier } from '../icon/Icon.enums';

import './InputDate.scss';

export type InputDateProps = {
	label?: string,
	labelPosition?: LabelPosition,
	icon?: IconIdentifier,
	onChange?: (date: Nullable<Date>) => void,
	min?: Date,
	max?: Date
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'min' | 'max' | 'required'>;

export function InputDate(props: InputDateProps) {
	const {
		label,
		labelPosition = LabelPosition.TOP,
		icon,
		disabled,
		readOnly,
		onChange,
		min,
		max,
		...rest
	} = props;

	const labelClassMap = {
		'input-date__label': true,
		'input-date__label--disabled': disabled,
		'input-date__label--readonly': readOnly
	};

	const inputTextFieldClassMap = {
		'input-date__field__input': true,
		'input-date__field__input--readonly': readOnly
	};

	const formatBoudaries = (date?: Date): string | undefined => {
		if (date === undefined) {
			return undefined;
		}
		return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
	};

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		if (onChange !== undefined) {
			onChange(event.target.valueAsDate);
		}
	};

	// render functions
	const renderLabel = () => {
		if (label === undefined) {
			return null;
		}
		return (
			<span className={`input-date__label ${buildCssClassStringFromClassMap(labelClassMap)} ${buildCssClassNameFromEnumValue(labelPosition, 'input-date__label')}`}>
				{label}
			</span>
		);
	};

	const renderIcon = () => {
		if (icon === undefined) {
			return;
		}
		return (
			<span className="input-date__field__icon">
				<Icon name={icon} />
			</span>
		);
	};

	return (
		<label className={`input-date ${buildCssClassNameFromEnumValue(labelPosition, 'input-date')}`}>
			{renderLabel()}
			<span className="input-date__field">
				<input
					type="date"
					className={`input-date__field__input ${buildCssClassStringFromClassMap(inputTextFieldClassMap)} ${buildCssClassNameFromEnumValue(labelPosition, 'input-date__field__input')}`}
					disabled={disabled}
					onChange={handleChange}
					required={true}
					min={formatBoudaries(min)}
					max={formatBoudaries(max)}
					{...rest}
				/>
				{renderIcon()}
			</span>
		</label>
	);
}
