import React, { Fragment, ReactNode } from 'react';

import { useBreadcrumbs } from '../breadcrumbs/Breadcrumbs.context';
import { Icon } from '../icon/Icon';
import { IconIdentifier, IconSize } from '../icon/Icon.enums';
import { TopbarBreadcrumbItem } from '../topbar/topbar-breadcrumb-item/TopbarBreadcrumbItem';

export function BreadcrumbFactory() {

	const breadcrumbs = useBreadcrumbs();

	const renderBreadcrumbs = (): ReactNode => {
		return breadcrumbs.getBreadcrumbEntries().map((entry, index) => {
			const icon = index === 0 ? null : (<Icon size={IconSize.SMALL} name={IconIdentifier.CARET_RIGHT} />);
			return (
				<Fragment key={entry.link}>
					{icon}
					<TopbarBreadcrumbItem label={entry.label} link={entry.link} />
				</Fragment>
			);
		});
	};

	return (
		<>
			{renderBreadcrumbs()}
		</>
	);

}
