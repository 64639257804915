import React from 'react';

import { createAccessTokenLoader, useAuth } from '@abb-emobility/shared/auth-provider';
import { FetchError } from '@abb-emobility/shared/business-error';
import {
	CollectionDataProviderProps,
	createTaskCollectionDataProviderValue,
	FetchMode,
	useDataProviderFetchFailedHandler,
	useDataProviderFetchInit,
	useDataProviderFetchSuspense,
	useDataProviderWebsocketHandler
} from '@abb-emobility/shared/data-provider-foundation';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { useEnv } from '@abb-emobility/shared/environment';
import { AppError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { Optional } from '@abb-emobility/shared/util';
import { AnyTaskCollectionItemModel, TaskStatus } from '@abb-emobility/usertask/domain-model';
import { WebsocketEvent, WebsocketMessage, WebsocketTopic } from '@abb-emobility/usertask/websocket';

import { orderTaskCollectionData } from './OrderTaskCollectionData';
import { createOrderTaskCollectionFilterCriteria, createOrderTaskCollectionSortCriteria } from './OrderTaskCollectionDataProvider.util';
import { OrderTaskCollectionCrudStore, orderTaskCollectionStoreAccessors, orderTaskCollectionStoreName } from './OrderTaskCollectionSlice';

export type OrderTaskCollectionDataProviderProps =
	Omit<CollectionDataProviderProps<AnyTaskCollectionItemModel>, 'sortCriteria' | 'filterCriteria'>
	& { orderId: ModelPrimaryKey };

export function OrderTaskCollectionDataProvider(props: OrderTaskCollectionDataProviderProps) {
	const {
		fetchMode = FetchMode.IMMEDIATE,
		suspense = fetchMode === FetchMode.IMMEDIATE,
		pendingComponent = null,
		forceFetch,
		orderId
	} = props;

	const env = useEnv();
	const auth = useAuth();
	const l10n = useL10n();

	const apiBaseUrl = new Optional(process.env['NX_USER_TASK_MANAGEMENT_API_BASE_URL'])
		.getOrThrow(new AppError('API base URL unavailable'));

	const dataProviderValue = createTaskCollectionDataProviderValue<AnyTaskCollectionItemModel, OrderTaskCollectionCrudStore>(
		orderTaskCollectionStoreName,
		orderTaskCollectionStoreAccessors,
		apiBaseUrl,
		createAccessTokenLoader(auth, env)
	);

	useDataProviderFetchFailedHandler(dataProviderValue, new FetchError(l10n.translate('usertaskDataProvider.error.taskCollectionFetchMessage')));

	useDataProviderFetchInit(fetchMode, () => dataProviderValue.fetch(createOrderTaskCollectionSortCriteria(), createOrderTaskCollectionFilterCriteria(orderId), forceFetch));

	const handleMessage = (message: WebsocketMessage): void => {
		switch (message.event) {
			case WebsocketEvent.TASK_ASSIGNED:
				dataProviderValue.refetchEntity(message.resourceId);
				break;
			case WebsocketEvent.TASK_COMPLETED:
				dataProviderValue.setTaskStatus(message.resourceId, TaskStatus.DONE);
				break;
		}
	};
	useDataProviderWebsocketHandler(orderTaskCollectionStoreName, dataProviderValue, WebsocketTopic.INSTALLATION_PARTNER_TASK, handleMessage);

	return useDataProviderFetchSuspense(dataProviderValue, suspense, pendingComponent).getOrDefault(
		<orderTaskCollectionData.Provider value={dataProviderValue}>
			{props.children}
		</orderTaskCollectionData.Provider>
	);

}
