import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

import {
	hasApprovableInstallationTradeEffort,
	InstallationPeriod,
	InstallationTradeEffortModel,
	InstallationTradeModel,
	isInstallationTradeEffort
} from '@abb-emobility/shared/domain-model';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { ButtonTertiary, Hint, HintLevel, IconIdentifier } from '@abb-emobility/shared/ui-primitive';

import { TaskTradeAppointmentEffortItem } from './TaskTradeAppointmentEffortItem';

import './TaskTradeAppointmentEffort.scss';

export type TaskTradeAppointmentEffortProps = {
	tradeOptions: Array<InstallationTradeModel>,
	onChange: (tradeEfforts: Array<InstallationTradeEffortModel>) => void
};

export const TaskTradeAppointmentEffort = (props: TaskTradeAppointmentEffortProps) => {

	const { tradeOptions, onChange } = props;

	const l10n = useL10n();
	const [tradeEfforts, setTradeEfforts] = useState<Map<string, Partial<InstallationTradeEffortModel>>>(new Map());

	useEffect(() => {
		if (tradeEfforts.size === 0) {
			addTradeEffort(true);
		}
	}, []);

	const handleChange = (tradeEfforts: Map<string, Partial<InstallationTradeEffortModel>>) => {
		let qualifiedTradeEfforts: Array<InstallationTradeEffortModel> = [];
		tradeEfforts.forEach((value) => {
			if (isInstallationTradeEffort(value) && value.effortInHours > 0) {
				qualifiedTradeEfforts.push(value);
			}
		});
		if (!hasApprovableInstallationTradeEffort(qualifiedTradeEfforts)) {
			qualifiedTradeEfforts = [];
		}
		onChange(qualifiedTradeEfforts);
	};

	const addTradeEffort = (initial = false): void => {
		const map = new Map(tradeEfforts);
		const mapId = uuid();
		map.set(mapId, {
			effortInHours: 0,
			period: InstallationPeriod.PERMANENT,
			tradeId: tradeOptions?.[0].id,
			approvable: initial
		});
		setTradeEfforts(map);
		handleChange(map);
	};

	const updateTradeEffort = (mapId: string, tradeEffort: Partial<InstallationTradeEffortModel>): void => {
		const map = new Map(tradeEfforts);
		map.set(mapId, tradeEffort);
		setTradeEfforts(map);
		handleChange(map);
	};

	const handleAdd = () => {
		addTradeEffort();
	};

	const handleRemove = (mapId: string) => {
		const map = new Map(tradeEfforts);
		map.delete(mapId);
		setTradeEfforts(map);
		handleChange(map);
	};

	const renderNotifications = (): ReactNode => {
		const notifications: Array<ReactElement> = [];
		const validTradeEfforts: Array<InstallationTradeEffortModel> = [];
		const invalidTradeEfforts: Array<Partial<InstallationTradeEffortModel>> = [];
		tradeEfforts.forEach((value) => {
			if (!isInstallationTradeEffort(value) || isNaN(value.effortInHours) || value.effortInHours < 1) {
				invalidTradeEfforts.push(value);
			} else {
				validTradeEfforts.push(value);
			}
		});
		if (validTradeEfforts.length > 0 && !hasApprovableInstallationTradeEffort(validTradeEfforts)) {
			notifications.push(
				<Hint
					heading={l10n.translate('omsInstallationPartnerOfficeApp.task.component.taskTradeAppointmentEffort.hint.notApprovable')}
					level={HintLevel.ERROR}
					key="notApprovable"
				/>
			);
		}
		if (invalidTradeEfforts.length > 0) {
			notifications.push(
				<Hint
					heading={l10n.translate('omsInstallationPartnerOfficeApp.task.component.taskTradeAppointmentEffort.hint.invalid')}
					level={HintLevel.WARNING}
					key="invalid"
				/>
			);
		}
		return notifications;
	};

	const renderInputRows = (): ReactNode => {
		const elements: Array<ReactNode> = [];
		let intitalSet = true;

		tradeEfforts.forEach((value, key) => {
			const removeCallback = () => handleRemove(key);
			elements.push(
				<TaskTradeAppointmentEffortItem
					key={key}
					isFirstRow={intitalSet}
					tradeEffort={value}
					tradeOptions={tradeOptions}
					onRemove={tradeEfforts.size > 1 ? removeCallback : undefined}
					onChange={(tradeEffort) => {
						updateTradeEffort(key, tradeEffort);
					}}
				/>
			);
			intitalSet = false;
		});
		return elements;
	};

	return (
		<article className="task-trade-appointment-effort">
			<div className="task-trade-appointment-effort__items">
				{renderNotifications()}
				{renderInputRows()}
			</div>
			<div className="task-trade-appointment-effort__action">
				<ButtonTertiary
					onClick={handleAdd}
					label={l10n.translate('omsInstallationPartnerOfficeApp.task.component.taskTradeAppointmentEffort.button.add')}
					icon={IconIdentifier.PLUS_CIRCLE}
				/>
			</div>
		</article>
	);
};
