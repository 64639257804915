import { ChangeEvent, TextareaHTMLAttributes } from 'react';

import { buildCssClassStringFromClassMap, buildCssClassNameFromEnumValue } from '@abb-emobility/shared/util';

import { LabelPosition } from '../../types/LabelPosition';
import { Icon } from '../icon/Icon';
import { IconIdentifier } from '../icon/Icon.enums';

import './InputTextArea.scss';

export type InputTextAreaProps = {
	label?: string,
	labelPosition?: LabelPosition,
	icon?: IconIdentifier,
	onChange?: (value: string) => void
} & Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'onChange'>;

export function InputTextArea(props: InputTextAreaProps) {
	const {
		label,
		labelPosition = 'top',
		icon = null,
		onChange = null,
		disabled,
		...rest
	} = props;

	// conditional classNames
	const labelClassMap = {
		'input-text-area__label': true,
		'input-text-area__label--disabled': disabled
	};

	const inputTextAreaFieldClassMap = {
		'input-text-area__field__input': true,
		'input-text-area__field__input--icon': icon !== null
	};

	// event handling
	const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
		if (onChange !== null) {
			onChange(event.target.value);
		}
	};

	// render functions
	const renderLabel = () => {
		if (label === undefined) {
			return;
		}
		return (
			<span className={`input-text-area__label ${buildCssClassStringFromClassMap(labelClassMap)} ${buildCssClassNameFromEnumValue(labelPosition, 'input-text-area__label')}`}>
				{label}
			</span>
		);
	};

	const renderIcon = () => {
		if (icon === null) {
			return;
		}
		return (
			<span className="input-text-area__field__icon">
				<Icon name={icon} />
			</span>
		);
	};

	return (
		<label className={`input-text-area ${buildCssClassNameFromEnumValue(labelPosition, 'input-text-area')}`}>
			{renderLabel()}
			<span className="input-text-area__field">
				<textarea
					onChange={handleChange}
					className={buildCssClassStringFromClassMap(inputTextAreaFieldClassMap)}
					disabled={disabled}
					{...rest}
				/>
				{renderIcon()}
			</span>
		</label>
	);
}
