import { Nullable } from '@abb-emobility/shared/util';

import { WebsocketConnector } from './WebsocketConnector';

export class WebsocketInstrument {

	private static webSocketConnector: Nullable<WebsocketConnector> = null;

	public static get(): WebsocketConnector {
		if (this.webSocketConnector === null) {
			this.webSocketConnector = new WebsocketConnector();
		}
		return this.webSocketConnector;
	}

}
