import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	omsInstallationPartnerOfficeApp: {
		action: {
			cancelOrderAppointments: 'Cancel Appointments'
		},
		actionItemFactory: {
			cancelOrderAppointmentsLabel: 'Cancel Appointments'
		},
		actionModalFactory: {
			cancelOrderAppointmentsHeading: 'Cancel Order Appointments'
		},
		actionModal: {
			cancelOrderAppointments: {
				content: {
					notificationHeading: 'Cancel the installation appointments.',
					notificationMessage: 'All installation appointments will be cancelled.',
					successHeading: 'Appointments cancelled',
					successCloseButtonLabel: 'Close',
					reason: 'Reason'
				},
				cancelReasonValue: {
					deliveryDelay: 'Delivery Delay',
					installationPartnerConflict: 'Installation Partner Conflict',
					customerConflict: 'Customer Conflict',
					replacement: 'Replacement'
				},
				footer: {
					confirm: 'Confirm Cancellation',
					cancel: 'Cancel'
				},
				error: {
					heading: 'Could not cancel appointments.',
					message: 'An Error occured while deleting the order appointments.'
				}
			}
		},
		appName: 'ABB wallbox installation office',
		pageTitle: {
			dashboard: 'Dashboard',
			error: 'Error',
			notFound: 'Not found',
			orders: 'Orders',
			order: 'Order by {{displayName}}'
		},
		navigation: {
			dashboard: 'Dashboard',
			orders: 'Orders',
			logout: 'Logout'
		},
		breadcrumbs: {
			tasks: 'Task overview',
			orders: 'Order overview'
		},
		viewHeader: {
			tasks: 'Task overview',
			orders: 'Orders overview'
		},
		installerAppointmentCollection: {
			heading: 'Planned Installations',
			emptyState: {
				heading: 'No planned installations',
				message: 'The order has no planned installations.'
			}
		},
		orderCollection: {
			heading: 'Orders',
			tableHead: {
				order: 'Order',
				status: 'Status',
				estimated: 'Estimated installation date',
				progress: 'Progress'
			},
			emptyState: {
				heading: 'No orders found',
				message: 'Adjust your filter settings to find some orders.'
			},
			action: {
				refresh: 'Refresh',
				showMore: 'Show more'
			},
			filter: {
				filter: 'Filter',
				none: 'none',
				active: '{{count}} active',
				resetFilter: 'Reset filter',
				options: {
					dueDateOverdue: 'Delayed',
					dueDateToday: 'Due today',
					priorityHigh: 'Priority high',
					priorityMedium: 'Priority medium',
					priorityLow: 'Priority low',
					statusDone: 'Done',
					statusOpen: 'Open',
					installationTypeStandard: 'Standard installation',
					installationTypeDelta: 'Delta installation',
					installationTypeUndetermined: 'Undetermined'
				}
			}
		},
		orderDetail: {
			progress: {
				heading: 'Progress'
			},
			customerInformation: {
				heading: 'Customer information',
				address: 'Address',
				phone: 'Phone',
				mail: 'Mail'
			},
			installationAddress: {
				heading: 'Installation address',
				address: 'Address'
			},
			status: {
				onSchedule: 'On schedule',
				delayed: 'Delayed',
				cancelPending: 'Cancel pending',
				cancelled: 'Cancelled',
				done: 'Done'
			},
			installationType: {
				none: 'Undetermined',
				undetermined: 'Undetermined',
				cancelled: 'Cancelled',
				standard: 'Standard',
				delta: 'Delta'
			}
		},
		task: {
			progressStepShortName: {
				installationPartnerEvaluateBasicAnswers: 'Basic check',
				installationPartnerArrangeAppointment: 'Appointment',
				installationPartnerExecuteRemotePreCheck: 'Final check',
				installationPartnerRegisterAtGridOperator: 'Registration grid operator',
				installationPartnerProvideGridOperatorDecision: 'Decision grid operator',
				installationPartnerRevokeGridOperatorRegistration: 'Revocation grid operator',
				installationPartnerProvideQuotation: 'Offer',
				installationPartnerCompleteInstallation: 'Installation'
			},
			displayName: {
				installationPartnerEvaluateBasicAnswers: 'Basic information',
				installationPartnerArrangeAppointment: 'Arrange appointment',
				installationPartnerExecuteRemotePreCheck: 'Pre-Check',
				installationPartnerRegisterAtGridOperator: 'Registration grid operator',
				installationPartnerProvideGridOperatorDecision: 'Decision grid operator',
				installationPartnerRevokeGridOperatorRegistration: 'Revocation grid operator registration',
				installationPartnerProvideQuotation: 'Offer',
				installationPartnerCompleteInstallation: 'Installation completion'
			},
			status: {
				started: 'Upcoming',
				dueToday: 'Due today',
				overdue: 'Overdue',
				escalated: 'Escalated',
				done: 'Done',
				doneOverdue: 'Done',
				doneEscalated: 'Done'
			},
			priority: {
				low: 'Low',
				medium: 'Medium',
				high: 'High'
			},
			assign: {
				successMessage: 'The task was assigned successful'
			},
			completion: {
				successHeading: 'Task completed',
				closeButtonLabel: 'Close'
			},
			component: {
				taskTradeAppointmentCraft: {
					hint: {
						invalid: 'One or more of the trade and effort settings are incomplete or invalid and will not be submitted.'
					},
					estimatedEffort: 'Estimated effort',
					selectedEffort: 'Selected',
					installationPeriod: {
						preparation: 'as installation preparation',
						beginning: 'at the begin of the installation',
						permanent: 'during the installation',
						end: 'at the end of the installation',
						followUp: 'after the installation'
					},
					unit: {
						hours: 'hours'
					},
					button: {
						add: 'Add more days'
					}
				},
				taskTradeAppointmentCraftItem: {
					input: {
						installer: 'Installer',
						date: 'Date',
						start: 'Start',
						end: 'End'
					}
				},
				taskTradeAppointmentEffort: {
					heading: 'Expected installation trades and effort',
					hint: {
						invalid: 'One or more of the trade and effort settings are incomplete or invalid and will not be submitted.',
						notApprovable: 'No trade capable of acceptance has been determined. At least one trade must be ready for acceptance to complete the installation.'
					},
					button: {
						add: 'Add trade'
					}
				},
				taskTradeAppointmentEffortItem: {
					input: {
						trade: 'Trade',
						period: 'Period',
						effort: 'Effort',
						canComplete: 'can complete'
					},
					installationPeriod: {
						preparation: 'Installation preparation',
						beginning: 'Beginning of installation',
						permanent: 'Permanent',
						end: 'End of installation',
						followUp: 'Installation follow up'
					}
				},
				taskChoice: {
					decision: {
						standard: 'Information sufficient - standard installation',
						delta: 'Information sufficient - delta installation',
						preCheckRequired: 'More information needed',
						unfeasible: 'Installation not possible',
						project: 'Installation as a separated project possible'
					},
					unfeasibleInstallationReasons: {
						label: 'Select a reason',
						noPotentialRailAvailable: 'No potential rail available',
						overvoltageProtectionUnfeasible: 'Overvoltage protection cannot be installed',
						spaceForCableOutletMissing: 'No space for additional cable outlet',
						houseConnectionUndersized: 'House connection undersized',
						noThreePhaseAvailable: 'No three-phase current in the house (1 phase)',
						outdatedMeterCabinet: 'Outdated meter cabinet',
						notTabCompliant: 'Does not comply with TAB',
						other: 'Other reason'
					},
					projectInstallationReasons: {
						label: 'Select a reason',
						condominiumOwnersAssociation: 'Condominium (Permission is required)',
						highPowerEvu: 'Request 22 KW, EVU challenges',
						undergroundGarageInstallation: 'Installation in underground garage',
						fireProtectionIssues: 'Fire protection challenges',
						multipleWallboxManagement: 'Load management required due to multiple wallboxes',
						other: 'Other reason',
						hint: {
							heading: 'If the installation is possible in general, but not doable in a direct customer relation, you can choose to create a B2B project from the order, that needs to be handled separately.'
						},
						information: {
							heading: 'Propose installation project',
							message: 'The customer and ABB will be informed about your decision. All relevant information including more information from the customer is forwarded to you via mail. You then can get in touch with the customer to set up a separated installation project.'
						}
					}
				},
				taskOfferCollection: {
					fullPrice: 'Total price (net)'
				}
			},
			arrangeInstallationAppointment: {
				heading: 'Appointment',
				replacementInfo: 'The installation to be scheduled is a replacement installation.',
				rescheduleInfo: 'The appointment to be arranged is a rescheduling.',
				contact: {
					heading: 'Client contact'
				},
				notes: {
					heading: 'Notes',
					placeholder: 'Add notes'
				},
				button: {
					close: 'Close',
					submit: 'Done'
				}
			},
			basicAnswers: {
				customer: {
					heading: 'Installation address'
				},
				questionnaire: {
					heading: 'Customer answers'
				},
				decision: {
					heading: 'Decision'
				},
				notes: {
					heading: 'Notes',
					placeholder: 'Add notes'
				},
				button: {
					close: 'Close',
					submit: 'Done'
				},
				continueToQuotation: {
					buttonLabel: 'Create an offer'
				}
			},
			gridOperatorDecision: {
				gridOperator: {
					heading: 'Grid operator'
				},
				wallboxPower: {
					heading: 'Wallbox information',
					hint: 'The customer has ordered a wallbox with {{wallboxPower}} kW power consumption.'
				},
				documents: {
					heading: 'Documents'
				},
				decision: {
					heading: 'Decision',
					accepted: 'The grid operator agreed with the installation',
					declined: 'The grid operator declined the installation'
				},
				notes: {
					heading: 'Notes',
					placeholder: 'Add notes'
				},
				button: {
					close: 'Close',
					submit: 'Submit'
				}
			},
			gridOperatorRegistration: {
				gridOperator: {
					heading: 'Grid operator'
				},
				wallboxPower: {
					heading: 'Wallbox information',
					hint: 'The customer has ordered a wallbox with {{wallboxPower}} kW power consumption.'
				},
				documents: {
					heading: 'Documents'
				},
				registrationRequired: {
					heading: 'Registration required',
					required: 'Yes, a registration is required',
					unnecessary: 'No, a registration is unnecessary'
				},
				notes: {
					heading: 'Notes',
					placeholder: 'Add notes'
				},
				button: {
					close: 'Close',
					submit: 'Submit'
				},
				continueToFeedback: {
					buttonLabel: 'Enter grid operator feedback'
				}
			},
			gridOperatorRevokeRegistration: {
				gridOperator: {
					heading: 'Grid operator'
				},
				notes: {
					heading: 'Notes',
					placeholder: 'Add notes'
				},
				button: {
					close: 'Close',
					submit: 'Submit'
				}
			},
			preCheck: {
				contact: {
					heading: 'Client contact',
					description: 'What time of the day is the customer at home?'
				},
				questionnaire: {
					heading: 'Basic questionnaire customer answers'
				},
				decision: {
					heading: 'Decision'
				},
				notes: {
					heading: 'Notes',
					placeholder: 'Add notes'
				},
				button: {
					close: 'Close',
					submit: 'Done'
				}
			},
			quotation: {
				customer: {
					heading: 'Installation address'
				},
				questionnaire: {
					heading: 'Basic questionnaire customer answers'
				},
				preCheck: {
					heading: 'Precheck results: Installation appointments and effort'
				},
				comments: {
					heading: 'Additional comments'
				},
				additionalEffort: {
					heading: 'Additional effort',
					text: 'In order to carry out the installation, additional work must be done in addition to the standard installation. A corresponding offer will be created based on the following Delta installation packages.'
				},
				conditions: {
					heading: 'Offer conditions towards the customer',
					placeholder: 'Under what conditions is the offer valid?'
				},
				notes: {
					heading: 'Notes',
					placeholder: 'Add notes'
				},
				button: {
					close: 'Close',
					submit: 'Submit'
				},
				deltaPackages: {
					fullPrice: 'Total price (net)'
				}
			},
			installationComplete: {
				contact: {
					heading: 'Client'
				},
				documents: {
					heading: 'Documents'
				},
				trades: {
					heading: 'Trades and appointments'
				},
				customerAcceptance: {
					heading: 'Customer acceptance',
					onSite: 'The customer has approved the installation on-site',
					remote: 'The customer was not available'
				},
				wallbox: {
					heading: 'Ordered Wallbox',
					serial: 'I hereby confirm the proper and professional installation and commissioning of the delivered wallbox with the following serial number: {{serialNumber}}.',
					throttle: 'The power consumption of the wallbox was reduced according to the grid operators requirements.'
				},
				notes: {
					heading: 'Notes',
					placeholder: 'Add notes'
				},
				button: {
					close: 'Close',
					submit: 'Submit'
				}
			}
		},
		taskCollection: {
			heading: 'Tasks',
			tableHead: {
				order: 'Task',
				status: 'Status',
				due: 'Due Date',
				priority: 'Priority',
				responsible: 'Responsible'
			},
			action: {
				refresh: 'Refresh',
				showMore: 'Show more'
			},
			assign: {
				successMessage: 'The task was assigned successful',
				errorMessage: 'While assigning the task an error occurred.'
			},
			emptyState: {
				heading: 'No tasks found',
				message: 'Adjust your filter settings to find some tasks.'
			},
			orderEmptyState: {
				heading: 'No tasks found',
				message: 'Nothing needs to be done for this order.'
			}
		},
		error: {
			generic: {
				heading: 'Something went wrong',
				message: 'An unforeseen error occurred that was not resolvable',
				resolveAction: {
					label: 'Reload'
				}
			},
			offline: {
				heading: 'Network connection unavailable',
				message: 'Please connect to a network to use the application.'
			},
			notFound: {
				heading: 'Not found',
				message: 'The requested ressource was not found',
				resolveAction: {
					label: 'Dashboard'
				}
			},
			orderNotFound: 'Order not found.',
			taskNotFound: 'Task not found.',
			taskTypeNotFound: 'Task type not found: {{type}}',
			taskCompleteMessage: 'While completing the task an error occurred.',
			taskAssignMessage: 'While assigning the task an error occurred.'
		}
	}
};

export default literals;
