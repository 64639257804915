import React, { ChangeEvent, useState } from 'react';

import { SortDirection } from '@abb-emobility/shared/api-integration-foundation';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { buildCssClassStringFromClassMap } from '@abb-emobility/shared/util';

import { Icon } from '../icon/Icon';
import { IconIdentifier, IconSize } from '../icon/Icon.enums';

import './CollectionSortDirection.scss';

export type CollectionSortDirectionProps = {
	direction: SortDirection,
	onChange: (direction: SortDirection) => void
};

export function CollectionSortDirection(props: CollectionSortDirectionProps) {

	const { direction, onChange } = props;

	const l10n = useL10n();
	const [selectedDirection, setSelectedDirection] = useState<SortDirection>(direction);

	const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
		const direction = event.currentTarget.value as SortDirection;
		setSelectedDirection(direction);
		onChange(direction);
	};

	const ascendingClass = buildCssClassStringFromClassMap({
		'collection-sort-direction__item': true,
		'collection-sort-direction__item--inactive': selectedDirection !== SortDirection.ASCENDING
	});

	const decendingClass = buildCssClassStringFromClassMap({
		'collection-sort-direction__item': true,
		'collection-sort-direction__item--inactive': selectedDirection !== SortDirection.DESCENDING
	});

	return (
		<section className="collection-sort-direction">
			<label className={ascendingClass}>
				<input
					checked={selectedDirection === SortDirection.ASCENDING}
					type="radio"
					className="collection-sort-direction__item__input"
					value={SortDirection.ASCENDING}
					name="filter-direction"
					onChange={handleChange}
				/>
				<span className="collection-sort-direction__item__label">
					<Icon size={IconSize.SMALL} name={IconIdentifier.SORT_ASCENDING} />
					{l10n.translate('sharedUiPrimitive.collection.sort.direction.ascending')}
				</span>
			</label>

			<label className={decendingClass}>
				<input
					checked={selectedDirection === SortDirection.DESCENDING}
					type="radio"
					className="collection-sort-direction__item__input"
					value={SortDirection.DESCENDING}
					name="filter-direction"
					onChange={handleChange}
				/>
				<span className="collection-sort-direction__item__label">
					<Icon size={IconSize.SMALL} name={IconIdentifier.SORT_DESCENDING} />
					{l10n.translate('sharedUiPrimitive.collection.sort.direction.descending')}
				</span>
			</label>
		</section>
	);
}
