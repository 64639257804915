import React, { MouseEvent } from 'react';

import { Icon } from '../../icon/Icon';
import { IconIdentifier } from '../../icon/Icon.enums';

import './ActionbarItem.scss';

export type ActionbarItemProps = {
	label: string,
	icon: IconIdentifier,
	href?: string,
	onTrigger?: () => void
};

export function ActionbarItem(props: ActionbarItemProps) {
	const { label, href = '#', icon, onTrigger } = props;

	const handleClick = (event: MouseEvent) => {
		if (onTrigger !== undefined) {
			event.preventDefault();
			onTrigger();
		}
	};

	return (
		<a
			href={href}
			target="_blank" rel="noopener noreferrer"
			className="actionbar-item"
			tabIndex={0}
			onClick={handleClick}
		>
			<Icon name={icon} />
			<div className="actionbar-item__label">
				{label}
			</div>
		</a>
	);

}
