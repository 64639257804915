import React from 'react';

import { InstallationPartnerActionType } from '@abb-emobility/oms/domain-model';
import { HypermediaLink } from '@abb-emobility/shared/api-integration-foundation';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { ActionbarItem, IconIdentifier } from '@abb-emobility/shared/ui-primitive';

export type ActionItemFactoryProps = {
	hypermediaLink: HypermediaLink
	onClick: () => void
};

export function ActionItemFactory(props: ActionItemFactoryProps): React.JSX.Element {

	const { hypermediaLink, onClick } = props;
	const l10n = useL10n();

	const renderActionItem = () => {

		switch (hypermediaLink.rel) {
			case InstallationPartnerActionType.CANCEL_ORDER_APPOINTMENTS:
				return (
					<ActionbarItem
						label={l10n.translate('omsInstallationPartnerOfficeApp.actionItemFactory.cancelOrderAppointmentsLabel')}
						icon={IconIdentifier.CALENDAR_X}
						onTrigger={onClick}
					/>
				);
			default:
				console.warn('HypermediaActionItemFactory: case not defined: ' + hypermediaLink.rel);
				return null;
		}
	};

	return (
		<>
			{renderActionItem()}
		</>
	);
}
