import React, { useEffect, useState } from 'react';

import { TaskActionStatus } from '@abb-emobility/shared/data-provider-foundation';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	ButtonGhost,
	ButtonGroup,
	ButtonPrimary,
	CollectionItemContentSection,
	GridOperatorInformation,
	InputTextArea,
	SectionHeader,
	SubmissionStatus,
	useModalDialogue,
	useModalDialogueManager
} from '@abb-emobility/shared/ui-primitive';
import { Nullable } from '@abb-emobility/shared/util';
import { useAnyTaskEntityData } from '@abb-emobility/usertask/data-provider';
import { PreCheckRevokeGridOperatorRegistrationTaskModel } from '@abb-emobility/usertask/domain-model';

export type TaskRevcationGridOperatorRegistrationProps = {
	task: PreCheckRevokeGridOperatorRegistrationTaskModel
};

export function TaskRevcationGridOperatorRegistration(props: TaskRevcationGridOperatorRegistrationProps) {

	const { task } = props;

	const l10n = useL10n();
	const modalDialogue = useModalDialogue();
	const modalDialogueManager = useModalDialogueManager();
	const anyTaskData = useAnyTaskEntityData();

	const [comment, setComment] = useState<Nullable<string>>(null);
	const [taskCompletionButtonState, setTaskCompletionButtonState] = useState<SubmissionStatus>(SubmissionStatus.IDLE);
	const completionActionStatus = anyTaskData.queryActionStatus();

	anyTaskData.useActionStatusEffect([TaskActionStatus.COMPLETE_PENDING], () => {
		setTaskCompletionButtonState(SubmissionStatus.PENDING);
	}, false);

	anyTaskData.useActionStatusEffect([TaskActionStatus.COMPLETE_SUCCESS], () => {
		setTaskCompletionButtonState(SubmissionStatus.DONE);
	}, true);

	anyTaskData.useActionStatusEffect([TaskActionStatus.COMPLETE_FAILED], () => {
		setTaskCompletionButtonState(SubmissionStatus.IDLE);
	}, true);

	useEffect(() => {
		modalDialogue.setFooter(
			<ButtonGroup>
				<ButtonGhost
					label={l10n.translate('omsInstallationPartnerOfficeApp.task.gridOperatorRevokeRegistration.button.close')}
					onClick={handleCancel}
				/>
				<ButtonPrimary
					label={l10n.translate('omsInstallationPartnerOfficeApp.task.gridOperatorRevokeRegistration.button.submit')}
					submissionStatus={taskCompletionButtonState}
					onClick={handleTaskCompletion}
					disabled={completionActionStatus !== TaskActionStatus.IDLE}
				/>
			</ButtonGroup>
		);
		return () => {
			modalDialogue.unsetFooter();
		};
	}, [taskCompletionButtonState, completionActionStatus, comment]);

	const handleTaskCompletion = (): void => {
		const payload = { ...task.payload };
		payload.comment = comment ?? undefined;
		anyTaskData.complete(task, payload);
	};

	const handleCancel = (): void => {
		modalDialogueManager.pop();
	};

	const handleComment = (comment: string) => {
		const handledComment = comment.length === 0 ? null : comment;
		setComment(handledComment);
	};

	return (
		<>
			<CollectionItemContentSection>
				<SectionHeader heading={l10n.translate('omsInstallationPartnerOfficeApp.task.gridOperatorRevokeRegistration.gridOperator.heading')} />
				<GridOperatorInformation gridOperator={task.payload.gridOperator} />
			</CollectionItemContentSection>

			<CollectionItemContentSection>
				<SectionHeader heading={l10n.translate('omsInstallationPartnerOfficeApp.task.gridOperatorRevokeRegistration.notes.heading')} />
				<InputTextArea
					placeholder={l10n.translate('omsInstallationPartnerOfficeApp.task.gridOperatorRevokeRegistration.notes.placeholder')}
					rows={4}
					onChange={handleComment}
				/>
			</CollectionItemContentSection>

		</>
	);
}
