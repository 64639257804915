import { InstallationPartnerOrderModel } from '@abb-emobility/oms/domain-model';
import { DataInterceptEmptyProps } from '@abb-emobility/shared/data-provider-foundation';

import { useInstallationPartnerOderCollectionData } from './InstallationPartnerOrderCollectionDataConsumer';

export function InstallationPartnerOrderCollectionDataInterceptEmpty(props: DataInterceptEmptyProps<InstallationPartnerOrderModel>) {
	const { children, emptyStateComponent, filter } = props;

	const taskCollectionData = useInstallationPartnerOderCollectionData();
	const hasResults = taskCollectionData.hasResults(filter);

	if (!hasResults) {
		return (
			<>
				{emptyStateComponent()}
			</>
		);
	}

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{children}
		</>
	);
}
