import { ReactNode } from 'react';

import { ExpandableProvider } from '@abb-emobility/shared/interaction';

import './ActionbarFilter.scss';

export type FilterLayoutProps = {
	children: ReactNode
};

export function ActionbarFilter(props: FilterLayoutProps) {
	const { children } = props;

	return (
		<ExpandableProvider>
			<article className="actionbar-filter">
				{children}
			</article>
		</ExpandableProvider>
	);
}
