import React, { ReactNode, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { InstallerAppointment, TradeAppointment } from '@abb-emobility/shared/domain-model';
import { createDateFromTimestamp, Timestamp } from '@abb-emobility/shared/domain-model-foundation';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { ButtonTertiary, Hint, HintLevel, IconIdentifier } from '@abb-emobility/shared/ui-primitive';
import { l10nLiteralFromEnumValue, Nullable } from '@abb-emobility/shared/util';
import { RequestedTradeEffort } from '@abb-emobility/usertask/domain-model';

import { TaskTradeAppointmentCraftItem } from './TaskTradeAppointmentCraftItem';

import './TaskTradeAppointmentCraft.scss';

type TaskTradeAppointmentCraftProps = {
	earliestInstallationDate: Timestamp,
	availableTrade: RequestedTradeEffort,
	onChange: (tradeAppointment: TradeAppointment) => void
};

export function TaskTradeAppointmentCraft(props: TaskTradeAppointmentCraftProps) {

	const { availableTrade, earliestInstallationDate, onChange } = props;

	const l10n = useL10n();

	const [installerAppointments, setInstallerAppointments] = useState<Map<string, Nullable<InstallerAppointment>>>(new Map());
	const [effortSum, setEffortSum] = useState<number>(0);

	useEffect(() => {
		if (installerAppointments.size === 0) {
			addPeriod();
		}
	}, []);

	const addPeriod = (): void => {
		const map = new Map(installerAppointments);
		const mapId = uuid();
		map.set(mapId, null);
		setInstallerAppointments(map);
	};

	const updatePeriod = (mapId: string, installerAppointment: Nullable<InstallerAppointment>): void => {
		const map = new Map(installerAppointments);
		map.set(mapId, installerAppointment);
		setInstallerAppointments(map);
		const validInstallerAppointments = Array.from(map.values())
			.filter((installerAppointment): installerAppointment is InstallerAppointment => {
				return installerAppointment !== null;
			});
		calculateEffort(validInstallerAppointments);
		onChange({
			tradeId: availableTrade.trade.id,
			installerAppointments: validInstallerAppointments
		});
	};

	const removePeriod = (mapId: string): void => {
		const map = new Map(installerAppointments);
		map.delete(mapId);
		setInstallerAppointments(map);
		const validInstallerAppointments = Array.from(map.values())
			.filter((installerAppointment): installerAppointment is InstallerAppointment => {
				return installerAppointment !== null;
			});
		calculateEffort(validInstallerAppointments);
		onChange({
			tradeId: availableTrade.trade.id,
			installerAppointments: validInstallerAppointments
		});
	};

	const calculateEffort = (installerAppointments: Array<InstallerAppointment>): void => {
		let effortSum = 0;
		installerAppointments.forEach((installerAppointment) => {
			effortSum += (createDateFromTimestamp(installerAppointment.period.end).getTime() - createDateFromTimestamp(installerAppointment.period.start).getTime());
		});
		setEffortSum(effortSum);
	};

	// RENDER
	const displayEffortSum = (): string => {
		const hours = Math.floor(effortSum / (1000 * 60 * 60));
		const minutes = Math.floor((effortSum % (1000 * 60 * 60)) / (1000 * 60));
		return hours.toString() + ':' + minutes.toString().padStart(2, '0');
	};

	const renderCraftHeader = (): ReactNode => {
		const hoursDisplayUnit = l10n.translate('omsInstallationPartnerOfficeApp.task.component.taskTradeAppointmentCraft.unit.hours');
		const installationPeriodDisplay = l10n.translate(l10nLiteralFromEnumValue(
			availableTrade.installationPeriod,
			'omsInstallationPartnerOfficeApp.task.component.taskTradeAppointmentCraft.installationPeriod'
		));
		return (
			<div className="task-trade-appointment-craft__header__effort">
				<span className="task-trade-appointment-craft__header__effort__estimation">
					<span className="task-trade-appointment-craft__header__effort__estimation__label">
						{l10n.translate('omsInstallationPartnerOfficeApp.task.component.taskTradeAppointmentCraft.estimatedEffort')}
					</span>
					<span className="task-trade-appointment-craft__header__effort__estimation__value">
						{installationPeriodDisplay}: {availableTrade.expectedEffort}:00&nbsp;{hoursDisplayUnit}
					</span>
				</span>
				<span className="task-trade-appointment-craft__header__effort__selection">
					<span className="task-trade-appointment-craft__header__effort__selection__label">
						{l10n.translate('omsInstallationPartnerOfficeApp.task.component.taskTradeAppointmentCraft.selectedEffort')}
					</span>
					<span className="task-trade-appointment-craft__header__effort__selection__value">
						{displayEffortSum()}&nbsp;{hoursDisplayUnit}
					</span>
				</span>
			</div>
		);
	};

	const renderNotifications = (): ReactNode => {
		let notification = null;
		installerAppointments.forEach((value) => {
			if (value === null) {
				notification = (
					<Hint
						heading={l10n.translate('omsInstallationPartnerOfficeApp.task.component.taskTradeAppointmentCraft.hint.invalid')}
						level={HintLevel.WARNING}
					/>
				);
				return;
			}
		});
		return notification;
	};

	const renderInputRows = (): ReactNode => {
		const elements: Array<ReactNode> = [];
		let intitalSet = true;

		installerAppointments.forEach((value, key) => {
			const removeCallback = () => removePeriod(key);
			elements.push(
				<TaskTradeAppointmentCraftItem
					key={key}
					period={value?.period ?? null}
					earliestInstallationDate={earliestInstallationDate}
					installationPeriod={availableTrade.installationPeriod}
					availableInstallers={availableTrade.availableInstallers}
					isFirstRow={intitalSet}
					onRemove={installerAppointments.size > 1 ? removeCallback : undefined}
					onChange={(installerAppointment) => {
						updatePeriod(key, installerAppointment);
					}}
				/>
			);
			intitalSet = false;
		});
		return elements;
	};

	return (
		<section className="task-trade-appointment-craft">
			<header className="task-trade-appointment-craft__header">
				<h1 className="task-trade-appointment-craft__header__heading">
					{availableTrade.trade.name}
				</h1>
				{renderCraftHeader()}
			</header>
			<main className="task-trade-appointment-craft__main">
				<section className="task-trade-appointment-craft__main__items">

					<main className="task-trade-appointment-craft__main__items__main">
						{renderNotifications()}
						{renderInputRows()}
					</main>

					<footer className="task-trade-appointment-craft__main__items__footer">
						<ButtonTertiary
							onClick={addPeriod}
							label={l10n.translate('omsInstallationPartnerOfficeApp.task.component.taskTradeAppointmentCraft.button.add')}
							icon={IconIdentifier.PLUS_CIRCLE}
						/>
					</footer>

				</section>
			</main>
		</section>
	);
}
