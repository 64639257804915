import { buildCssClassStringFromClassMap } from '@abb-emobility/shared/util';

import './CardSectionItem.scss';

export type CardSectionItemProps = {
	label: string,
	value: string
	noLabelWrap?: boolean
};

export function CardSectionItem(props: CardSectionItemProps) {
	const { label, value, noLabelWrap = false } = props;

	const classMap = {
		'card-section-item__label': true,
		'card-section-item__label--no-wrap': noLabelWrap
	};

	return (
		<div className="card-section-item">
			<div className={buildCssClassStringFromClassMap(classMap)}>
				{label}
			</div>
			<div className="card-section-item__value">
				{value}
			</div>
		</div>
	);
}
