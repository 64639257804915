import { ReactNode } from 'react';

import './TopbarBreadcrumb.scss';

export type TopbarBreadcrumbProps = {
	children: ReactNode
};

export function TopbarBreadcrumb(props: TopbarBreadcrumbProps) {

	const { children } = props;

	return (
		<nav className="topbar-breadcrumb">
			{children}
		</nav>
	);
}
