import { useParams } from 'react-router-dom';

import { InstallationPartnerOrderEntityDataProvider } from '@abb-emobility/oms/data-provider';
import { InstallationPartnerOrderModel } from '@abb-emobility/oms/domain-model';
import { createFullNameFromCustomer } from '@abb-emobility/shared/domain-model';
import { NotFoundError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { usePageTitleModifier } from '@abb-emobility/shared/react';
import { UiErrorHandler } from '@abb-emobility/shared/ui-error-handler';
import { useBreadcrumbs, SpinnerCircle } from '@abb-emobility/shared/ui-primitive';

import { OrderDetail } from '../../components/order-detail/OrderDetail';
import { usePageTitleBuilder } from '../../components/page-title/PageTitle.hook';
import { RoutePath, useRouteUrl } from '../../router/Routes';

export function OrderView() {

	const params = useParams();
	const orderId = params?.orderId ?? null;

	const l10n = useL10n();
	const pageTitleModifier = usePageTitleModifier();
	const pageTitleBuilder = usePageTitleBuilder();
	const breadcrumbs = useBreadcrumbs();
	const { createRouteUrl } = useRouteUrl();

	if (orderId === null) {
		throw new NotFoundError(l10n.translate('omsInstallationPartnerOfficeApp.error.taskNotFound'));
	}

	const handleOrderQuery = (order: InstallationPartnerOrderModel): void => {
		const pageTitleReplacements = new Map();
		pageTitleReplacements.set('displayName', createFullNameFromCustomer(order.customer));
		pageTitleModifier(pageTitleBuilder('omsInstallationPartnerOfficeApp.pageTitle.order', pageTitleReplacements));
		breadcrumbs.setBreadcrumbEntries([
			{
				label: l10n.translate('omsInstallationPartnerOfficeApp.breadcrumbs.orders'),
				link: createRouteUrl(RoutePath.ORDERS)
			},
			{
				label: createFullNameFromCustomer(order.customer),
				link: createRouteUrl(RoutePath.ORDER, ['orderId', orderId])
			}
		]);
	};

	return (
		<UiErrorHandler>
			<InstallationPartnerOrderEntityDataProvider
				pendingComponent={SpinnerCircle}
				primaryKey={orderId}
			>
				<OrderDetail onQuery={handleOrderQuery} />
			</InstallationPartnerOrderEntityDataProvider>
		</UiErrorHandler>
	);
}
