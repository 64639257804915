import { ReactNode } from 'react';

import './TopbarSection.scss';

export type TopbarSectionProps = {
	children: ReactNode
};

export function TopbarSection(props: TopbarSectionProps) {

	const { children } = props;

	return (
		<div className="topbar-section">
			{children}
		</div>
	);
}
