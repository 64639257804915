import React from 'react';

import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { Card } from '@abb-emobility/shared/ui-primitive';
import { TaskCollectionDataProviderProvider, useOrderTaskCollectionData } from '@abb-emobility/usertask/data-provider';

import { OrderTaskCollection } from './OrderTaskCollection';

export type OrderTaskCollectionDataAssemblerProps = {
	orderId: ModelPrimaryKey
};

export function OrderTaskCollectionDataAssembler(props: OrderTaskCollectionDataAssemblerProps) {

	const { orderId } = props;

	const orderTaskCollectionDataProvider = useOrderTaskCollectionData();

	return (
		<Card>
			<TaskCollectionDataProviderProvider dataProvider={orderTaskCollectionDataProvider}>
				<OrderTaskCollection orderId={orderId} />
			</TaskCollectionDataProviderProvider>
		</Card>
	);
}
