import React from 'react';

import { useExpandable } from '@abb-emobility/shared/interaction';

import { Icon } from '../../../icon/Icon';
import { IconIdentifier } from '../../../icon/Icon.enums';

import './ActionbarFilterHeader.scss';

export type ActionbarFilterHeaderProps = {
	label: string,
	value: string,
	icon?: IconIdentifier,
	onOpen?: () => void
};

export function ActionbarFilterHeader(props: ActionbarFilterHeaderProps) {
	const { label, value, icon = IconIdentifier.FUNNEL, onOpen } = props;

	const expandable = useExpandable();
	const expanded = expandable.isExpanded();

	const handleOpen = () => {
		if (expanded) {
			return;
		}
		expandable.expand();
		if (onOpen !== undefined) {
			onOpen();
		}
	};

	return (
		<div className="actionbar-filter-header" tabIndex={0} onClick={handleOpen}>
			<Icon name={icon} />

			<div className="actionbar-filter-header__text">
				<div className="actionbar-filter-header__text__label">{label}</div>
				<div className="actionbar-filter-header__text__value">{value}</div>
			</div>
		</div>
	);
}
