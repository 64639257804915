import React from 'react';

import { useL10n } from '@abb-emobility/shared/localization-provider';

import { LabelPosition } from '../../types/LabelPosition';
import { ButtonSelect } from '../button-select/ButtonSelect';
import { InputSelect, InputSelectOption } from '../input-select/InputSelect';

import './Choose.scss';

export type ChooseProps = {
	label: string,
	options?: Array<InputSelectOption>,
	onSelect?: (selected: boolean) => void,
	isSelected?: boolean
};

export const Choose = (props: ChooseProps) => {
	const { label, options, onSelect = null, isSelected = false } = props;

	const l10n = useL10n();

	const handleSelect = (checked: boolean): void => {
		if (onSelect !== null) {
			onSelect(checked);
		}
	};

	const renderChoose = () => {
		if (options === undefined) {
			return (
				<div className="choose">
					<span className="choose__label">{label}</span>
					<ButtonSelect
						checkedState={isSelected}
						label={l10n.translate('sharedUiPrimitive.choose.label')}
						onSelect={handleSelect}
					/>
				</div>
			);
		}
		return (
			<div className="choose--options">
				<InputSelect
					label={label}
					labelPosition={LabelPosition.LEADING}
					options={options}
				/>
				<ButtonSelect
					checkedState={isSelected}
					label={l10n.translate('sharedUiPrimitive.choose.label')}
					onSelect={handleSelect}
				/>
			</div>
		);
	};

	return (
		renderChoose()
	);
};
