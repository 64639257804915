import { FilterCriteria, FilterCriteriaEntry } from '@abb-emobility/shared/api-integration-foundation';
import { Nullable } from '@abb-emobility/shared/util';

import { FilterOptionGroup } from './CollectionGroupedFilter.types';

export const filterOptionsFromFilterCriteria = <FilterOption extends string, Model>(
	filterOptionGroups: Array<FilterOptionGroup<FilterOption, Model>>,
	filterCriteria: FilterCriteria<Model>
): Array<FilterOption> => {
	let filterOptions: Array<FilterOption> = [];
	for (const filterOptionGroup of filterOptionGroups) {
		filterOptions = [...filterOptions, ...filterOptionGroup.buildFilterOptions(filterCriteria)];
	}
	return filterOptions;
};

export const filterCriteriaFromFilterOptions = <FilterOption extends string, Model>(
	filterOptionGroups: Array<FilterOptionGroup<FilterOption, Model>>,
	filterOptions: Array<FilterOption>
): FilterCriteria<Model> => {
	return filterOptionGroups
		.map((filterGroup): Nullable<FilterCriteriaEntry<Model>> => {
			return filterGroup.buildFilterCriteria(filterOptions);
		})
		.filter((filterCriteriaEntry): filterCriteriaEntry is FilterCriteriaEntry<Model> => {
			return filterCriteriaEntry !== null;
		});
};
