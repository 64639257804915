import { SortCriteria } from '@abb-emobility/shared/api-integration-foundation';
import { useIdentity } from '@abb-emobility/shared/identity-provider';
import { readLocalStorage, writeLocalStorage } from '@abb-emobility/shared/local-storage';
import { Maybe } from '@abb-emobility/shared/util';

export const useSortCriteriaStorage = <T>(storageKey: string) => {

	const identity = useIdentity();

	const readSortCriteria = (): Maybe<SortCriteria<T>> => {
		return readLocalStorage<SortCriteria<T>>(
			'sort-criteria_' + storageKey,
			{ namespace: identity.getIdentity().get()?.userId ?? undefined }
		).getOrUndefined();
	};

	const writeSortCriteria = (sortCriteria: SortCriteria<T>): void => {
		return writeLocalStorage<SortCriteria<T>>(
			'sort-criteria_' + storageKey,
			sortCriteria,
			{ namespace: identity.getIdentity().get()?.userId ?? undefined }
		);
	};

	return { readSortCriteria, writeSortCriteria };
};
