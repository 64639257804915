import React, { CSSProperties, ReactNode, useEffect, useState } from 'react';

import { CandidateGroup } from '@abb-emobility/shared/candidate-groups-provider';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { useExpandable } from '@abb-emobility/shared/interaction';
import {
	ActionbarFilterContent,
	ActionbarFilterContentItems,
	CollectionItemHeaderItem, InputCheckbox,
	InputRadioGroup,
	Pill
} from '@abb-emobility/shared/ui-primitive';
import { colorFromString } from '@abb-emobility/shared/util';

import './CandidateGroupSelect.scss';

type CandidateGroupsProps = {
	selectedCandidateGroupIds: Array<ModelPrimaryKey>,
	availableCandidateGroups: Array<CandidateGroup>,
	disabled?: boolean,
	onSelect: (candidateGroupIds: Array<ModelPrimaryKey>) => void
};

export function CandidateGroupSelect(props: CandidateGroupsProps) {

	const { availableCandidateGroups, selectedCandidateGroupIds, disabled = false, onSelect } = props;

	const expandable = useExpandable();
	const expanded = expandable.isExpanded();

	const [selectedGroupIds, setSelectedGroupIds] = useState<Array<ModelPrimaryKey>>([]);

	useEffect(() => {
		setSelectedGroupIds(selectedCandidateGroupIds);
	}, [selectedCandidateGroupIds]);

	const handleOpen = () => {
		if (disabled || expanded) {
			return;
		}
		expandable.expand();
	};

	const handleApply = () => {
		const selectedCandidateGroupIdsCheck = [...selectedCandidateGroupIds].sort().join(':');
		const selectedGroupIdsCheck = [...selectedGroupIds].sort().join(':');
		if (selectedCandidateGroupIdsCheck === selectedGroupIdsCheck) {
			return;
		}
		onSelect(selectedGroupIds);
	};

	const handleCandidateGroupChange = (candidateGroup: CandidateGroup, checked: boolean) => {
		let result: Array<ModelPrimaryKey>;
		if (!checked) {
			result = selectedGroupIds.filter((candidateGroupId) => {
				return candidateGroupId !== candidateGroup.id;
			});
		} else {
			result = [...selectedGroupIds];
			result.push(candidateGroup.id);
		}
		setSelectedGroupIds(result);
	};

	const renderCandidateGroupDisplayName = (): string => {
		const candidateGroup = availableCandidateGroups.find((candidateGroup): boolean => {
			return selectedGroupIds.includes(candidateGroup.id);
		});
		if (candidateGroup === undefined) {
			return '?';
		}
		return candidateGroup.name
			.split(/[, -]/)
			.map((part) => {
				return part.substring(0, 1).toUpperCase();
			})
			.slice(0, 2)
			.join('');
	};

	const renderCandidateGroupDisplayColor = (): CSSProperties => {
		const candidateGroup = availableCandidateGroups.find((candidateGroup): boolean => {
			return selectedGroupIds.includes(candidateGroup.id);
		});
		if (candidateGroup === undefined) {
			return {};
		}
		return { backgroundColor: colorFromString(candidateGroup.name, 50, 50) };
	};

	const renderPill = (): ReactNode => {
		if (selectedGroupIds.length > 1) {
			return (
				<span className="candidate-groups-tag-pill">
				<Pill value={`+${selectedGroupIds.length - 1}`} />
			</span>
			);
		}
		return null;
	};

	const renderCandidateGroupOptions = () => {
		return availableCandidateGroups.map((candidateGroup) => {

			let isChecked;
			selectedGroupIds.forEach((selectedGroupId) => {
				if (selectedGroupId === candidateGroup.id) {
					isChecked = true;
				}
			});

			return (
				<InputCheckbox
					defaultChecked={isChecked}
					label={candidateGroup.name}
					value={candidateGroup.id}
					onChange={(checked) => handleCandidateGroupChange(candidateGroup, checked)}
					key={candidateGroup.id}
					disabled={isChecked && selectedGroupIds.length === 1}
				/>
			);
		});
	};

	return (
		<CollectionItemHeaderItem allowOverflow={true}>
			<div className="candidate-groups">
				<section className="candidate-groups-header">
					<div className="candidate-groups-tag" style={renderCandidateGroupDisplayColor()} onClick={handleOpen}>
						<span className={`candidate-groups-tag__label`}>
							{renderCandidateGroupDisplayName()}
						</span>
					</div>
					{renderPill()}
				</section>

				<ActionbarFilterContent onClose={handleApply}>
					<ActionbarFilterContentItems>
						<InputRadioGroup>
							{renderCandidateGroupOptions()}
						</InputRadioGroup>
					</ActionbarFilterContentItems>
				</ActionbarFilterContent>
			</div>
		</CollectionItemHeaderItem>
	);

}
