import './InformationHeadLayout.scss';

import { ReactNode } from 'react';

export type InformationHeadLayoutProps = {
	children: ReactNode
};

export function InformationHeadLayout(props:InformationHeadLayoutProps) {
	const {children} = props;

	return (
		<article className='information-head-layout'>
			{children}
		</article>
	);
}
