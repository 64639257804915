import React, { ReactNode } from 'react';

import {
	OrderInstallerAppointmentCollectionDataAppear,
	OrderInstallerAppointmentCollectionDataInterceptEmpty,
	OrderInstallerAppointmentCollectionDataSuspense,
	useInstallationPartnerOrderEntityData,
	useOrderInstallerAppointmentCollectionData
} from '@abb-emobility/oms/data-provider';
import { InstallationPartnerActionType } from '@abb-emobility/oms/domain-model';
import { HypermediaLinkFilter, HypermediaLinkSort } from '@abb-emobility/shared/data-provider-foundation';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	Actionbar,
	Card,
	Cardlayout,
	CardSection,
	Direction,
	NotificationBig,
	NotificationBigStatus,
	Separator,
	SpinnerCircle
} from '@abb-emobility/shared/ui-primitive';

import { groupInstallerAppointmentsByTrades } from './InstallerAppointmentCollection.util';
import { InstallerAppointmentCollectionItem } from './InstallerAppointmentCollectionItem';
import { ActionItemFactory } from '../action-item-factory/ActionItemFactory';
import { useActionModalFactory } from '../action-modal-factory/ActionModalFactory.hooks';
import { createHypermediaActionFromHypermediaLink } from '../action-modal-factory/ActionModalFactory.types';

export type InstallerAppointmentCollectionProps = {
	orderId: ModelPrimaryKey
};

export function InstallerAppointmentCollection(props: InstallerAppointmentCollectionProps) {

	const { orderId } = props;

	const l10n = useL10n();

	const orderInstallerAppointmentCollectionData = useOrderInstallerAppointmentCollectionData();
	const installerAppointments = orderInstallerAppointmentCollectionData.query();

	const orderEntityData = useInstallationPartnerOrderEntityData();
	const actionModalFactory = useActionModalFactory();

	const hypermediaLinkFilter: HypermediaLinkFilter = (value) => value.rel === InstallationPartnerActionType.CANCEL_ORDER_APPOINTMENTS;
	const hypermediaLinkSort: HypermediaLinkSort = (left, right) => left.rel.localeCompare(right.rel);
	const orderHypermediaLinks = orderEntityData.queryHypermediaLinks(hypermediaLinkSort, hypermediaLinkFilter);

	const renderActionItems = (): ReactNode => {
		return orderHypermediaLinks.map((hypermediaLink, idx) => {
			return (
				<ActionItemFactory
					key={idx}
					hypermediaLink={hypermediaLink}
					onClick={() => actionModalFactory.renderModal(createHypermediaActionFromHypermediaLink(hypermediaLink))}
				/>
			);
		});
	};

	const renderAppointments = () => {
		return groupInstallerAppointmentsByTrades(installerAppointments).map((tradeAppointment, index) => {
			return (
				<InstallerAppointmentCollectionItem key={index} tradeAppointment={tradeAppointment} />
			);
		});
	};

	const renderEmptyState = (): ReactNode => {
		return (
			<NotificationBig
				status={NotificationBigStatus.NO_APPOINTMENT}
				heading={l10n.translate('omsInstallationPartnerOfficeApp.installerAppointmentCollection.emptyState.heading')}
				message={l10n.translate('omsInstallationPartnerOfficeApp.installerAppointmentCollection.emptyState.message')}
			/>
		);
	};

	return (
		<Card>
			<Actionbar label={l10n.translate('omsInstallationPartnerOfficeApp.installerAppointmentCollection.heading')}>
				{renderActionItems()}
			</Actionbar>

			<Separator />

			<Cardlayout>
				<CardSection direction={Direction.COLUMN}>
					<OrderInstallerAppointmentCollectionDataAppear orderId={orderId}>
						<OrderInstallerAppointmentCollectionDataSuspense pendingComponent={SpinnerCircle}>
							<OrderInstallerAppointmentCollectionDataInterceptEmpty emptyStateComponent={renderEmptyState}>
								{renderAppointments()}
							</OrderInstallerAppointmentCollectionDataInterceptEmpty>
						</OrderInstallerAppointmentCollectionDataSuspense>
					</OrderInstallerAppointmentCollectionDataAppear>
				</CardSection>
			</Cardlayout>
		</Card>
	);
}
