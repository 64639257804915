import React, { useLayoutEffect, useRef } from 'react';

import { scrollElementIntoView } from '@abb-emobility/shared/browser';
import { buildCssClassStringFromClassMap, Nullable } from '@abb-emobility/shared/util';

import { TabbarPill } from '../tabbar-pill/TabbarPill';

import './TabbarItem.scss';

export type TabbarItemProps = {
	label: string,
	pill?: string,
	disabled?: boolean,
	active?: boolean,
	onClick?: () => void,
	currency?: string,
	tabId: string
};

export function TabbarItem(props: TabbarItemProps) {

	const { label, pill, disabled = false, onClick, currency, active } = props;

	const tabItemElement = useRef<Nullable<HTMLDivElement>>(null);

	useLayoutEffect(() => {
		if (active && tabItemElement.current !== null) {
			scrollElementIntoView(tabItemElement.current, { block: 'nearest' });
		}
	}, [active, pill, label]);

	const handleClick = (): void => {
		if (disabled) {
			return;
		}
		if (onClick !== undefined) {
			onClick();
		}
	};

	const tabbarItemContainerClassMap = {
		'tabbar-item__container': true,
		'tabbar-item__container--active': active,
		'tabbar-item__container--disabled': disabled
	};

	const renderPill = () => {
		if (pill) {
			return (
				<TabbarPill
					value={pill}
					disabled={disabled}
					currency={currency}
				/>
			);
		}

		return;
	};

	return (
		<div className={'tabbar-item'} onClick={handleClick} ref={tabItemElement}>
			<div className={buildCssClassStringFromClassMap(tabbarItemContainerClassMap)}>
				<span className="tabbar-item__container__label">
					{label}
				</span>
				{renderPill()}
			</div>
		</div>
	);
}
