import { DataSuspenseProps, FetchStatus } from '@abb-emobility/shared/data-provider-foundation';

import { useTaskCollectionData } from './TaskCollectionDataConsumer';

export function TaskCollectionDataSuspense(props: DataSuspenseProps) {
	const {
		children,
		pendingComponent = null
	} = props;

	const taskCollectionData = useTaskCollectionData();
	// eslint-disable-next-line @typescript-eslint/naming-convention
	const PendingComponent = pendingComponent;

	const fetchStatus = taskCollectionData.queryFetchStatus();

	switch (fetchStatus) {
		case FetchStatus.IDLE:
			return null;
		case FetchStatus.PENDING:
			if (PendingComponent !== null) {
				return (<PendingComponent />);
			}
			return (<code>Loading</code>);
		case FetchStatus.FAILED:
			return null;
	}

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{children}
		</>
	);
}
