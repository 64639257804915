import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	omsInstallationPartnerOfficeApp: {
		action: {
			cancelOrderAppointments: 'Cancelar marcações'
		},
		actionItemFactory: {
			cancelOrderAppointmentsLabel: 'Cancelar marcações'
		},
		actionModalFactory: {
			cancelOrderAppointmentsHeading: 'Cancelar marcações de encomendas'
		},
		actionModal: {
			cancelOrderAppointments: {
				content: {
					notificationHeading: 'Cancelar as marcações de instalação.',
					notificationMessage: 'Todas as marcações de instalação serão canceladas.',
					successHeading: 'Marcações canceladas',
					successCloseButtonLabel: 'Fechar',
					reason: 'Motivo'
				},
				cancelReasonValue: {
					deliveryDelay: 'Atraso na entrega',
					installationPartnerConflict: 'Conflito entre parceiros de instalação',
					customerConflict: 'Conflito com clientes',
					replacement: 'Substituição'
				},
				footer: {
					confirm: 'Confirmar o cancelamento',
					cancel: 'Cancelar'
				},
				error: {
					heading: 'Não foi possível cancelar as marcações.',
					message: 'Ocorreu um erro ao eliminar as marcações da encomenda.'
				}
			}
		},
		appName: 'Gabinete de instalação da Wallbox ABB',
		pageTitle: {
			dashboard: 'Painel de controlo',
			error: 'Erro',
			notFound: 'Não encontrado',
			orders: 'Encomendas',
			order: 'Encomenda por {{displayName}}'
		},
		navigation: {
			dashboard: 'Painel de controlo',
			orders: 'Encomendas',
			logout: 'Terminar sessão'
		},
		breadcrumbs: {
			tasks: 'Síntese da tarefa',
			orders: 'Síntese da encomenda'
		},
		viewHeader: {
			tasks: 'Síntese da tarefa',
			orders: 'Síntese de encomendas'
		},
		installerAppointmentCollection: {
			heading: 'Instalações planeadas',
			emptyState: {
				heading: 'Não estão previstas instalações',
				message: 'A encomenda não tem instalações planeadas.'
			}
		},
		orderCollection: {
			heading: 'Encomendas',
			tableHead: {
				order: 'Encomenda',
				status: 'Estado',
				estimated: 'Data prevista para a instalação',
				progress: 'Progresso'
			},
			emptyState: {
				heading: 'Não foram encontradas encomendas',
				message: 'Ajuste as definições de filtro para encontrar algumas encomendas.'
			},
			action: {
				refresh: 'Atualizar',
				showMore: 'Mostrar mais'
			},
			filter: {
				filter: 'Filtro',
				none: 'nenhuma',
				active: '{{contagem}} ativa',
				resetFilter: 'Reiniciar filtro',
				options: {
					dueDateOverdue: 'Atrasada',
					dueDateToday: 'Prazo para entrega hoje',
					priorityHigh: 'Prioridade elevada',
					priorityMedium: 'Prioridade média',
					priorityLow: 'Prioridade baixa',
					statusDone: 'Pronto',
					statusOpen: 'Aberto',
					installationTypeStandard: 'Instalação standard',
					installationTypeDelta: 'Instalação Delta',
					installationTypeUndetermined: 'Indeterminado'
				}
			}
		},
		orderDetail: {
			progress: {
				heading: 'Progresso'
			},
			customerInformation: {
				heading: 'Informação ao cliente',
				address: 'Endereço',
				phone: 'Telefone',
				mail: 'Correio'
			},
			installationAddress: {
				heading: 'Endereço de instalação',
				address: 'Endereço'
			},
			status: {
				onSchedule: 'Dentro do prazo',
				delayed: 'Atrasada',
				cancelPending: 'Cancelar pendente',
				cancelled: 'Cancelada',
				done: 'Pronto'
			},
			installationType: {
				none: 'Indeterminado',
				undetermined: 'Indeterminado',
				cancelled: 'Cancelado',
				standard: 'Padrão',
				delta: 'Delta'
			}
		},
		task: {
			progressStepShortName: {
				installationPartnerEvaluateBasicAnswers: 'Verificação básica',
				installationPartnerArrangeAppointment: 'Marcação',
				installationPartnerExecuteRemotePreCheck: 'Verificação final',
				installationPartnerRegisterAtGridOperator: 'Registo do operador de rede',
				installationPartnerProvideGridOperatorDecision: 'Decisão do operador de rede',
				installationPartnerRevokeGridOperatorRegistration: 'Revogação do operador de rede',
				installationPartnerProvideQuotation: 'Oferta',
				installationPartnerCompleteInstallation: 'Instalação'
			},
			displayName: {
				installationPartnerEvaluateBasicAnswers: 'Informação básica',
				installationPartnerArrangeAppointment: 'Efetuar marcação',
				installationPartnerExecuteRemotePreCheck: 'Pré-verificação',
				installationPartnerRegisterAtGridOperator: 'Registo do operador de rede',
				installationPartnerProvideGridOperatorDecision: 'Decisão do operador de rede',
				installationPartnerRevokeGridOperatorRegistration: 'Revogação do registo do operador de rede',
				installationPartnerProvideQuotation: 'Oferta',
				installationPartnerCompleteInstallation: 'Conclusão da instalação'
			},
			status: {
				started: 'Em breve',
				dueToday: 'Prazo para entrega hoje',
				overdue: 'Atrasada',
				escalated: 'Encaminhada',
				done: 'Pronto',
				doneOverdue: 'Pronto',
				doneEscalated: 'Pronto'
			},
			priority: {
				low: 'Baixo',
				medium: 'Médio',
				high: 'Alto'
			},
			assign: {
				successMessage: 'A tarefa foi atribuída com êxito'
			},
			completion: {
				successHeading: 'Tarefa concluída',
				closeButtonLabel: 'Fechar'
			},
			component: {
				taskTradeAppointmentCraft: {
					hint: {
						invalid: 'Uma ou mais definições de trabalho e esforço estão incompletas ou são inválidas e não serão apresentadas.'
					},
					estimatedEffort: 'Esforço estimado',
					selectedEffort: 'Selecionado',
					installationPeriod: {
						preparation: 'como preparação da instalação',
						beginning: 'no início da instalação',
						permanent: 'durante a instalação',
						end: 'no final da instalação',
						followUp: 'após a instalação'
					},
					unit: {
						hours: 'horas'
					},
					button: {
						add: 'Adicionar mais dias'
					}
				},
				taskTradeAppointmentCraftItem: {
					input: {
						installer: 'Instalador',
						date: 'Data',
						start: 'Iniciar',
						end: 'Fim'
					}
				},
				taskTradeAppointmentEffort: {
					heading: 'Trabalhos e esforços de instalação previstos',
					hint: {
						invalid: 'Uma ou mais definições de trabalho e esforço estão incompletas ou são inválidas e não serão apresentadas.',
						notApprovable: 'Não foi determinado nenhum trabalho suscetível de ser aceite. Para completar a instalação, pelo menos um dos trabalhos deve estar pronto para ser aceite.'
					},
					button: {
						add: 'Adicionar trabalho'
					}
				},
				taskTradeAppointmentEffortItem: {
					input: {
						trade: 'Trabalho',
						period: 'Período',
						effort: 'Esforço',
						canComplete: 'pode completar'
					},
					installationPeriod: {
						preparation: 'Preparação da instalação',
						beginning: 'Início da instalação',
						permanent: 'Permanente',
						end: 'Fim da instalação',
						followUp: 'Acompanhamento da instalação'
					}
				},
				taskChoice: {
					decision: {
						standard: 'Informação suficiente - instalação padrão',
						delta: 'Informação suficiente - instalação delta',
						preCheckRequired: 'Mais informações necessárias',
						unfeasible: 'A instalação não é possível',
						project: 'Possibilidade de instalação como um projeto separado'
					},
					unfeasibleInstallationReasons: {
						label: 'Selecionar um motivo',
						noPotentialRailAvailable: 'Não existe nenhum carril potencial disponível',
						overvoltageProtectionUnfeasible: 'A proteção contra sobretensão não pode ser instalada',
						spaceForCableOutletMissing: 'Não há espaço para uma tomada de cabo adicional',
						houseConnectionUndersized: 'Ligação da casa subdimensionada',
						noThreePhaseAvailable: 'Não há corrente trifásica na casa (1 fase)',
						outdatedMeterCabinet: 'Armário de contadores desatualizado',
						notTabCompliant: 'Não está em conformidade com o TAB',
						other: 'Outro motivo'
					},
					projectInstallationReasons: {
						label: 'Selecionar um motivo',
						condominiumOwnersAssociation: 'Condomínio (é necessária autorização)',
						highPowerEvu: 'Pedido 22 KW, desafios EVU',
						undergroundGarageInstallation: 'Instalação em garagem subterrânea',
						fireProtectionIssues: 'Desafios da proteção contra incêndios',
						multipleWallboxManagement: 'Gestão da carga necessária devido a múltiplas wallboxes',
						other: 'Outro motivo',
						hint: {
							heading: 'Se a instalação for possível em geral, mas não for possível numa relação direta com o cliente, pode optar por criar um projeto B2B a partir da encomenda, que deve ser tratado separadamente.'
						},
						information: {
							heading: 'Propor um projeto de instalação',
							message: 'O cliente e a ABB serão informados da sua decisão. Todas as informações relevantes, incluindo mais informações do cliente, são-lhe transmitidas por correio eletrónico. Em seguida, pode entrar em contacto com o cliente para estabelecer um projeto de instalação separado.'
						}
					}
				},
				taskOfferCollection: {
					fullPrice: 'Preço total (líquido)'
				}
			},
			arrangeInstallationAppointment: {
				heading: 'Marcação',
				replacementInfo: 'A instalação a programar é uma instalação de substituição.',
				rescheduleInfo: 'A marcação a efetuar é um reagendamento.',
				contact: {
					heading: 'Contacto do cliente'
				},
				notes: {
					heading: 'Notas',
					placeholder: 'Adicionar notas'
				},
				button: {
					close: 'Fechar',
					submit: 'Pronto'
				}
			},
			basicAnswers: {
				customer: {
					heading: 'Endereço de instalação'
				},
				questionnaire: {
					heading: 'Respostas dos clientes'
				},
				decision: {
					heading: 'Decisão'
				},
				notes: {
					heading: 'Notas',
					placeholder: 'Adicionar notas'
				},
				button: {
					close: 'Fechar',
					submit: 'Pronto'
				},
				continueToQuotation: {
					buttonLabel: 'Criar uma oferta'
				}
			},
			gridOperatorDecision: {
				gridOperator: {
					heading: 'Operador de rede'
				},
				wallboxPower: {
					heading: 'Informações sobre a Wallbox',
					hint: 'O cliente encomendou uma Wallbox com um consumo de energia de {{wallboxPower}} kW.'
				},
				documents: {
					heading: 'Documentos'
				},
				decision: {
					heading: 'Decisão',
					accepted: 'O operador da rede concordou com a instalação',
					declined: 'O operador da rede recusou a instalação'
				},
				notes: {
					heading: 'Notas',
					placeholder: 'Adicionar notas'
				},
				button: {
					close: 'Fechar',
					submit: 'Submeter'
				}
			},
			gridOperatorRegistration: {
				gridOperator: {
					heading: 'Operador de rede'
				},
				wallboxPower: {
					heading: 'Informações sobre a Wallbox',
					hint: 'O cliente encomendou uma Wallbox com um consumo de energia de {{wallboxPower}} kW.'
				},
				documents: {
					heading: 'Documentos'
				},
				registrationRequired: {
					heading: 'Registo obrigatório',
					required: 'Sim, é necessário um registo',
					unnecessary: 'Não, não é necessário um registo'
				},
				notes: {
					heading: 'Notas',
					placeholder: 'Adicionar notas'
				},
				button: {
					close: 'Fechar',
					submit: 'Submeter'
				},
				continueToFeedback: {
					buttonLabel: 'Introduzir o feedback do operador de rede'
				}
			},
			gridOperatorRevokeRegistration: {
				gridOperator: {
					heading: 'Operador de rede'
				},
				notes: {
					heading: 'Notas',
					placeholder: 'Adicionar notas'
				},
				button: {
					close: 'Fechar',
					submit: 'Submeter'
				}
			},
			preCheck: {
				contact: {
					heading: 'Contacto do cliente',
					description: 'A que horas do dia é que o cliente está em casa?'
				},
				questionnaire: {
					heading: 'Respostas do cliente ao questionário básico'
				},
				decision: {
					heading: 'Decisão'
				},
				notes: {
					heading: 'Notas',
					placeholder: 'Adicionar notas'
				},
				button: {
					close: 'Fechar',
					submit: 'Pronto'
				}
			},
			quotation: {
				customer: {
					heading: 'Endereço de instalação'
				},
				questionnaire: {
					heading: 'Respostas do cliente ao questionário básico'
				},
				preCheck: {
					heading: 'Verificar previamente os resultados: Prazos e esforço de instalação'
				},
				comments: {
					heading: 'Observações adicionais'
				},
				additionalEffort: {
					heading: 'Esforço adicional',
					text: 'Para efetuar a instalação, devem ser realizados trabalhos adicionais para além da instalação normal. Será criada uma oferta correspondente com base nos seguintes pacotes de instalação Delta.'
				},
				conditions: {
					heading: 'Condições de oferta para o cliente',
					placeholder: 'Em que condições é que a oferta é válida?'
				},
				notes: {
					heading: 'Notas',
					placeholder: 'Adicionar notas'
				},
				button: {
					close: 'Fechar',
					submit: 'Submeter'
				},
				deltaPackages: {
					fullPrice: 'Preço total (líquido)'
				}
			},
			installationComplete: {
				contact: {
					heading: 'Cliente'
				},
				documents: {
					heading: 'Documentos'
				},
				trades: {
					heading: 'Trabalhos e nomeações'
				},
				customerAcceptance: {
					heading: 'Aceitação do cliente',
					onSite: 'O cliente aprovou a instalação no local',
					remote: 'O cliente não estava disponível'
				},
				wallbox: {
					heading: 'Wallbox encomendada',
					serial: 'Confirmo, por este meio, a instalação e a colocação em funcionamento corretas e profissionais da Wallbox fornecida com o número de série seguinte: {{serialNumber}}.',
					throttle: 'O consumo de energia da Wallbox foi reduzido de acordo com os requisitos dos operadores de rede.'
				},
				notes: {
					heading: 'Notas',
					placeholder: 'Adicionar notas'
				},
				button: {
					close: 'Fechar',
					submit: 'Submeter'
				}
			}
		},
		taskCollection: {
			heading: 'Tarefas',
			tableHead: {
				order: 'Tarefa',
				status: 'Estado',
				due: 'Data de vencimento',
				priority: 'Prioridade',
				responsible: 'Responsável'
			},
			action: {
				refresh: 'Atualizar',
				showMore: 'Mostrar mais'
			},
			assign: {
				successMessage: 'A tarefa foi atribuída com êxito',
				errorMessage: 'Durante a atribuição da tarefa, ocorreu um erro.'
			},
			emptyState: {
				heading: 'Não foram encontradas tarefas',
				message: 'Ajuste as definições de filtro para encontrar algumas tarefas.'
			},
			orderEmptyState: {
				heading: 'Não foram encontradas tarefas',
				message: 'Não é necessário fazer nada para esta encomenda.'
			}
		},
		error: {
			generic: {
				heading: 'Algo correu mal',
				message: 'Ocorreu um erro imprevisto que não pôde ser resolvido',
				resolveAction: {
					label: 'Recarregar'
				}
			},
			offline: {
				heading: 'Ligação de rede indisponível',
				message: 'Ligue-se a uma rede para utilizar a aplicação.'
			},
			notFound: {
				heading: 'Não encontrado',
				message: 'O recurso solicitado não foi encontrado',
				resolveAction: {
					label: 'Painel de controlo'
				}
			},
			orderNotFound: 'Encomenda não encontrada',
			taskNotFound: 'Tarefa não encontrada.',
			taskTypeNotFound: 'Tipo de tarefa não encontrado: {{type}}',
			taskCompleteMessage: 'Durante a conclusão da tarefa, ocorreu um erro.',
			taskAssignMessage: 'Durante a atribuição da tarefa, ocorreu um erro.'
		}
	}
};

export default literals;
