import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	omsInstallationPartnerOfficeApp: {
		action: {
			cancelOrderAppointments: 'Afspraken annuleren'
		},
		actionItemFactory: {
			cancelOrderAppointmentsLabel: 'Afspraken annuleren'
		},
		actionModalFactory: {
			cancelOrderAppointmentsHeading: 'Bestelafspraken annuleren'
		},
		actionModal: {
			cancelOrderAppointments: {
				content: {
					notificationHeading: 'De installatieafspraken annuleren.',
					notificationMessage: 'Alle installatieafspraken worden geannuleerd.',
					successHeading: 'Afspraken geannuleerd',
					successCloseButtonLabel: 'Afsluiten',
					reason: 'Reden'
				},
				cancelReasonValue: {
					deliveryDelay: 'Vertraging in levering',
					installationPartnerConflict: 'Conflict tussen installatiepartners',
					customerConflict: 'Conflict met klanten',
					replacement: 'Vervanging'
				},
				footer: {
					confirm: 'Annulering bevestigen',
					cancel: 'Annuleren'
				},
				error: {
					heading: 'Kon afspraken niet annuleren.',
					message: 'Er is een fout opgetreden tijdens het verwijderen van de bestelafspraken.'
				}
			}
		},
		appName: 'Vestiging belast met installatie ABB wallbox',
		pageTitle: {
			dashboard: 'Dashboard',
			error: 'Fout',
			notFound: 'Niet gevonden',
			orders: 'Bestellingen',
			order: 'Rangschikken op {{displayName}}'
		},
		navigation: {
			dashboard: 'Dashboard',
			orders: 'Bestellingen',
			logout: 'Afmelden'
		},
		breadcrumbs: {
			tasks: 'Taakoverzicht',
			orders: 'Besteloverzicht'
		},
		viewHeader: {
			tasks: 'Taakoverzicht',
			orders: 'Overzicht bestellingen'
		},
		installerAppointmentCollection: {
			heading: 'Geplande installaties',
			emptyState: {
				heading: 'Geen geplande installaties',
				message: 'Voor de bestelling zijn geen installaties gepland.'
			}
		},
		orderCollection: {
			heading: 'Bestellingen',
			tableHead: {
				order: 'Bestelling',
				status: 'Status',
				estimated: 'Geschatte installatiedatum',
				progress: 'Voortgang'
			},
			emptyState: {
				heading: 'Geen bestellingen gevonden',
				message: 'Pas je filterinstellingen aan om bestellingen te vinden.'
			},
			action: {
				refresh: 'Vernieuw',
				showMore: 'Meer tonen'
			},
			filter: {
				filter: 'Filter',
				none: 'geen',
				active: '{{count}} actief',
				resetFilter: 'Filter resetten',
				options: {
					dueDateOverdue: 'Vertraagd',
					dueDateToday: 'Vandaag uitgerekend',
					priorityHigh: 'Prioriteit hoog',
					priorityMedium: 'Prioriteit middelhoog',
					priorityLow: 'Prioriteit laag',
					statusDone: 'Gereed',
					statusOpen: 'Open',
					installationTypeStandard: 'Standaardinstallatie',
					installationTypeDelta: 'Delta-installatie',
					installationTypeUndetermined: 'Onbepaald'
				}
			}
		},
		orderDetail: {
			progress: {
				heading: 'Voortgang'
			},
			customerInformation: {
				heading: 'Klantgegevens',
				address: 'Adres',
				phone: 'Telefoon',
				mail: 'Mail'
			},
			installationAddress: {
				heading: 'Installatieadres',
				address: 'Adres'
			},
			status: {
				onSchedule: 'Op schema',
				delayed: 'Vertraagd',
				cancelPending: 'In afwachting van annulering',
				cancelled: 'Geannuleerd',
				done: 'Gereed'
			},
			installationType: {
				none: 'Onbepaald',
				undetermined: 'Onbepaald',
				cancelled: 'Geannuleerd',
				standard: 'Standaard',
				delta: 'Delta'
			}
		},
		task: {
			progressStepShortName: {
				installationPartnerEvaluateBasicAnswers: 'Basiscontrole',
				installationPartnerArrangeAppointment: 'Afspraak',
				installationPartnerExecuteRemotePreCheck: 'Laatste controle',
				installationPartnerRegisterAtGridOperator: 'Registratie netbeheerder',
				installationPartnerProvideGridOperatorDecision: 'Besluit netbeheerder',
				installationPartnerRevokeGridOperatorRegistration: 'Herroeping netbeheerder',
				installationPartnerProvideQuotation: 'Offerte',
				installationPartnerCompleteInstallation: 'Installatie'
			},
			displayName: {
				installationPartnerEvaluateBasicAnswers: 'Basisgegevens',
				installationPartnerArrangeAppointment: 'Afspraak maken',
				installationPartnerExecuteRemotePreCheck: 'Voorcontrole',
				installationPartnerRegisterAtGridOperator: 'Registratie netbeheerder',
				installationPartnerProvideGridOperatorDecision: 'Besluit netbeheerder',
				installationPartnerRevokeGridOperatorRegistration: 'Intrekking registratie netbeheerder',
				installationPartnerProvideQuotation: 'Offerte',
				installationPartnerCompleteInstallation: 'Voltooiing van de installatie'
			},
			status: {
				started: 'Komende',
				dueToday: 'Vandaag uitgerekend',
				overdue: 'Achterstallig',
				escalated: 'Geëscaleerd',
				done: 'Gereed',
				doneOverdue: 'Gereed',
				doneEscalated: 'Gereed'
			},
			priority: {
				low: 'Laag',
				medium: 'Middelhoog',
				high: 'Hoog'
			},
			assign: {
				successMessage: 'De taak werd met succes toegewezen'
			},
			completion: {
				successHeading: 'Taak voltooid',
				closeButtonLabel: 'Afsluiten'
			},
			component: {
				taskTradeAppointmentCraft: {
					hint: {
						invalid: 'Een of meer van de instellingen voor vakgroep en inspanning zijn onvolledig of ongeldig en worden niet verzonden.'
					},
					estimatedEffort: 'Geschatte inspanning',
					selectedEffort: 'Geselecteerd',
					installationPeriod: {
						preparation: 'als voorbereiding op de installatie',
						beginning: 'aan het begin van de installatie',
						permanent: 'tijdens de installatie',
						end: 'aan het einde van de installatie',
						followUp: 'na de installatie'
					},
					unit: {
						hours: 'uur'
					},
					button: {
						add: 'Meer dagen toevoegen'
					}
				},
				taskTradeAppointmentCraftItem: {
					input: {
						installer: 'Installateur',
						date: 'Datum',
						start: 'Start',
						end: 'Einde'
					}
				},
				taskTradeAppointmentEffort: {
					heading: 'Verwachte vakgroepen en inspanning',
					hint: {
						invalid: 'Een of meer van de instellingen voor vakgroep en inspanning zijn onvolledig of ongeldig en worden niet verzonden.',
						notApprovable: 'Er is geen vakgroep vastgesteld voor acceptatie. Er moet minstens één vakgroep klaar zijn voor acceptatie om de installatie te voltooien.'
					},
					button: {
						add: 'Vakgroep toevoegen'
					}
				},
				taskTradeAppointmentEffortItem: {
					input: {
						trade: 'Vakgroep',
						period: 'Periode',
						effort: 'Inspanning',
						canComplete: 'kan voltooien'
					},
					installationPeriod: {
						preparation: 'Installatievoorbereiding',
						beginning: 'Begin van de installatie',
						permanent: 'Permanent',
						end: 'Einde installatie',
						followUp: 'Follow-up van installatie'
					}
				},
				taskChoice: {
					decision: {
						standard: 'Informatie voldoende - standaard installatie',
						delta: 'Informatie voldoende - delta-installatie',
						preCheckRequired: 'Meer informatie nodig',
						unfeasible: 'Installatie niet mogelijk',
						project: 'Installatie als afzonderlijk project mogelijk'
					},
					unfeasibleInstallationReasons: {
						label: 'Selecteer een reden',
						noPotentialRailAvailable: 'Geen potentiaalrail beschikbaar',
						overvoltageProtectionUnfeasible: 'Overspanningsbeveiliging kan niet worden geïnstalleerd',
						spaceForCableOutletMissing: 'Geen ruimte voor extra kabeluitgang',
						houseConnectionUndersized: 'Huisaansluiting van onvoldoende capaciteit ',
						noThreePhaseAvailable: 'Geen driefasenstroom in het huis (1 fase)',
						outdatedMeterCabinet: 'Verouderde meterkast',
						notTabCompliant: 'Voldoet niet aan TAB',
						other: 'Andere reden'
					},
					projectInstallationReasons: {
						label: 'Selecteer een reden',
						condominiumOwnersAssociation: 'Condominium (toestemming vereist)',
						highPowerEvu: 'Verzoek 22 KW, EVU-uitdagingen',
						undergroundGarageInstallation: 'Installatie in ondergrondse garage',
						fireProtectionIssues: 'Uitdagingen op het gebied van brandbeveiliging',
						multipleWallboxManagement: 'Belastingsbeheer vereist vanwege meerdere wallboxen',
						other: 'Andere reden',
						hint: {
							heading: 'Als de installatie in het algemeen mogelijk is, maar niet in een directe klantrelatie, kun je ervoor kiezen om aan de hand van de bestelling een B2B-project te creëren dat apart moet worden afgehandeld.'
						},
						information: {
							heading: 'Installatieproject voorstellen',
							message: 'De klant en ABB worden op de hoogte gebracht van je beslissing. Alle relevante informatie, met inbegrip van meer informatie van de klant, wordt per e-mail naar je doorgestuurd. Je kunt dan contact opnemen met de klant voor het opzetten van een gescheiden installatieproject.'
						}
					}
				},
				taskOfferCollection: {
					fullPrice: 'Totale prijs (netto)'
				}
			},
			arrangeInstallationAppointment: {
				heading: 'Afspraak',
				replacementInfo: 'De te plannen installatie is een vervangingsinstallatie.',
				rescheduleInfo: 'De te maken afspraak komt in de plaats van een eerdere afspraak. ',
				contact: {
					heading: 'Contact met klant'
				},
				notes: {
					heading: 'Opmerkingen',
					placeholder: 'Opmerkingen toevoegen'
				},
				button: {
					close: 'Afsluiten',
					submit: 'Gereed'
				}
			},
			basicAnswers: {
				customer: {
					heading: 'Installatieadres'
				},
				questionnaire: {
					heading: 'Antwoorden van klant'
				},
				decision: {
					heading: 'Beslissing'
				},
				notes: {
					heading: 'Opmerkingen',
					placeholder: 'Opmerkingen toevoegen'
				},
				button: {
					close: 'Afsluiten',
					submit: 'Gereed'
				},
				continueToQuotation: {
					buttonLabel: 'Een offerte maken'
				}
			},
			gridOperatorDecision: {
				gridOperator: {
					heading: 'Netbeheerder'
				},
				wallboxPower: {
					heading: 'Wallbox-informatie',
					hint: 'De klant heeft een wallbox besteld met {{wallboxPower}} kW opgenomen vermogen.'
				},
				documents: {
					heading: 'Documenten'
				},
				decision: {
					heading: 'Beslissing',
					accepted: 'De netbeheerder is akkoord met de installatie',
					declined: 'De netbeheerder heeft de installatie afgewezen'
				},
				notes: {
					heading: 'Opmerkingen',
					placeholder: 'Opmerkingen toevoegen'
				},
				button: {
					close: 'Afsluiten',
					submit: 'Verzenden'
				}
			},
			gridOperatorRegistration: {
				gridOperator: {
					heading: 'Netbeheerder'
				},
				wallboxPower: {
					heading: 'Wallbox-informatie',
					hint: 'De klant heeft een wallbox besteld met {{wallboxPower}} kW opgenomen vermogen.'
				},
				documents: {
					heading: 'Documenten'
				},
				registrationRequired: {
					heading: 'Registratie vereist',
					required: 'Ja, een registratie is vereist',
					unnecessary: 'Nee, een registratie is niet nodig'
				},
				notes: {
					heading: 'Opmerkingen',
					placeholder: 'Opmerkingen toevoegen'
				},
				button: {
					close: 'Afsluiten',
					submit: 'Verzenden'
				},
				continueToFeedback: {
					buttonLabel: 'Feedback van netbeheerder invoeren'
				}
			},
			gridOperatorRevokeRegistration: {
				gridOperator: {
					heading: 'Netbeheerder'
				},
				notes: {
					heading: 'Opmerkingen',
					placeholder: 'Opmerkingen toevoegen'
				},
				button: {
					close: 'Afsluiten',
					submit: 'Verzenden'
				}
			},
			preCheck: {
				contact: {
					heading: 'Contact met klant',
					description: 'Op welk moment van de dag is de klant thuis?'
				},
				questionnaire: {
					heading: 'Antwoorden op basisvragenlijst klant'
				},
				decision: {
					heading: 'Beslissing'
				},
				notes: {
					heading: 'Opmerkingen',
					placeholder: 'Opmerkingen toevoegen'
				},
				button: {
					close: 'Afsluiten',
					submit: 'Gereed'
				}
			},
			quotation: {
				customer: {
					heading: 'Installatieadres'
				},
				questionnaire: {
					heading: 'Antwoorden op basisvragenlijst klant'
				},
				preCheck: {
					heading: 'Resultaten vooraf controleren: Installatieafspraken en -inspanning'
				},
				comments: {
					heading: 'Aanvullende opmerkingen'
				},
				additionalEffort: {
					heading: 'Extra inspanning',
					text: 'Om de installatie uit te voeren moet er extra werk worden gedaan in aanvulling op de standaardinstallatie. Er wordt een bijbehorende offerte gemaakt op basis van de volgende Delta-installatiepakketten.'
				},
				conditions: {
					heading: 'Offertevoorwaarden voor de klant',
					placeholder: 'Onder welke voorwaarden is de offerte geldig?'
				},
				notes: {
					heading: 'Opmerkingen',
					placeholder: 'Opmerkingen toevoegen'
				},
				button: {
					close: 'Afsluiten',
					submit: 'Verzenden'
				},
				deltaPackages: {
					fullPrice: 'Totale prijs (netto)'
				}
			},
			installationComplete: {
				contact: {
					heading: 'Klant'
				},
				documents: {
					heading: 'Documenten'
				},
				trades: {
					heading: 'Vakgroepen en afspraken'
				},
				customerAcceptance: {
					heading: 'Acceptatie door klant',
					onSite: 'De klant heeft de installatie ter plaatse goedgekeurd',
					remote: 'De klant was niet beschikbaar'
				},
				wallbox: {
					heading: 'Bestelde Wallbox',
					serial: 'Hierbij bevestig ik de correcte en professionele installatie en ingebruikname van de geleverde wallbox met het volgende serienummer: {{serialNumber}}.',
					throttle: 'Het stroomverbruik van de wallbox werd gereduceerd volgens de eisen van de netbeheerder.'
				},
				notes: {
					heading: 'Opmerkingen',
					placeholder: 'Opmerkingen toevoegen'
				},
				button: {
					close: 'Afsluiten',
					submit: 'Verzenden'
				}
			}
		},
		taskCollection: {
			heading: 'Taken',
			tableHead: {
				order: 'Taak',
				status: 'Status',
				due: 'Vervaldatum',
				priority: 'Prioriteit',
				responsible: 'Verantwoordelijk'
			},
			action: {
				refresh: 'Vernieuw',
				showMore: 'Meer tonen'
			},
			assign: {
				successMessage: 'De taak werd met succes toegewezen',
				errorMessage: 'Tijdens het toewijzen van de taak is een fout opgetreden.'
			},
			emptyState: {
				heading: 'Geen taken gevonden',
				message: 'Pas je filterinstellingen aan om taken te vinden.'
			},
			orderEmptyState: {
				heading: 'Geen taken gevonden',
				message: 'Voor deze bestelling hoeft niets te worden gedaan.'
			}
		},
		error: {
			generic: {
				heading: 'Er ging iets mis',
				message: 'Er is een onvoorziene fout opgetreden die niet kon worden opgelost',
				resolveAction: {
					label: 'Opnieuw laden'
				}
			},
			offline: {
				heading: 'Netwerkverbinding niet beschikbaar',
				message: 'Maak verbinding met een netwerk om de applicatie te gebruiken.'
			},
			notFound: {
				heading: 'Niet gevonden',
				message: 'De opgevraagde bron is niet gevonden',
				resolveAction: {
					label: 'Dashboard'
				}
			},
			orderNotFound: 'Bestelling niet gevonden.',
			taskNotFound: 'Taak niet gevonden.',
			taskTypeNotFound: 'Taaktype niet gevonden: {{type}}',
			taskCompleteMessage: 'Tijdens het uitvoeren van de taak is een fout opgetreden.',
			taskAssignMessage: 'Tijdens het toewijzen van de taak is een fout opgetreden.'
		}
	}
};

export default literals;
