import { FilterCriteria, SingleValueFilterComparator, SortCriteria, SortDirection } from '@abb-emobility/shared/api-integration-foundation';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { AnyTaskCollectionItemModel } from '@abb-emobility/usertask/domain-model';

export const createOrderTaskCollectionSortCriteria = (): SortCriteria<AnyTaskCollectionItemModel> => {
	return [
		{ property: 'dueDate', direction: SortDirection.ASCENDING },
		{ property: 'priority', direction: SortDirection.DESCENDING }
	];
};

export const createOrderTaskCollectionFilterCriteria = (orderId: ModelPrimaryKey): FilterCriteria<AnyTaskCollectionItemModel> => {
	return [{
		id: 'ORDER_ID',
		criteria: [{
			property: 'orderId',
			comparator: SingleValueFilterComparator.EQUAL,
			value: orderId
		}]
	}];
};
