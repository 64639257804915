import { CSSTransition } from 'react-transition-group';

import { useExpandable } from '@abb-emobility/shared/interaction';

import { Icon } from '../icon/Icon';
import { IconIdentifier, IconSize } from '../icon/Icon.enums';
import { Separator } from '../separator/Separator';

import './ExpandableSectionHeader.scss';

export type ExpandableSectionHeaderProps = {
	heading: string
};

export function ExpandableSectionHeader(props: ExpandableSectionHeaderProps) {
	const { heading } = props;

	const expandable = useExpandable();
	const expanded = expandable.isExpanded();

	const handleClick = () => {
		expandable.toggle();
	};

	const renderIcon = () => {
		return (
			<CSSTransition in={expanded} timeout={200} classNames="expandable-section-header__action">
				<span className="expandable-section-header__action">
					<Icon name={IconIdentifier.CARET_RIGHT} size={IconSize.SMALL} />
				</span>
			</CSSTransition>
		);
	};

	return (
		<header className="expandable-section-header" tabIndex={0} onClick={() => handleClick()}>
			<div className="expandable-section-header__content">
				{renderIcon()}
				<h1 className="expandable-section-header__content__heading">{heading}</h1>
			</div>
			<Separator />
		</header>
	);
}
