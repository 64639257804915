import { useEffect, useRef } from 'react';

import { InstallerAppointmentModel } from '@abb-emobility/oms/domain-model';
import { CollectionDataProviderAppeareProps } from '@abb-emobility/shared/data-provider-foundation';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { Nullable } from '@abb-emobility/shared/util';

import { useOrderInstallerAppointmentCollectionData } from './OrderInstallerAppointmentCollectionDataConsumer';
import { createFilterCriteria, createSortCriteria } from './OrderInstallerAppointmentCollectionDataProvider.util';

export type OrderInstallerAppointmentCollectionDataAppearProps =
	Omit<CollectionDataProviderAppeareProps<InstallerAppointmentModel>, 'sortCriteria' | 'filterCriteria'>
	& { orderId: ModelPrimaryKey };

export function OrderInstallerAppointmentCollectionDataAppear(props: OrderInstallerAppointmentCollectionDataAppearProps) {
	const { orderId, forceFetch, children } = props;

	const providerElement = useRef<Nullable<HTMLDivElement>>(null);

	const dataProviderValue = useOrderInstallerAppointmentCollectionData();

	useEffect(() => {
		if (providerElement.current === null) {
			return;
		}
		const intersectionObserver = new IntersectionObserver((entries, observer) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					dataProviderValue.fetch(createSortCriteria(), createFilterCriteria(orderId), forceFetch);
					observer.disconnect();
				}
			});
		});
		intersectionObserver.observe(providerElement.current);
		return () => {
			intersectionObserver.disconnect();
		};
	}, []);

	return (
		<div ref={providerElement}>
			{children}
		</div>
	);
}
