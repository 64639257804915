import { useEnv } from '@abb-emobility/shared/environment';
import { NotFoundError } from '@abb-emobility/shared/error';

export enum RoutePath {
	ROOT = '/',
	TASKS = '/tasks/',
	TASK = '/task/:taskId',
	ORDERS = '/orders/',
	ORDER = '/order/:orderId',
	ORDER_TASK = '/order/:orderId/task/:taskId'
}

export type RouteParameter = [string, string];

export const useRouteUrl = () => {
	const env = useEnv();
	const installationPartnerPath = env.get<string>('installationPartnerPath').getOrThrow(new NotFoundError('Installation partner path not found'));

	return {
		createRouteUrl: (routePath: RoutePath, ...parameters: Array<RouteParameter>): string => {
			let url = String(routePath);
			for (const parameter of parameters) {
				url = url.replace(':' + parameter[0], parameter[1]);
			}
			return '/' + installationPartnerPath + url;
		}
	};
};
