import { ReactNode } from 'react';
import { CSSTransition } from 'react-transition-group';

import { useExpandable } from '@abb-emobility/shared/interaction';

import './SectionItems.scss';

export type SectionItemsProps = {
	children?: ReactNode
};

export function SectionItems(props: SectionItemsProps) {
	const { children } = props;

	const expandable = useExpandable();
	const expanded = expandable.isExpanded();

	return (
		<CSSTransition in={expanded} timeout={400} classNames="section-items">
			<main className="section-items">
				{children}
			</main>
		</CSSTransition>
	);
}
