import { ButtonHTMLAttributes, useEffect, useState } from 'react';

import { buildCssClassStringFromClassMap } from '@abb-emobility/shared/util';

import './ButtonSelect.scss';

export type ButtonSelectProps = {
	label: string,
	checkedState?: boolean,
	onSelect?: (checked: boolean) => void
} & Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick' | 'onSelect'>;

export function ButtonSelect(props: ButtonSelectProps) {
	const { type = 'button', label, checkedState = false, onSelect = null, ...rest } = props;

	const [checked, setChecked] = useState(checkedState);

	useEffect(() => {
		setChecked(checkedState);
	});

	const buttonClassMap = {
		'button-select': true,
		'button-select--checked': checked
	};

	const handleClick = () => {
		setChecked((prevState) => {
			if (onSelect !== null) {
				onSelect(!prevState);
			}
			return !prevState;
		});
	};

	return (
		<button
			type={type}
			className={buildCssClassStringFromClassMap(buttonClassMap)}
			onClick={handleClick}
			{...rest}
		>{label}</button>
	);
}
