import { useEffect, useRef } from 'react';

import { CollectionDataProviderAppeareProps } from '@abb-emobility/shared/data-provider-foundation';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { Nullable } from '@abb-emobility/shared/util';
import { AnyTaskCollectionItemModel } from '@abb-emobility/usertask/domain-model';

import { useOrderTaskCollectionData } from './OrderTaskCollectionDataConsumer';
import { createOrderTaskCollectionFilterCriteria, createOrderTaskCollectionSortCriteria } from './OrderTaskCollectionDataProvider.util';

export type OrderTaskCollectionDataAppearProps =
	Omit<CollectionDataProviderAppeareProps<AnyTaskCollectionItemModel>, 'sortCriteria' | 'filterCriteria'>
	& { orderId: ModelPrimaryKey };

export function OrderTaskCollectionDataAppear(props: OrderTaskCollectionDataAppearProps) {
	const { orderId, forceFetch, children } = props;

	const providerElement = useRef<Nullable<HTMLDivElement>>(null);

	const dataProviderValue = useOrderTaskCollectionData();

	useEffect(() => {
		if (providerElement.current === null) {
			return;
		}
		const intersectionObserver = new IntersectionObserver((entries, observer) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					dataProviderValue.fetch(createOrderTaskCollectionSortCriteria(), createOrderTaskCollectionFilterCriteria(orderId), forceFetch);
					observer.disconnect();
				}
			});
		});
		intersectionObserver.observe(providerElement.current);
		return () => {
			intersectionObserver.disconnect();
		};
	}, []);

	return (
		<div ref={providerElement}>
			{children}
		</div>
	);
}
