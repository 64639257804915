import React from 'react';

import { TaskAssignError, TaskCompleteError } from '@abb-emobility/shared/business-error';
import { TaskActionStatus } from '@abb-emobility/shared/data-provider-foundation';
import { AuthenticationFailedError, AuthenticationRequiredError, NotFoundError, restoreError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { useAnyTaskEntitySearchData } from '@abb-emobility/usertask/data-provider';

import { TaskModalAssembler } from './TaskModalAssembler';

export function TaskSearchModalResolver() {

	const l10n = useL10n();

	const anyTaskSearchData = useAnyTaskEntitySearchData();
	const task = anyTaskSearchData.query().getOrThrow(new NotFoundError(l10n.translate('omsInstallationPartnerOfficeApp.error.taskNotFound')));
	const actionErrorObject = anyTaskSearchData.queryActionError();

	anyTaskSearchData.useActionStatusEffect([TaskActionStatus.COMPLETE_FAILED], () => {
		if (actionErrorObject === null) {
			return;
		}
		const error = restoreError(actionErrorObject);
		if (error instanceof AuthenticationFailedError || error instanceof AuthenticationRequiredError) {
			throw error;
		}
		throw new TaskCompleteError(l10n.translate('omsInstallationPartnerOfficeApp.error.taskCompleteMessage'), undefined, undefined, error);
	}, true);

	anyTaskSearchData.useActionStatusEffect([TaskActionStatus.ASSIGN_FAILED], () => {
		if (actionErrorObject === null) {
			return;
		}
		const error = restoreError(actionErrorObject);
		if (error instanceof AuthenticationFailedError || error instanceof AuthenticationRequiredError) {
			throw error;
		}
		throw new TaskAssignError(l10n.translate('omsInstallationPartnerOfficeApp.error.taskAssignMessage'), undefined, undefined, error);
	}, true);

	return (
		<TaskModalAssembler taskId={task.id} />
	);

}
