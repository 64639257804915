import React, { ReactNode } from 'react';

import { InstallationPartnerOrderModel } from '@abb-emobility/oms/domain-model';
import { createFullNameFromCustomer } from '@abb-emobility/shared/domain-model';
import { OrderState, ProgressState } from '@abb-emobility/shared/domain-model';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	ButtonIcon,
	CollectionItemDate,
	CollectionItemHeader,
	CollectionItemHeaderItem,
	CollectionItemLabel,
	Icon,
	IconIdentifier,
	Separator,
	Status,
	Tag
} from '@abb-emobility/shared/ui-primitive';
import { arrayReverse, buildCssClassStringFromClassMap, isString, l10nLiteralFromEnumValue } from '@abb-emobility/shared/util';

import './OrderCollectionItem.scss';

type ItemProps = {
	order: InstallationPartnerOrderModel,
	onNavigate: (id: ModelPrimaryKey) => void
};

export function OrderCollectionItem(props: ItemProps) {

	const { order, onNavigate } = props;

	const l10n = useL10n();

	const handleNavigate = () => {
		onNavigate(order.id);
	};

	const renderProgressLabel = (): ReactNode => {
		let currentIndex = order.progress.steps.findIndex((value) => {
			return value.status === ProgressState.ONGOING;
		});

		if (currentIndex === -1) {
			const reverseIndex = arrayReverse(order.progress.steps).findIndex((value) => {
				return value.status === ProgressState.DONE;
			});
			currentIndex = order.progress.steps.length - 1 - reverseIndex;
		}

		const currentProgress = order.progress.steps[currentIndex];
		if (currentProgress === undefined) {
			return null;
		}
		return l10n.translate(l10nLiteralFromEnumValue(currentProgress.taskType, 'omsUiDomain.task.shortName'), undefined, 'unknown');
	};

	const renderProgressIndicator = (): ReactNode => {
		return order.progress.steps.map((step, index) => {
			const classMap = {
				'order-collection-item__progress__progress__step': true,
				'order-collection-item__progress__progress__step--highlighted': step.status === ProgressState.DONE
			};
			return (<span className={buildCssClassStringFromClassMap(classMap)} key={index} />);
		});
	};

	const renderInstallationAddress = (): string => {
		const address = order.installationAddress;
		if (isString(address)) {
			return address;
		}
		return address.zip + ' ' + address.city;
	};

	return (
		<>
			<CollectionItemHeader>
				<CollectionItemHeaderItem>
					<CollectionItemLabel
						label={createFullNameFromCustomer(order.customer)}
						orderId={order.marketplaceOrderId}
						additionalIcon={IconIdentifier.MAP_PIN}
						additionalAttribute={renderInstallationAddress()}
						disabled={order.state === OrderState.CANCELLED}
					>
						<Tag label={l10n.translate(l10nLiteralFromEnumValue(order.installationType, 'omsUiDomain.order.installationType'))} />
					</CollectionItemLabel>
				</CollectionItemHeaderItem>

				<CollectionItemHeaderItem>
					<Status label={l10n.translate(l10nLiteralFromEnumValue(order.state, 'omsUiDomain.order.status'))} state={order.state} />
				</CollectionItemHeaderItem>

				<CollectionItemHeaderItem>
					<CollectionItemDate date={l10n.formatTimestampDate(order.estimatedCompletionDate)} />
				</CollectionItemHeaderItem>

				<CollectionItemHeaderItem>
					<div className="order-collection-item__progress">
						<div className="order-collection-item__progress__progress">
							{renderProgressIndicator()}
						</div>
						<div className="order-collection-item__progress__label">
							{renderProgressLabel()}
						</div>
					</div>
				</CollectionItemHeaderItem>

				<CollectionItemHeaderItem>
					<ButtonIcon onClick={handleNavigate}>
						<Icon name={IconIdentifier.ARROW_RIGHT} />
					</ButtonIcon>
				</CollectionItemHeaderItem>

			</CollectionItemHeader>
			<Separator />
		</>
	);
}
