import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import { Dto, ModelConverter, ModelValue } from '@abb-emobility/shared/domain-model-foundation';
import { ApiError } from '@abb-emobility/shared/error';
import { Nullable } from '@abb-emobility/shared/util';

import { JsonRestRequestFactory } from '../network/JsonRestRequestFactory';

export class CommandApiClient<PayloadModel extends Record<string, ModelValue>, InvocationPayloadModel extends Record<string, ModelValue>> {

	private readonly endpointUrl: string;

	private readonly payloadModelConverter: ModelConverter<PayloadModel>;

	private readonly invocationPayloadModelConverter: ModelConverter<InvocationPayloadModel>;

	private jsonWebToken: Nullable<JsonWebToken> = null;

	constructor(
		endpointUrl: string,
		payloadModelConverter: ModelConverter<PayloadModel>,
		invocationPayloadModelConverter: ModelConverter<InvocationPayloadModel>,
		jsonWebToken: Nullable<JsonWebToken>
	) {
		this.endpointUrl = endpointUrl;
		this.payloadModelConverter = payloadModelConverter;
		this.invocationPayloadModelConverter = invocationPayloadModelConverter;
		this.jsonWebToken = jsonWebToken;
	}

	public async fetchPayload(): Promise<PayloadModel> {
		if (
			this.endpointUrl === null
			|| this.payloadModelConverter === null
		) {
			throw new ApiError('Command API client not initialized');
		}
		const parsedResponse = await JsonRestRequestFactory.create(this.jsonWebToken).get(this.endpointUrl);
		const apiModel = parsedResponse.body as Dto<PayloadModel>;
		return this.payloadModelConverter.toModel(apiModel);
	}

	public async invoke(payload: InvocationPayloadModel): Promise<void> {
		if (
			this.endpointUrl === null
			|| this.invocationPayloadModelConverter === null
		) {
			throw new ApiError('Command API client not initialized');
		}
		const apiPayload = this.invocationPayloadModelConverter.toDto(payload);
		await JsonRestRequestFactory.create(this.jsonWebToken).put(this.endpointUrl, apiPayload);
	}

}
