import React, { ReactNode, useRef } from 'react';

import { useExpandable, useOnClickOutside } from '@abb-emobility/shared/interaction';
import { Nullable } from '@abb-emobility/shared/util';

import './ActionbarFilterContent.scss';

export type FilterLayoutContentProps = {
	onClose?: () => void,
	children: ReactNode
};

export function ActionbarFilterContent(props: FilterLayoutContentProps): Nullable<React.JSX.Element> {

	const { onClose, children } = props;

	const expandableElement = useRef<HTMLElement>(null);
	const expandable = useExpandable();
	const expanded = expandable.isExpanded();

	const handleClose = () => {
		if (onClose !== undefined) {
			onClose();
		}
	};

	useOnClickOutside(expandableElement, () => {
		expandable.collapse();
		handleClose();
	}, expanded);

	if (expanded) {
		return (
			<section className="actionbar-filter-content" ref={expandableElement}>
				{children}
			</section>
		);
	}

	return null;

}
