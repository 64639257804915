import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	omsInstallationPartnerOfficeApp: {
		action: {
			cancelOrderAppointments: 'Cancelar citas'
		},
		actionItemFactory: {
			cancelOrderAppointmentsLabel: 'Cancelar citas'
		},
		actionModalFactory: {
			cancelOrderAppointmentsHeading: 'Cancelar citas de pedidos'
		},
		actionModal: {
			cancelOrderAppointments: {
				content: {
					notificationHeading: 'Cancele las citas de instalación.',
					notificationMessage: 'Se cancelarán todas las citas de instalación.',
					successHeading: 'Citas canceladas',
					successCloseButtonLabel: 'Cerrar',
					reason: 'Motivo'
				},
				cancelReasonValue: {
					deliveryDelay: 'Retraso en la entrega',
					installationPartnerConflict: 'Conflicto con socio instalador',
					customerConflict: 'Conflicto con cliente',
					replacement: 'Sustitución'
				},
				footer: {
					confirm: 'Confirmar cancelación',
					cancel: 'Cancelar'
				},
				error: {
					heading: 'No han podido cancelar citas.',
					message: 'Se ha producido un error al eliminar las citas del pedido.'
				}
			}
		},
		appName: 'Oficina de instalación de wallbox de ABB',
		pageTitle: {
			dashboard: 'Panel de control',
			error: 'Error',
			notFound: 'No encontrado',
			orders: 'Pedidos',
			order: 'Ordenar por {{displayName}}'
		},
		navigation: {
			dashboard: 'Panel de control',
			orders: 'Pedidos',
			logout: 'Cerrar sesión'
		},
		breadcrumbs: {
			tasks: 'Información general de tareas',
			orders: 'Información general del pedido'
		},
		viewHeader: {
			tasks: 'Información general de tareas',
			orders: 'Información general de los pedidos'
		},
		installerAppointmentCollection: {
			heading: 'Instalaciones previstas',
			emptyState: {
				heading: 'No hay instalaciones previstas',
				message: 'El pedido no tiene instalaciones previstas.'
			}
		},
		orderCollection: {
			heading: 'Pedidos',
			tableHead: {
				order: 'Pedido',
				status: 'Estado',
				estimated: 'Fecha estimada de instalación',
				progress: 'En curso'
			},
			emptyState: {
				heading: 'No se han encontrado pedidos',
				message: 'Ajuste la configuración de sus filtros para encontrar algunos pedidos.'
			},
			action: {
				refresh: 'Actualizar',
				showMore: 'Mostrar más'
			},
			filter: {
				filter: 'Filtro',
				none: 'ninguna',
				active: '{{cuenta}} activa',
				resetFilter: 'Restablecer filtro',
				options: {
					dueDateOverdue: 'Retrasado',
					dueDateToday: 'Vence hoy',
					priorityHigh: 'Prioridad alta',
					priorityMedium: 'Prioridad media',
					priorityLow: 'Prioridad baja',
					statusDone: 'Hecho',
					statusOpen: 'Pendiente',
					installationTypeStandard: 'Instalación estándar',
					installationTypeDelta: 'Instalación Delta',
					installationTypeUndetermined: 'Indeterminado'
				}
			}
		},
		orderDetail: {
			progress: {
				heading: 'En curso'
			},
			customerInformation: {
				heading: 'Información del cliente',
				address: 'Dirección',
				phone: 'Teléfono',
				mail: 'Correo'
			},
			installationAddress: {
				heading: 'Dirección de instalación',
				address: 'Dirección'
			},
			status: {
				onSchedule: 'Según lo previsto',
				delayed: 'Retrasado',
				cancelPending: 'Cancelar pendiente',
				cancelled: 'Cancelado',
				done: 'Hecho'
			},
			installationType: {
				none: 'Indeterminado',
				undetermined: 'Indeterminado',
				cancelled: 'Cancelado',
				standard: 'Norma',
				delta: 'Delta'
			}
		},
		task: {
			progressStepShortName: {
				installationPartnerEvaluateBasicAnswers: 'Comprobación básica',
				installationPartnerArrangeAppointment: 'Cita',
				installationPartnerExecuteRemotePreCheck: 'Comprobación final',
				installationPartnerRegisterAtGridOperator: 'Operador de red de registro',
				installationPartnerProvideGridOperatorDecision: 'Decisión de operador de red',
				installationPartnerRevokeGridOperatorRegistration: 'Revocación de operador de red',
				installationPartnerProvideQuotation: 'Oferta',
				installationPartnerCompleteInstallation: 'Instalación'
			},
			displayName: {
				installationPartnerEvaluateBasicAnswers: 'Información básica',
				installationPartnerArrangeAppointment: 'Concertar cita',
				installationPartnerExecuteRemotePreCheck: 'Comprobación previa',
				installationPartnerRegisterAtGridOperator: 'Operador de red de registro',
				installationPartnerProvideGridOperatorDecision: 'Decisión de operador de red',
				installationPartnerRevokeGridOperatorRegistration: 'Revocación de registro de operador de red',
				installationPartnerProvideQuotation: 'Oferta',
				installationPartnerCompleteInstallation: 'Finalización de la instalación'
			},
			status: {
				started: 'Próximamente',
				dueToday: 'Vence hoy',
				overdue: 'Atrasado',
				escalated: 'Escalado',
				done: 'Hecho',
				doneOverdue: 'Hecho',
				doneEscalated: 'Hecho'
			},
			priority: {
				low: 'Bajo',
				medium: 'Medio',
				high: 'Alto'
			},
			assign: {
				successMessage: 'La tarea se asignó con éxito'
			},
			completion: {
				successHeading: 'Tarea completada',
				closeButtonLabel: 'Cerrar'
			},
			component: {
				taskTradeAppointmentCraft: {
					hint: {
						invalid: 'Una o más de las configuraciones de operación y esfuerzo están incompletas o no son válidas y no se enviarán.'
					},
					estimatedEffort: 'Esfuerzo estimado',
					selectedEffort: 'Seleccionado',
					installationPeriod: {
						preparation: 'como preparación de la instalación',
						beginning: 'al inicio de la instalación',
						permanent: 'durante la instalación',
						end: 'al final de la instalación',
						followUp: 'después de la instalación'
					},
					unit: {
						hours: 'horas'
					},
					button: {
						add: 'Añadir más días'
					}
				},
				taskTradeAppointmentCraftItem: {
					input: {
						installer: 'Instalador',
						date: 'Fecha',
						start: 'Inicio',
						end: 'Fin'
					}
				},
				taskTradeAppointmentEffort: {
					heading: 'Operaciones y esfuerzo de instalación previstos',
					hint: {
						invalid: 'Una o más de las configuraciones de operación y esfuerzo están incompletas o no son válidas y no se enviarán.',
						notApprovable: 'No se ha determinado ninguna operación susceptible de aceptación. Al menos una operación debe estar lista para su aceptación para completar la instalación.'
					},
					button: {
						add: 'Añadir operación'
					}
				},
				taskTradeAppointmentEffortItem: {
					input: {
						trade: 'Operación',
						period: 'Periodo',
						effort: 'Esfuerzo',
						canComplete: 'puede completar'
					},
					installationPeriod: {
						preparation: 'Preparación de la instalación',
						beginning: 'Inicio de la instalación',
						permanent: 'Permanente',
						end: 'Fin de la instalación',
						followUp: 'Seguimiento de la instalación'
					}
				},
				taskChoice: {
					decision: {
						standard: 'Información suficiente - instalación estándar',
						delta: 'Información suficiente - instalación delta',
						preCheckRequired: 'Se necesita más información',
						unfeasible: 'Instalación no es posible',
						project: 'Posibilidad de instalación como proyecto independiente'
					},
					unfeasibleInstallationReasons: {
						label: 'Seleccionar un motivo',
						noPotentialRailAvailable: 'Ningún carril de potencial disponible',
						overvoltageProtectionUnfeasible: 'No se puede instalar la protección contra sobretensiones',
						spaceForCableOutletMissing: 'No hay espacio para una toma de cable adicional',
						houseConnectionUndersized: 'Conexión doméstica subdimensionada',
						noThreePhaseAvailable: 'No hay corriente trifásica en la vivienda (monofásica)',
						outdatedMeterCabinet: 'Armario de contadores desactualizado',
						notTabCompliant: 'No cumple TAB',
						other: 'Otro motivo'
					},
					projectInstallationReasons: {
						label: 'Seleccionar un motivo',
						condominiumOwnersAssociation: 'Condominio (se requiere permiso)',
						highPowerEvu: 'Solicitud de 22 KW, problemas de EVU',
						undergroundGarageInstallation: 'Instalación en garaje subterráneo',
						fireProtectionIssues: 'Problemas de la protección contra incendios',
						multipleWallboxManagement: 'Gestión de la carga necesaria debido a varios wallboxes',
						other: 'Otro motivo',
						hint: {
							heading: 'Si la instalación es posible en general, aunque no es factible en una relación directa con el cliente, puede optar por crear un proyecto B2B a partir del pedido, que debe gestionarse por separado.'
						},
						information: {
							heading: 'Proponer un proyecto de instalación',
							message: 'El cliente y ABB serán informados de su decisión. Toda la información pertinente, incluida más información del cliente, se le envía por correo. A continuación, puede ponerse en contacto con el cliente para establecer un proyecto de instalación por separado.'
						}
					}
				},
				taskOfferCollection: {
					fullPrice: 'Precio total (neto)'
				}
			},
			arrangeInstallationAppointment: {
				heading: 'Cita',
				replacementInfo: 'La instalación que se va a programar es una instalación de sustitución.',
				rescheduleInfo: 'La cita que hay que concertar es una reprogramación.',
				contact: {
					heading: 'Contacto con el cliente'
				},
				notes: {
					heading: 'Notas',
					placeholder: 'Añadir notas'
				},
				button: {
					close: 'Cerrar',
					submit: 'Hecho'
				}
			},
			basicAnswers: {
				customer: {
					heading: 'Dirección de instalación'
				},
				questionnaire: {
					heading: 'Respuestas del cliente'
				},
				decision: {
					heading: 'Decisión'
				},
				notes: {
					heading: 'Notas',
					placeholder: 'Añadir notas'
				},
				button: {
					close: 'Cerrar',
					submit: 'Hecho'
				},
				continueToQuotation: {
					buttonLabel: 'Crear una oferta'
				}
			},
			gridOperatorDecision: {
				gridOperator: {
					heading: 'Operador de red'
				},
				wallboxPower: {
					heading: 'Información sobre el wallbox',
					hint: 'El cliente ha pedido un wallbox con {{wallboxPower}} kW de consumo de potencia.'
				},
				documents: {
					heading: 'Documentos'
				},
				decision: {
					heading: 'Decisión',
					accepted: 'El operador de red está de acuerdo con la instalación',
					declined: 'El operador de red rechazó la instalación'
				},
				notes: {
					heading: 'Notas',
					placeholder: 'Añadir notas'
				},
				button: {
					close: 'Cerrar',
					submit: 'Enviar'
				}
			},
			gridOperatorRegistration: {
				gridOperator: {
					heading: 'Operador de red'
				},
				wallboxPower: {
					heading: 'Información sobre el wallbox',
					hint: 'El cliente ha pedido un wallbox con {{wallboxPower}} kW de consumo de potencia.'
				},
				documents: {
					heading: 'Documentos'
				},
				registrationRequired: {
					heading: 'Registro obligatorio',
					required: 'Sí, es necesario registrarse',
					unnecessary: 'No, no es necesario registrarse'
				},
				notes: {
					heading: 'Notas',
					placeholder: 'Añadir notas'
				},
				button: {
					close: 'Cerrar',
					submit: 'Enviar'
				},
				continueToFeedback: {
					buttonLabel: 'Introducir comentarios del operador de red'
				}
			},
			gridOperatorRevokeRegistration: {
				gridOperator: {
					heading: 'Operador de red'
				},
				notes: {
					heading: 'Notas',
					placeholder: 'Añadir notas'
				},
				button: {
					close: 'Cerrar',
					submit: 'Enviar'
				}
			},
			preCheck: {
				contact: {
					heading: 'Contacto con el cliente',
					description: '¿A qué hora del día está el cliente en casa?'
				},
				questionnaire: {
					heading: 'Respuestas del cliente al cuestionario básico'
				},
				decision: {
					heading: 'Decisión'
				},
				notes: {
					heading: 'Notas',
					placeholder: 'Añadir notas'
				},
				button: {
					close: 'Cerrar',
					submit: 'Hecho'
				}
			},
			quotation: {
				customer: {
					heading: 'Dirección de instalación'
				},
				questionnaire: {
					heading: 'Respuestas del cliente al cuestionario básico'
				},
				preCheck: {
					heading: 'Resultados de la comprobación previa: Citas y esfuerzo de instalación'
				},
				comments: {
					heading: 'Comentarios adicionales'
				},
				additionalEffort: {
					heading: 'Esfuerzo adicional',
					text: 'Para llevar a cabo la instalación, hay que realizar trabajos adicionales además de la instalación estándar. Se creará una oferta correspondiente basada en los siguientes paquetes de instalación Delta.'
				},
				conditions: {
					heading: 'Condiciones de la oferta para el cliente',
					placeholder: '¿En qué condiciones es válida la oferta?'
				},
				notes: {
					heading: 'Notas',
					placeholder: 'Añadir notas'
				},
				button: {
					close: 'Cerrar',
					submit: 'Enviar'
				},
				deltaPackages: {
					fullPrice: 'Precio total (neto)'
				}
			},
			installationComplete: {
				contact: {
					heading: 'Cliente'
				},
				documents: {
					heading: 'Documentos'
				},
				trades: {
					heading: 'Operaciones y citas'
				},
				customerAcceptance: {
					heading: 'Aceptación por parte del cliente',
					onSite: 'El cliente ha aprobado la instalación in situ',
					remote: 'El cliente no estaba disponible'
				},
				wallbox: {
					heading: 'Wallbox pedido',
					serial: 'Por la presente, confirmo la instalación y puesta en servicio correctas y profesionales del wallbox suministrado con el siguiente número de serie: {{serialNumber}}.',
					throttle: 'El consumo de potencia del wallbox se redujo de acuerdo con los requisitos de los operadores de red.'
				},
				notes: {
					heading: 'Notas',
					placeholder: 'Añadir notas'
				},
				button: {
					close: 'Cerrar',
					submit: 'Enviar'
				}
			}
		},
		taskCollection: {
			heading: 'Tareas',
			tableHead: {
				order: 'Tarea',
				status: 'Estado',
				due: 'Fecha límite',
				priority: 'Prioridad',
				responsible: 'Responsable'
			},
			action: {
				refresh: 'Actualizar',
				showMore: 'Mostrar más'
			},
			assign: {
				successMessage: 'La tarea se asignó con éxito',
				errorMessage: 'Al asignar la tarea se ha producido un error.'
			},
			emptyState: {
				heading: 'No se han encontrado tareas',
				message: 'Ajuste la configuración del filtro para encontrar algunas tareas.'
			},
			orderEmptyState: {
				heading: 'No se han encontrado tareas',
				message: 'No es necesario hacer nada para este pedido.'
			}
		},
		error: {
			generic: {
				heading: 'Algo salió mal',
				message: 'Se ha producido un error imprevisto que no se ha podido resolver',
				resolveAction: {
					label: 'Volver a cargar'
				}
			},
			offline: {
				heading: 'Conexión de red no disponible',
				message: 'Conéctese a una red para utilizar la aplicación.'
			},
			notFound: {
				heading: 'No encontrado',
				message: 'No se ha encontrado el recurso solicitado',
				resolveAction: {
					label: 'Panel de control'
				}
			},
			orderNotFound: 'Pedido no encontrado.',
			taskNotFound: 'Tarea no encontrada.',
			taskTypeNotFound: 'Tipo de tarea no encontrado: {{type}}',
			taskCompleteMessage: 'Se produjo un error al completar la tarea.',
			taskAssignMessage: 'Al asignar la tarea se ha producido un error.'
		}
	}
};

export default literals;
