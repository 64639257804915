import React from 'react';

import { buildCssClassNameFromEnumValue } from '@abb-emobility/shared/util';

import './Status.scss';

type StatusProps = {
	label: string,
	state: string
};

export function Status(props: StatusProps) {

	const { label, state } = props;

	return (
		<section className="status">
			<div className="status__icon">
				<div className={`status__icon__outer ${buildCssClassNameFromEnumValue(state, 'status__icon__outer')}`} />
				<div className={`status__icon__inner ${buildCssClassNameFromEnumValue(state, 'status__icon__inner')}`} />
			</div>
			<span className="status__label">
				{label}
			</span>
		</section>
	);
}
