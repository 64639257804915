import { AnyAction, combineReducers, configureStore, MiddlewareArray } from '@reduxjs/toolkit';

import {
	installationPartnerFiscalReportCollectionStoreName,
	installationPartnerFiscalReportCollectionSlice,
	installationPartnerOrderCollectionStoreName,
	installationPartnerOrderCollectionSlice,
	installationPartnerOrderEntityStoreName,
	installationPartnerOrderEntitySlice,
	installationPartnerOrderInformationCollectionStoreName,
	installationPartnerOrderInformationCollectionSlice,
	installationPartnerOrderInformationEntityStoreName,
	installationPartnerOrderInformationEntitySlice,
	orderInstallerAppointmentCollectionStoreName,
	orderInstallerAppointmentCollectionSlice
} from '@abb-emobility/oms/data-provider';
import { sentryPerformanceMiddleware, sentrySpanManagementMiddleware } from '@abb-emobility/shared/sentry-integration';
import {
	anyTaskEntityStoreName,
	anyTaskEntitySlice,
	taskCollectionSlice,
	taskCollectionStoreName,
	orderTaskCollectionStoreName,
	orderTaskCollectionSlice,
	anyTaskEntitySearchStoreName,
	anyTaskEntitySearchSlice
} from '@abb-emobility/usertask/data-provider';

const combinedReducer = combineReducers({
	[anyTaskEntityStoreName]: anyTaskEntitySlice.reducer,
	[anyTaskEntitySearchStoreName]: anyTaskEntitySearchSlice.reducer,
	[installationPartnerFiscalReportCollectionStoreName]: installationPartnerFiscalReportCollectionSlice.reducer,
	[installationPartnerOrderCollectionStoreName]: installationPartnerOrderCollectionSlice.reducer,
	[installationPartnerOrderInformationCollectionStoreName]: installationPartnerOrderInformationCollectionSlice.reducer,
	[installationPartnerOrderInformationEntityStoreName]: installationPartnerOrderInformationEntitySlice.reducer,
	[installationPartnerOrderEntityStoreName]: installationPartnerOrderEntitySlice.reducer,
	[taskCollectionStoreName]: taskCollectionSlice.reducer,
	[orderInstallerAppointmentCollectionStoreName]: orderInstallerAppointmentCollectionSlice.reducer,
	[orderTaskCollectionStoreName]: orderTaskCollectionSlice.reducer
});

export type RootState = ReturnType<typeof combinedReducer> | undefined;

export const UNAUTHENTICATE_SIGNAL = '@@unauth';

const rootReducer = (state: RootState, action: AnyAction) => {
	if (action.type === UNAUTHENTICATE_SIGNAL) {
		state = undefined;
	}
	return combinedReducer(state, action);
};

export const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		let middleware: MiddlewareArray<any> = getDefaultMiddleware();
		if (process.env['NX_SENTRY_ENABLED'] === 'true') {
			middleware = middleware.concat(sentrySpanManagementMiddleware).concat(sentryPerformanceMiddleware);
		}
		return middleware;
	}
});
