import React, { ReactNode, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { TaskActionStatus } from '@abb-emobility/shared/data-provider-foundation';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { EffectCallbackReturn } from '@abb-emobility/shared/react';
import {
	Actionbar,
	ActionbarItem,
	CollectionHead,
	CollectionHeadItem,
	CollectionItem,
	IconIdentifier,
	NotificationBig,
	NotificationBigStatus,
	Separator,
	SpinnerCircle,
	ToastLevel, useModalDialogueManager,
	useToastManager
} from '@abb-emobility/shared/ui-primitive';
import {
	OrderTaskCollectionDataAppear,
	OrderTaskCollectionDataInterceptEmpty,
	OrderTaskCollectionDataSuspense,
	useOrderTaskCollectionData
} from '@abb-emobility/usertask/data-provider';


import { TaskCollectionItem } from '../task-collection-item/TaskCollectionItem';
import { TaskModalAssembler } from '../task-modal/TaskModalAssembler';

export type OrderTaskCollectionProps = {
	orderId: ModelPrimaryKey
};

export function OrderTaskCollection(props: OrderTaskCollectionProps) {

	const { orderId } = props;

	const params = useParams();
	const l10n = useL10n();
	const toast = useToastManager();
	const modalDialogueManager = useModalDialogueManager();
	const orderTaskCollectionData = useOrderTaskCollectionData();
	const orderTasks = orderTaskCollectionData.query();

	// Open task from URL
	const taskId = params?.taskId ?? null;
	useEffect((): EffectCallbackReturn => {
		if (taskId !== null) {
			modalDialogueManager.push({
				content: (): ReactNode => {
					return (
						<TaskModalAssembler taskId={taskId} />
					);
				}
			});
			return modalDialogueManager.pop;
		}
	}, []);

	orderTaskCollectionData.useActionStatusEffect([TaskActionStatus.ASSIGN_FAILED], () => {
		toast.addToastEntry({
			label: l10n.translate('omsInstallationPartnerOfficeApp.taskCollection.assign.errorMessage'),
			level: ToastLevel.WARNING
		});
	}, true);

	orderTaskCollectionData.useActionStatusEffect([TaskActionStatus.ASSIGN_SUCCESS], () => {
		toast.addToastEntry({
			label: l10n.translate('omsInstallationPartnerOfficeApp.taskCollection.assign.successMessage'),
			level: ToastLevel.SUCCESS,
			autoDismiss: true
		});
	}, true);

	const handleRefresh = () => {
		orderTaskCollectionData.refetch();
	};

	const renderEmptyState = (): ReactNode => {
		return (
			<NotificationBig
				status={NotificationBigStatus.NORESULT}
				heading={l10n.translate('omsInstallationPartnerOfficeApp.taskCollection.orderEmptyState.heading')}
				message={l10n.translate('omsInstallationPartnerOfficeApp.taskCollection.orderEmptyState.message')}
			/>
		);
	};

	const renderItems = () => {
		return orderTasks.map((orderTask) => {
			return (
				<CollectionItem key={orderTask.id}>
					<TaskCollectionItem task={orderTask} />
				</CollectionItem>
			);
		});
	};

	return (
		<>
			<Actionbar label={l10n.translate('omsInstallationPartnerOfficeApp.taskCollection.heading')}>
				<ActionbarItem
					label={l10n.translate('omsInstallationPartnerOfficeApp.taskCollection.action.refresh')}
					icon={IconIdentifier.ARROW_CLOCKWISE}
					onTrigger={handleRefresh}
				/>
			</Actionbar>

			<Separator />

			<OrderTaskCollectionDataAppear orderId={orderId}>
				<OrderTaskCollectionDataSuspense pendingComponent={SpinnerCircle}>
					<OrderTaskCollectionDataInterceptEmpty emptyStateComponent={renderEmptyState}>
						<CollectionHead>
							<CollectionHeadItem label={l10n.translate('omsInstallationPartnerOfficeApp.taskCollection.tableHead.order')} />
							<CollectionHeadItem label={l10n.translate('omsInstallationPartnerOfficeApp.taskCollection.tableHead.status')} />
							<CollectionHeadItem label={l10n.translate('omsInstallationPartnerOfficeApp.taskCollection.tableHead.due')} />
							<CollectionHeadItem label={l10n.translate('omsInstallationPartnerOfficeApp.taskCollection.tableHead.priority')} />
							<CollectionHeadItem label={l10n.translate('omsInstallationPartnerOfficeApp.taskCollection.tableHead.responsible')} />
							{/* last item is used as an alignment util for the list item action */}
							<CollectionHeadItem />
						</CollectionHead>

						<Separator />

						{renderItems()}

					</OrderTaskCollectionDataInterceptEmpty>
				</OrderTaskCollectionDataSuspense>
			</OrderTaskCollectionDataAppear>
		</>
	);
}
