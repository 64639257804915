import './ActionbarFilterContentSeparator.scss';

export type ActionbarFilterContentSeparatorProps = {
	label: string
};

export function ActionbarFilterContentSeparator(props: ActionbarFilterContentSeparatorProps) {

	const { label } = props;

	return (
		<label className="filter-layout-content-separator">
			{label}
		</label>
	);
}
