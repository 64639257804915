import React from 'react';

import { useAuth } from '@abb-emobility/shared/auth-provider';
import { useCandidateGroups } from '@abb-emobility/shared/candidate-groups-provider';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { ExpandableProvider } from '@abb-emobility/shared/interaction';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	ButtonIcon,
	CollectionItemDate,
	CollectionItemHeader,
	CollectionItemHeaderItem,
	CollectionItemLabel,
	Icon,
	IconIdentifier,
	Status
} from '@abb-emobility/shared/ui-primitive';
import { buildCssClassNameFromEnumValue, l10nLiteralFromEnumValue } from '@abb-emobility/shared/util';
import { AnyTaskCollectionItemModel } from '@abb-emobility/usertask/domain-model';

import { CandidateGroupSelect } from '../candidate-group-select/CandidateGroupSelect';

import './TaskCollectionItem.scss';

type TaskCollectionItemHeaderProps = {
	task: AnyTaskCollectionItemModel,
	icon?: IconIdentifier,
	onAssign: (candidateGroupIds: Array<ModelPrimaryKey>) => void,
	onClick?: () => void,
	disabled?: boolean
};

export function TaskCollectionItemHeader(props: TaskCollectionItemHeaderProps) {

	const { task, icon = IconIdentifier.CARDS, onAssign, onClick = null, disabled = false } = props;

	const auth = useAuth();
	const l10n = useL10n();
	const candidateGroups = useCandidateGroups();

	const handleClick = () => {
		if (onClick !== null) {
			onClick();
		}
	};

	const handleCandidateGroupsSelect = (candidateGroupIds: Array<ModelPrimaryKey>): void => {
		onAssign(candidateGroupIds);
	};

	const renderInstallationAddress = (): string | undefined => {
		const address = task.installationAddress;
		// TODO: Remove the condition once the member is mandatory
		if ((address ?? null) === null) {
			return undefined;
		}
		// TODO: Remove the optional chaining once the member is mandatory
		return address?.zip + ' ' + address?.city;
	};

	return (
		<CollectionItemHeader>
			<CollectionItemHeaderItem>
				<CollectionItemLabel
					label={l10n.translate(l10nLiteralFromEnumValue(task.type, 'omsInstallationPartnerOfficeApp.task.displayName'))}
					orderId={task.marketplaceOrderId}
					additionalAttribute={renderInstallationAddress()}
					additionalIcon={IconIdentifier.MAP_PIN}
					disabled={disabled}
				/>
			</CollectionItemHeaderItem>

			<CollectionItemHeaderItem>
				<Status label={l10n.translate(l10nLiteralFromEnumValue(task.status, 'omsInstallationPartnerOfficeApp.task.status'))} state={task.status} />
			</CollectionItemHeaderItem>

			<CollectionItemHeaderItem>
				<CollectionItemDate date={l10n.formatTimestampDate(task.dueDate)} />
			</CollectionItemHeaderItem>

			<CollectionItemHeaderItem>
				<div className={`task-collection-item__tag ${buildCssClassNameFromEnumValue(task.priority, 'task-collection-item__tag')}`}>
							<span className={`task-collection-item__tag__label ${buildCssClassNameFromEnumValue(task.priority, 'task-collection-item__tag__label')}`}>
								{l10n.translate(l10nLiteralFromEnumValue(task.priority, 'omsInstallationPartnerOfficeApp.task.priority'))}
							</span>
					<span className={`task-collection-item__tag__icon ${buildCssClassNameFromEnumValue(task.priority, 'task-collection-item__tag__icon')}`} />
				</div>
			</CollectionItemHeaderItem>

			<ExpandableProvider>
				<CandidateGroupSelect
					availableCandidateGroups={candidateGroups.getAvailableCandidateGroups()}
					selectedCandidateGroupIds={task.candidateGroupIds ?? []}
					onSelect={handleCandidateGroupsSelect}
					disabled={!auth.hasRole('installation-partner') || disabled}
				/>
			</ExpandableProvider>

			<CollectionItemHeaderItem>
				<ButtonIcon disabled={onClick === null} onClick={handleClick}>
					<Icon name={icon} />
				</ButtonIcon>
			</CollectionItemHeaderItem>

		</CollectionItemHeader>
	);
}
