import React, { ReactNode, useEffect, useState } from 'react';

import { Choose, Hint, InputSelectOption } from '@abb-emobility/shared/ui-primitive';

type TaskChoiceProps<T> = {
	children?: ReactNode,
	label: string,
	hint?: boolean,
	isSelected?: boolean,
	onSelect: (value: T) => void,
	onDeselect: () => void,
	options?: Array<InputSelectOption>,
	value: T
};

export function TaskChoice<T>(props: TaskChoiceProps<T>) {
	const { children, hint, label, isSelected = false, options, value, onSelect, onDeselect } = props;

	const [selected, setSelected] = useState(isSelected);

	useEffect(() => {
		setSelected(isSelected);
	}, [isSelected]);

	const handleSelect = (userSelected: boolean) => {
		setSelected(userSelected);
		if (userSelected) {
			onSelect(value);
		} else {
			onDeselect();
		}
	};

	const renderHint = () => {
		if (hint) {
			return <Hint heading="Exspected installation time in hours" message="Lorem ipsum dolor sit amet consectetur adipisicing elit." />;
		}
		return;
	};

	const renderChildren = () => {
		if (selected) {
			return children;
		}
		return;
	};

	return (
		<>
			<Choose isSelected={selected} label={label} onSelect={handleSelect} options={options} />
			{renderHint()}
			{renderChildren()}
		</>
	);
}
