import React, { useEffect } from 'react';

import {
	InstallationPartnerOrderInformationCollectionDataProvider,
	OrderInstallerAppointmentCollectionDataProvider,
	useInstallationPartnerOrderEntityData
} from '@abb-emobility/oms/data-provider';
import { InstallationPartnerOrderModel } from '@abb-emobility/oms/domain-model';
import { OrderInformation, OrderLineItems } from '@abb-emobility/oms/ui-domain';
import { FetchMode } from '@abb-emobility/shared/data-provider-foundation';
import { createFullNameFromCustomer } from '@abb-emobility/shared/domain-model';
import { NotFoundError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { UiErrorHandler } from '@abb-emobility/shared/ui-error-handler';
import {
	Card,
	CardHeader,
	Cardlayout,
	CardSection,
	CardSectionItem,
	InformationHead,
	InformationHeadLayout,
	InformationHeadOrderInformation,
	SpinnerCircle,
	Status
} from '@abb-emobility/shared/ui-primitive';
import { isString, l10nLiteralFromEnumValue } from '@abb-emobility/shared/util';
import { OrderTaskCollectionDataProvider } from '@abb-emobility/usertask/data-provider';

import { Progress } from './progress/Progress';
import { InstallerAppointmentCollection } from '../installer-appointment-collection/InstallerAppointmentCollection';
import { OrderTaskCollectionDataAssembler } from '../order-task-collection/OrderTaskCollectionDataAssembler';

export type OrderDetailProps = {
	onQuery?: (order: InstallationPartnerOrderModel) => void
};

export function OrderDetail(props: OrderDetailProps) {

	const { onQuery } = props;

	const l10n = useL10n();

	const orderData = useInstallationPartnerOrderEntityData();
	const order = orderData.query().getOrThrow(new NotFoundError(l10n.translate('omsInstallationPartnerOfficeApp.error.orderNotFound')));

	useEffect(() => {
		if (onQuery !== undefined) {
			onQuery(order);
		}
	}, []);

	const renderInstallationAddress = (): string => {
		const address = order.installationAddress;
		if (isString(address)) {
			return address;
		}
		return address.street + ', ' + address.zip + ' ' + address.city;
	};

	return (
		<>
			{/*Order information*/}
			<InformationHeadLayout>
				<InformationHead
					informationKey={createFullNameFromCustomer(order.customer)}
					informationValue={l10n.translate(l10nLiteralFromEnumValue(order.installationType, 'omsInstallationPartnerOfficeApp.orderDetail.installationType'))}
				/>
				<InformationHeadOrderInformation orderDate={l10n.formatTimestampDate(order.orderDate)} orderNumber={order.marketplaceOrderId}>
					<Status label={l10n.translate(l10nLiteralFromEnumValue(order.state, 'omsInstallationPartnerOfficeApp.orderDetail.status'))} state={order.state} />
				</InformationHeadOrderInformation>
			</InformationHeadLayout>

			<Progress progress={order.progress} />

			{/*Customer information*/}
			<Card>
				<Cardlayout>
					<CardHeader heading={l10n.translate('omsInstallationPartnerOfficeApp.orderDetail.customerInformation.heading')} />
					<CardSection>
						<CardSectionItem
							label={l10n.translate('omsInstallationPartnerOfficeApp.orderDetail.customerInformation.address')}
							value={order.customer.address}
						/>
						<CardSectionItem
							label={l10n.translate('omsInstallationPartnerOfficeApp.orderDetail.customerInformation.phone')}
							value={order.customer.phoneNumber}
						/>
						<CardSectionItem
							label={l10n.translate('omsInstallationPartnerOfficeApp.orderDetail.customerInformation.mail')}
							value={order.customer.mailAddress}
							noLabelWrap={true}
						/>
					</CardSection>
				</Cardlayout>
			</Card>

			{/*Installation Address*/}
			{
				order.installationAddress &&
				<Card>
					<Cardlayout>
						<CardHeader heading={l10n.translate('omsInstallationPartnerOfficeApp.orderDetail.installationAddress.heading')} />
						<CardSection>
							<CardSectionItem
								label={l10n.translate('omsInstallationPartnerOfficeApp.orderDetail.installationAddress.address')}
								value={renderInstallationAddress()}
							/>
						</CardSection>
					</Cardlayout>
				</Card>
			}

			<OrderLineItems order={order} showProductCode={true} />

			{/*Order Task Collection*/}
			<UiErrorHandler>
				<OrderTaskCollectionDataProvider
					pendingComponent={SpinnerCircle}
					fetchMode={FetchMode.LAZY}
					orderId={order.id}
				>
					<OrderTaskCollectionDataAssembler orderId={order.id} />
				</OrderTaskCollectionDataProvider>
			</UiErrorHandler>

			{/*Installer Appointments*/}
			<UiErrorHandler>
				<OrderInstallerAppointmentCollectionDataProvider
					orderId={order.id}
					fetchMode={FetchMode.LAZY}
				>
					<InstallerAppointmentCollection orderId={order.id} />
				</OrderInstallerAppointmentCollectionDataProvider>
			</UiErrorHandler>

			{/*Order completed Tasks information*/}
			<UiErrorHandler>
				<InstallationPartnerOrderInformationCollectionDataProvider
					orderId={order.id}
					fetchMode={FetchMode.LAZY}
				>
					<OrderInformation orderId={order.id} />
				</InstallationPartnerOrderInformationCollectionDataProvider>
			</UiErrorHandler>
		</>
	);
}
