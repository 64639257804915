import React, { ChangeEvent, InputHTMLAttributes, useEffect, useRef, useState } from 'react';

import { Nullable } from '@abb-emobility/shared/util';

import { Icon } from '../icon/Icon';
import { IconIdentifier } from '../icon/Icon.enums';

import './Numeric-Stepper.scss';

export type NumericStepperType = {
	value?: number,
	step?: number,
	min?: number,
	max?: number,
	onChange?: (value: number) => void
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'>;

const calculateNullValue = (min: number): number => {
	return Math.max(min, 0);
};

export const NumericStepper = (props: NumericStepperType) => {

	const { value, min = Number.MIN_SAFE_INTEGER, max = Number.MAX_SAFE_INTEGER, step = 1, onChange, ...rest } = props;

	const [count, setCount] = useState<Nullable<number>>(value ?? calculateNullValue(min));
	const initialRender = useRef<boolean>(true);

	useEffect(() => {
		if (onChange === undefined) {
			return;
		}
		if (initialRender.current) {
			initialRender.current = false;
		} else {
			onChange(count ?? calculateNullValue(min));
		}
	}, [count]);

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		let value: Nullable<number> = event.target.valueAsNumber;
		if (isNaN(value)) {
			value = null;
		} else {
			value = Math.min(value, max);
			value = Math.max(value, min);
		}
		setCount(value);
	};

	const decrease = () => {
		setCount((prevCount) => {
			prevCount = prevCount ?? calculateNullValue(min);
			if (prevCount < Number.MIN_SAFE_INTEGER + step || prevCount < min + step) {
				return prevCount;
			}
			const value = prevCount - step;
			return value;
		});
	};

	const increase = () => {
		setCount((prevCount) => {
			prevCount = prevCount ?? calculateNullValue(min);
			if (prevCount > Number.MAX_SAFE_INTEGER - step || prevCount > max - step) {
				return prevCount;
			}
			const value = prevCount + step;
			return value;
		});
	};

	return (
		<div className="numeric-stepper">
			<button
				type="button"
				className="numeric-stepper__button"
				onClick={decrease}
			>
				<Icon name={IconIdentifier.MINUS} />
			</button>
			<span className="numeric-stepper__input">
			<input
				className="numeric-stepper__input__count"
				type="number"
				id="numeric-stepper-count"
				min={min}
				max={max}
				step={step}
				value={count ?? undefined}
				onChange={handleChange}
				{...rest}
			/>
			</span>
			<button
				type="button"
				onClick={increase}
				className="numeric-stepper__button"
			>
				<Icon name={IconIdentifier.PLUS} />
			</button>
		</div>
	);
};
