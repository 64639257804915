import { InstallationPeriod } from './InstallationPeriod';

export const sortableValueFromInstallationPeriod = (installationPeriod: InstallationPeriod): number => {
	switch (installationPeriod) {
		case InstallationPeriod.PREPARATION:
			return 0;
		case InstallationPeriod.BEGINNING:
			return 1;
		case InstallationPeriod.PERMANENT:
			return 2;
		case InstallationPeriod.END:
			return 3;
		case InstallationPeriod.FOLLOW_UP:
			return 4;
	}
};
