import { ReactNode } from 'react';

import { useL10n } from '@abb-emobility/shared/localization-provider';

import { Separator } from '../separator/Separator';

import './InformationHeadOrderInformation.scss';

export type InformationHeadOrderInformationProps = {
	orderDate: string,
	orderNumber: string,
	children?: ReactNode
};

export function InformationHeadOrderInformation(props: InformationHeadOrderInformationProps) {
	const { orderDate, orderNumber, children } = props;

	const l10n = useL10n();

	return (
		<article className="information-head-order-information">
			<span className="information-head-order-information__info">
				{l10n.translate('sharedUiPrimitive.informationHead.orderDate')}: {orderDate}
			</span>
			<Separator />
			<span className="information-head-order-information__info">
				{l10n.translate('sharedUiPrimitive.informationHead.orderNumber')}: {orderNumber}
			</span>
			<span className="information-head-order-information__info">
				{children}
			</span>
		</article>
	);
}
