import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	usertaskDataProvider: {
		error: {
			customerCollectionFetchMessage : 'Une erreur s\'est produite lors du chargement des données clients',
			customerEntityFetchMessage : 'Une erreur s\'est produite lors du chargement des données relatives aux clients',
			taskFetchMessage : 'Une erreur s\'est produite lors du chargement des données relatives à la tâche',
			taskSearchMessage : 'Une erreur s\'est produite lors de la recherche d\'une tâche',
			taskCollectionFetchMessage : 'Une erreur s\'est produite lors du chargement des données relatives à la tâche',
			orderFetchMessage : 'Une erreur s\'est produite lors du chargement des détails de la commande',
			orderCollectionFetchMessage : 'Une erreur s\'est produite lors du chargement des données de la commande',
			orderEventCollectionFetchMessage : 'Une erreur s\'est produite lors du chargement de l\'historique de la commande',
			orderEventEntityFetchMessage : 'Une erreur s\'est produite lors du chargement de l\'événement de la commande',
			orderShipmentCollectionFetchMessage : 'Une erreur s\'est produite lors du chargement des données relatives à l\'expédition',
			orderAccountingCollectionFetchMessage : 'Une erreur s\'est produite lors du chargement des données relatives au compte',
			orderFilesCollectionFetchMessage : 'Une erreur s\'est produite lors du chargement des fichiers',
			orderInstallationCollectionFetchMessage : 'Une erreur s\'est produite lors du chargement des données d\'installation',
			orderNotesCollectionFetchMessage : 'Une erreur s\'est produite lors du chargement des notes',
			orderInformationCollectionFetchMessage : 'Une erreur s\'est produite lors du chargement des informations relatives à la commande',
			installerAppointmentCollectionFetchMessage : 'Une erreur s\'est produite lors du chargement des rendez-vous',
			reportCollectionFetchMessage : 'Une erreur s\'est produite lors du chargement des rapports',
			searchCollectionFetchMessage : 'Une erreur s\'est produite lors de la recherche de données',
			orderPaymentCollectionFetchMessage : 'Une erreur s\'est produite lors du chargement des données de paiement',
			orderInformationEntityFetchMessage : 'Une erreur s\'est produite lors du chargement des informations relatives à la commande.'
		}
	}
};

export default literals;
