import React, { useRef } from 'react';

import { SortCriteria, SortDirection } from '@abb-emobility/shared/api-integration-foundation';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { useForceRerender } from '@abb-emobility/shared/react';
import { Nullable } from '@abb-emobility/shared/util';

import { CollectionSortDirection } from './CollectionSortDirection';
import { ActionbarFilter } from '../actionbar/actionbar-filter/ActionbarFilter';
import { ActionbarFilterContent } from '../actionbar/actionbar-filter/actionbar-filter-content/ActionbarFilterContent';
import {
	ActionbarFilterContentFooter
} from '../actionbar/actionbar-filter/actionbar-filter-content/actionbar-filter-content-footer/ActionbarFilterContentFooter';
import {
	ActionbarFilterContentItems
} from '../actionbar/actionbar-filter/actionbar-filter-content/actionbar-filter-content-items/ActionbarFilterContentItems';
import { ActionbarFilterHeader } from '../actionbar/actionbar-filter/actionbar-filter-header/ActionbarFilterHeader';
import { IconIdentifier } from '../icon/Icon.enums';
import { InputRadio } from '../input-radio/InputRadio';

type SortProps<Model> = {
	sortCriteria?: SortCriteria<Model>,
	sortOptions: Array<keyof Model>,
	implicitSortOption?: keyof Model,
	onApply: (sortCriteria: SortCriteria<Model>) => void
};

export function CollectionSort<Model>(props: SortProps<Model>) {

	const { sortCriteria, sortOptions, implicitSortOption, onApply } = props;

	const l10n = useL10n();
	const rerender = useForceRerender();

	const effectiveSortProperty = sortCriteria?.[0].property ?? null;
	const effectiveSortDirection = sortCriteria?.[0]?.direction ?? SortDirection.ASCENDING;

	const selectionChanged = useRef<boolean>(false);
	const selectedSortProperty = useRef<Nullable<keyof Model>>(effectiveSortProperty);
	const selectedSortDirection = useRef<Nullable<SortDirection>>(effectiveSortDirection);

	React.useEffect(() => {
		selectedSortProperty.current = sortCriteria?.[0].property ?? null;
		selectedSortDirection.current = sortCriteria?.[0]?.direction ?? SortDirection.ASCENDING;
		rerender.invoke();
	}, [sortCriteria]);

	const handleOpen = () => {
		selectionChanged.current = false;
	};

	const handlePropertyChange = (property: keyof Model) => {
		if (selectedSortProperty.current !== property) {
			selectionChanged.current = true;
		}
		selectedSortProperty.current = property;
	};

	const handleDirectionChange = (direction: SortDirection) => {
		if (selectedSortDirection.current !== direction) {
			selectionChanged.current = true;
		}
		selectedSortDirection.current = direction;
	};

	const handleApply = () => {
		if (!selectionChanged.current || selectedSortProperty.current === null) {
			return;
		}
		const selectedSortCriteria: SortCriteria<Model> = [
			{ property: selectedSortProperty.current, direction: selectedSortDirection.current ?? SortDirection.ASCENDING }
		];

		if (implicitSortOption !== undefined) {
			if (selectedSortProperty.current !== implicitSortOption) {
				selectedSortCriteria.push(
					{ property: implicitSortOption, direction: selectedSortDirection.current ?? SortDirection.ASCENDING }
				);
			}
		}

		onApply(selectedSortCriteria);
	};

	const renderPropertyOptions = () => {
		return sortOptions.map((sortOption) => {
			return (
				<InputRadio
					defaultChecked={selectedSortProperty.current === sortOption}
					name="sort"
					value={sortOption as string}
					label={l10n.translate('sharedUiPrimitive.collection.sort.options.' + (sortOption as string))}
					onChange={() => handlePropertyChange(sortOption)}
					key={sortOption as string}
				/>
			);
		});
	};

	const renderDirectionIcon = () => {
		if (effectiveSortDirection === SortDirection.DESCENDING) {
			return IconIdentifier.SORT_DESCENDING;
		}
		return IconIdentifier.SORT_ASCENDING;
	};

	let sortCriteriaDisplayValue = '…';
	if (effectiveSortProperty !== null) {
		sortCriteriaDisplayValue = l10n.translate('sharedUiPrimitive.collection.sort.options.' + (effectiveSortProperty as string));
	}

	return (
		<ActionbarFilter>
			<ActionbarFilterHeader
				label={l10n.translate('sharedUiPrimitive.collection.sort.sortBy')}
				value={sortCriteriaDisplayValue}
				icon={renderDirectionIcon()}
				onOpen={handleOpen}
			/>

			<ActionbarFilterContent onClose={handleApply} key={String(rerender.key())}>

				<ActionbarFilterContentItems>
					{renderPropertyOptions()}
				</ActionbarFilterContentItems>

				<ActionbarFilterContentFooter>
					<CollectionSortDirection
						direction={selectedSortDirection.current ?? SortDirection.ASCENDING}
						onChange={handleDirectionChange}
					/>
				</ActionbarFilterContentFooter>

			</ActionbarFilterContent>
		</ActionbarFilter>
	);
}
