import { ReactNode } from 'react';

import './CollectionItemHeader.scss';

export type CollectionItemHeaderProps = {
	children: ReactNode,
	disabled?: boolean
};

export function CollectionItemHeader(props: CollectionItemHeaderProps) {
	const { children, disabled } = props;

	return (
		<article className={`collection-item-header ${disabled ? 'collection-item-header--disabled' : ''}`}>
			{children}
		</article>
	);
}
