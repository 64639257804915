import React, { ReactNode, useEffect, useState } from 'react';

import { addLocalStorageListener, removeLocalStorageListener } from '@abb-emobility/shared/local-storage';

import { candidateGroupContext } from './CandidateGroupContext';
import { CandidateGroupHandler } from './CandidateGroupHandler';
import { CandidateGroup } from '../foundation/CandidateGroup';

export type CandidateGroupProviderProps = {
	children: ReactNode,
	candidateGroupScope?: string
};

export const CandidateGroupProvider = (props: CandidateGroupProviderProps): React.JSX.Element => {

	const { children, candidateGroupScope } = props;

	const candidateGroupHandler = CandidateGroupHandler.get(candidateGroupScope);

	const [candidateGroups, setCandidateGroups] = useState<Array<CandidateGroup>>(candidateGroupHandler.getCandidateGroups());

	useEffect(() => {
		addLocalStorageListener(handleStorageChange);
		return () => {
			removeLocalStorageListener(handleStorageChange);
		};
	}, []);

	const handleStorageChange = () => {
		setCandidateGroups(candidateGroupHandler.getCandidateGroups());
	};

	const contextValue = {
		isPresent: (): boolean => {
			return true;
		},
		setAvailableCandidateGroups: (candidateGroups: Array<CandidateGroup>): void => {
			candidateGroupHandler.setCandidateGroups(candidateGroups);
			setCandidateGroups(candidateGroups);
		},
		unsetAvailableCandidateGroups: (): void => {
			candidateGroupHandler.unsetCandidateGroups();
			setCandidateGroups([]);
		},
		getAvailableCandidateGroups: (): Array<CandidateGroup> => {
			return candidateGroups;
		}
	};

	return (
		<candidateGroupContext.Provider value={contextValue}>
			{children}
		</candidateGroupContext.Provider>
	);

};
