import { InstallationPartnerOrderModel } from '@abb-emobility/oms/domain-model';
import {
	FilterCriteria,
	FilterCriteriaEntry,
	FilterCriteriaRuleValue,
	MultiValueFilterComparator,
	SingleValueFilterComparator,
	ValueFilterCriteriaRule
} from '@abb-emobility/shared/api-integration-foundation';
import { createTimestampDtoFromDate } from '@abb-emobility/shared/domain-model-foundation';
import { FilterOptionGroup } from '@abb-emobility/shared/ui-primitive';
import { getCalendarDay, getToday, Nullable } from '@abb-emobility/shared/util';

export enum OrderFilterOption {
	STATUS_DONE = 'STATUS_DONE',
	STATUS_OPEN = 'STATUS_OPEN',
	INSTALLATION_TYPE_STANDARD = 'INSTALLATION_TYPE_STANDARD',
	INSTALLATION_TYPE_DELTA = 'INSTALLATION_TYPE_DELTA',
	INSTALLATION_TYPE_UNDETERMINED = 'INSTALLATION_TYPE_UNDETERMINED',
	INSTALLATION_TYPE_CANCELLED = 'INSTALLATION_TYPE_CANCELLED',
	DUE_DATE_OVERDUE = 'DUE_DATE_OVERDUE',
	DUE_DATE_TODAY = 'DUE_DATE_TODAY'
}

const estimatedCompletionDateFilterOptions = [
	OrderFilterOption.DUE_DATE_OVERDUE,
	OrderFilterOption.DUE_DATE_TODAY
];

const statusFilterOptions = [
	OrderFilterOption.STATUS_DONE,
	OrderFilterOption.STATUS_OPEN
];

const installationTypeOptions = [
	OrderFilterOption.INSTALLATION_TYPE_STANDARD,
	OrderFilterOption.INSTALLATION_TYPE_DELTA,
	OrderFilterOption.INSTALLATION_TYPE_UNDETERMINED,
	OrderFilterOption.INSTALLATION_TYPE_CANCELLED
];

export const orderFilterOptionGroups: Array<FilterOptionGroup<OrderFilterOption, InstallationPartnerOrderModel>> = [
	{
		property: 'estimatedCompletionDate',
		options: estimatedCompletionDateFilterOptions,
		buildFilterCriteria: (selectedOptions: Array<FilterCriteriaRuleValue>): Nullable<FilterCriteriaEntry<InstallationPartnerOrderModel>> => {
			selectedOptions = selectedOptions.filter((selectedOption) => {
				return estimatedCompletionDateFilterOptions.includes(selectedOption as OrderFilterOption);
			});
			if (selectedOptions.length === 0) {
				return null;
			}
			let criteriaId = '';
			let criteria: Array<ValueFilterCriteriaRule<InstallationPartnerOrderModel>> = [];
			if (selectedOptions.length === 1) {
				const selectedOption = selectedOptions[0];
				if (selectedOption === OrderFilterOption.DUE_DATE_OVERDUE) {
					criteriaId = OrderFilterOption.DUE_DATE_OVERDUE;
					criteria = [
						{
							property: 'estimatedCompletionDate',
							comparator: SingleValueFilterComparator.LESS_THAN,
							value: createTimestampDtoFromDate(getToday())
						}
					];
				}
				if (selectedOption === OrderFilterOption.DUE_DATE_TODAY) {
					criteriaId = OrderFilterOption.DUE_DATE_TODAY;
					criteria = [
						{
							property: 'estimatedCompletionDate',
							comparator: SingleValueFilterComparator.GREATER_THAN,
							value: createTimestampDtoFromDate(getToday())
						},
						{
							property: 'estimatedCompletionDate',
							comparator: SingleValueFilterComparator.LESS_THAN,
							value: createTimestampDtoFromDate(getCalendarDay(1))
						}
					];
				}
			} else {
				criteriaId = OrderFilterOption.DUE_DATE_TODAY + ':' + OrderFilterOption.DUE_DATE_OVERDUE;
				criteria = [
					{
						property: 'estimatedCompletionDate',
						comparator: SingleValueFilterComparator.LESS_THAN,
						value: createTimestampDtoFromDate(getCalendarDay(1))
					}
				];
			}
			return {
				id: criteriaId,
				criteria
			};
		},
		buildFilterOptions: <Model extends InstallationPartnerOrderModel>(filterCriteria: FilterCriteria<Model>): Array<OrderFilterOption> => {
			let filterOptions: Array<OrderFilterOption> = [];
			for (const filterCriterion of filterCriteria) {
				filterOptions = [...filterOptions, ...(filterCriterion.id.split(':') as Array<OrderFilterOption>)];
			}
			return filterOptions.filter((taskFilterOption) => {
				return estimatedCompletionDateFilterOptions.includes(taskFilterOption);
			});
		}
	},
	{
		property: 'state',
		options: statusFilterOptions,
		buildFilterCriteria: (selectedOptions: Array<FilterCriteriaRuleValue>): Nullable<FilterCriteriaEntry<InstallationPartnerOrderModel>> => {
			selectedOptions = selectedOptions.filter((selectedOption) => {
				return statusFilterOptions.includes(selectedOption as OrderFilterOption);
			});
			if (selectedOptions.length === 0) {
				return null;
			}
			let criteriaId: string;
			let criteria: Array<ValueFilterCriteriaRule<InstallationPartnerOrderModel>>;
			if (selectedOptions.length === 1) {
				const selectedOption = selectedOptions[0];
				criteriaId = selectedOption as string;
				criteria = [
					{
						property: 'state',
						comparator: SingleValueFilterComparator.EQUAL,
						value: selectedOption
					}
				];
			} else {
				criteriaId = selectedOptions.join(':');
				criteria = [
					{
						property: 'state',
						comparator: MultiValueFilterComparator.ONE_OF,
						value: selectedOptions
					}
				];
			}
			return {
				id: criteriaId,
				criteria
			};
		},
		buildFilterOptions: <Model extends InstallationPartnerOrderModel>(filterCriteria: FilterCriteria<Model>): Array<OrderFilterOption> => {
			let filterOptions: Array<OrderFilterOption> = [];
			for (const filterCriterion of filterCriteria) {
				filterOptions = [...filterOptions, ...(filterCriterion.id.split(':') as Array<OrderFilterOption>)];
			}
			return filterOptions.filter((taskFilterOption) => {
				return statusFilterOptions.includes(taskFilterOption);
			});
		}
	},
	{
		property: 'installationType',
		options: installationTypeOptions,
		buildFilterCriteria: (selectedOptions: Array<FilterCriteriaRuleValue>): Nullable<FilterCriteriaEntry<InstallationPartnerOrderModel>> => {
			selectedOptions = selectedOptions.filter((selectedOption) => {
				return installationTypeOptions.includes(selectedOption as OrderFilterOption);
			});
			if (selectedOptions.length === 0) {
				return null;
			}
			let criteriaId: string;
			let criteria: Array<ValueFilterCriteriaRule<InstallationPartnerOrderModel>>;
			if (selectedOptions.length === 1) {
				const selectedOption = selectedOptions[0];
				criteriaId = selectedOption as string;
				criteria = [
					{
						property: 'installationType',
						comparator: SingleValueFilterComparator.EQUAL,
						value: selectedOption
					}
				];
			} else {
				criteriaId = selectedOptions.join(':');
				criteria = [
					{
						property: 'installationType',
						comparator: MultiValueFilterComparator.ONE_OF,
						value: selectedOptions
					}
				];
			}
			return {
				id: criteriaId,
				criteria
			};
		},
		buildFilterOptions: <Model extends InstallationPartnerOrderModel>(filterCriteria: FilterCriteria<Model>): Array<OrderFilterOption> => {
			let filterOptions: Array<OrderFilterOption> = [];
			for (const filterCriterion of filterCriteria) {
				filterOptions = [...filterOptions, ...(filterCriterion.id.split(':') as Array<OrderFilterOption>)];
			}
			return filterOptions.filter((filterOption) => {
				return installationTypeOptions.includes(filterOption);
			});
		}
	}
];
