import { ReactNode } from 'react';

import './OrderCommentCollection.scss';

type OrderInformationCommentCollectionProps = {
	children: ReactNode
};

export function OrderCommentCollection(props: OrderInformationCommentCollectionProps) {

	const { children } = props;

	return (
		<section className="order-comment-collection">
			{children}
		</section>
	);
}
