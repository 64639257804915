import React, { useEffect, useRef, useState } from 'react';

import { InstallationPartnerOrderApiClientFactory } from '@abb-emobility/oms/api-integration';
import {
	WebsocketEvent as OmsWebsocketEvent,
	WebsocketMessage as OmsWebsocketMessage,
	WebsocketTopic as OmsWebsocketTopic
} from '@abb-emobility/oms/websocket';
import { createAccessTokenLoader, useAuth } from '@abb-emobility/shared/auth-provider';
import { useEnv } from '@abb-emobility/shared/environment';
import { AppError } from '@abb-emobility/shared/error';
import { useIdentity } from '@abb-emobility/shared/identity-provider';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	AppLayout,
	AppLayoutMain,
	AppLayoutMainContent,
	AppLayoutMainHeader,
	AppLayoutNavigation,
	BreadcrumbFactory,
	Breadcrumbs,
	IconIdentifier,
	NavigationFooter,
	NavigationItem,
	NavigationItems,
	NavigationSubItem,
	Topbar,
	TopbarBreadcrumb,
	TopbarSection,
	TopbarUser
} from '@abb-emobility/shared/ui-primitive';
import { Nullable, Optional } from '@abb-emobility/shared/util';
import { useWebsocket } from '@abb-emobility/shared/websocket';
import { AnyTaskCollectionApiClientFactory } from '@abb-emobility/usertask/api-integration';
import {
	WebsocketEvent as UsertaskWebsocketEvent,
	WebsocketMessage as UsertaskWebsocketMessage,
	WebsocketTopic as UsertaskWebsocketTopic
} from '@abb-emobility/usertask/websocket';

import { Router } from '../../router/Router';
import { RoutePath, useRouteUrl } from '../../router/Routes';

export function AppContainer() {

	const env = useEnv();
	const auth = useAuth();
	const identity = useIdentity();
	const l10n = useL10n();
	const { createRouteUrl } = useRouteUrl();

	const websocket = useWebsocket<OmsWebsocketTopic | UsertaskWebsocketTopic, OmsWebsocketMessage | UsertaskWebsocketMessage>();
	const websocketSubscriptionId = useRef<Nullable<string>>(null);
	const orderSubscriptionId = useRef<Nullable<string>>(null);

	const [taskCount, setTaskCount] = useState<Nullable<number>>(null);
	const [orderCount, setOrderCount] = useState<Nullable<number>>(null);

	const handleLogout = () => {
		auth.unauthenticate();
	};

	const updateTaskCount = async (): Promise<void> => {
		const apiBaseUrl = new Optional(process.env['NX_USER_TASK_MANAGEMENT_API_BASE_URL'])
			.getOrThrow(new AppError('Could read userTaskManagementApiBaseUrl from env'));
		try {
			const accessTokenLoader = createAccessTokenLoader(auth, env);
			const response = await AnyTaskCollectionApiClientFactory
				.create(apiBaseUrl, await accessTokenLoader())
				.headCollection();
			setTaskCount(response.totalItems ?? null);
		} catch (e) {
			console.warn(e);
		}
	};

	const updateOrderCount = async (): Promise<void> => {
		const apiBaseUrl = new Optional(process.env['NX_WALLBOX_INSTALLATION_API_BASE_URL'])
			.getOrThrow(new AppError('Could read wallboxInstallationApiBaseUrl from env'));
		try {
			const accessTokenLoader = createAccessTokenLoader(auth, env);
			const response = await InstallationPartnerOrderApiClientFactory
				.create(apiBaseUrl, await accessTokenLoader())
				.headCollection();
			setOrderCount(response.totalItems ?? null);
		} catch (e) {
			console.warn(e);
		}
	};

	useEffect(() => {
		void updateTaskCount();
		void updateOrderCount();

		if (websocket !== null) {
			if (websocketSubscriptionId.current === null) {
				websocketSubscriptionId.current = websocket.subscribe(UsertaskWebsocketTopic.INSTALLATION_PARTNER_TASK, (message): void => {
					if (message.event === UsertaskWebsocketEvent.TASK_ASSIGNED || message.event === UsertaskWebsocketEvent.TASK_COMPLETED) {
						void updateTaskCount();
					}
				});
			}
			if (orderSubscriptionId.current === null) {
				orderSubscriptionId.current = websocket.subscribe(OmsWebsocketTopic.ORDER, (message): void => {
					if (message.event === OmsWebsocketEvent.CREATED || message.event === OmsWebsocketEvent.DELETED) {
						void updateOrderCount();
					}
				});
			}
		}

		return () => {
			if (websocket !== null) {
				if (websocketSubscriptionId.current !== null) {
					websocket.unsubscribe(UsertaskWebsocketTopic.INSTALLATION_PARTNER_TASK, websocketSubscriptionId.current);
					websocketSubscriptionId.current = null;
				}
				if (orderSubscriptionId.current !== null) {
					websocket.unsubscribe(OmsWebsocketTopic.ORDER, orderSubscriptionId.current);
					orderSubscriptionId.current = null;
				}
			}
		};
	}, [websocket]);

	const taskPill = (taskCount !== null && taskCount > 0) ? l10n.formatNumber(taskCount, 0) : undefined;
	const orderPill = (orderCount !== null && orderCount > 0) ? l10n.formatNumber(orderCount, 0) : undefined;

	return (
		<AppLayout>
			<AppLayoutNavigation>
				<NavigationItems>
					<NavigationItem
						to={createRouteUrl(RoutePath.TASKS)}
						icon={IconIdentifier.CHECK}
						pill={taskPill}
						label={l10n.translate('omsInstallationPartnerOfficeApp.navigation.dashboard')}
						highlightPaths={[createRouteUrl(RoutePath.TASKS), createRouteUrl(RoutePath.TASK)]}
					/>
					<NavigationItem
						to={createRouteUrl(RoutePath.ORDERS)}
						icon={IconIdentifier.CLIPBOARD}
						pill={orderPill}
						label={l10n.translate('omsInstallationPartnerOfficeApp.navigation.orders')}
						highlightPaths={[
							createRouteUrl(RoutePath.ORDERS),
							createRouteUrl(RoutePath.ORDER),
							createRouteUrl(RoutePath.ORDER_TASK)
						]}
					/>
				</NavigationItems>
				<NavigationFooter>
					<NavigationSubItem
						onClick={handleLogout}
						icon={IconIdentifier.SIGN_OUT}
						label={l10n.translate('omsInstallationPartnerOfficeApp.navigation.logout')}
					/>
				</NavigationFooter>
			</AppLayoutNavigation>
			<Breadcrumbs>
				<AppLayoutMain>
					<AppLayoutMainHeader>
						<Topbar>
							<TopbarSection>
								<TopbarBreadcrumb>
									<BreadcrumbFactory />
								</TopbarBreadcrumb>
							</TopbarSection>
							<TopbarSection>
								<TopbarUser
									name={identity.getIdentity().get()?.fullname}
									mailAddress={identity.getIdentity().get()?.mailAddress}
								/>
							</TopbarSection>
						</Topbar>
					</AppLayoutMainHeader>
					<AppLayoutMainContent>
						<Router />
					</AppLayoutMainContent>
				</AppLayoutMain>
			</Breadcrumbs>
		</AppLayout>
	);
}
