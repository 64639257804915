import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	omsInstallationPartnerOfficeApp: {
		action: {
			cancelOrderAppointments: 'Avboka möten'
		},
		actionItemFactory: {
			cancelOrderAppointmentsLabel: 'Avboka möten'
		},
		actionModalFactory: {
			cancelOrderAppointmentsHeading: 'Avboka ordermöten'
		},
		actionModal: {
			cancelOrderAppointments: {
				content: {
					notificationHeading: 'Avboka installationsmöten.',
					notificationMessage: 'Alla installationsmöten kommer att avbokas.',
					successHeading: 'Avbokade möten',
					successCloseButtonLabel: 'Stäng',
					reason: 'Orsak'
				},
				cancelReasonValue: {
					deliveryDelay: 'Leveransförsening',
					installationPartnerConflict: 'Konflikt med installationspartner',
					customerConflict: 'Kundkonflikt',
					replacement: 'Byte'
				},
				footer: {
					confirm: 'Bekräfta avbokning',
					cancel: 'Avbryt'
				},
				error: {
					heading: 'Kunde inte avboka möten.',
					message: 'Det uppstod ett fel vid radering av ordermöten.'
				}
			}
		},
		appName: 'Installationskontor för ABB-laddbox',
		pageTitle: {
			dashboard: 'Översikt',
			error: 'Fel',
			notFound: 'Hittades inte',
			orders: 'Ordrar',
			order: 'Order från {{displayName}}'
		},
		navigation: {
			dashboard: 'Översikt',
			orders: 'Ordrar',
			logout: 'Logga ut'
		},
		breadcrumbs: {
			tasks: 'Översikt över uppgiften',
			orders: 'Översikt över beställning'
		},
		viewHeader: {
			tasks: 'Översikt över uppgiften',
			orders: 'Översikt över beställningar'
		},
		installerAppointmentCollection: {
			heading: 'Planerade installationer',
			emptyState: {
				heading: 'Inga planerade installationer',
				message: 'Ordern har inga planerade installationer.'
			}
		},
		orderCollection: {
			heading: 'Ordrar',
			tableHead: {
				order: 'Order',
				status: 'Status',
				estimated: 'Beräknat installationsdatum',
				progress: 'Framsteg'
			},
			emptyState: {
				heading: 'Inga ordrar hittades',
				message: 'Justera dina filterinställningar för att hitta några ordrar.'
			},
			action: {
				refresh: 'Uppdatera',
				showMore: 'Visa mer'
			},
			filter: {
				filter: 'Filter',
				none: 'inga',
				active: '{{count}} aktiva',
				resetFilter: 'Återställ filter',
				options: {
					dueDateOverdue: 'Fördröjd',
					dueDateToday: 'Förfaller idag',
					priorityHigh: 'Hög prioritet',
					priorityMedium: 'Medelhög',
					priorityLow: 'Låg prioritet',
					statusDone: 'Klar',
					statusOpen: 'Öppen',
					installationTypeStandard: 'Standardinstallation',
					installationTypeDelta: 'Delta-installation',
					installationTypeUndetermined: 'Ej fastställd'
				}
			}
		},
		orderDetail: {
			progress: {
				heading: 'Framsteg'
			},
			customerInformation: {
				heading: 'Kundinformation',
				address: 'Adress',
				phone: 'Tel',
				mail: 'E-post'
			},
			installationAddress: {
				heading: 'Installationsadress',
				address: 'Adress'
			},
			status: {
				onSchedule: 'Enligt schema',
				delayed: 'Fördröjd',
				cancelPending: 'Avbryt väntande',
				cancelled: 'Avbruten',
				done: 'Klar'
			},
			installationType: {
				none: 'Ej fastställd',
				undetermined: 'Ej fastställd',
				cancelled: 'Avbokad',
				standard: 'Standard',
				delta: 'Delta'
			}
		},
		task: {
			progressStepShortName: {
				installationPartnerEvaluateBasicAnswers: 'Grundläggande kontroll',
				installationPartnerArrangeAppointment: 'Möte',
				installationPartnerExecuteRemotePreCheck: 'Slutkontroll',
				installationPartnerRegisterAtGridOperator: 'Registrering av nätoperatör',
				installationPartnerProvideGridOperatorDecision: 'Beslut från nätoperatör',
				installationPartnerRevokeGridOperatorRegistration: 'Nekande av nätoperatör',
				installationPartnerProvideQuotation: 'Erbjudande',
				installationPartnerCompleteInstallation: 'Installation'
			},
			displayName: {
				installationPartnerEvaluateBasicAnswers: 'Grundläggande information',
				installationPartnerArrangeAppointment: 'Boka tid för möte',
				installationPartnerExecuteRemotePreCheck: 'Inledande kontroll',
				installationPartnerRegisterAtGridOperator: 'Registrering av nätoperatör',
				installationPartnerProvideGridOperatorDecision: 'Beslut från nätoperatör',
				installationPartnerRevokeGridOperatorRegistration: 'Nekande av registrering av nätoperatör',
				installationPartnerProvideQuotation: 'Erbjudande',
				installationPartnerCompleteInstallation: 'Slutförande av installationen'
			},
			status: {
				started: 'Kommande',
				dueToday: 'Förfaller idag',
				overdue: 'Förfallen',
				escalated: 'Eskalerad',
				done: 'Klar',
				doneOverdue: 'Klar',
				doneEscalated: 'Klar'
			},
			priority: {
				low: 'Låg',
				medium: 'Medel',
				high: 'Hög'
			},
			assign: {
				successMessage: 'Uppgiften har tilldelats'
			},
			completion: {
				successHeading: 'Uppgiften är slutförd',
				closeButtonLabel: 'Stäng'
			},
			component: {
				taskTradeAppointmentCraft: {
					hint: {
						invalid: 'En eller flera av inställningarna för avtal och arbete är ofullständiga eller ogiltiga och kommer inte att lämnas in.'
					},
					estimatedEffort: 'Beräknad arbetsinsats',
					selectedEffort: 'Vald',
					installationPeriod: {
						preparation: 'som förberedelse för installation',
						beginning: 'i början av installationen',
						permanent: 'under installationen',
						end: 'i slutet av installationen',
						followUp: 'efter installationen'
					},
					unit: {
						hours: 'timmar'
					},
					button: {
						add: 'Lägg till fler dagar'
					}
				},
				taskTradeAppointmentCraftItem: {
					input: {
						installer: 'Installatör',
						date: 'Datum',
						start: 'Start',
						end: 'Slut'
					}
				},
				taskTradeAppointmentEffort: {
					heading: 'Förväntade installationsavtal och arbetsinsats',
					hint: {
						invalid: 'En eller flera av inställningarna för avtal och arbete är ofullständiga eller ogiltiga och kommer inte att lämnas in.',
						notApprovable: 'Inget avtal som kan accepteras har fastställts. Minst ett avtal måste vara klart för godkännande för att installationen ska kunna slutföras.'
					},
					button: {
						add: 'Lägg till avtal'
					}
				},
				taskTradeAppointmentEffortItem: {
					input: {
						trade: 'Avtal',
						period: 'Period',
						effort: 'Arbetsinsats',
						canComplete: 'kan slutföra'
					},
					installationPeriod: {
						preparation: 'Förberedelser för installation',
						beginning: 'Början av installationen',
						permanent: 'Permanent',
						end: 'Slut på installationen',
						followUp: 'Uppföljning av installationen'
					}
				},
				taskChoice: {
					decision: {
						standard: 'Information tillräcklig – standardinstallation',
						delta: 'Information tillräcklig – deltainstallation',
						preCheckRequired: 'Mer information behövs',
						unfeasible: 'Installation ej möjlig',
						project: 'Installation som ett separat projekt möjligt'
					},
					unfeasibleInstallationReasons: {
						label: 'Välj en anledning',
						noPotentialRailAvailable: 'Ingen potentialskena tillgänglig',
						overvoltageProtectionUnfeasible: 'Överspänningsskydd kan inte installeras',
						spaceForCableOutletMissing: 'Inget utrymme för extra kabeluttag',
						houseConnectionUndersized: 'Husanslutning underdimensionerad',
						noThreePhaseAvailable: 'Ingen trefasström i huset (1 fas)',
						outdatedMeterCabinet: 'Föråldrat mätarskåp',
						notTabCompliant: 'Överensstämmer inte med TAB',
						other: 'Annat skäl'
					},
					projectInstallationReasons: {
						label: 'Välj en anledning',
						condominiumOwnersAssociation: 'Ägarlägenheter (tillstånd krävs)',
						highPowerEvu: 'Begär 22 KW, EVU-utmaningar',
						undergroundGarageInstallation: 'Installation i underjordiskt garage',
						fireProtectionIssues: 'Utmaningar inom brandskydd',
						multipleWallboxManagement: 'Lasthantering krävs på grund av flera laddboxar',
						other: 'Annat skäl',
						hint: {
							heading: 'Om installationen är möjlig i allmänhet, men inte genomförbar i en direkt kundrelation, kan du välja att skapa ett B2B-projekt från ordern, som måste hanteras separat.'
						},
						information: {
							heading: 'Föreslå installationsprojekt',
							message: 'Kunden och ABB kommer att informeras om ditt beslut. All relevant information inklusive mer information från kunden vidarebefordras till dig via e-post. Du kan sedan kontakta kunden för att skapa ett separat installationsprojekt.'
						}
					}
				},
				taskOfferCollection: {
					fullPrice: 'Totalt pris (netto)'
				}
			},
			arrangeInstallationAppointment: {
				heading: 'Möte',
				replacementInfo: 'Den installation som ska planeras är en ersättningsinstallation.',
				rescheduleInfo: 'Det möte som ska bokas är en ombokning.',
				contact: {
					heading: 'Kundkontakt'
				},
				notes: {
					heading: 'Anm.',
					placeholder: 'Lägg till anmärkningar'
				},
				button: {
					close: 'Stäng',
					submit: 'Klar'
				}
			},
			basicAnswers: {
				customer: {
					heading: 'Installationsadress'
				},
				questionnaire: {
					heading: 'Svar från kund'
				},
				decision: {
					heading: 'Beslut'
				},
				notes: {
					heading: 'Anm.',
					placeholder: 'Lägg till anmärkningar'
				},
				button: {
					close: 'Stäng',
					submit: 'Klar'
				},
				continueToQuotation: {
					buttonLabel: 'Skapa ett erbjudande'
				}
			},
			gridOperatorDecision: {
				gridOperator: {
					heading: 'Nätoperatör'
				},
				wallboxPower: {
					heading: 'Information om laddboxar',
					hint: 'Kunden har beställt en laddbox med {{wallboxPower}} kW effektförbrukning.'
				},
				documents: {
					heading: 'Dokument'
				},
				decision: {
					heading: 'Beslut',
					accepted: 'Nätoperatören godkände installationen',
					declined: 'Nätoperatören nekade installationen'
				},
				notes: {
					heading: 'Anm.',
					placeholder: 'Lägg till anmärkningar'
				},
				button: {
					close: 'Stäng',
					submit: 'Skicka'
				}
			},
			gridOperatorRegistration: {
				gridOperator: {
					heading: 'Nätoperatör'
				},
				wallboxPower: {
					heading: 'Information om laddboxar',
					hint: 'Kunden har beställt en laddbox med {{wallboxPower}} kW effektförbrukning.'
				},
				documents: {
					heading: 'Dokument'
				},
				registrationRequired: {
					heading: 'Registrering krävs',
					required: 'Ja, en registrering krävs',
					unnecessary: 'Nej, en registrering är inte nödvändig'
				},
				notes: {
					heading: 'Anm.',
					placeholder: 'Lägg till anmärkningar'
				},
				button: {
					close: 'Stäng',
					submit: 'Skicka'
				},
				continueToFeedback: {
					buttonLabel: 'Ange feedback från nätoperatören'
				}
			},
			gridOperatorRevokeRegistration: {
				gridOperator: {
					heading: 'Nätoperatör'
				},
				notes: {
					heading: 'Anm.',
					placeholder: 'Lägg till anmärkningar'
				},
				button: {
					close: 'Stäng',
					submit: 'Skicka'
				}
			},
			preCheck: {
				contact: {
					heading: 'Kundkontakt',
					description: 'Vilken tid på dygnet är kunden hemma?'
				},
				questionnaire: {
					heading: 'Kundsvar från grundläggande frågeformulär'
				},
				decision: {
					heading: 'Beslut'
				},
				notes: {
					heading: 'Anm.',
					placeholder: 'Lägg till anmärkningar'
				},
				button: {
					close: 'Stäng',
					submit: 'Klar'
				}
			},
			quotation: {
				customer: {
					heading: 'Installationsadress'
				},
				questionnaire: {
					heading: 'Kundsvar från grundläggande frågeformulär'
				},
				preCheck: {
					heading: 'Resultat av inledande kontroll: Tid och arbetsinsats för installation'
				},
				comments: {
					heading: 'Ytterligare kommentarer'
				},
				additionalEffort: {
					heading: 'Ytterligare arbetsinsats',
					text: 'För att kunna utföra installationen måste ytterligare arbete utföras utöver standardinstallationen. Ett motsvarande erbjudande kommer att skapas baserat på följande Delta-installationspaket.'
				},
				conditions: {
					heading: 'Erbjudandevillkor för kunden',
					placeholder: 'Under vilka villkor är erbjudandet giltigt?'
				},
				notes: {
					heading: 'Anm.',
					placeholder: 'Lägg till anmärkningar'
				},
				button: {
					close: 'Stäng',
					submit: 'Skicka'
				},
				deltaPackages: {
					fullPrice: 'Totalt pris (netto)'
				}
			},
			installationComplete: {
				contact: {
					heading: 'Kund'
				},
				documents: {
					heading: 'Dokument'
				},
				trades: {
					heading: 'Avtal och möten'
				},
				customerAcceptance: {
					heading: 'Kundens godkännande',
					onSite: 'Kunden har godkänt installationen på plats',
					remote: 'Kunden var inte tillgänglig'
				},
				wallbox: {
					heading: 'Beställd laddbox',
					serial: 'Härmed bekräftar jag att den levererade laddboxen med följande serienummer har installerats och tagits i drift på ett fackmannamässigt sätt: {{serialNumber}}.',
					throttle: 'Laddboxens strömförbrukning reducerades i enlighet med nätoperatörens krav.'
				},
				notes: {
					heading: 'Anm.',
					placeholder: 'Lägg till anmärkningar'
				},
				button: {
					close: 'Stäng',
					submit: 'Skicka'
				}
			}
		},
		taskCollection: {
			heading: 'Uppgifter',
			tableHead: {
				order: 'Uppgift',
				status: 'Status',
				due: 'Förfallodag',
				priority: 'Prioritet',
				responsible: 'Ansvarig'
			},
			action: {
				refresh: 'Uppdatera',
				showMore: 'Visa mer'
			},
			assign: {
				successMessage: 'Uppgiften har tilldelats',
				errorMessage: 'När uppgiften tilldelades uppstod ett fel.'
			},
			emptyState: {
				heading: 'Inga uppgifter hittades',
				message: 'Justera dina filterinställningar för att hitta uppgifter.'
			},
			orderEmptyState: {
				heading: 'Inga uppgifter hittades',
				message: 'Inget behöver göras för denna order.'
			}
		},
		error: {
			generic: {
				heading: 'Något gick fel',
				message: 'Ett oförutsett fel inträffade som inte kunde åtgärdas',
				resolveAction: {
					label: 'Ladda om'
				}
			},
			offline: {
				heading: 'Nätverksanslutningen är inte tillgänglig',
				message: 'Anslut till ett nätverk för att använda applikationen.'
			},
			notFound: {
				heading: 'Hittades inte',
				message: 'Den efterfrågade resursen hittades inte',
				resolveAction: {
					label: 'Översikt'
				}
			},
			orderNotFound: 'Ordern hittades inte.',
			taskNotFound: 'Uppgiften hittades inte.',
			taskTypeNotFound: 'Uppgiftstypen hittades inte: {{type}}',
			taskCompleteMessage: 'Under slutförandet av uppgiften uppstod ett fel.',
			taskAssignMessage: 'När uppgiften tilldelades uppstod ett fel.'
		}
	}
};

export default literals;
