import { Gravatar } from '../../gravatar/Gravatar';

import './TopbarUser.scss';

export type TopbarUserProps = {
	name?: string,
	mailAddress?: string
};

export function TopbarUser(props: TopbarUserProps) {

	const { name, mailAddress } = props;

	const renderName = () => {
		if ((name ?? null) === null) {
			return null;
		}
		return (<div className="topbar-user__name">{name}</div>);
	};

	return (
		<div className="topbar-user">
			{renderName()}
			<Gravatar name={name} mailAddress={mailAddress} />
		</div>
	);

}
