import { InstallerAppointmentModel } from '@abb-emobility/oms/domain-model';
import { DataInterceptEmptyProps } from '@abb-emobility/shared/data-provider-foundation';

import { useOrderInstallerAppointmentCollectionData } from './OrderInstallerAppointmentCollectionDataConsumer';

export function OrderInstallerAppointmentCollectionDataInterceptEmpty(props: DataInterceptEmptyProps<InstallerAppointmentModel>) {
	const { children, emptyStateComponent, filter } = props;

	const orderInstallerAppointmentCollectionData = useOrderInstallerAppointmentCollectionData();
	const hasResults = orderInstallerAppointmentCollectionData.hasResults(filter);

	if (!hasResults) {
		return (
			<>
				{emptyStateComponent()}
			</>
		);
	}

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{children}
		</>
	);
}
