import React, { ReactNode } from 'react';

import './CollectionItemWrapper.scss';

export type CollectionItemWrapperProps = {
	children: ReactNode
};

export function CollectionItemWrapper(props: CollectionItemWrapperProps) {

	const { children } = props;

	return (
		<div className="collection-item-wrapper">
			{children}
		</div>
	);
}
