import { useContext } from 'react';

import { TaskCollectionDataProviderValue } from '@abb-emobility/shared/data-provider-foundation';
import { AnyTaskCollectionItemModel } from '@abb-emobility/usertask/domain-model';

import { orderTaskCollectionData } from './OrderTaskCollectionData';

export const useOrderTaskCollectionData = (): TaskCollectionDataProviderValue<AnyTaskCollectionItemModel> => {
	return useContext(orderTaskCollectionData);
};
