import { ReactNode } from 'react';

import { buildCssClassStringFromClassMap } from '@abb-emobility/shared/util';

import './ActionModalContent.scss';

export type ActionModalContentProps = {
	wide?: boolean
	children?: ReactNode
};

export function ActionModalContent(props: ActionModalContentProps) {

	const { wide = false, children } = props;

	const classMap = {
		'action-modal-content': true,
		'action-modal-content--wide': wide ?? false
	};

	return (
		<section className={buildCssClassStringFromClassMap(classMap)}>
			{children}
		</section>
	);
}
