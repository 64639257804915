import React, { ReactNode } from 'react';

import { useAuth } from '@abb-emobility/shared/auth-provider';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import {
	CollectionItemWrapper,
	IconIdentifier,
	Separator,
	useModalDialogueManager
} from '@abb-emobility/shared/ui-primitive';
import { useTaskCollectionDataProviderProvider } from '@abb-emobility/usertask/data-provider';
import { AnyTaskCollectionItemModel, taskIsCompleted } from '@abb-emobility/usertask/domain-model';

import { TaskCollectionItemHeader } from './TaskCollectionItemHeader';
import { TaskModalAssembler } from '../task-modal/TaskModalAssembler';

import './TaskCollectionItem.scss';

type ItemProps = {
	task: AnyTaskCollectionItemModel
};

export function TaskCollectionItem(props: ItemProps) {

	const { task } = props;

	const auth = useAuth();
	const modalDialogueManager = useModalDialogueManager();
	const taskCollectionData = useTaskCollectionDataProviderProvider().getTaskCollectionDataProvider();

	const handleCandidateGroupsSelect = (candidateGroupIds: Array<ModelPrimaryKey>): void => {
		if (auth.hasRole('installation-partner')) {
			taskCollectionData.assign(task, candidateGroupIds);
		}
	};

	const handleTaskOpen = (): void => {
		modalDialogueManager.push({
			content: (): ReactNode => {
				return (
					<TaskModalAssembler taskId={task.id} />
				);
			}
		});
	};

	return (
		<CollectionItemWrapper>
			<TaskCollectionItemHeader
				task={task}
				onAssign={handleCandidateGroupsSelect}
				onClick={handleTaskOpen}
				disabled={taskIsCompleted(task.status)}
				icon={taskIsCompleted(task.status) ? IconIdentifier.CHECK : IconIdentifier.COPY_SIMPLE}
			/>
			<Separator />
		</CollectionItemWrapper>
	);
}
